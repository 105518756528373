import React from "react";
import UserSidbar from "../components/UserSidbar";
import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { UserModal } from "../schemas";
import { toast } from "react-toastify";
import qs from 'qs';
import UserPasswordChange from "../components/UserPasswordChange";
import { baseURL } from "../config/apiUrl";
import { Helmet } from "react-helmet-async";

const MyProfile = () => {


  const { token } = useSelector((state) => state.auth);
  const [myUser, setmyUser] = useState([]);
const [myAddress, setMyAddress] = useState()
  // -----------User Get API-Start-----------
  const UserApi = async() => {
    var config = {
      method: "get",
      url: baseURL + "/user",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios(config)
      .then(function (response) {
        setmyUser(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchAddress = async() => {
      var data = new FormData();
      data.append("email", myUser.email);

      var config = {
        method: "post",
        url: baseURL + "/get_details",
        data: data,
      };

      await axios(config)
        .then(function (response) {
          setMyAddress(response.data.data.user_details);
        })
        .catch(function (error) {
          console.log(error);
        });
  };
  const initialValues = {
    name: myUser.name || "",
  };
  // -----------User Get API-End-----------

  // -----------User Post API-Start-----------
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: UserModal,
    onSubmit: async (values, action) => {
      
      var data = qs.stringify({
        'name': values.name,
      });

      
      var config = {
        method: "put",
        url: baseURL + `/user/${myUser.id}`,
        headers: {
          Authorization:
          `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };

      await axios(config)
        .then(function (response) {
          toast.success(response.data.message);

          UserApi();
        })
        .catch(function (error) {
          console.log(error);
        });

      // action.resetForm();
    },
  });
  // -----------User Post API-End-----------

  useEffect(() => {
    UserApi();
  // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if(myUser.id){
      fetchAddress()
    }
  // eslint-disable-next-line
  }, [myUser.id]);


  return (
    <div>
                    <Helmet>
                    <title>Makfuels | My-Profile</title>
        <link rel="canonical" href="https://makcom.cf/myProfile" />
      </Helmet>
      <section className="container-fluid mt-3">
        <div className="row">
          <UserSidbar/>
          <div className="col-md-12 col-lg-9 mb-2">
            <div
              className="p-3 mb-3"
              style={{
                boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
                borderRadius: "8px",
              }}
            >
              <h4 className="ms-2 mb-3">My Profile</h4>
              <div className="col-md-11">
                <table className="table table-borderless table_width">
                  <thead>
                    <tr>
                       <th scope="col" colspan="2">Address #1 - default shipping address</th> 
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Name</th>
                      <td>{myUser.name}</td>
                    </tr>
                    <tr>
                      <th scope="row">Email</th>
                      <td>{myUser.email}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="d-flex">
                  <button
                    className="btn btn-secondary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Edit Details
                  </button>
                  <button                     data-bs-toggle="modal"
                    data-bs-target="#changePassModal" className="btn check_out_btn ms-3">
                    Change Password
                  </button>
                </div>
              </div>
              <div className="mt-4">
                <table className="table  table_width">
                  <tr>
                      <h1 style={{fontSize:"1.2rem"}}>Address #1 - default shipping address</h1>
                  </tr>

                  {/* <thead>
                    <tr>
                      <th scope="col" colSpan={2}>
                        <h1 style={{fontSize:"1.2rem"}}>Address #1 - default shipping address</h1>
                      </th>
                    </tr>
                  </thead> */}
                  <tbody>
                   {/* <tr><td> {myAddress===null && "No Address added"}</td></tr> */}
                   {(myAddress===null && "No Address added")?<tr><td> {myAddress===null && "No Address added"}</td></tr>: ""}
                    <tr>
                      <th scope="row">Name :</th>
                      <td>{myUser?.name}</td>
                    </tr>
                    <tr>
                      <th scope="row">Address :</th>
                      <td>
                       {myAddress?.billing_details}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Postal code :</th>
                      <td>{myAddress?.zipcode}</td>
                    </tr>
                    <tr>
                      <th scope="row">Phone number :</th>
                      <td>{myAddress?.phone_number}</td>
                    </tr>
                    {/* <tr>
                      <th scope="row">Country</th>
                      <td>Pakistan</td>
                    </tr> */}
                    <tr>
                      <th scope="row">State/Province :</th>
                      <td>{myAddress?.state}</td>
                    </tr>
                    <tr>
                      <th scope="row">City :</th>
                      <td>{myAddress?.city}</td>
                    </tr>
                  </tbody>
                </table>
                {/* <div className="d-flex">
                  <button className="btn btn-secondary">Edit Address</button>
                  <button className="btn check_out_btn ms-3">
                    Delete Address
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ----Profile--modal-start--- */}
      {/* Button trigger modal */}
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Customer Details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body">
                <div className="p-1">
                    <div className="row">
                      <div className="col-md-12 mb-2">
                        <label htmlFor="name" className="form-label">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          autoComplete="off"
                          name="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                        />
                        {errors.name && touched.name ? (
                          <p className="form-error">{errors.name}</p>
                        ) : null}
                      </div>
                    </div>
                  
                </div>
              </div>
              <div className="modal-footer">

                {errors.name ? <button
                  type="submit"
                  className="btn check_out_btn"
                >
                  Save
                </button>:<button
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  type="submit"
                  className="btn check_out_btn"
                >
                  Save
                </button>}
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* ----Profile--modal-end--- */}

            {/* ----Profile-Password-modal-start--- */}
                  <UserPasswordChange></UserPasswordChange>
      {/* ----Profile-Password-modal-end--- */}
    </div>
  );
};

export default MyProfile;
