import React from 'react'
import { Link } from 'react-router-dom'
import confuse from '../assets/images/confuse.png'
import { Helmet } from 'react-helmet-async'

function NotFound() {
  return (
    <>
                  <Helmet>
                  <title>Makfuels | Not-Found</title>
        <link rel="canonical" href="https://makcom.cf/s" />
      </Helmet>
        <section className="four_zero_four">
    <div>
      <img src={confuse} alt="" />
    </div> 
    <div className="four_content">
      <h1>4 0 4</h1>
      <h5>Page not found</h5>
      <div className="d-grid gap-2">
        <Link to="/"><button className="btn mt-2 check_out_btn">Go To Home</button></Link>
      </div>
    </div>
  </section>
    </>

  )
}

export default NotFound
