import React, { useState } from "react";
import {  Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { baseURL } from "../../config/apiUrl";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import deleteimg from "../../assets/images/delete.png"


function CardDeleteModal({ toggle, modal,deleteID,fetchPaymentCart }) {
    const { token } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);

    const deletePaymentCart = () => {
        setLoading(true)
        let config = {
          method: "get",
          url: baseURL + `/deleteCardDetails/${deleteID}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        axios(config)
          .then(function (response) {
            toast.success(response.data.message);
            fetchPaymentCart()
            setLoading(false)
            toggle()
          })
          .catch(function (error) {
            console.log(error);
          });
      };

  return (
    <div>
      <Modal
        className="product_detals_modal"
        isOpen={modal}
        toggle={toggle}
        centered={true}
      >
        <ModalHeader toggle={toggle}>Delete Card</ModalHeader>
        <ModalBody>
        <div className="text-center">
          <img height={50} width={50} src={deleteimg} alt="questionMark" />
            <h5 className="mt-2">Are you sure ? <br/>You want to delete Payment Card</h5>
          </div>
        
                {/* <h4>Are you sure ? You want to delete Payment Card</h4> */}
                {/* <button type="button" className="delivery_btn mt-2 ms-2" onClick={toggle}>NO</button> */}
                {/* <button type="button" className="delivery_btn mt-2 ms-2">YES</button> */}


                {/* <button
                className="delivery_btn mt-2 ms-2"
                disabled={loading ? true : false}
                onClick={deletePaymentCart}
                      >
                        {loading ? (
                          <div
                            className="spinner-border text-secondary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          "YES"
                        )}
                      </button> */}

        </ModalBody>
        <ModalFooter>

        <button className="btn check_out_btn" onClick={toggle}>NO</button>
        <button
                className="btn check_out_btn"
                disabled={loading ? true : false}
                onClick={deletePaymentCart}
                      >
                        {loading ? (
                          <div
                            className="spinner-border"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          "YES"
                        )}
                      </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default CardDeleteModal;
