import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  myOrderVal: {},
  allMyOrderList:{},
  selectedMyOrderId: ""
}

export const myOrderSlice = createSlice({
  name: 'mySingleOrders',
  initialState,
  reducers: {      
      getAllOrders: (state, action) => {
        state.allMyOrderList = action.payload
    },
    getSelectedOrderId: (state, action) => {
      state.selectedMyOrderId = action.payload
  },


  },
})

// Action creators are generated for each case reducer function
export const {  getAllOrders , getSelectedOrderId } = myOrderSlice.actions

export default myOrderSlice.reducer