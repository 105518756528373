import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../../config/apiUrl";
import { addToCart } from "../../redux/features/slices/cartSlice";
import productImg from "../../assets/images/grocery_product.jpg";
import { Modal, Spinner } from "reactstrap";
import VerifyAgeModal from "./VerifyAgeModal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import RelatedProductSlider from "../slider/RelatedProductSlider";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { useNavigate, Link } from "react-router-dom";
import ModalCartShow from "../ModalCartShow";

const GroceryProductModalPos = ({
  productValue,
  prod_id,
  show,
  seoTags,
  catName,
  handleClose,
  search,
}) => {
  const dispatch = useDispatch();
  let [num, setNum] = useState(1);
  const [addons, setAddons] = useState([]);
  // eslint-disable-next-line
  const [relatedProduct, setRelatedProduct] = useState([]);
  const [prodID, setprodID] = useState();
  const [products, setProducts] = useState(productValue);
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [quantities, setQuantities] = useState({});
  const [modal, setModal] = useState(false);
  const [state, setState] = useState();
  const [product, setProduct] = useState({});
  const [addValue, setaddValue] = useState({});
  const [selectedSubproduct, setSelectedSubproduct] = useState({});
  const { cartItems, cartTotalQuantity } = useSelector((state) => state.cart);

  const navigate = useNavigate();

  useEffect(() => {
    setProducts(productValue);
  }, [productValue]);

  const handleIncrement = (id) => {
    const updatedQuantities = { ...quantities };
    const currentQuantity = updatedQuantities[id] || 1;
    if (currentQuantity < 20) {
      updatedQuantities[id] = currentQuantity + 1;
      setQuantities(updatedQuantities);
    }
  };

  const handleDecrement = (id) => {
    const updatedQuantities = { ...quantities };
    const currentQuantity = updatedQuantities[id] || 1;
    if (currentQuantity > 1) {
      updatedQuantities[id] = currentQuantity - 1;
      setQuantities(updatedQuantities);
    }
  };

  const handleChange = (e, id) => {
    const updatedQuantities = { ...quantities };
    updatedQuantities[id] = parseInt(e.target.value, 10) || 1;
    setQuantities(updatedQuantities);
  };
  console.log(quantities);

  let handleToggle = () => {
    setModal(!modal);
  };

  const handleAddToCart = (val, product, num) => {
    console.log(val);

    setProduct(product);
    setaddValue(val);
    setNum(num);
    // const subItems = {
    //   id: val.id,
    //   name: val.name,
    //   price: val.price,
    // };
    // setSelectedSubproduct(subItems);
    if (product.verification === "1" && state === "yes") {
      let data = {
        ...val,
        num: quantities[val.id] || 1,
        selectedSubproduct: {
          id: val.id,
          name: val.name,
          price: val.price,
        },
      };
      dispatch(addToCart(data));
      setprodID();
    } else if (product.verification === "0") {
      let data = {
        ...val,
        num: quantities[val.id] || 1,
        selectedSubproduct: {
          id: val.id,
          name: val.name,
          price: val.price,
        },
      };
      dispatch(addToCart(data));
    } else {
      handleToggle();
    }
  };

  useMemo(() => {
    if (state === "yes") {
      handleAddToCart(addValue, product, num);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleClickPro = (val) => {
    setprodID(val.id);
    setProducts(val);
    setNum(1);
  };

  const fetchAddons = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}/getSubProductByProductId/${
          prodID === undefined ? prod_id : prodID
        }`
      );
      setAddons(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchRelatedProduct = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}/getRelatedProducts/${
          prodID === undefined ? prod_id : prodID
        }`
      );
      setRelatedProduct(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useMemo(() => {
    if (prodID) {
      fetchAddons();
      fetchRelatedProduct();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prodID]);

  useMemo(() => {
    if (prod_id) {
      fetchAddons();
      fetchRelatedProduct();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prod_id]);

  const handleCloseModal = () => {
    handleClose();
    setState();
    setprodID();
    setQuantities({});
    if (search === undefined) {
      navigate("/pos-system");
    }
  };
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  return (
    <>
      {
        products && (
          <Modal isOpen={show} size="xl">
            <div className="modal-content product_detals_modal">
              <div className="modal-header">
                <div className="back_btn">
                  <span onClick={handleCloseModal}>
                    <i className="fa-solid fa-chevron-left" />
                    Back
                  </span>
                </div>
                <div className="close__icon__modal d-flex align-items-center">
                  <div className="me-3 position-relative">
                    <span className="counter_span__">{cartTotalQuantity}</span>
                    <i
                      class="fa-solid fa-cart-shopping add_ons_cart_btn"
                      onClick={handleClick}
                    />
                    <div
                      className={`main_modal_card_div ${
                        isClicked ? "active" : ""
                      }`}
                    >
                      <div>
                        <ModalCartShow />
                      </div>
                    </div>
                  </div>
                  <div>
                    <i
                      onClick={handleCloseModal}
                      className="fa-sharp fa-solid fa-square-xmark"
                    />
                  </div>
                </div>
              </div>

              <Breadcrumb
                className="pt-3 px-3"
                style={{ fontSize: "small" }}
                onClick={handleCloseModal}
              >
                <BreadcrumbItem style={{ color: "blue" }}>
                  <Link to="/">Home</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>{seoTags}</BreadcrumbItem>
              </Breadcrumb>

              <div className="modal-body pt-0 pb-0">
                <h1 className="product_name hide_detalis ">{products.name}</h1>
                <div className="row">
                  {/* {products?.description !== null  ? (
                      <p className="hide_details hide_detalis2">
                        <hr />

                        <div
                          className="pt-1 pb-2 mb-3 description_para hide_details_headings"
                          dangerouslySetInnerHTML={{
                            __html: products?.description,
                          }}
                        />
                        <hr />
                      </p>
                    ) : (
                      ""
                    )} */}
                  <div className="col-md-3">
                    <div className="product__information">
                      <LazyLoadImage
                        src={products.image}
                        loading="lazy"
                        className="pro__imG__"
                        alt={products.name}
                        title={products.name}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = productImg;
                        }}
                      />
                      <div className="d-flex justify-content-between align-items-baseline my-2">
                        <h3 className="heading_h3">{products.name}</h3>
                      </div>
                      {/* {products?.description !== null  ? (
                      <p className="show_details">
                        <hr />

                        <div
                          className="pt-1 pb-2 mb-3 description_para "
                          dangerouslySetInnerHTML={{
                            __html: products?.description,
                          }}
                        />
                        <hr />
                      </p>
                    ) : (
                      ""
                    )} */}
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="add__onns_main">
                      {/* <h6 className="mb-3">Please Select Varient</h6> */}
                      {loading && (
                        <Spinner color="warning" type="grow"></Spinner>
                      )}
                      <div className="row">
                        {!loading &&
                          addons &&
                          addons.map((val, index) => (
                            <div className="col-md-4 mb-2" key={index}>
                              <div>
                                <div className="top_main_GroCe position-relative">
                                  {cartItems.map(
                                    (curr, ind) =>
                                      curr.id === val.id && (
                                        <span className="cart_quantityy_gc">
                                          {curr.cartQuantity}
                                        </span>
                                      )
                                  )}

                                  <div>
                                    <LazyLoadImage
                                      className="grocecery_card_img"
                                      src={val.image}
                                      loading="lazy"
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = products.image;
                                      }}
                                      alt={val.name}
                                      title={val.name}
                                    />
                                  </div>
                                  <div>
                                    <div className="text-center px-2">
                                      <p>{val.name}</p>
                                    </div>
                                    <div>
                                      <div className="cart__Box_M my-1 d-flex justify-content-between align-items-center px-2">
                                        <div className="cart__box">
                                          <div
                                            className="qty qty-minus"
                                            onClick={() =>
                                              handleDecrement(val.id)
                                            }
                                          >
                                            -
                                          </div>
                                          <input
                                            className="qty"
                                            type="number"
                                            value={quantities[val.id] || 1}
                                            onChange={(e) =>
                                              handleChange(e, val.id)
                                            }
                                            disabled
                                          />
                                          <div
                                            className="qty qty-plus"
                                            onClick={() =>
                                              handleIncrement(val.id)
                                            }
                                          >
                                            +
                                          </div>
                                        </div>
                                        {/* ///////////////////////////////////// */}
                                        {val.out_of_stock == 1 && (
                                          <p className="grocecery_card_p text-danger">
                                            <span>
                                              <span className="text-danger">
                                                Out Of Stock &nbsp;
                                              </span>
                                            </span>
                                          </p>
                                        )}
                                        {/* ///////////////////////////////////// */}

                                        {val.out_of_stock == 0 && (
                                          <p className="grocecery_card_p">
                                            {val.discount_price === null &&
                                              `$ ${val.price}`}
                                            {val.discount_price !== null && (
                                              <span>
                                                <del className="me-4 text-danger">
                                                  $ {val.price}
                                                </del>
                                                <span>
                                                  ${val.discount_price}&nbsp;
                                                  &nbsp;
                                                </span>
                                              </span>
                                            )}
                                          </p>
                                        )}
                                      </div>

                                      <div>
                                        <button
                                          className="grocecery_card_ATC"
                                          disabled={(val.out_of_stock == 1)?true:false}
                                          onClick={() =>
                                            handleAddToCart(val, products, num)
                                          }
                                        >
                                          Add To Cart
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      {/* {!loading &&
                        addons &&
                        addons.map((val, index) => (
                          <label className="modal_label_" key={index}>
                            <div className="d-flex justify-content-between align-items-center add__onns py-1 px-2 mb-2 position-relative">
                              {val.discount_price !== null && (
                                <span className="addon_sale_tag">Sale</span>
                              )}
                              <span
                                style={{ width: "150px" }}
                                className="d-flex align-items-center"
                              >
                                <img
                                  className="addons_img me-3"
                                  src={val.image}
                                  alt="product"
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = products.image;
                                  }}
                                />{" "}
                                <span>{val.name}</span>
                              </span>
                              <div className="d-flex align-items-center me-3">
                                <div className="cart__box">
                                  <div
                                    className="qty qty-minus"
                                    onClick={() => handleDecrement(val.id)}
                                  >
                                    -
                                  </div>
                                  <input
                                    className="qty"
                                    type="number"
                                    value={quantities[val.id] || 1}
                                    onChange={(e) => handleChange(e, val.id)}
                                    disabled
                                  />
                                  <div
                                    className="qty qty-plus"
                                    onClick={() => handleIncrement(val.id)}
                                  >
                                    +
                                  </div>
                                </div>
                              </div>
                              <p>
                                {val.discount_price === null &&
                                  `$ ${val.price}`}{" "}
                                &nbsp; &nbsp;
                                {val.discount_price !== null && (
                                  <span>
                                    <del className="me-4 text-danger">
                                      $ {val.price}
                                    </del>
                                    <span>
                                      ${val.discount_price}&nbsp; &nbsp;
                                    </span>
                                  </span>
                                )}
                                <i
                                  className="fa-solid fa-cart-shopping add_ons_cart_btn"
                                  onClick={() =>
                                    handleAddToCart(val, products, num)
                                  }
                                ></i>
                              </p>
                            </div>
                          </label>
                        ))} */}

                      {!loading && addons.length === 0
                        ? "No Varient Added"
                        : ""}
                    </div>
                  </div>
                </div>
                <RelatedProductSlider
                  loading={loading}
                  relatedProduct={relatedProduct}
                  handleClickPro={handleClickPro}
                />
              </div>
            </div>
          </Modal>
        )
        // </div>
      }
      <VerifyAgeModal
        modal={modal}
        handleToggle={handleToggle}
        setState={setState}
      />
    </>
  );
};

export default GroceryProductModalPos;
