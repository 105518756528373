import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Search_banner from "../assets/images/search_banner.jpg";
import { getTotals } from "../redux/features/slices/cartSlice";
import { useLocation } from "react-router-dom";
import { baseURL } from "../config/apiUrl";
import ProductModal from "../components/modal/ProductModal";
import GroceryProductModal from "../components/modal/GroceryProductModal";
import RightSideCart from "../components/RightSideCart";
import notfound from "../assets/images/not_found.png";
import Pagination from "@material-ui/lab/Pagination";
import axios from "axios";
import Products from "../components/productsCard/Products";
import { Helmet } from "react-helmet-async";

const SearchHome = () => {
  const cart = useSelector((state) => state.cart);
  const [mainID, setMainID] = useState();
  const { state } = useLocation();
  const [productValue, setProductValue] = useState();
  const [products, setProducts] = useState();
  const [prod_id, setProd_id] = useState();
  const [count, setcount] = useState(1);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);

  // const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const productID = (value) => {
    setProductValue(value);
    setProd_id(value.id);
    setMainID(value.main_cat_id);
    handleShow();
  };
  useEffect(() => {
    setProducts(state.product.data);
    setcount(state.product.last_page);
    setPage(1);
  }, [state]);

  const handleChange = (e, p) => {
    setPage(p);
  };

  const handleClick = async () => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("keywords", state.value);

    var config = {
      method: "post",
      url: baseURL + `/search?page=${page}`,
      // headers: {
      //   ...data.getHeaders()
      // },
      data: data,
    };

    await axios(config)
      .then(function (response) {
        const { data, last_page } = response.data.data;
        setProducts(data);
        setcount(last_page);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (page > 1) {
      handleClick();

    }
  // eslint-disable-next-line
  }, [page]);
  return (
    <>
                  <Helmet>
                  <title>Makfuels | Search</title>
        <link rel="canonical" href="https://makcom.cf/search" />
      </Helmet>
      <section className="container-fluid px-sm-5 px-2 mt-2">
        <div className="row">
          <div className="col-lg-9 col-md-8">
            <div className="__banner mt-3 position-relative d-flex align-items-center">
              <div className="position-absolute banner_text">
                <h6>Search</h6>
                <h4>{state.value}</h4>
              </div>
              <img src={Search_banner} className="d-block w-100" alt="..." />
            </div>

            {/* --------cards-start------- */}
            <section className="mt-3">
              <div>
                <h4 className="mb-3">Products</h4>
              </div>
              {products && products.length === 0 && (
                <div className="text-center">
                  <img
                    className=""
                    style={{ width: "100px" }}
                    src={notfound}
                    alt="empty-cart"
                  ></img>

                  <h3>Product Not Found</h3>
                  {/* <h5 className="mb-2">Add something to make me happy</h5> */}
                </div>
              )}
              <Products products={products} productID={productID} />
            </section>
            {products&&products.length>0 && (
              <div className="mt-4 mb-4">
                <Pagination
                  count={count}
                  page={page}
                  onChange={handleChange}
                  color="primary"
                />
              </div>
              )}
            {/* --------cards-end------- */}
          </div>
          {/* -------Cart-Start--------- */}
          <div className="col-md-4 col-lg-3 d-none d-lg-block d-md-block">
            <RightSideCart />
          </div>
          {/* -------Cart-end--------- */}
        </div>
 
      </section>

      <>
        {mainID === "1" && (
          <ProductModal
            productValue={productValue}
            prod_id={prod_id}
            show={show}
            handleClose={handleClose}
            handleShow={handleShow}
            search={state.value}
          />
        )}
        {mainID !== "1" && (
          <GroceryProductModal
            productValue={productValue}
            prod_id={prod_id}
            show={show}
            handleClose={handleClose}
            handleShow={handleShow}
            search={state.value}
          />
        )}
      </>
    </>
  );
};

export default SearchHome;
