import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cartimg from "../assets/images/carts.png";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import notfound from "../assets/images/not_found.png";
import { getTotals } from "../redux/features/slices/cartSlice";
import { Link, useNavigate } from "react-router-dom";
import { getProductId, getFoodId } from "../redux/features/slices/productSlice";
import axios from "axios";
import { baseURL } from "../config/apiUrl";
import ProductModal from "../components/modal/ProductModal";
import RightSideCart from "../components/RightSideCart";
import { LazyLoadImage } from "react-lazy-load-image-component";
import GroceryProductModal from "../components/modal/GroceryProductModal";
import Banner from "../components/slider/Banner";
import Categories from "../components/slider/Categories";
import Products from "../components/productsCard/Products";
import Pagination from "@material-ui/lab/Pagination";
import { Helmet } from "react-helmet-async";
import QRmodal from "../components/modal/QRmodal";

const Home = () => {
  const { cartTotalQuantity } = useSelector((state) => state.cart);
  const cart = useSelector((state) => state.cart);

  const [loading, setLoading] = useState(false);
  const [catName, setCatName] = useState();
  const [midCategory, setMidCategory] = useState([]);
  const [products, setProducts] = useState([]);
  const [prod_id, setProd_id] = useState();
  const [popular, setPopular] = useState();
  const [cartVisible, setcartVisible] = useState(false);
  const [cat_id, setCat_id] = useState();
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);
  const [mainID, setMainID] = useState();
  const [productValue, setProductValue] = useState();
  const [popCat, setPopCat] = useState(true);
  const [foodCategory, setFoodCategory] = useState([]);
  const { token } = useSelector((state) => state.auth);
  const [saleProduct, setSaleProduct] = useState([]);
  const [previousOrder, setPreviousOrder] = useState([]);
  const [show, setShow] = useState(false);
  const [seoTags, setSeoTags] = useState();
  const [seoTagsData, setSeoTagsData] = useState([]);
  const [url, setUrl] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const foodId = useSelector((state) => state.productCategory.foodId);
  const productId = useSelector((state) => state.productCategory.productID);

  const fetchFoodCategory = async () => {
    var config = {
      method: "get",
      url: baseURL + `/getMidCategories/1`,
      headers: {},
    };
    // setLoader(true)
    // setLoading(true);
    await axios(config)
      .then(function (response) {
        const { midCategory, products } = response.data.data;
        setFoodCategory(midCategory);
        // setLoading(false);
        // setLoader(false)
      })
      .catch(function (error) {
        console.log(error);
        // setLoading(false);

        // setLoader(false)
      });
  };

  useMemo(() => {
    fetchFoodCategory();
  }, []);

  const handleChange = (e, p) => {
    setPage(p);
    const element = document.getElementById("products_id");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const listenToScroll = () => {
    let heightToHide = 100;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > heightToHide) {
      setcartVisible(true);
    } else {
      setcartVisible(false);
    }
  };
  useEffect(() => {
    dispatch(getTotals());
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, [cart, dispatch]);

  const handleMidCategory = () => {
    setPopCat(false);
    setPage(page !== 1 ? 1 : page);
    setCat_id();
    setCatName();
    setPopular();
    fetchMidCategory();
    navigate("/");
  };

  useEffect(()=>{
    setUrl(window.location.href)
    console.log("url" ,window.location.href);
  },[productId.id, cat_id, productValue , foodId])

  const fetchMidCategory = async () => {
    var config = {
      method: "get",
      url: baseURL + `/getMidCategories/0?page=${page}`,
      headers: {},
    };
    // setLoader(true)
    setLoading(true);
    await axios(config)
      .then(function (response) {
        const { midCategory, products } = response.data.data;
        setCount(products.last_page);
        setMidCategory(midCategory);
        setProducts(products.data);
        setLoading(false);
        // setLoader(false)
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);

        // setLoader(false)
      });
  };
  const fetchPopularCategory = async () => {
    try {
      setLoading(true);

      const response = await axios.get(
        `${baseURL}/getPopularCategory/0?page=${page}`
      );
      const { midCategory, products } = response.data.data;
      setCount(products.last_page);
      setMidCategory(midCategory);
      setProducts(products.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPopularItems = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}/getPopularProducts/0?page=${page}`
      );
      const { data, last_page } = response.data.data;
      setProducts(data);
      setCount(last_page);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchSaleProduct = async () => {
    try {
      const response = await axios.get(baseURL + "/getSaleProducts");
      const { data } = response.data;
      setSaleProduct(data);
    } catch (error) {
      console.error(error);
    }
  };

  useMemo(() => fetchSaleProduct(), []);

  const getCatID = (id, name, slug) => {
    const element = document.getElementById("products_id");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setCat_id(id);
    setCatName(name);
    setPopular();
    setPage(page !== 1 ? 1 : page);
    setSeoTags(slug);
    navigate(`/${slug}`, { replace: true });
  };

  const productID = (value) => {
    setProductValue(value);
    setProd_id(value.id);
    setMainID(value.main_cat_id);
    handleShow();
    setSeoTags(value.slug);
    navigate(`/${value.slug}`, { replace: true });
  };


  /////////////////////////////////
  /////////////////////////////////
  /////////////////////////////////
  useMemo(() => {
    setCat_id(productId.id);
    setCatName(productId.name);
    setPopular();
    setPage(page !== 1 ? 1 : page);
    setSeoTags(productId.slug);
    navigate(`/${productId.slug}`, { replace: true });
  }, [productId.id, productId.name, productId.slug]);

  useMemo(() => {
    setCat_id(foodId.id);
    setCatName(foodId.name);
    setPopular();
    setPage(page !== 1 ? 1 : page);
    setSeoTags(foodId.slug);
    navigate(`/${foodId.slug}`, { replace: true });
  }, [foodId.id, foodId.name, foodId.slug]);

  //////////////////////////////////
  //////////////////////////////////
  //////////////////////////////////

  useMemo(() => {
    const fetchSubCategory = async () => {
      var config = {
        method: "get",
        url: baseURL + `/getSubCategories/${cat_id}?page=${page}`,
        headers: {},
      };
      setLoading(true);
      await axios(config)
        .then(function (response) {
          const { products } = response.data.data;
          setCount(products.last_page);

          // setSubCategory(subCategory);
          setProducts(products.data);
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
        });
    };
    if (cat_id) {
      fetchSubCategory();
    }
  }, [cat_id, page]);

  useMemo(() => {
    if (!popular && !cat_id && popCat === true) {
      fetchPopularCategory();
      setPopCat(true);
      navigate("/");
    }
    // eslint-disable-next-line
  }, [page]);

  useMemo(() => {
    if (popular) {
      fetchPopularItems();
    }
    // eslint-disable-next-line
  }, [popular, page]);

  useMemo(() => {
    if (!cat_id && !popular && popCat === false) {
      fetchMidCategory();
    }
    // eslint-disable-next-line
  }, [page]);

  const fetchPreviousOrder = async () => {
    setLoading(true);
    try {
      const config = {
        method: "get",
        url: `${baseURL}/getPreviousOrder`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios(config);
      const { data } = response.data;
      setPreviousOrder(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      fetchPreviousOrder();
    }
    // eslint-disable-next-line
  }, []);

  const fetchSeoTags = async () => {
    try {
      const response = await axios.get(`${baseURL}/metatagToShow/${seoTags}`);
      const { data } = response.data;
      setSeoTagsData(data);
      console.log("seotagdata" , data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {

      fetchSeoTags();

    // eslint-disable-next-line
  }, [seoTags]);

  const setFoodId = (val) => {
    dispatch(getFoodId(val));

    const element = document.getElementById("products_id");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const setProductId = (val) => {
    dispatch(getProductId(val));

    const element = document.getElementById("products_id");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Helmet>
        {seoTagsData?.length === 0 ? (
          <title>Makfuels | Shop</title>
        ) : (
          seoTagsData?.map((value, index) => {
            const { name, content } = value;
            if (name.toLowerCase() === "title") {
              return <title key={index}>Makfuels | Shop - {content}</title>;
            } else {
              return (
                <title key={index}>
                  Makfuels | Shop {seoTags ? `- ${seoTags}` : ""}
                </title>
              );
            }
          })
        )}
        {seoTagsData?.map((value, index) => {
          const { name } = value;
          if (name.toLowerCase() === "description") {
            return (
              <meta name={value.name} content={value.content} key={index} />
            )
          }
          return null
        })}

        {seoTagsData.length !== 0 ? "":<meta name="descriptiom" content="Fuel up at Makfuels, your Missouri gas station. Discover affordable prices 24/7 on food, cigars, cigarettes, liquor, beer, soda, energy drinks, and more. Visit us for convenience on every journe" />}

        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={url} />
        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://makfuels.com" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Makfuels A Gas Sation" />
        <meta
          property="og:description"
          content="Fuel up at Makfuels, your Missouri gas station. Discover affordable prices on food, cigars, cigarettes, liquor, beer, soda, energy drinks, and more."
        />
        <meta
          property="og:image"
          content="https://makfuels.com/static/media/logo.4ed5bcbedcce5108870c.jpg"
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://makfuels.com" />
        <meta name="twitter:title" content="Makfuels A Gas Sation" />
        <meta
          name="twitter:description"
          content="Fuel up at Makfuels, your Missouri gas station. Discover affordable prices on food, cigars, cigarettes, liquor, beer, soda, energy drinks, and more."
        />
        <meta
          name="twitter:image"
          content="https://makfuels.com/static/media/logo.4ed5bcbedcce5108870c.jpg"
        />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{
            "@context": "http://www.schema.org",
            "@type": "Corporation",
            "name": "Makfuels",
            "url": "https://Makfuels.com",
            "sameAs": [
            "https://www.facebook.com/makfuelsofficial/",
            "https://twitter.com/",
            "https://www.instagram.com/",           
          "https://www.linkedin.com/company/",
            "https://www.pinterest.com/"
            ],
            "logo": "https://makfuels.com/static/media/logo.4ed5bcbedcce5108870c.jpg ",
            "image": "https://makfuels.com/static/media/logo.4ed5bcbedcce5108870c.jpg",
            "description": "Fuel up at Makfuels, your Missouri gas station. Discover affordable prices on food, cigars, cigarettes, liquor, beer, soda, energy drinks, and more.",
            "address": {
            "@type": "PostalAddress",
            "streetAddress": "Howdershell Rd",
            "addressLocality": "Hazelwood",
            "addressRegion": "Missouri",
            "postalCode": "6901",	
            "addressCountry": "USA"
            },
            "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+1-314-724-2828",
            "contactType": "Telephone" ,
          "email":"contact@makfuels.com"
        }`,
          }}
        />
      </Helmet>

      {/* <Helmet>
      {seoTagsData.map((value,index)=>{
          const {name,content}=value
          if(name.toLowerCase() ==="title"){
          return(
            <title>MAKFUELS | Shop - {content}</title>
        )}else{
          return(
           <title>MAKFUELS | Shop {seoTags ? `- ${seoTags}`:""}</title>
      )}
        })}
       {seoTagsData.map((value,index)=>{
            const {name}=value
            if(name.toLowerCase() !=="title"){
              return(
                <meta name={value.name} content={value.content} key={index}/>
              )
            }
            return null;
          })}
        <link rel="canonical" href="https://makcom.cf/shop" />

      </Helmet> */}
      <section className="container-fluid px-sm-5 px-2 mt-2">

        <div className="container23 hide_at_320">
          <div className="dropdown2 item">
            <button className="dropbtn2">
              Liquor  <i className="fa-solid fa-chevron-down"></i>
            </button>
            <ul className="dropdown-content2">

              <li
                className="dropDownlink2"
                onClick={() => {
                  setProductId({ id: 31, name: "Rum", slug: "rum" });
                }}
              >
                Rum
              </li>
              <li
                className="dropDownlink2"
                onClick={() => {
                  setProductId({ id: 35, name: "Wine", slug: "wine" });
                }}
              >
                Wine
              </li>
              <li
                className="dropDownlink2"
                onClick={() => {
                  setProductId({
                    id: 36,
                    name: "Champagne",
                    slug: "champagne",
                  });
                }}
              >
                Champagne
              </li>
              <li
                className="dropDownlink2"
                onClick={() => {
                  setProductId({ id: 29, name: "Vodka", slug: "vodka" });
                }}
              >
                Vodka
              </li>
              <li
                className="dropDownlink2"
                onClick={() => {
                  setProductId({ id: 30, name: "Tequila", slug: "tequila" });
                }}
              >
                Tequila
              </li>
              <li
                className="dropDownlink2"
                onClick={() => {
                  setProductId({ id: 32, name: "Brandy", slug: "brandy" });
                }}
              >
                Brandy
              </li>
              <li
              
                className="dropDownlink2"
                onClick={() => {
                  setProductId({ id: 34, name: "Gin", slug: "gin" });
                }}
              >
                Gin
              </li>
              <li
                className="dropDownlink2"
                onClick={() => {
                  setProductId({ id: 33, name: "Whisky", slug: "whisky" });
                }}
              >
                Whisky
              </li>
            </ul>
          </div>

          <div className="dropdown2 item">
            <button className="dropbtn2">
              Food  <i className="fa-solid fa-chevron-down"></i>
            </button>
            <ul className="dropdown-content2">
              {foodCategory.map((val, ind) => {
                return (
                  <li
                    key={ind}
                    className="dropDownlink2"
                    onClick={() => {
                      setFoodId(val);
                    }}
                  >
                    {val.name}
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="dropdown2 item">
            <button
              className="dropbtn2"
              onClick={() => {
                setProductId({ id: 6, name: "Cigarettes", slug: "cigarettes" });
              }}
            >
              Cigerattes
            </button>
            
          </div>

          <div className="dropdown2 item">
            <button
              className="dropbtn2"
              onClick={() => {
                setProductId({ id: 17, name: "Cigar", slug: "cigar" });
              }}
            >
              Cigar
            </button>
       
          </div>

          <div className="dropdown2 item">
            <button
              className="dropbtn2"
              onClick={() => {
                setProductId({ id: 16, name: "Beer", slug: "beer" });
              }}
            >
              Beer{" "}
            </button>
          </div>

        </div>

        {/* /////////////////////////////////////////
        /////////////////////////////////////////
        ///////////////////////////////////////// */}
        <div className="row min_height_50">
          <div className="col-lg-9 col-md-8 ">
            <Banner />

            <div className=" mt-2 min_height_20">
              {/* {!token && (
                  <button 
                  className="scan_qr_code_btn d-sm-none"
                  data-bs-toggle="modal"
                  data-bs-target="#qrModal">
                  Scan QR Code <i className="fa-solid fa-qrcode"></i>
                </button>
            )}

            {token && (
              <Link to="/qr_scanner">
                <button className="scan_qr_code_btn d-sm-none">
                  Scan QR Code <i className="fa-solid fa-qrcode"></i>
                </button>
              </Link>
            )} */}

              {/* ///////////////////////
            ///////////////////////
            /////////////////////// */}

              <Categories
                midCategory={midCategory}
                getCatID={getCatID}
                cat_id={cat_id}
                handleMidCategory={handleMidCategory}
              />
            </div>

            {/* ///////////////////////////
            ///////////////////////////
            /////////////////////////// */}

            {/* //  --------previosOrder_card-start------- */}
            {!cat_id && token && (
              <section className="mt-3">
                {/* <div>
                  <h4 className="mb-3">Previous Order</h4>
                </div> */}
                <Products
                  loading={loading}
                  products={previousOrder}
                  productID={productID}
                />
              </section>
            )}

            {/* //  --------previosOrder_card-end------- */}

            {/* --------products_card-start------- */}
            <section className="mt-3" id="section-1">
              <div id="products_id">
                <p style={{ fontSize: "1.5rem" }} className="mb-3">
                  {catName ? catName : "Products"}
                </p>
              </div>
              {loading && (
                <lottie-player
                  src="https://assets7.lottiefiles.com/private_files/lf30_vb7v5ca0.json"
                  background="transparent"
                  speed={1}
                  style={{ width: "300px", height: "200px", margin: "auto" }}
                  loop
                  autoPlay
                />
              )}
              {!loading && products && products.length === 0 && (
                <div className="text-center">
                  <img
                    className=""
                    style={{ width: "100px" }}
                    src={notfound}
                    alt="empty-cart"
                  ></img>

                  <h3>Product Not Found</h3>
                  {/* <h5 className="mb-2">Add something to make me happy</h5> */}
                </div>
              )}
              <Products
                loading={loading}
                products={products}
                productID={productID}
                catName={catName}
              />
              <div className="mt-4 mb-4">
                <Pagination
                  count={count}
                  page={page}
                  onChange={handleChange}
                  color="primary"
                />
              </div>
            </section>

            {/* --------products_card-end------- */}

            {/* --------Sale_products-start------- */}
            {saleProduct.length > 0 && (
              <section className="mt-3">
                <div>
                  <h4 className="mb-3">Sale Products</h4>
                </div>
                <Products
                  loading={loading}
                  products={saleProduct}
                  productID={productID}
                />
              </section>
            )}

            {/* --------Sale_products-end------- */}
          </div>
          {/* -------Cart-Start--------- */}
          <div className="col-md-4 col-lg-3 d-none d-lg-block d-md-block">
            <RightSideCart />
          </div>
          {/* -------Cart-end--------- */}
        </div>
        {cartVisible && (
          <Link to="/shopping-cart">
            <div className="responsive_cart">
              <LazyLoadImage className="" src={cartimg} alt="" loading='lazy' />
              <p className="cart-num"> {cartTotalQuantity} </p>
            </div>
          </Link>
        )}
      </section>

      {/* -----Modal-start------ */}
      {/* eslint-disable-next-line */}

      {mainID == "1" && (
        <ProductModal
          productValue={productValue}
          prod_id={prod_id}
          show={show}
          seoTags={seoTags}
          catName={catName?.toLowerCase()}
          handleClose={handleClose}
          handleShow={handleShow}
        />
      )}
      {/* eslint-disable-next-line */}
      {mainID != "1" && (
        <GroceryProductModal
          productValue={productValue}
          prod_id={prod_id}
          show={show}
          seoTags={seoTags}
          catName={catName?.toLowerCase()}
          handleClose={handleClose}
          handleShow={handleShow}
        />
      )}
      <QRmodal />

      {/* -----Modal-end------ */}
    </>
  );
};

export default Home;
