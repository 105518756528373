import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
// import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../config/apiUrl";
import { addCardSchema } from "../schemas";
import { Helmet } from "react-helmet-async";
import CardDeleteModal from "../components/modal/CardDeleteModal";
import credit_img from "../assets/images/credit-card.png"


const PaymentMethod = () => {
  const [loading, setLoading] = useState(false);
  const [selectState, setSlectState] = useState();
  const [selectCity, setSelectCity] = useState();
  const [paymentCard, setPaymentCard] = useState([]);
  const { token } = useSelector((state) => state.auth);
  const [modal, setModal] = useState(false);
  const [deleteID, setDeleteID] = useState();
  //   const navigate = useNavigate();

  //   const { state } = useLocation();
  const initialValues = {
    card_holder_name: "",
    card_holder_last_name: "",
    card_number: "",
    expiration_month: "",
    expiration_year: "",
    cvc: "",
    zipcode: "",
    phone_number: "",
    email: "",
    billing_details: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema: addCardSchema,
      onSubmit: async (values, action) => {
        var data = new FormData();
        data.append("card_holder_name", values.card_holder_name);
        data.append("card_holder_last_name", values.card_holder_last_name);
        data.append("card_number", values.card_number);
        data.append("expiration_month", values.expiration_month);
        data.append("expiration_year", values.expiration_year);
        data.append("cvc", values.cvc);
        data.append("zipcode", values.zipcode);
        data.append("state", selectState);
        data.append("city", selectCity);
        data.append("phone_number", values.phone_number);
        data.append("email", values.email);
        data.append("billing_details", values.billing_details);

        var config = {
          method: "post",
          url: baseURL + "/addNewCard",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: data,
        };
        setLoading(true);
        await axios(config)
          .then(function (response) {
            toast.success(response.data.message);
            fetchPaymentCart();
            setTimeout(() => {
              //   navigate("/payment-successful", {
              //     state: { orderID: response.data.data },
              //   });
              setLoading(false);
            }, 2000);
            action.resetForm();
          })
          .catch(function (error) {
            toast.error(error.response.data.message);
            setLoading(false);
          });
      },
    });

    useMemo(() => {
      const fetchStateAndCities = async () => {
        try {
          const formData = new FormData();
          formData.append("zip_code", values.zipcode);
    
          const response = await axios.post(baseURL + "/getStateAndCity", formData);
          const { city, state } = response.data.data;
    
          setSelectCity(city);
          setSlectState(state);
        } catch (error) {
          console.log(error);
          setSelectCity();
          setSlectState();
        }
      };
    
      if (String(values.zipcode).length >= 4) {
        fetchStateAndCities();
      }
    }, [values.zipcode]);

  const fetchPaymentCart = () => {
    let config = {
      method: "get",
      url: baseURL + `/getCardDetails`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setPaymentCard(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchPaymentCart();
  // eslint-disable-next-line
  }, [token]);

  const toggle = () => setModal(!modal);

  const handleDelete = (id) => {
    setDeleteID(id);
    toggle();
  };

  return (
    <>
      <Helmet>
      <title>Makfuels | Payment-Method</title>
        <link rel="canonical" href="https://makcom.cf/payment-method" />
      </Helmet>
      <section className="container-fluid px-lg-5 px-2 pt-4 mt-2">
        <div className="row">
          <div className="col-md-3">
            <div>
              <h4 className="text-center">Your Cards</h4>
              {paymentCard.length === 0 && 
              <div className="text-center">
                <img height={80} width={80} src={credit_img} alt="credit_img" />
                <p>There is currently no card available</p>
                </div>
              }
              {paymentCard &&
                paymentCard.map((value, index) => {
                  return (
                    <div className="mb-2" key={index}>
                      <div className="card_details__main_div">
                        <div className="card_number mb-2 position-relative">
                          <span
                            className="card_delete_Icon"
                            onClick={() => handleDelete(value.id)}
                          >
                            <i className="fa-solid fa-delete-left"></i>
                          </span>
                          <div className="form-check">
                            <label
                              className="form-check-label"
                              htmlFor={value.id}
                            >
                              XXXX-XXXX-XXXX-
                              <span className="fw-bold card_last_four">
                                {value.card_number}
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>

            {/* {selectedCard === "" && (
              <div>
                <button
                  className="qr_add_new_card"
                  onClick={() => setaddNewCardShow(!addNewCardShow)}
                >
                  ADD NEW CARD
                </button>
              </div>
            )} */}
          </div>
          <div className={`col-md-9`}>
          {/* <div className="text-end mb-2">
            <Link to="/">
                <button
                  className="qr_add_new_card"
                >
                  Go To Home
                </button>
                </Link>
              </div> */}
            <div className="p-3 pay__details_ naV__StyLe">
              <div className="d-flex align-items-center">
                <h4 className="me-3">Payment Details</h4>
              </div>

              <div>
                <form onSubmit={handleSubmit}>
                  <div className="py-2">
                    <div className="row py-2">
                      <div className="col-md-4 pt-md-0 pt-3">
                        <label>Email</label>
                        <input
                          type="text"
                          className="bg-light form-control"
                          placeholder="name@example.com"
                          id="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // onKeyUp={fetchUserDetail}
                        />
                        {errors.email && touched.email ? (
                          <p className="form-error">{errors.email}</p>
                        ) : null}
                      </div>
                      <div className="col-md-4">
                        <label>Card Holder First Name</label>
                        <input
                          type="text"
                          className="bg-light form-control"
                          placeholder="Haris"
                          id="card_holder_name"
                          name="card_holder_name"
                          value={values.card_holder_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.card_holder_name && touched.card_holder_name ? (
                          <p className="form-error">
                            {errors.card_holder_name}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-4 pt-md-0 pt-3">
                        <label>Last Name </label>
                        <input
                          type="text"
                          className="bg-light form-control"
                          placeholder="Umer"
                          id="card_holder_last_name"
                          name="card_holder_last_name"
                          value={values.card_holder_last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.card_holder_last_name &&
                        touched.card_holder_last_name ? (
                          <p className="form-error">
                            {errors.card_holder_last_name}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-6 pt-md-0 pt-3">
                        <label>Card Number</label>
                        <input
                          type="number"
                          className="bg-light form-control"
                          placeholder={12345678912345}
                          id="card_number"
                          name="card_number"
                          value={values.card_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.card_number && touched.card_number ? (
                          <p className="form-error">{errors.card_number}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="row py-2">
                      <div className="col-md-3">
                        <label>Expiration Month</label>
                        <select
                          className="form-select"
                          id="expiration_month"
                          name="expiration_month"
                          value={values.expiration_month}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option>Month</option>
                          <option value="jan">Jan</option>
                          <option value="feb">Feb</option>
                          <option value="mar">Mar</option>
                          <option value="apr">Apr</option>
                          <option value="may">May</option>
                          <option value="jun">Jun</option>
                          <option value="jul">Jul</option>
                          <option value="aug">Aug</option>
                          <option value="sep">Sep</option>
                          <option value="oct">Oct</option>
                          <option value="nov">Nov</option>
                          <option value="dec">Dec</option>
                        </select>
                        {errors.expiration_month && touched.expiration_month ? (
                          <p className="form-error">
                            {errors.expiration_month}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-3 pt-md-0 pt-3">
                        <label>Expiration Year</label>
                        <select
                          className="form-select"
                          id="expiration_year"
                          name="expiration_year"
                          value={values.expiration_year}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option>Year</option>
                          {/* <option value={2022}>2022</option> */}
                          <option value={2023}>2023</option>
                          <option value={2024}>2024</option>
                          <option value={2025}>2025</option>
                          <option value={2026}>2026</option>
                          <option value={2027}>2027</option>
                          <option value={2028}>2028</option>
                          <option value={2029}>2029</option>
                          <option value={2030}>2030</option>
                        </select>
                        {errors.expiration_year && touched.expiration_year ? (
                          <p className="form-error">{errors.expiration_year}</p>
                        ) : null}
                      </div>
                      <div className="col-md-3 pt-md-0 pt-3">
                        <label>CVC</label>
                        <input
                          type="number"
                          className="bg-light form-control"
                          placeholder={123}
                          id="cvc"
                          name="cvc"
                          value={values.cvc}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.cvc && touched.cvc ? (
                          <p className="form-error">{errors.cvc}</p>
                        ) : null}
                      </div>
                      <div className="col-md-3 pt-md-0 pt-3">
                        <label htmlFor="zipcode">Zipcode</label>
                        <input
                          type="number"
                          className="bg-light form-control"
                          placeholder={12345}
                          id="zipcode"
                          name="zipcode"
                          value={values.zipcode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.zipcode && touched.zipcode ? (
                          <p className="form-error">{errors.zipcode}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="row py-2">
                      <div className="col-md-6">
                        <label>State</label>

                        <select
                          className="form-select"
                          value={selectState}
                          disabled
                        >
                          {selectState === undefined ? (
                            <option value={0}>Select State</option>
                          ) : (
                            <option value={selectState}>{selectState}</option>
                          )}
                        </select>
                        {errors.state && touched.state ? (
                          <p className="form-error">{errors.state}</p>
                        ) : null}
                      </div>
                      <div className="col-md-6 pt-md-0 pt-3">
                        <label>City</label>
                        <select
                          className="form-select"
                          value={selectCity}
                          disabled
                        >
                          {selectCity === undefined ? (
                            <option value={0}>select City</option>
                          ) : (
                            <option value={selectCity}>{selectCity}</option>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="row py-2">
                      <div className="col-md-6">
                        <label htmlFor="state">Phone Number</label>
                        <input
                          type="number"
                          className="bg-light form-control"
                          placeholder=""
                          id="phone_number"
                          name="phone_number"
                          value={values.phone_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.phone_number && touched.phone_number ? (
                          <p className="form-error">{errors.phone_number}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="row py-2">
                      <div className="col-md-12">
                        <label>Billing Address</label>
                        <input
                          type="text"
                          className="bg-light form-control"
                          placeholder="xyz address"
                          id="billing_details"
                          name="billing_details"
                          value={values.billing_details}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.billing_details && touched.billing_details ? (
                          <p className="form-error">{errors.billing_details}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="py-3 pb-4">
                      <button
                        type="submit"
                        className="btn check_out_btn mr-3"
                        disabled={loading ? true : false}
                      >
                        {loading ? (
                          <div
                            className="spinner-border text-secondary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          "Confirm"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CardDeleteModal
        toggle={toggle}
        modal={modal}
        deleteID={deleteID}
        fetchPaymentCart={fetchPaymentCart}
      />
    </>
  );
};

export default PaymentMethod;
