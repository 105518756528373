import axios from "axios";
import UserSidbar from "../components/UserSidbar";
import { baseURL } from "../config/apiUrl";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";

const Notifications = () => {
  const { token } = useSelector((state) => state.auth);
  const [notification, setNotification] = useState()
  const [loading, setLoading] = useState(false);

  const fetchReceiveableNotification = async() => {
    var config = {
      method: "get",
      url: baseURL + "/getReceiveableNotification",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios(config)
      .then(function (response) {
        setNotification(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchReceiveableNotification()
  // eslint-disable-next-line
  }, [])
  const initialValues = {
    // eslint-disable-next-line
    promotional_email: notification?.promotional_email === "1" && true || "",
    // eslint-disable-next-line
    alcohol_email: notification?.alcohol_email === "1" && true || "",
    // eslint-disable-next-line
    tobacco_email :notification?.tobacco_email === "1" && true || "",
  };
  const { values, handleBlur, handleChange, handleSubmit } =
  useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values, action) => {
      var data = new FormData();
      data.append("promotional_email", values.promotional_email ? 1 : 0);
      data.append("alcohol_email", values.alcohol_email ? 1 : 0);
      data.append("tobacco_email", values.tobacco_email ? 1 : 0);

      setLoading(true);

      var config = {
        method: "post",
        url: baseURL + "/updateReceiveableNotification",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      await axios(config)
        .then(function (response) {
          toast.success(response.data.message);
          setLoading(false);

        })
        .catch(function (error) {
          toast.error(error.response.data.message);
          setLoading(false);
        });
      // setLoading(false)
    },
  });
  
  return (
    <div>
                    <Helmet>
                    <title>Makfuels | Notifications</title>
        <link rel="canonical" href="https://makcom.cf/notifications" />
      </Helmet>
      <section className="container-fluid mt-3">
        <div className="row">
          <UserSidbar />
          <div className="col-md-12 col-lg-9 mb-2">
            <div
              className="p-3 mb-4"
              style={{
                boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
                borderRadius: "8px",
              }}
            >
              <h4 className="mb-3">Notification References</h4>
              <form onSubmit={handleSubmit}>
              <div>
                <div className="noti__switches_">
                  <div className="form-check form-switch mb-3">
                    <label
                      className="form-check-label"
                      htmlFor="promotional_email"
                    >
                      Receive Promotional Emails
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="promotional_email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.promotional_email}
                      checked={values.promotional_email}
                    />
                  </div>
                  <div className="form-check form-switch mb-3">
                    <label
                      className="form-check-label"
                      htmlFor="alcohol_email"
                    >
                      Receive offers for alcohol products
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="alcohol_email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.alcohol_email}
                      checked={values.alcohol_email}
                    />
                  </div>
                  <div className="form-check form-switch mb-3">
                    <label
                      className="form-check-label"
                      htmlFor="tobacco_email"
                    >
                      Receive offers for tobacco products
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="tobacco_email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tobacco_email}
                      checked={values.tobacco_email}
                    />
                  </div>

                  <button
                      type="submit"
                      className="btn check_out_btn"
                      disabled={loading ? true : false}
                    >
                      {loading ? (
                        <div className="spinner-border text-warning" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        "Update Info"
                      )}
                    </button>

                </div>
              </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Notifications;
