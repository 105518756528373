import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import authReducer from '../features/auth/authSlice.js';
import storage from "redux-persist/lib/storage";
import cartReducer from "../features/slices/cartSlice";
import productCategoryReducer from "../features/slices/productSlice"
import myOrderReducer from "../features/slices/orderSlice"

const persistConfig = {
  key: "root",
  // version: 1,
  storage,
  blacklist: ['productCategory']
};

const reducer = combineReducers({
    auth: authReducer,
    cart: cartReducer,
    productCategory: productCategoryReducer,
    mySingleOrders: myOrderReducer
});

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export default store;



// import { configureStore } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice';

// export const store = configureStore({
//   reducer: {
//     counter: counterReducer,
//   },
// });











// import {
//   combineReducers,
//   configureStore,
//   getDefaultMiddleware,
// } from '@reduxjs/toolkit';
// import {
//   persistReducer,
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
// } from 'redux-persist';
// import authReducer from '../features/auth/authSlice';
// import storage from "redux-persist/lib/storage";


// const persistConfig = {
//   key: "root",
//   // version: 1,
//   storage,
// };

// const reducer = combineReducers({
//     auth: authReducer,
// });

// const persistedReducer = persistReducer(persistConfig, reducer);

// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: getDefaultMiddleware({
//     serializableCheck: {
//       ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//     },
//   }),
// });

// export default store;