import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import  store  from './redux/app/store';
// import { store } from "./redux/features/auth/authSlice";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { HelmetProvider } from 'react-helmet-async';
let persistor = persistStore(store)

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
  
    <Provider store={store}>
    <PersistGate persistor={persistor}>
      <HelmetProvider>
      <App />
      </HelmetProvider>
      </PersistGate>
    </Provider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

