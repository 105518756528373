import * as Yup from "yup";

export const signUpSchema = Yup.object({
  name: Yup.string().min(2).max(25).required("Please Enter Your Name"),
  email: Yup.string().email().required("Please Enter Your Email"),
  password: Yup.string().required('Password is required'),
  c_password: Yup.string()
     .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  phone_number: Yup.string().min(10 , "Please enter a valid number").max(10, "Please enter a valid number").required("Please Enter Your Phone Number"),
  promotional_email: Yup.string(),
  alcohol_email: Yup.string(),
  tobacco_email: Yup.string(),

});

export const UserGasRequest = Yup.object({
  name: Yup.string().min(2).max(25).required("Please Enter Your Name"),
  email: Yup.string().email().required("Please Enter Your Email"),
  phone: Yup.string().max(10).required("Please Enter Your Phone"),
  address: Yup.string().required('Please Enter Your address'),
  
  federal_tax_id_front: Yup.string().required('Please Upload Federal Tax ID(Front Side)'),
  federal_tax_id_back: Yup.string().required('Please Upload Federal Tax ID(Back Side)'),

  state_registeration_front: Yup.string().required('Please Upload Secretary of state registration(Front Side)'),
  state_registeration_back: Yup.string().required('Please Upload Secretary of state registration(Back Side)'),

  state_tax_id_front: Yup.string().required('Please Upload Driver License(Front Side)'),
  state_tax_id_back: Yup.string().required('Please Upload Driver License(Front Side)'),

  license_front: Yup.string().required('Please Upload Driver License(Front Side)'),
  license_back: Yup.string().required('Please Upload Driver License(Back Side)'),
  zip_code: Yup.string().required('Please Enter Your Zip Code'),
  password: Yup.string().required('Password is required'),
  c_password: Yup.string()
     .oneOf([Yup.ref('password'), null], 'Passwords must match')

});


export const UserModal = Yup.object({
  name: Yup.string().min(2).max(25).required("Please Enter Your Name"),

});
export const Change__Password = Yup.object({
  password: Yup.string().min(8).required('Password is required'),
  c_password: Yup.string().required('Password is required')
     .oneOf([Yup.ref('password'), null], 'Passwords must match')
});


export const checkoutSchema = Yup.object({
  card_holder_name: Yup.string().min(2).max(25).required("Please Enter Your First Name"),
  card_holder_last_name: Yup.string().required("Please Enter Your Last Name"),
  card_number: Yup.string().min(16).max(19).required("Please Enter Your Card Number"),
  expiration_month: Yup.string().required('Please Enter Expiry Month'),
  expiration_year: Yup.string().required('Password is Expiry Year'),
  cvc: Yup.string().min(3).max(3).required('Please Enter CVC'),
  zipcode: Yup.string().required('Please Enter Your Zip Code'),
  // state: Yup.string().required('Please Enter Your State'),
  // city: Yup.string().required('Please Enter Your City'),
  phone_number: Yup.string().required('Please Enter Your Phone Number'),
  email: Yup.string().email().required('Please Enter Your Email'),
  billing_details: Yup.string().required('Please Enter Your Billing Address'),

});

export const addCardSchema = Yup.object({
  card_holder_name: Yup.string().min(2).max(25).required("Please Enter Your First Name"),
  card_holder_last_name: Yup.string().required("Please Enter Your Last Name"),
  card_number: Yup.string().min(16).max(16).required("Please Enter Your Card Number"),
  expiration_month: Yup.string().required('Please Enter Expiry Month'),
  expiration_year: Yup.string().required('Password is Expiry Year'),
  cvc: Yup.string().min(3).max(3).required('Please Enter CVC'),
  zipcode: Yup.string().required('Please Enter Your Zip Code'),
  // state: Yup.string().required('Please Enter Your State'),
  // city: Yup.string().required('Please Enter Your City'),
  phone_number: Yup.string().required('Please Enter Your Phone Number'),
  email: Yup.string().email().required('Please Enter Your Email'),
  billing_details: Yup.string().required('Please Enter Your Billing Address'),

});

export const contactus = Yup.object({
  name: Yup.string().min(2).max(25).required("Please Enter Your Name"),
  email: Yup.string().email().required("Please Enter Your Email"),
  message: Yup.string().min(2).max(25).required("Please Enter Your Message"),

});