import React, { useState } from "react";
import slide1 from "../assets/images/main_slide_1.jpg";
import slide2 from "../assets/images/main_slide_2.jpg";
import slide3 from "../assets/images/main_slide_3.jpg";
import Footer from "../components/Footer";
import MainHeader from "../components/MainHeader";
import { contactus } from "../schemas";
import { useFormik } from "formik";
import { baseURL } from "../config/apiUrl";
import axios from "axios";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";

const initialValues = {
  name: "",
  email: "",
  message: "",
};

const ContactUs = () => {
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: contactus,
      onSubmit: async (values, action) => {

        var FormData = require("form-data");
        var data = new FormData();
        data.append("name", values.name);
        data.append("email", values.email);
        data.append("message", values.message);

        var config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${baseURL}/contact_us`,
          headers: {
            // ...data.getHeaders()
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            toast.success(response.data.message);
          })
          .catch(function (error) {
            console.log(error);
          });

        // action.resetForm();
      },
    });

  const [loading, setloading] = useState(true);

  setTimeout(() => {
    setloading(false);
  }, 2000);

  return (
    <>
          <Helmet>

          <link rel="canonical" href="https://makfuels.com/contact-us" />
<title>Contact Us | Makfuels</title>
<meta name="description" content="Contact us for any help related to gas. Our experts at Makfuels will get back to you within 24 hours."/>

{/* <!-- Facebook Meta Tags --> */}
<meta property="og:url" content="makfuels.com/contact-us"/>
<meta property="og:type" content="website"/>
<meta property="og:title" content="Contact Us | Makfuels"/>
<meta property="og:description" content="Contact us for any help related to gas. Our experts at Makfuels will get back to you within 24 hours."/>
<meta property="og:image" content="https://makfuels.com/static/media/logo.4ed5bcbedcce5108870c.jpg "/>

{/* <!-- Twitter Meta Tags --> */}
<meta name="twitter:card" content="summary_large_image"/>
<meta property="twitter:url" content="makfuels.com/contact-us"/>
<meta name="twitter:title" content="Contact Us | Makfuels"/>
<meta name="twitter:description" content="Contact us for any help related to gas. Our experts at Makfuels will get back to you within 24 hours."/>
<meta name="twitter:image" content="https://makfuels.com/static/media/logo.4ed5bcbedcce5108870c.jpg "/>

<script type='application/ld+json' 
          dangerouslySetInnerHTML={{ __html: `{
            "@context": "http://www.schema.org",
            "@type": "Corporation",
            "name": "Makfuels",
            "url": "https://makfuels.com/contact-us ",
            "sameAs": [
            "https://www.facebook.com/makfuelsofficial/",
            "https://twitter.com/",
            "https://www.instagram.com/",           
          "https://www.linkedin.com/company/",
            "https://www.pinterest.com/"
            ],
            "logo": "https://makfuels.com/static/media/logo.4ed5bcbedcce5108870c.jpg ",
            "image": "https://makfuels.com/static/media/logo.4ed5bcbedcce5108870c.jpg",
            "description": "Contact us for any help related to gas. Our experts at Makfuels will get back to you within 24 hours.",
            "address": {
            "@type": "PostalAddress",
            "streetAddress": "Howdershell Rd",
            "addressLocality": "Hazelwood",
            "addressRegion": "Missouri",
            "postalCode": "6901",	
            "addressCountry": "USA"
            },
            "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+1-314-724-2828",
            "contactType": "Telephone" ,
            "email":"contact@makfuels.com"
          }`}}
        
          />



      </Helmet>
      {/* <MainHeader /> */}
      <section>
        <div className="privacy_background mt-3">
          <div>
            <h1 className="bann_heading">Contact</h1>
          </div>
        </div>
      </section>
      {/* <section>
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to={0}
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            />
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to={1}
              aria-label="Slide 2"
            />
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to={2}
              aria-label="Slide 3"
            />
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img width="100%" height={360} src={slide1} className="d-block" alt="..." />
            </div>
            <div className="carousel-item">
              <img width="100%" height={360} src={slide2} className="d-block" alt="..." />
            </div>
            <div className="carousel-item">
              <img width="100%" height={360} src={slide3} className="d-block" alt="..." />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </section> */}
      <section className="contact__Secc py-4 mt-3">
        <div className="container my-3">
          <div className="row">
            <div className="col-lg-6 mb-3 mb-lg-0">
              <div>
                {loading && (
                  <div className="main_map_load">
                    <div className="spinner-border text-warning " role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
                {!loading && (
                  <iframe
                    className="w-100"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3109.8538374191157!2d-90.3774213852499!3d38.7899849608746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87df30cd365c7c65%3A0xd1dbc71fa2c85f68!2s6901%20Howdershell%20Rd%2C%20Hazelwood%2C%20MO%2063042%2C%20USA!5e0!3m2!1sen!2s!4v1677510847574!5m2!1sen!2s"
                    width={600}
                    height={400}
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Google Map of MAK FUELS location"
                  />
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <form onSubmit={handleSubmit}>
                <div>
                  <h1 className="cont__head">Contact Us</h1>
                  <div className="mak_head_line"></div>
                  <div>
                    <div>
                      <div className="mb-2">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Name
                        </label>
                        <input
                          type="name"
                          className="form-control"
                          id="name"
                          autoComplete="off"
                          name="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                        />
                        {errors.name && touched.name ? (
                          <p className="form-error">{errors.name}</p>
                        ) : null}
                      </div>
                      <div className="mb-2">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Email address
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          autoComplete="off"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                        {errors.email && touched.email ? (
                          <p className="form-error">{errors.email}</p>
                        ) : null}
                      </div>
                      <div className="mb-2">
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          Message
                        </label>
                        <textarea
                          className="form-control"
                          id="message"
                          autoComplete="off"
                          name="message"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.message}
                          rows={5}
                          defaultValue={""}
                        />
                        {errors.message && touched.message ? (
                          <p className="form-error">{errors.message}</p>
                        ) : null}
                      </div>
                      <div className="mb-2 text-center">
                        <button className="btn check_out_btn" type="submit">
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default ContactUs;
