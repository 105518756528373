import { createSlice, current } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  cartItems: [],
  cartTotalQuantity: 1,
  cartSubTotalAmount: 0,
  cartTotalAmount: 0,
  cartTotalTax: 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, action) {
      const existingItem = current(state.cartItems).find(
        (item) => item.id === action.payload.id
      );
    
      if (existingItem) {
        const updatedItems = state.cartItems.map((item) => {
          if (item.id === existingItem.id) {
            return {
              ...item,
              cartQuantity: item.cartQuantity + action.payload.num,
            };
          }
          return item;
        });
    
        state.cartItems = updatedItems;
      } else {
        const tempProductItem = {
          ...action.payload,
          id: action.payload.id,
          cartQuantity: action.payload.num,
          toppingsArray: action.payload.toppingsArray || [],
          saucesArray: action.payload.saucesArray || [],
          extrasaucesArray: action.payload.extrasaucesArray || [],
          sidesArray: action.payload.sidesArray || [],
          sidesQuantity: action.payload.sidesQuantity || 0,
          selectedSubproduct: action.payload.selectedSubproduct,
          selectedSoftDrinks: action.payload.selectedSoftDrinks ,
          selectedFountainDrinks: action.payload.selectedFountainDrinks,
          selectedFriesType: action.payload.selectedFriesType,
          selectedFriesSize: action.payload.selectedFriesSize,
        };
        console.log(action.payload)
    
        state.cartItems.push(tempProductItem);
      }
    
      toast.success("Product added to cart", {
        position: "bottom-right",
      });
    },
    
    
    increaseCart(state, action) {
      const existingIndex = current(state.cartItems).findIndex(
        (item) => item.toppingsArray === action.payload.toppingsArray
      );

      if (existingIndex >= 0) {
        state.cartItems[existingIndex] = {
          ...state.cartItems[existingIndex],
          cartQuantity: state.cartItems[existingIndex].cartQuantity + 1,
        };
        toast.info("Increased product quantity", {
          position: "bottom-right",
        });
      }
    },
    decreaseCart(state, action) {
      const itemIndex = current(state.cartItems).findIndex(
        (item) => item.toppingsArray === action.payload.toppingsArray
      );

      if (state.cartItems[itemIndex].cartQuantity > 1) {
        state.cartItems[itemIndex].cartQuantity -= 1;

        toast.info("Decreased product quantity", {
          position: "bottom-right",
        });
      } else if (state.cartItems[itemIndex].cartQuantity === 1) {
        const nextCartItems = current(state.cartItems).filter(
          (item) => item.toppingsArray !== action.payload.toppingsArray
        );

        state.cartItems = nextCartItems;

        toast.error("Product removed from cart", {
          position: "bottom-right",
        });
      }

      // localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    removeFromCart(state, action) {
      state.cartItems.map((cartItem) => {
        if (current(cartItem.toppingsArray) === action.payload.toppingsArray) {
          const nextCartItems = state.cartItems.filter(
            (item) => item.toppingsArray !== cartItem.toppingsArray
          );

          state.cartItems = nextCartItems;

          toast.error("Product removed from cart", {
            position: "bottom-right",
          });
          // }
        }
        // localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        return state;
      });
    },
    getTotals(state, action) {
      let { total , subTotal , quantity, tax } = state.cartItems.reduce(
        (cartTotal, cartItem) => {
          // let subProPrice = cartItem.selectedSubproduct?.price;
          let {
            discount_price,
            price,
            cartQuantity,
            toppingsArray,
            tax_percentage,
            extrasaucesArray,
            finalSauceQuantity,
            selectedSides,
            selectedSoftDrinks,
            selectedFriesSize,
            sidesArray,
            sidesQuantity,
            FriesResult,
          } = cartItem;

          var num = 0;
          price = price===null?cartItem.selectedSubproduct?.price:price
          let totalPrice = discount_price===null?price:discount_price
          toppingsArray.map(
            (price) => (num += parseFloat(price.price * cartQuantity))
          );
        const newQuantity =  finalSauceQuantity
        const totalSaucePrice = extrasaucesArray.reduce((accumulator, currentItem) => {
          // return accumulator + parseFloat(currentItem.price);
          return parseFloat(currentItem.price)*newQuantity;
        }, 0);

        const totalSidePrice = sidesArray?.reduce(
          
          (total, currentValue , i) => {

            if (sidesQuantity > i) {
              return total
            }
            else{
        
              return total = total + parseFloat(currentValue.price)
            }

        }, 0);
        
        let totolSides = totalSidePrice * cartQuantity||0;
        console.log(FriesResult)
        let FriesResults = FriesResult||0;

        let extraNum = totalSaucePrice * cartQuantity;
        const Sidesresult = selectedSides?.price==null?0:parseFloat(selectedSides?.price);
        const SoftDrinksresult = selectedSoftDrinks?.price==null?0:parseFloat(selectedSoftDrinks?.price);

          const itemTotal = totalPrice * cartQuantity;
          const itemTotalTax = (itemTotal+ num + Sidesresult +SoftDrinksresult +FriesResults +totolSides+ extraNum) * tax_percentage / 100 ;
          cartTotal.total += itemTotal + num + extraNum+ totolSides + Sidesresult +FriesResults +SoftDrinksresult+ itemTotalTax;
          cartTotal.subTotal += itemTotal + num+ Sidesresult +SoftDrinksresult +FriesResults +totolSides+ extraNum ;
          cartTotal.quantity += cartQuantity;
          cartTotal.tax += itemTotalTax;
          return cartTotal;
        },
        {
          total: 0,
          quantity: 0,
          tax:0,
          subTotal:0,
        }
      );
      total = parseFloat(total).toFixed(2);
      tax = parseFloat(tax).toFixed(2);
      subTotal = parseFloat(subTotal).toFixed(2);
      state.cartTotalQuantity = quantity;
      state.cartTotalAmount = total;
      state.cartSubTotalAmount = subTotal;
      state.cartTotalTax = tax;
    },
    clearCart(state, action) {
      state.cartItems = [];
      state.cartTotalAmount = 0;
      // localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      toast.error("Cart cleared", { position: "bottom-right" });
    },
    clearCartHome(state, action) {
      state.cartItems = [];
      state.cartTotalAmount = 0;
      // localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
  },
});

export const {
  addToCart,
  decreaseCart,
  removeFromCart,
  getTotals,
  clearCart,
  clearCartHome,
  increaseCart,
} = cartSlice.actions;

export default cartSlice.reducer;
