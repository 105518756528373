import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductId, getFoodId } from "../redux/features/slices/productSlice";
import { logout } from "../redux/features/auth/authSlice";
import logo from "../assets/images/logo.webp";
import cartimg from "../assets/images/carts.png";
import bugerIcon from "../assets/images/bugerIcon.webp";
import user_icon from "../assets/images/user-icon.webp";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { clearCart } from "../redux/features/slices/cartSlice";
import { baseURL } from "../config/apiUrl";
import axios from "axios";
import LogoutModal from "./modal/LogoutModal";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Header = () => {
  const { cartTotalQuantity } = useSelector((state) => state.cart);
  const { token, name } = useSelector((state) => state.auth);
  const [midCategory, setMidCategory] = useState([]);
  const [foodCategory, setFoodCategory] = useState([]);
 
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearCart());
    navigate("/");
  };

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const fetchFoodCategory = async () => {
    var config = {
      method: "get",
      url: baseURL + `/getMidCategories/1`,
      headers: {},
    };
    // setLoader(true)
    // setLoading(true);
    await axios(config)
      .then(function (response) {
        const { midCategory, products } = response.data.data;
        setFoodCategory(midCategory);
        // setLoading(false);
        // setLoader(false)
      })
      .catch(function (error) {
        console.log(error);
        // setLoading(false);

        // setLoader(false)
      });
  };

  const fetchMidCategory = async () => {
    var config = {
      method: "get",
      url: baseURL + `/getMidCategories/2`,
      headers: {},
    };
    // setLoader(true)
    // setLoading(true);
    await axios(config)
      .then(function (response) {
        const { midCategory, products } = response.data.data;
        setMidCategory(midCategory);
        // setLoading(false);
        // setLoader(false)
      })
      .catch(function (error) {
        console.log(error);
        // setLoading(false);

        // setLoader(false)
      });
  };
  useEffect(() => {
    fetchMidCategory();
    fetchFoodCategory();
    dispatch(getFoodId({}));
    dispatch(getProductId({}));
  }, []);
  // console.log(midCategory,"midCategory")

  const handleSearch = async () => {
    try {
      const formData = new FormData();
      formData.append("keywords", message);
      const response = await axios.post(`${baseURL}/search`, formData);
      const { data } = response;
      navigate("/search", {
        state: { product: data.data, value: message },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleClick = () => {
    handleSearch();
  };

  const setFoodId = (val) => {
    dispatch(getFoodId(val));

    const element = document.getElementById("products_id");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const setProductId = (val) => {
    dispatch(getProductId(val));

    const element = document.getElementById("products_id");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <header id="" className="topbar px-5 p-2 container-fluid">
        <div className="mt-1">
          <div className=" d-flex justify-content-between align-items-center main_location__div flex-wrap h-100">
            <div className="social-links align-items-center social__iconss_">
              {/* <Link to="#" className="twitter ms-0" title="twitter">
                <i className="fa-brands fa-twitter " />
              </Link> */}
              <Link to="https://www.facebook.com/makfuelsofficial" className="facebook" title="facebook">
                <i className="fa-brands fa-facebook-f" />
              </Link>
              {/* <Link to="#" className="instagram" title="instagram">
                <i className="fa-brands fa-instagram" />
              </Link>
              <Link to="#" className="linkedin" title="linkedin">
                <i className="fa-brands fa-linkedin" />
              </Link> */}
              <a href="mailto:contact@example.com" title="contact">
                <span className="me-2">
                  <i className="fa-solid fa-phone" />
                </span>
                314-274-2828
              </a>
            </div>
            <div className="address_heaD">
              <a
                className="address__"
                title="address"
                target="_blank"
                href="https://www.google.com/maps/place/MAK+FUELS/@38.7899936,-90.3751865,3a,75y/data=!3m8!1e2!3m6!1sAF1QipMQzp1pkPje61gNe_Kpws4cw9SszyodDQAOa_3l!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipMQzp1pkPje61gNe_Kpws4cw9SszyodDQAOa_3l%3Dw152-h86-k-no!7i2560!8i1440!4m11!1m2!2m1!1smakfuels!3m7!1s0x87df30cd35853f0d:0xacc3946726cdae00!8m2!3d38.7899936!4d-90.3751865!10e5!15sCgltYWsgZnVlbHNaCyIJbWFrIGZ1ZWxzkgELZ2FzX3N0YXRpb27gAQA!16s%2Fg%2F11g8wxcp4l"
                rel="noopener noreferrer"
              >
                <span className="me-2">
                  <i className="fa-solid fa-location-dot" />
                </span>
                6901 Howdershell Rd Hazelwood, MO, 63042
              </a>
            </div>

            <div className="contact-info d-flex align-items-center visiblity_none">
              {token && (
                <span className="priceWithTok">
                  <span>
                    <i className="fa-solid fa-users" />
                  </span>
                  <span
                    className="text-decoration-underline"
                    data-bs-toggle="modal"
                    data-bs-target="#logoutModal"
                  >
                    Member Gas Price
                  </span>
                </span>
              )}
              {!token && (
                //to="/member-login"
                <Link>
                  <span>
                    <i className="fa-solid fa-users" />
                  </span>
                  <span className="text-decoration-underline">
                    Member Gas Price
                  </span>
                </Link>
              )}
            </div>
          </div>
        </div>
      </header>

      <section className="container-fluid px-sm-5 px-2 p-0 mt-3">
        <div className="row d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center col-lg-5 col-md-5 col-sm-7 col-7 header_left">
            <div>
              <Link to="/" title="MAKFUELS">
                <LazyLoadImage
                  loading="lazy"
                  height={50}
                  width={50}
                  className="header-img me-4"
                  src={logo}
                  alt=""
                />
              </Link>
            </div>
            <div className="input-group ">
              <input
                type="text"
                className="form-control input__sty_rou"
                placeholder="Search"
                id="message"
                name="message"
                value={message}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
              <span
                className="input-group-text magnifying__"
                onClick={handleClick}
              >
                <i className="fa-solid fa-magnifying-glass fs-4" />
              </span>
            </div>
          </div>
          <div className="col-lg-5 col-md-4 col-sm-2 col-3">
            <div className="d-flex justify-content-end align-items-center">
              {/* <div>
                <NavLink to="/grocery" >
                  <button className=" menu__btns">Grocery</button>
                </NavLink>
                <NavLink to="/foods" >
                  <button className=" menu__btns ms-4">Food</button>
                </NavLink>
              </div> */}
              <div className="dropdown2 hide_at_880">
                <button
                  className="dropbtn2"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Home
                </button>
              </div>

              <div className="dropdown2 hide_at_880">
                <button
                  className="dropbtn2"
                  onClick={() => {
                    navigate("/about-us");
                  }}
                >
                  About
                </button>
              </div>

              <div className="dropdown2 hide_at_880">
                <button
                  className="dropbtn2"
                  onClick={() => {
                    navigate("/contact-us");
                  }}
                >
                  Contact Us{" "}
                </button>
              </div>
              <div className="d-flex justify-content-end align-items-center">
                {token && (
                  <h6 className="me-3 header_username">
                    Hi ! {name === "" ? "Guest" : name.split(" ")[0]}{" "}
                  </h6>
                )}
                <div className="dropdown user_icon">
                  <LazyLoadImage
                    loading="lazy"
                    src={user_icon}
                    className="icon_header"
                    height={30}
                    width={28}
                    alt="user-icon"
                  />

                  <div className="dropdown-content">
                    {token && (
                      <Link to="/myProfile">
                        <button type="button" className=" menu__btns w-100">
                          My Profile
                        </button>
                      </Link>
                    )}
                    {token && (
                      <button
                        type="button"
                        className=" menu__btns w-100"
                        onClick={handleLogout}
                      >
                        Logout
                      </button>
                    )}
                    {!token && (
                      <Link to="/login">
                        <button type="button" className=" menu__btns w-100">
                          Login
                        </button>
                      </Link>
                    )}
                  </div>
                </div>
                <Link to="/shopping-cart">
                  <div className="cart_icon ms-2 position-relative">
                    <LazyLoadImage
                      loading="lazy"
                      className="icon_header"
                      height={30}
                      width={28}
                      src={cartimg}
                      alt="cart-icon"
                    />
                    <p className="cart-num"> {cartTotalQuantity} </p>
                  </div>
                </Link>

                <div
                  className="cart_icon ms-2 position-relative show_at_880"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                >
                  <LazyLoadImage
                    loading="lazy"
                    className="icon_header"
                    height={30}
                    width={28}
                    src={bugerIcon}
                    alt="cart-icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LogoutModal />

      {/* ///////////////////////////////////////////
      ///////////////////////////////////////////
      ///////////////////////////////////////////
                    /////////////////////////////////////////// */}

      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">
            <Link to="/shop" title="MAKFUELS">
              <img
                height={50}
                width={50}
                className="header-img me-4"
                src={logo}
                alt=""
              />
            </Link>
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <ul style={{ listStyleType: "none", padding: "0px" }}>
            <li
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              className="offcanvas_list"
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </li>

            <li
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              className="offcanvas_list"
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              About
            </li>

            <li
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              className="offcanvas_list"
              onClick={() => {
                navigate("/about-us");
              }}
            >
              Contact Us
            </li>
          </ul>
        </div>
      </div>

      {/* ///////////////////////////////////////////
      ///////////////////////////////////////////
      ///////////////////////////////////////////
      /////////////////////////////////////////// */}
    </>
  );
};

export default Header;
