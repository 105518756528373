import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../config/apiUrl";
import { login } from "../redux/features/auth/authSlice";
import { signUpSchema } from "../schemas";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import PaymentModal from "./modal/PaymentModal";

const Login = () => {
  document.title="MAKFUELS | Login"

  const [Seye, setSeye] = useState(true);
  const [SshowPassword, setSshowPassword] = useState("password");
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);


  const SEye = () => {
    if (SshowPassword === "password") {
      setSshowPassword("text");
      setSeye(false);
    } else {
      setSshowPassword("password");
      setSeye(true);
    }
  };

  const [eye, seteye] = useState(true);
  const [showPassword, setshowPassword] = useState("password");
  const Eye = () => {
    if (showPassword === "password") {
      setshowPassword("text");
      seteye(false);
    } else {
      setshowPassword("password");
      seteye(true);
    }
  };

  const initialValues = {
    name: "",
    email: "",
    password: "",
    c_password: "",
    phone_number: "",
    promotional_email: "",
    alcohol_email: "",
    tobacco_email: "",
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const submitForm = async (e) => {
    e.preventDefault();
    var data = new FormData();
    data.append("email", email);
    data.append("password", password);
    setLoading(true);
    var config = {
      method: "post",
      url: baseURL + "/login",
      data: data,
    };

    await axios(config)
      .then(function (response) {
        const { message, data } = response.data;
        dispatch(login(data));
        toast.success(message);
        setLoading(false);
        navigate("/");
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
        setLoading(false);
      });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: signUpSchema,
      onSubmit: async (values, action) => {
        var data = new FormData();
        data.append("name", values.name);
        data.append("email", values.email);
        data.append("password", values.password);
        data.append("c_password", values.c_password);
        data.append("phone_number", values.phone_number);
        data.append("user_type", "user");
        data.append("promotional_email", values.promotional_email ? 1 : 0);
        data.append("alcohol_email", values.alcohol_email ? 1 : 0);
        data.append("tobacco_email", values.tobacco_email ? 1 : 0);

        setLoading(true);

        var config = {
          method: "post",
          url: baseURL + "/register",
          data: data,
        };

        await axios(config)
          .then(function (response) {
            const { message, data } = response.data;
            dispatch(login(data));
            toast.success(message);
            setLoading(false);
            toggle()
            navigate("/");

            // toggleLogin();
          })
          .catch(function (error) {
            toast.error(error.response.data.message);
            console.log(error);
            setLoading(false);
          });
        // setLoading(false)
      },
    });

  function toggleSignup() {
    document.getElementById("login-toggle").style.backgroundColor = "#fff";
    document.getElementById("login-toggle").style.color = "#222";
    document.getElementById("signup-toggle").style.backgroundColor = "#ffcc07";
    document.getElementById("signup-toggle").style.color = "#fff";
    document.getElementById("login-form").style.display = "none";
    document.getElementById("signup-form").style.display = "block";
  }

  function toggleLogin() {
    document.getElementById("login-toggle").style.backgroundColor = "#ffcc07";
    document.getElementById("login-toggle").style.color = "#fff";
    document.getElementById("signup-toggle").style.backgroundColor = "#fff";
    document.getElementById("signup-toggle").style.color = "#222";
    document.getElementById("signup-form").style.display = "none";
    document.getElementById("login-form").style.display = "block";
  }

  let date = new Date(new Date().setFullYear(new Date().getFullYear() - 21));
  let getDate=moment(date ).format('MMM DD YYYY')
  return (
    <>
                  <Helmet>
        <link rel="canonical" href="https://makcom.cf/login" />
      </Helmet>
        <div className="form-modal">
      <div className="form-toggle">
        <button id="login-toggle" onClick={toggleLogin}>
          log in
        </button>
        <button id="signup-toggle" onClick={toggleSignup}>
          sign up
        </button>
      </div>
      <div id="login-form">
        <form onSubmit={submitForm}>
          <input
            type="text"
            placeholder="Enter email or username"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="position-relative pAss_Sty">
            <input
              type={showPassword}
              placeholder="Enter password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <i
              onClick={Eye}
              className={`position-absolute fa ${
                eye ? "fa-eye-slash" : "fa-eye"
              }`}
            ></i>
          </div>
          <button
            type="submit"
            className="btn login"
            disabled={loading ? true : false}
          >
            {loading ? (
              <div className="spinner-border text-warning" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              "login"
            )}
          </button>
          {/* <p><a href="javascript:void(0)">Forgotten account</a></p> */}
          <hr />
        </form>
      </div>

      <div id="signup-form">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Enter your name"
            id="name"
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.name && touched.name ? (
            <p className="form-error">{errors.name}</p>
          ) : null}

          <input
            type="email"
            placeholder="Enter your email"
            id="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.email && touched.email ? (
            <p className="form-error">{errors.email}</p>
          ) : null}

          <input
            type="number"
            placeholder="Enter your phone number"
            id="phone_number"
            name="phone_number"
            maxLength={11}
            value={values.phone_number}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.phone_number && touched.phone_number ? (
            <p className="form-error">{errors.phone_number}</p>
          ) : null}

          <input
            type={SshowPassword}
            placeholder="Password"
            id="password"
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          {errors.password && touched.password ? (
            <p className="form-error">{errors.password}</p>
          ) : null}
          <div className="position-relative pAss_Sty">
            <input
              type={SshowPassword}
              placeholder="Re-type password"
              id="c_password"
              name="c_password"
              value={values.c_password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <i
              onClick={SEye}
              className={`position-absolute fa ${
                Seye ? "fa-eye-slash" : "fa-eye"
              }`}
            ></i>
          </div>
          {errors.c_password && touched.c_password ? (
            <p className="form-error">{errors.c_password}</p>
          ) : null}
                <div className="noti__switches_ noti_inp">
                  <div className="form-check form-switch mb-3">
                    <label
                      className="form-check-label"
                      htmlFor="promotional_email"
                    >
                      Receive Promotional Emails
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="promotional_email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.promotional_email}
                      checked={values.promotional_email}
                    />
                  </div>
                  <div className="form-check form-switch mb-3">
                    <label
                      className="form-check-label"
                      htmlFor="alcohol_email"
                    >
                      Receive offers for alcohol products
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="alcohol_email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.alcohol_email}
                      checked={values.alcohol_email}
                    />
                  </div>
                  <div className="form-check form-switch mb-3">
                    <label
                      className="form-check-label"
                      htmlFor="tobacco_email"
                    >
                      Receive offers for tobacco products
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="tobacco_email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tobacco_email}
                      checked={values.tobacco_email}
                    />
                  </div>
                  {values.alcohol_email || values.tobacco_email ?
                  <div className="my-2">
                    <div className="form-text text-danger ">
                      <span className="note__high"> Do you agree you are born before <b><u>{getDate}</u></b></span>
                      </div>
                  </div>:""
                  }
                </div>
                <div>
                <div className="form-check">
        <input className="form-check-input check_box" type="checkbox" defaultValue id="flexCheckDefault" required />
        <label className="form-check-label" htmlFor="flexCheckDefault">
        I have read and agree to the website <Link to="/privacy-policy"><span className="pri__TEXT">privacy policy</span></Link> and <Link to="/terms-of-use"><span className="pri__TEXT">terms and conditions</span></Link>.
        </label>
      </div>
                </div>
          <button
            type="submit"
            className="btn signup"
            disabled={loading ? true : false}
          >
            {loading ? (
              <div className="spinner-border text-warning" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              "create account"
            )}
          </button>
          {/* <p>Clicking <strong>create account</strong> means that you are agree to our <a href="javascript:void(0)">terms of services</a>.</p> */}
          <hr />
        </form>
      </div>
    </div>
    {/* <PaymentModal toggle={toggle} modal={modal}/> */}

    </>

  );
};

export default Login;
