import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  productID: {},
  foodId: {},
}

export const productCategorySlice = createSlice({
  name: 'productCategory',
  initialState,
  reducers: {
    getProductId: (state , action) => {
        
        state.productID = action.payload

    },
    getFoodId: (state , action) => {
        
        state.foodId = action.payload

    },
    
  },
})

// Action creators are generated for each case reducer function
export const { getProductId , getFoodId } = productCategorySlice.actions

export default productCategorySlice.reducer