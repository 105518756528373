import { clearCartHome } from "../redux/features/slices/cartSlice";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cartimg from "../assets/images/carts.png";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import notfound from "../assets/images/not_found.png";
import { addToCart, getTotals ,clearCart } from "../redux/features/slices/cartSlice";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../config/apiUrl";
import ProductModal from "../components/modal/ProductModal";
import PosRightSideCart from "../components/PosRightSideCart";
import GroceryProductModal from "../components/modal/GroceryProductModal";
import Banner from "../components/slider/Banner";
import PosCategories from "../components/slider/PosCategories";
import Products from "../components/productsCard/Products";
import Pagination from "@material-ui/lab/Pagination";
import { Helmet } from "react-helmet-async";
import QRmodal from "../components/modal/QRmodal";
import user_icon from "../assets/images/user-icon.webp";
import { logout } from "../redux/features/auth/authSlice";
import ProductModalPos from "../components/modal/ProductModalPos";
import GroceryProductModalPos from "../components/modal/GroceryProductModalPos";




const PosSystem = () => {
  const { cartTotalQuantity } = useSelector((state) => state.cart);
  const cart = useSelector((state) => state.cart);

  const [loading, setLoading] = useState(false);
  const [catName, setCatName] = useState();
  const [midCategory, setMidCategory] = useState([]);
  const [products, setProducts] = useState([]);
  const [prod_id, setProd_id] = useState();
  const [popular, setPopular] = useState();
  const [cartVisible, setcartVisible] = useState(false);
  const [cat_id, setCat_id] = useState();
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);
  const [mainID, setMainID] = useState();
  const [productValue, setProductValue] = useState();
  const [popCat, setPopCat] = useState(true);
  const { token } = useSelector((state) => state.auth);
  const [saleProduct, setSaleProduct] = useState([]);
  const [previousOrder, setPreviousOrder] = useState([]);
  const [show, setShow] = useState(false);
  const [seoTags, setSeoTags] = useState()
  const [barCodeMsg, setBarCodeMsg] = useState('');
  const [barCodeProduct, setBarCodeProduct] = useState([]);
  const [seoTagsData, setSeoTagsData] = useState([])
  const [formSubmitted, setFormSubmitted] = useState(false);


  
  const handleShow = () => setShow(true);
  const navigate = useNavigate()
  const dispatch = useDispatch();
  
  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearCart());
    dispatch(clearCartHome());
    navigate("/login");
  };

  const handleClose = () => {
    setShow(false)
  };


  const handleChange = (e, p) => {
    setPage(p);
    const element = document.getElementById("products_id");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const listenToScroll = () => {

    let heightToHide = 160;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > heightToHide) {
      setcartVisible(true);
    } else {
      setcartVisible(false);
    }
  };
  useEffect(() => {
    dispatch(getTotals());
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, [cart, dispatch]);

  const handleMidCategory = () => {
    setPopCat(false);
    setPage(page !== 1 ? 1 : page);
    setCat_id();
    setCatName();
    setPopular();
    fetchMidCategory();
    navigate("/pos-system")
  };
  const fetchMidCategory = async () => {
    var config = {
      method: "get",
      url: baseURL + `/getMidCategories/0?page=${page}`,
      headers: {},
    };
    // setLoader(true)
    setLoading(true);
    await axios(config)
      .then(function (response) {
        const { midCategory, products } = response.data.data;
        setCount(products.last_page);
        setMidCategory(midCategory);
        setProducts(products.data);
        setLoading(false);
        // setLoader(false)
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);

        // setLoader(false)
      });
  };
  const fetchPopularCategory = async () => {
    try {
      setLoading(true);
  
      const response = await axios.get(`${baseURL}/getPopularCategory/0?page=${page}`);
      const { midCategory, products } = response.data.data;
      setCount(products.last_page);
      setMidCategory(midCategory);
      setProducts(products.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPopularItems = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseURL}/getPopularProducts/0?page=${page}`);
      const { data, last_page } = response.data.data;
      setProducts(data);
      setCount(last_page);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };


  const fetchSaleProduct = async () => {
  try {
    const response = await axios.get(baseURL + '/getSaleProducts');
    const { data } = response.data;
    setSaleProduct(data);
  } catch (error) {
    console.error(error);
  }
};

  useMemo(() => fetchSaleProduct(), []);

  const getCatID = (id, name, slug) => {
    const element = document.getElementById("product_id");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setCat_id(id);
    setCatName(name);
    setPopular();
    setPage(page !== 1 ? 1 : page);
    setSeoTags(slug)
    navigate(`/pos-system/${slug}`, { replace: true })
  };
 

  const productID = (value) => {
    setProductValue(value);
    setProd_id(value.id);
    setMainID(value.main_cat_id);
    handleShow();
    setSeoTags(value.slug)
    navigate(`/pos-system/${value.slug}`, { replace: true })
  };

  useEffect(() => {
    const fetchSubCategory = async () => {
      var config = {
        method: "get",
        url: baseURL + `/getSubCategories/${cat_id}?page=${page}`,
        headers: {},
      };
      setLoading(true);
      await axios(config)
        .then(function (response) {
          const { products } = response.data.data;
          setCount(products.last_page);
  
          // setSubCategory(subCategory);
          setProducts(products.data);
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
        });
    };
    if (cat_id) {
      fetchSubCategory();
    }
  }, [cat_id, page]);


  useEffect(() => {
    if (!popular && !cat_id && popCat === true) {
      fetchPopularCategory();
      setPopCat(true);
      navigate("/pos-system")
    }
  // eslint-disable-next-line
  }, [page]);


  useEffect(() => {
    if (popular) {
      fetchPopularItems();
    }
  // eslint-disable-next-line
  }, [popular, page]);

  useEffect(() => {
    if (!cat_id && !popular && popCat === false) {
      fetchMidCategory();
    }
  // eslint-disable-next-line
  }, [page]);


  const fetchPreviousOrder = async () => {
    setLoading(true);
    try {
      const config = {
        method: "get",
        url: `${baseURL}/getPreviousOrder`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios(config);
      const { data } = response.data;
      setPreviousOrder(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    if (token) {
      fetchPreviousOrder();
    }
  // eslint-disable-next-line
  }, []);

  const fetchSeoTags = async () => {
    try {
      const response = await axios.get(`${baseURL}/metatagToShow/${seoTags}`);
      const { data } = response.data;
      setSeoTagsData(data);
    } catch (error) {
      console.log(error);
    }
  };
useEffect(() => {
  if(seoTags){
    fetchSeoTags()
  }
  // eslint-disable-next-line
}, [seoTags])

// ----bar-code--Enter-Start-----

const handleSearchBar = async () => {
  if (!barCodeMsg) {
    // If the input is empty, set the formSubmitted state to true
    setFormSubmitted(true);
    return;
  }
  var FormData = require("form-data");
  var data = new FormData();
  data.append("bar_code", barCodeMsg);

  var config = {
    method: "post",
    url: baseURL + `/getProductByBarCode`,
    // headers: {
    //   ...data.getHeaders()
    // },
    data: data,
  };

  await axios(config)
    .then(function (response) {
      const { data } = response.data;
      setBarCodeProduct(data);
      let aa = {
        ...data,
        num:1,
      };
      dispatch(addToCart(aa));
      setBarCodeMsg("")
    })
    .catch(function (error) {
      console.log(error);
    });
    
};


const handleKeyDown = (event) => {
  if (event.key === "Enter") {

    handleSearchBar();

    
  }
 
};
// ----bar-code--Enter-End-----



  return (
    <>
    <Helmet>
  {seoTagsData.length === 0 ? (
    <title>Makfuels | Shop</title>
  ) : (
    seoTagsData.map((value, index) => {
      const { name, content } = value;
      if (name.toLowerCase() === "title") {
        return <title key={index}>Makfuels | Shop - {content}</title>;
      } else {
        return (
          <title key={index}>
            Makfuels | Shop {seoTags ? `- ${seoTags}` : ""}
          </title>
        );
      }
    })
  )}
  {seoTagsData.map((value, index) => {
    const { name } = value;
    if (name.toLowerCase() !== "title") {
      return <meta name={value.name} content={value.content} key={index} />;
    }
    return null;
  })}
  <link rel="canonical" href="https://makcom.cf/shop" />
</Helmet>

      {/* <Helmet>
      {seoTagsData.map((value,index)=>{
          const {name,content}=value
          if(name.toLowerCase() ==="title"){
          return(
            <title>MAKFUELS | Shop - {content}</title>
        )}else{
          return(
           <title>MAKFUELS | Shop {seoTags ? `- ${seoTags}`:""}</title>
      )}
        })}
       {seoTagsData.map((value,index)=>{
            const {name}=value
            if(name.toLowerCase() !=="title"){
              return(
                <meta name={value.name} content={value.content} key={index}/>
              )
            }
            return null;
          })}
        <link rel="canonical" href="https://makcom.cf/shop" />

      </Helmet> */}
      <section className="container-fluid p-1" style={{backgroundColor:"#ffcc07"}}>
        <div className="d-flex justify-content-between align-items-center">
        <div>
            <h4 className="text-center" style={{color:"#2a2f8a"}}><i className="fa-solid fa-cash-register ms-2 me-2"></i>Point Of Sale (POS)</h4>
        </div>
        <div className="dropdown user_icon">
                  <img
                    className=""
                    width="100%"
                    height="auto"
                    src={user_icon}
                    alt="user-icon"
                  />

                  <div className="dropdown-content">
                    {token && (
                      <button
                        type="button"
                        className=" menu__btns w-100"
                        onClick={handleLogout}
                      >
                        Logout
                      </button>
                    )}
                  </div>
                </div>
        </div>
      </section>
      <section className="container-fluid px-sm-5 px-2 mt-2">

        <div className="row">
          <div className="col-lg-9 col-md-8">
            {/* <Banner /> */}
            <div className="d-flex justify-content-between mt-3">
              <div>
                <h4>Choose Category</h4>
              </div>
              <div className="mb-2">
        {/* <label htmlFor="exampleFormControlInput1" className="form-label">Enter SKU</label> */}
        <input type="text" className="form-control w-100" id="exampleFormControlInput1" placeholder="Enter SKU" value={barCodeMsg} onKeyDown={handleKeyDown} onChange={(e)=>setBarCodeMsg(e.target.value)} required/>
        {formSubmitted && !barCodeMsg && (
    <div className="text-danger">SKU is required</div>
  )}
      </div>
            </div>


            <div className=" mt-2">


              {/* <button
                className="view_all_cat"
                onClick={() => handleMidCategory()}
              >
                View All Categories
              </button> */}
              <PosCategories
                midCategory={midCategory}
                getCatID={getCatID}
                cat_id={cat_id}
                handleMidCategory={handleMidCategory}
              />
            </div>

            {/* //  --------previosOrder_card-start------- */}
            {!cat_id && token && (
              <section className="mt-3">
                {/* <div>
                  <h4 className="mb-3">Previous Order</h4>
                </div> */}
                <Products
                  loading={loading}
                  products={previousOrder}
                  productID={productID}
                />
              </section>
            )}

            {/* //  --------previosOrder_card-end------- */}

            {/* --------products_card-start------- */}
            <section className="mt-3" id="section-1">
              <div id="products_id">
                <p style={{fontSize:"1.5rem"}} className="mb-3">{catName ? catName : "Products"}</p>
              </div>
              {loading && (
                <lottie-player
                  src="https://assets7.lottiefiles.com/private_files/lf30_vb7v5ca0.json"
                  background="transparent"
                  speed={1}
                  style={{ width: "300px", height: "200px", margin: "auto" }}
                  loop
                  autoPlay
                />
              )}
                            {!loading && products && products.length === 0 && (
                <div className="text-center">
                  <img
                    className=""
                    style={{ width: "100px" }}
                    src={notfound}
                    alt="empty-cart"
                  ></img>

                  <h3>Product Not Found</h3>
                  {/* <h5 className="mb-2">Add something to make me happy</h5> */}
                </div>
              )}
              <Products
                loading={loading}
                products={products}
                productID={productID}
                catName={catName}
              />
              <div className="mt-4 mb-4">
                <Pagination
                  count={count}
                  page={page}
                  onChange={handleChange}
                  color="primary"
                />
              </div>
            </section>

            {/* --------products_card-end------- */}

            {/* --------Sale_products-start------- */}
            {saleProduct.length > 0 && (
              <section className="mt-3">
                <div>
                  <h4 className="mb-3">Sale Products</h4>
                </div>
                <Products
                  loading={loading}
                  products={saleProduct}
                  productID={productID}
                />
              </section>
            )}

            {/* --------Sale_products-end------- */}
          </div>
          {/* -------Cart-Start--------- */}
          <div className="col-md-4 col-lg-3 d-none d-lg-block d-md-block">
            <PosRightSideCart />
          </div>
          {/* -------Cart-end--------- */}
        </div>
        {cartVisible && (
          <Link to="/shopping-cart">
            <div className="responsive_cart">
              <img className="" src={cartimg} alt="" />
              <p className="cart-num"> {cartTotalQuantity} </p>
            </div>
          </Link>
        )}
      </section>

      {/* -----Modal-start------ */}
      {/* eslint-disable-next-line */}
      {mainID == "1" && (
        <ProductModalPos
          productValue={productValue}
          prod_id={prod_id}
          show={show}
          seoTags={seoTags}
          catName={catName}
          handleClose={handleClose}
          handleShow={handleShow}
        />
      )}
       {/* eslint-disable-next-line */}
      {mainID !== "1" && (
        <GroceryProductModalPos
          productValue={productValue}
          prod_id={prod_id}
          show={show}
          seoTags={seoTags}
          catName={catName}
          handleClose={handleClose}
          handleShow={handleShow}
        />
      )}
      <QRmodal/>

      {/* -----Modal-end------ */}
    </>
  );
};

export default PosSystem;
