// import { PDFDownloadLink, Document, Page, Text } from '@react-pdf/renderer';
// import React, { useState } from 'react'
// import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
// import ReceiptPDF from '../../pages/ReceiptPDF'
// import { baseURL } from '../../config/apiUrl'
// import axios from 'axios'
// import { toast } from 'react-toastify'
// import { useSelector } from 'react-redux'
// const CollectPaymentModal = ({ toggle, modal }) => {
//     const cart = useSelector((state) => state.cart);
//     const { token } = useSelector((state) => state.auth);
//     const [totalAmount, setTotalAmount] = useState()
//     const [isLoading, setLoading] = useState(false);
//     const [apiResponse, setApiResponse] = useState(null);

//     const handleApiCallAndDownloadPDF = async () => {
//         try {
//             var data = new FormData();
//             data.append("order_items", JSON.stringify(cart.cartItems));
//             data.append("total_price", cart.cartTotalAmount);

//             var config = {
//                 method: "post",
//                 url: baseURL + "/pos_order",
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                 },
//                 data: data,
//             };
//             setLoading(true);
//             await axios(config)
//                 .then(function (response) {
//                     toast.success(response.data.message);
//                     setLoading(false);
//                     setApiResponse(response.data.message);

//                     if (response.data.success == true) {
//                         console.log("ok");
//                     }

//                 })

//         } catch (error) {
//             console.error('Error fetching data:', error);
//             setLoading(false);
//         }
//     };

//     const renderPDFButton = () => {
//         if (isLoading) {
//             return "Loading...";
//         } else if (apiResponse) {
//             return (
//                 <PDFDownloadLink
//                     document={<ReceiptPDF apiResponse={apiResponse} />} // Pass the API response to your ReceiptPDF component
//                     fileName="generated_pdf.pdf"
//                 >
//                     {({ blob, url, loading, error }) => (
//                         <button className="btn check_out_btn w-100">Download PDF</button>
//                     )}
//                 </PDFDownloadLink>
//             );
//         } else {
//             return (
//                 <button
//                     className="btn check_out_btn w-100"
//                     onClick={handleApiCallAndDownloadPDF}
//                 >
//                     PDF
//                 </button>
//             );
//         }
//     };
//     return (
//         <>

//             <Modal isOpen={modal} toggle={toggle} >
//                 <ModalHeader toggle={toggle} >COLLECT PAYMENT</ModalHeader>
//                 <ModalBody>
//                     <div>
//                         <div className='collect_tab_button'>
//                             <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
//                                 <li className="nav-item col-md-6 p-1" role="presentation">
//                                     <button className="nav-link active w-100 collect-btn" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">CASH</button>
//                                 </li>
//                                 <li className="nav-item col-md-6 p-1" role="presentation">
//                                     <button className="nav-link w-100 collect-btn" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">CARD</button>
//                                 </li>
//                             </ul>
//                             <div className="tab-content" id="pills-tabContent">
//                                 <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
//                                     <div>
//                                         <h4 className='text-center mb-3'>Total Payment: <span className='total_pay_green'>$ 321.00</span></h4>
//                                         <div>
//                                             <div className="mb-3 ">
//                                                 <label htmlFor="exampleInputEmail1" className="form-label">Receive Payment:</label>
//                                                 <input type="number" className="form-control" onChange={(e) => setTotalAmount(e.target.value)} />
//                                             </div>
//                                             <div>
//                                                 <p className='return_amount_txt mb-3'>Return Amount: $  {totalAmount - 800.00}</p>
//                                             </div>
//                                             <div>
//                                                 <div className='d-flex gap-2'>
//                                                     {/* <button
//                                                         className="btn check_out_btn w-100"
//                                                     >
//                                                         Print
//                                                     </button> */}
//                                                     {renderPDFButton()}
//                                                     <button
//                                                         className="btn check_out_btn w-100"
//                                                     >
//                                                         Text
//                                                     </button>
//                                                     <button
//                                                         className="btn check_out_btn w-100"
//                                                     >
//                                                         Email
//                                                     </button>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
//                                     <div>
//                                         <h4 className='text-center mb-3'>Total Payment: <span className='total_pay_green'>$ 321.00</span></h4>
//                                         <div>

//                                             <div>
//                                                 <div className='d-flex gap-2'>
//                                                     <button
//                                                         className="btn check_out_btn w-100"
//                                                     >
//                                                         Print
//                                                     </button>
//                                                     <button
//                                                         className="btn check_out_btn w-100"
//                                                     >
//                                                         Text
//                                                     </button>
//                                                     <button
//                                                         className="btn check_out_btn w-100"
//                                                     >
//                                                         Email
//                                                     </button>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </ModalBody>
//             </Modal>

//         </>
//     )
// }

// export default CollectPaymentModal

import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import axios from "axios";
import { baseURL } from "../../config/apiUrl";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReceiptPDF from "../../pages/ReceiptPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useNavigate } from "react-router-dom";
import { clearCartHome } from "../../redux/features/slices/cartSlice";



const CollectPaymentModal = ({ toggle, modal }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

const backHome = () => {
    dispatch(clearCartHome());
    navigate("/pos-system");
    // setApiResponse(false);
    //  toggle();
  };

  const cart = useSelector((state) => state.cart);
  const { cartTotalAmount } = useSelector((state) => state.cart);
  const { token } = useSelector((state) => state.auth);
  const [isLoading, setLoading] = useState(false);
  const [isLoading2, setLoading2] = useState(false);
  const [isSend , setIsSend] = useState(false)
  const [apiResponse, setApiResponse] = useState(false);
  const [totalAmount, setTotalAmount] = useState("");
  const [email, setEmail] = useState("");
  const [textDiv, setTextDiv] = useState("d-none");
  const [emailDiv, setEmailDiv] = useState("d-none");

  const textToggle =() =>{
    setTextDiv(textDiv=="d-none"?"d-block":"d-none")
    setEmailDiv("d-none")
  }
  const emailToggle =() =>{
    setEmailDiv(emailDiv=="d-none"?"d-block":"d-none")
    setTextDiv("d-none")
  }

  const [CartData, setCartData] = useState()

  const handleApiCallAndDownloadPDF = async (e) => {


    try {
      var data = new FormData();
      data.append("order_items", JSON.stringify(cart.cartItems));
      data.append("total_price", cart.cartTotalAmount);
      if (e === "ahmer") {
        // Do nothing or handle a different case if e is "ahmer"
        console.log("email not found")
      } else {
        data.append("email", email);
      }
    //   data.append("email", cart.cartTotalAmount);

      var config = {
        method: "post",
        url: baseURL + "/pos_order",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };
      setLoading(true);
      setLoading2(true);
      const response = await axios(config);
      toast.success(response.data.message);
      setEmail("")
      setIsSend(true)
      setLoading(false);
      setLoading2(false);
      setApiResponse(true);
      setCartData(cart)

    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setLoading2(false);
      setApiResponse(false);
    }
  };

   const handleDownloadPDF = () => {
       toggle();
       setTimeout(() => {
       setApiResponse(false);
       dispatch(clearCartHome());
       setCartData("")
       setTotalAmount("")
       setIsSend(false)
       }, 2000); // Close the modal
   };

  console.log(totalAmount);
  const convertPrice = (input) => {
    // Remove any non-digit characters from the input
    const userInput = input.replace(/[^0-9]/g, "");

    // Convert the input to a number and divide it by 100
    const priceValue = parseFloat(userInput) / 100;

    // Return the converted price rounded to 2 decimal places
    return priceValue.toFixed(2);
  };

  const handleInputChange = (e) => {
    const input = e.target.value;
    const convertedPrice = convertPrice(input);
    setTotalAmount(convertedPrice);
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>COLLECT PAYMENT</ModalHeader>
        <ModalBody>
          <div>
            <div className="collect_tab_button">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item col-md-6 p-1" role="presentation">
                  <button
                    className="nav-link active w-100 collect-btn"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    CASH
                  </button>
                </li>
                <li className="nav-item col-md-6 p-1" role="presentation">
                  <button
                    className="nav-link w-100 collect-btn"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    CARD
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div>
                    <h4 className="text-center mb-3">
                      Total Payment:{" "}
                      <span className="total_pay_green">
                        $ {cartTotalAmount}
                      </span>
                    </h4>
                    <div>
                      <div className="mb-3 ">
                        {console.log(totalAmount)}
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Receive Payment:
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          value={totalAmount}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <p className="return_amount_txt mb-3">
                          Return Amount: ${" "}
                          {totalAmount > 0
                            ? Math.abs(totalAmount - cartTotalAmount).toFixed(2)
                            : 0.0}
                        </p>
                      </div>
                      <div>
                        <div className="d-flex gap-2">
                          {/* <button
                                                        className="btn check_out_btn w-100"
                                                    >
                                                        Print
                                                    </button> */}
                          {apiResponse === false && (
                            <button
                              className="btn check_out_btn w-100"
                              onClick={()=>handleApiCallAndDownloadPDF("ahmer")}
                              
                            >
                              {isLoading ? "Loading..." : "PDF"}
                            </button>
                          )}
                          {/* Render PDF download link only when apiResponse is available */}
                          {apiResponse && (
                            <PDFDownloadLink
                            className="btn check_out_btn w-100"
                            ahmer={CartData}
                            // apiResponse={apiResponse}
                              document={<ReceiptPDF apiResponse={apiResponse} CartData={CartData} />} // Pass the API response to ReceiptPDF
                              fileName="generated_pdf.pdf"
                               onClick={handleDownloadPDF}
                            >
                              {({ blob, url, loading, error }) => (
                                <p >
                                  Download PDF
                                </p>
                              )}
                            </PDFDownloadLink>
                          )}
                            <button className="btn check_out_btn w-100" onClick={textToggle}>
                            Text
                          </button>
                          <button className="btn check_out_btn w-100" onClick={emailToggle}>
                            Email
                          </button>

                        </div>

                      </div>
                      <div className={`mt-2 ${textDiv}`}>
                        <input type="email" className="form-control " id="exampleFormControlInput1" placeholder="Enter Number" />

                        </div>
                        <div className={`mt-2 position-relative  ${emailDiv}`}>
                        <input type="email" disabled={(isSend)?true:false} className="form-control" onChange={(e) => setEmail(e.target.value)} placeholder="Enter Email" />
                              
                        {isLoading2?
                        <button disabled={true} className="send_email__btn">Sending...</button> :
                         <button className="send_email__btn" 
                        disabled={
                          (isSend)?true:false
                        }
                        onClick={()=>handleApiCallAndDownloadPDF("email")}
                        >{(isSend)?"Already Send":"Send Email" }</button>}
                        </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div>
                    <h4 className="text-center mb-3">
                      Total Payment:{" "}
                      <span className="total_pay_green">
                        $ {cartTotalAmount}
                      </span>
                    </h4>
                    <div>
                      <div>
                        <div className="d-flex gap-2">
                          <button className="btn check_out_btn w-100">
                            Print
                          </button>
                          <button className="btn check_out_btn w-100">
                            Text
                          </button>
                          <button className="btn check_out_btn w-100">
                            Email
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CollectPaymentModal;