import axios from "axios";
import { useFormik } from "formik";
import React, {   useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CheckoutCart from "../components/CheckoutCart";
import { baseURL } from "../config/apiUrl";
import { checkoutSchema } from "../schemas";
import { Helmet } from "react-helmet-async";
// import credit_img from "../assets/images/credit-card.png"
// import CardDeleteModal from "../components/modal/CardDeleteModal";

const Checkout = () => {
  const [filledData, setfilledData] = useState();
  const cart = useSelector((state) => state.cart);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState();
  const [phoneNumber, setphoneNumber] = useState("");
  const [selectState, setSlectState] = useState();
  const [selectCity, setSelectCity] = useState();
  const [isError , setIsError] = useState(false);
  const navigate = useNavigate();
  console.log(cart.cartItems)

  const PHONE_REGEX =  new RegExp(/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/);

  const initialValues = {
    card_holder_name: filledData?.card_holder_name || "",
    card_holder_last_name: filledData?.card_holder_last_name || "",
    card_number: filledData?.card_number || "",
    expiration_month: filledData?.expiration_month || "",
    expiration_year: filledData?.expiration_year || "",
    cvc: filledData?.cvc || "",
    zipcode: filledData?.zipcode || "",
    // state: filledData?.state || "" ,
    // city: filledData?.city || "",
    phone_number: filledData?.phone_number || "",
    email: filledData?.email || "",
    billing_details: filledData?.billing_details || "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema: checkoutSchema,
      onSubmit: async (values, action) => {
        var data = new FormData();
        data.append("card_holder_name", values.card_holder_name);
        data.append("card_holder_last_name", values.card_holder_last_name);
        data.append("card_number", (values.card_number).replace(/-/g, ''));
        data.append("expiration_month", values.expiration_month);
        data.append("expiration_year", values.expiration_year);
        data.append("cvc", values.cvc);
        data.append("zipcode", values.zipcode);
        data.append("state", selectState);
        data.append("city", selectCity);
        data.append("phone_number", values.phone_number);
        data.append("email", values.email);
        data.append("billing_details", values.billing_details);
        data.append("order_items", JSON.stringify(cart.cartItems));
        data.append("total_price", cart.cartTotalAmount);

        var config = {
          method: "post",
          url: baseURL + "/book_order",
          data: data,
        };
        setLoading(true);
        await axios(config)
          .then(function (response) {
            toast.success(response.data.message);
            setTimeout(() => {
              navigate("/order-confirmation", {
                state: { orderID: response.data.data },
              });
              setLoading(false);
            }, 2000);
          })
          .catch(function (error) {
            toast.error(error.response.data.message);
            console.log(error);
            setLoading(false);
          });
      },
    });
    const handleCardNumberChange = (e) => {
      // Remove all non-numeric characters
      const input = e.target.value.replace(/\D/g, '');
    
      // Insert "-" after every 4 characters
      const formattedInput = input.replace(/(\d{4})(?=\d)/g, '$1-');
    
      // Limit the input to 19 characters (16 digits and 3 dashes)
      const truncatedInput = formattedInput.slice(0, 19);
    
      // Update the card number in the form values
      handleChange({
        target: {
          name: 'card_number',
          value: truncatedInput,
        },
      });
    }

    function formatPhoneNumber(input) {
      //  if the input is null, return a null value
      if (!input) return input;
      // remove all characters from the input except number input.
      const numberInput = input.replace(/[^\d]/g, "");
      //  take the length of the value of the input
      const numberInputLength = numberInput.length;
      // if the number length is 1, 2, or 3, then return it as it is.
      if (numberInputLength < 4) {
        return numberInput;
      } else if (numberInputLength < 7) {
        // if the number input length is 4, 5, or 6, format it accordingly.
        return `(${numberInput.slice(0, 3)}) ${numberInput.slice(3)}`;
      } else {
        //  if the number input length is 7, 8, 9, 10, or more, format it like the below.
        return `(${numberInput.slice(0, 3)}) ${numberInput.slice(
          3,
          6
        )}-${numberInput.slice(6, 10)}`;
      }
      // return empty string in case any condition doesn't satisfy.
      return "";
    }
  
  
  
    const handlephoneNumber = (e) => {
      // format phone number
      const formattedPhoneNumber = formatPhoneNumber(e.target.value);
      //  set the formatted phone number to the input value
      setphoneNumber(formattedPhoneNumber);
       
    };


useMemo(() => {
  const fetchStateAndCities = async () => {
    try {
      const formData = new FormData();
      formData.append("zip_code", values.zipcode);

      const response = await axios.post(baseURL + "/getStateAndCity", formData);
      const { city, state } = response.data.data || {};

      setSelectCity(city);
      setSlectState(state);
    } catch (error) {
      console.log(error);
      setSelectCity();
      setSlectState();
    }
  };

  if (String(values.zipcode).length >= 4) {
    fetchStateAndCities();
  }
}, [values.zipcode]);


  const fetchUserDetail = async(e) => {
    if (e.target.value.includes(".com")) {
      var data = new FormData();
      data.append("email", e.target.value);

      var config = {
        method: "post",
        url: baseURL + "/get_details",
        data: data,
      };

      await axios(config)
        .then(function (response) {
          setfilledData(response.data.data.user_details);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const handleSubmitCash = async (e) => {
    e.preventDefault();
     if (PHONE_REGEX.test(phoneNumber) === false) {
      setIsError(true)
      setTimeout(() => {
        setIsError(false)
      }, 2000);
      return
     }
    var data = new FormData();
    data.append("email", email);
    data.append("phone_number", phoneNumber);
    data.append("order_items", JSON.stringify(cart.cartItems));
    data.append("total_price", cart.cartTotalAmount);

    var config = {
      method: "post",
      url: baseURL + "/cash_order",
      data: data,
    };
    setLoading(true);
    await axios(config)
      .then(function (response) {
        toast.success(response.data.message);
        setTimeout(() => {
          navigate("/order-confirmation", {
            state: { orderID: response.data.data },
          },{replace:true});
          setLoading(false);
        }, 2000);
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
        console.log(error);
        setLoading(false);
      });
  };

  const { token } = useSelector((state) => state.auth);
  const [selectedCard, setSelectedCard] = useState("");
  const [paymentCard, setPaymentCard] = useState([]);

  const handleOptionChange = (e) => {
    const cardId = e.target.id;
    if (selectedCard === cardId) {
      setSelectedCard(''); 
    } else {
      setSelectedCard(cardId); 
    }
  };
  const fetchPaymentCart = () => {
    let config = {
      method: "get",
      url: baseURL + `/getCardDetails`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setPaymentCard(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if(token){
      fetchPaymentCart();
    }
    // eslint-disable-next-line
  }, [token]);

  const handleSubmitCard = async() => {
    var data = new FormData();
    data.append("card_id", selectedCard);
    data.append("order_items", JSON.stringify(cart.cartItems));
    data.append("total_price", cart.cartTotalAmount);

    var config = {
      method: "post",
      url: baseURL + "/book_order",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    setLoading(true);
   await axios(config)
      .then(function (response) {
        toast.success(response.data.message);
        setTimeout(() => {
          navigate("/order-confirmation", {
            state: { orderID: response.data.data },
          });
          setLoading(false);
        }, 2000);
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
        setLoading(false);
      });
  };
  return (
    <>
        <Helmet>
        <title>Makfuels | Checkout</title>
        <link rel="canonical" href="https://makcom.cf/checkout" />
      </Helmet>    
      <section className="container-fluid px-lg-5 px-2 pt-4 mt-2">
      <div className="row">
        <div className="col-md-9 mb-3">
          <div className="p-3 pay__details_ naV__StyLe">
            <div className="d-flex align-items-center">
              <h1 style={{fontSize:"1.5rem"}} className="me-3">Payment Details</h1>
              <ul className="nav nav-pills" id="pills-tab" role="tablist">
                {/* <li className="nav-item" role="presentation">
                  <button
                    className="nav-link "
                    id="pills-credit-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-credit"
                    type="button"
                    role="tab"
                    aria-controls="pills-credit"
                    aria-selected="true"
                  >
                    Credit Card
                  </button>
                </li> */}
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-cash-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-cash"
                    type="button"
                    role="tab"
                    aria-controls="pills-cash"
                    aria-selected="false"
                  >
                    Cash Counter
                  </button>
                </li>
              </ul>
            </div>
            {/* -----tabs-Start---- */}

            <div className="tab-content" id="pills-tabContent">
              {/* <div
                className="tab-pane fade "
                id="pills-credit"
                role="tabpanel"
                aria-labelledby="pills-credit-tab"
              >
              {token &&
                        <div>
                        {paymentCard &&
                    paymentCard.map((value, index) => {
                      return(
                      <div className="mb-2" key={index}>
                        <div className="card_details__main_div">
                          <div className="card_number mb-2">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="cardID"
                                id={value.id}
                                checked={selectedCard == value.id}
                                onChange={handleOptionChange}
                              />
                              <label className="form-check-label" htmlFor={value.id}>
                                XXXX-XXXX-XXXX-
                                <span className="fw-bold card_last_four">
                                  {value.card_number}
                                </span>
                              </label>
                            </div>
                          </div>
                          {selectedCard == value.id && (
                            <div>
                              <button
                                className="qr_add_new_card"
                                onClick={() => handleSubmitCard()}
                                disabled={loading ? true : false}
                              >
                                {loading ? (
                                      <div
                                        className="spinner-border text-secondary"
                                        role="status"
                                      >
                                        <span className="visually-hidden">Loading...</span>
                                      </div>
                                    ) : (
                                      "CONFIRM"
                                    )}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                        )})}
                        </div>

            }
                <form onSubmit={handleSubmit}  className={`col-md-9 ${ selectedCard === "" ? "d-block" : "d-none"}`}>
                  <div className="py-2">
                    <div className="row py-2">
                      <div className="col-md-4 pt-md-0 pt-3">
                        <label>Email</label>
                        <input
                          type="text"
                          className="bg-light form-control"
                          placeholder="name@example.com"
                          id="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onKeyUp={fetchUserDetail}
                        />
                        {errors.email && touched.email ? (
                          <p className="form-error">{errors.email}</p>
                        ) : null}
                      </div>
                      <div className="col-md-4">
                        <label>Card Holder First Name</label>
                        <input
                          type="text"
                          className="bg-light form-control"
                          placeholder="Haris"
                          id="card_holder_name"
                          name="card_holder_name"
                          value={values.card_holder_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.card_holder_name && touched.card_holder_name ? (
                          <p className="form-error">
                            {errors.card_holder_name}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-4 pt-md-0 pt-3">
                        <label>Last Name </label>
                        <input
                          type="text"
                          className="bg-light form-control"
                          placeholder="Umer"
                          id="card_holder_last_name"
                          name="card_holder_last_name"
                          value={values.card_holder_last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.card_holder_last_name &&
                        touched.card_holder_last_name ? (
                          <p className="form-error">
                            {errors.card_holder_last_name}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-6 pt-md-0 pt-3">
                        <label>Card Number</label>
                        <input
  type="text"
  className="bg-light form-control"
  placeholder={12345678912345}
  id="card_number"
  name="card_number"
  value={values.card_number}
  onChange={handleCardNumberChange} // Use the custom change handler
  onBlur={handleBlur}
/>
                        {errors.card_number && touched.card_number ? (
                          <p className="form-error">{errors.card_number}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="row py-2">
                      <div className="col-md-3">
                        <label>Expiration Month</label>
                        <select
                          className="form-select"
                          id="expiration_month"
                          name="expiration_month"
                          value={values.expiration_month}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option>Month</option>
                          <option value="01">Jan</option>
                          <option value="02">Feb</option>
                          <option value="03">Mar</option>
                          <option value="04">Apr</option>
                          <option value="05">May</option>
                          <option value="06">Jun</option>
                          <option value="07">Jul</option>
                          <option value="08">Aug</option>
                          <option value="09">Sep</option>
                          <option value="10">Oct</option>
                          <option value="11">Nov</option>
                          <option value="12">Dec</option>
                        </select>
                        {errors.expiration_month && touched.expiration_month ? (
                          <p className="form-error">
                            {errors.expiration_month}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-3 pt-md-0 pt-3">
                        <label>Expiration Year</label>
                        <select
                          className="form-select"
                          id="expiration_year"
                          name="expiration_year"
                          value={values.expiration_year}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option>Year</option>
                          <option value={2023}>2023</option>
                          <option value={2024}>2024</option>
                          <option value={2025}>2025</option>
                          <option value={2026}>2026</option>
                          <option value={2027}>2027</option>
                          <option value={2028}>2028</option>
                          <option value={2029}>2029</option>
                          <option value={2030}>2030</option>
                        </select>
                        {errors.expiration_year && touched.expiration_year ? (
                          <p className="form-error">{errors.expiration_year}</p>
                        ) : null}
                      </div>
                      <div className="col-md-3 pt-md-0 pt-3">
                        <label>CVC</label>
                        <input
                          type="number"
                          className="bg-light form-control"
                          placeholder={123}
                          id="cvc"
                          name="cvc"
                          value={values.cvc}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.cvc && touched.cvc ? (
                          <p className="form-error">{errors.cvc}</p>
                        ) : null}
                      </div>
                      <div className="col-md-3 pt-md-0 pt-3">
                        <label htmlFor="zipcode">Zipcode</label>
                        <input
                          type="number"
                          className="bg-light form-control"
                          placeholder={12345}
                          id="zipcode"
                          name="zipcode"
                          value={values.zipcode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.zipcode && touched.zipcode ? (
                          <p className="form-error">{errors.zipcode}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="row py-2">
                      <div className="col-md-6">
                        <label>State</label>

                        <select className="form-select" value={selectState}  disabled>

                            {selectState === undefined ? <option value={0} >Select State</option>:

                            <option value={selectState} >{selectState}</option>
                            }

                            </select>
                        {errors.state && touched.state ? (
                          <p className="form-error">{errors.state}</p>
                        ) : null}
                      </div>
                      <div className="col-md-6 pt-md-0 pt-3">
                        <label>City</label>
                          <select className="form-select"  value={selectCity}  disabled>

                          {selectCity === undefined ? <option value={0} >select City</option>:

                          <option value={selectCity} >{selectCity}</option>
                          }

                        </select>
                       
                      </div>
                    </div>
                    <div className="row py-2">
                      <div className="col-md-6">
                        <label htmlFor="state">Phone Number</label>
                        <input
                          type="number"
                          className="bg-light form-control"
                          placeholder=""
                          id="phone_number"
                          name="phone_number"
                          value={values.phone_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.phone_number && touched.phone_number ? (
                          <p className="form-error">{errors.phone_number}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="row py-2">
                      <div className="col-md-12">
                        <label>Billing Address</label>
                        <input
                          type="text"
                          className="bg-light form-control"
                          placeholder="xyz address"
                          id="billing_details"
                          name="billing_details"
                          value={values.billing_details}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.billing_details && touched.billing_details ? (
                          <p className="form-error">{errors.billing_details}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="py-3 pb-4">
                      <button
                        type="submit"
                        className="btn check_out_btn mr-3"
                        disabled={loading ? true : false}
                      >
                        {loading ? (
                          <div
                            className="spinner-border text-secondary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          "Confirm"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div> */}
              <div
                className="tab-pane fade show active"
                id="pills-cash"
                role="tabpanel"
                aria-labelledby="pills-cash-tab"
              >
                <form onSubmit={handleSubmitCash}>
                  <div className="py-2">
                  <div className="alert alert-warning d-flex align-items-center" role="alert">
                  <i className="fa-sharp fa-solid fa-circle-exclamation fs-4 me-3"></i>
                    <div>
                    Order Preparation will start once you pay at cash counter
                    </div>
                  </div>
                    <div className="row py-2">
                    <div className="col-sm-6 pt-md-0 pt-3">
                        <label>Phone Number</label>
                        <input
                          className="bg-light form-control"
                          id="phone_number"
                          placeholder="(555) xxx-xxxx"
                          onChange={(e) => handlephoneNumber(e)} value={phoneNumber}
                          required
                        />
                        {(isError)? <p className="mt-1" style={{color:"red"}}>Please enter a valid mobile number !</p>:""}
                        <span style={{fontSize:"9px"}}>(When entering your phone number, we will send you an invoice for your order)</span>
                      </div>
                      <div className="col-sm-6 pt-md-0 pt-3">
                        <label>Email</label>
                        <input
                          type="email"
                          className="bg-light form-control"
                          placeholder="name@example.com"
                          id="email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
               
                    </div>
                    <div className="py-3 pb-4">
                      <button
                        type="submit"
                        className="btn check_out_btn mr-3"
                        disabled={loading ? true : false}
                      >
                        {loading ? (
                          <div
                            className="spinner-border text-secondary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          "Confirm"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* -----tabs-end---- */}
          </div>
        </div>
        {/* -------Cart-Start--------- */}
        <div className="col-md-3">
          <CheckoutCart/>
        </div>
        {/* -------Cart-end--------- */}
      </div>
    </section>
    {/* <CardDeleteModal
        toggle={toggle}
        modal={modal}
        deleteID={deleteID}
        fetchPaymentCart={fetchPaymentCart}
      /> */}
    </>

  );
};

export default Checkout;
