import React from 'react'
import FproductImg from "../../assets/images/food_product.png";
import GproductImg from "../../assets/images/grocery_product.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Products = (props) => {
    const {loading,products,productID}=props
  return (
    <>
                {/* --------products_card-start------- */}
                

              <div className="row">                
                {!loading &&
                  products &&
                  products.map((value, index) => (
                    <div
                      className="col-lg-3 col-md-4 col-sm-6 col-6 mb-3"
                      key={index}
                    >
                      <span
                        className="prod_mod"
                        onClick={() => productID(value)}
                      >
                        <div className="card main_card__ position-relative">
                        {value.sale === "1" && <span className="sale_tag">Sale</span>}
                          <LazyLoadImage
                          height={140}
                          width="100%"
                            src={value.image}
                            fetchpriority="high"
                            loading='lazy'
                            className="card-img-top"
                            alt={value.name ? value.name : "product"}
                            title={value.name}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src =
                                value.main_cat_id === "1"
                                  ? FproductImg
                                  : GproductImg;
                            }}
                          />
                          <div className="card-body">
                            <p>{value.name}</p>
                           {/* {value.price !== null &&
                            <p>
                              ${value.price}
                            </p>} */}
                          </div>
                        </div>
                      </span>
                    </div>
                  ))}
              </div>

  
            {/* --------products_card-end------- */}
    </>
  )
}

export default Products;