import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { Change__Password } from "../schemas";
import { toast } from "react-toastify";
// import qs from 'qs';
import { baseURL } from "../config/apiUrl";


const initialValues = {
    password: "",
    c_password: "",
  };

const UserPasswordChange = () => {

  const[eye,seteye]=useState(true);
const[showPassword,setshowPassword]=useState("password");
const Eye=()=>{
  if(showPassword==="password"){
      setshowPassword("text");
      seteye(false);
  }
  else{
      setshowPassword("password");
      seteye(true);
  }
}

  const { token } = useSelector((state) => state.auth);

 // -----------User Post Password API-Start-----------
 const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Change__Password,
    onSubmit: async (values, action) => {
        var FormData = require('form-data');
        var data = new FormData();
        data.append('password', values.password);
        data.append('c_password', values.c_password);    
        
        var config = {
          method: 'post',
          url: baseURL + '/change_password',
          headers: { 
            'Authorization': `Bearer ${token}`, 
          },
          data : data
        };
        
       await axios(config)
        .then(function (response) {
           toast.success(response.data.message);

        })
        .catch(function (error) {
          console.log(error);
        });
        

      action.resetForm();
    },
  });



  return (
    <div
    className="modal fade"
    id="changePassModal"
    tabIndex={-1}
    // aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">
            Edit Customer Password
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            <div className="p-1">
                <div className="row">
                <div className="col-md-12 mb-2">
                        <label htmlFor="name" className="form-label">
                          Password
                        </label>
                        <input
                          type={showPassword}
                          className="form-control"
                          id="password"
                          autoComplete="off"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          placeholder=""
                        />
                        {errors.password && touched.password ? (
                          <p className="form-error">{errors.password}</p>
                        ) : null}
                      </div>
                      <div className="col-md-12 mb-2">
                        
                        <label htmlFor="name" className="form-label">
                        Confirm Password
                        </label>
                        <div className="position-relative">
                        <input
                          type={showPassword}
                          className="form-control"
                          id="c_password"
                          autoComplete="off"
                          name="c_password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.c_password}
                          placeholder=""
                        />
                        <i onClick={Eye} className={`position-absolute edit_pass_eye fa ${eye ? "fa-eye-slash" : "fa-eye" }`}></i>
                        </div>
                        {errors.c_password && touched.c_password ? (
                          <p className="form-error">{errors.c_password}</p>
                        ) : null}
                        
                      
                      </div>
                </div>
              
            </div>
          </div>
          <div className="modal-footer">
                {errors.c_password ? (<button
                  type="submit"
                  className="btn check_out_btn"
                >
                  Save
                </button>):<button
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  type="submit"
                  className="btn check_out_btn"
                >
                  Save
                </button>}
          </div>
        </form>
      </div>
    </div>
  </div>
  )
}

export default UserPasswordChange