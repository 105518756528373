import React from 'react'
import { Link } from 'react-router-dom';

const QRmodal = () => {
  return (
    <div>
    <div className="modal fade" id="qrModal" data-bs-backdrop="static" tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header" style={{backgroundImage: 'linear-gradient(#f8c301, #dcae00)'}}>
            {/* <h5 className="modal-title" >Modal title</h5> */}
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body text-center already_login">
          {/* <i className="fa-solid fa-triangle-exclamation"></i> */}
           <p>Already have account? <Link to="/login" ><span className='qr_logi_btn' data-bs-dismiss="modal" aria-label="Close">Login</span></Link></p>
           <p>OR</p>
           <p><Link to="/login"><button className='qr_reg__now' data-bs-dismiss="modal" aria-label="Close">Register Now</button></Link></p>
          </div>
          {/* <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" className="btn check_out_btn" data-bs-dismiss="modal" onClick={handleLogout}>Logout</button>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  )
}

export default QRmodal