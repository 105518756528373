import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link,useNavigate } from "react-router-dom";
import { baseURL } from "../config/apiUrl";
import axios from "axios";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";

const ScanQRDetail = () => {
  const { token } = useSelector((state) => state.auth);
  const [qrData, setQrData] = useState()
  // const [variation, setVariation] = useState([])
  const [email, setEmail] = useState()
  const [number, setNumber] = useState()
  const [loading, setLoading] = useState(false)
  const [isShow , setIsShow] = useState(false)
  const { state } = useLocation();
  const navigate = useNavigate()

  const postQrCode = () => {
    let data = new FormData();
    data.append("value", state.data);

    let config = {
      method: "post",
      url: baseURL + `/scan`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    setLoading(true)
    axios(config)
      .then(function (response) {
        setQrData(response.data.data)
       setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };

  // const fetchVariation = () => {

  //   let config = {
  //     method: "get",
  //     url: baseURL + `/getVariation`,
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };
  //   setLoading(true);
  //   axios(config)
  //     .then(function (response) {
  //       setVariation(response.data.data)
  //   setLoading(false);

  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //   setLoading(false);

  //     });
  // };
  

  useEffect(() => {
    // fetchPaymentCart()
    // fetchVariation()
    postQrCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // const [selectedOption, setSelectedOption] = useState('');

  // const handleOptionChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };

  const handleSubmit = () =>{
    if(email === undefined || number === undefined){
      toast.error("Please filled all fields")
    }
    else{
    //    navigate("/qr-payment", {
    //   state: {
    //     // variation_id:selectedOption,
    //     qr_id:qrData.id,
    //     price:priceValue
    //   }
    // })


    setLoading(true)

    setTimeout(() => {
      console.log("thank you for scanning qr code...");
      setLoading(false)
      setIsShow(true)
    }, 1200);


    }

  
    
  }

  if (loading) {
    return ( 
    <div className="main_spinner_divv">
      
      <Spinner color="warning" type="grow"/>
      </div>
    )}

  return (
    <div className="container mt-3">
      <Link to="/qr_scanner">
        <button className="back_btn_q">
          <i className="fa-solid fa-left-long me-1"></i>Back
        </button>
      </Link>
      <div className="qr__main_s mt-2">
        <div className="loca__qr_s py-4">
          {/* <p>6901 Howdershell Rd Hazelwood, MO, 63042</p> */}
          {/* <p>{state.data}</p> */}
          <h6>
            <span className="text-secondary"> Station Address :</span>
            <span className=" fw-bold">
              {" "}
              {qrData?.location_address}
            </span>
          </h6>
          {/* <p></p> */}

          <p className="fw-bold pt-3">Pump No. {qrData?.pump}</p>
        </div>
      </div>
      {/* <div className="mt-5">
      {variation&&variation.map((value, index) => (
        <div className="form-check ms-2" key={index}>
          <input
            className="form-check-input"
            type="radio"
            name="variations"
            id="variations"
            value={value.id}
            // checked={selectedOption === value.id}
            onChange={handleOptionChange}
          />
          <label className="form-check-label ps-3" htmlFor="variations">
            {value.name}
          </label>
        </div>
      ))}

      </div> */}

{
  (isShow)? <h2 className="qr_thank_you m-4 text-center">thank you</h2>:

<div className="enter__amount__qr">

        <p className="entergasAmount mb-2">Enter Your Email : </p>
        <input
          type="email"
          className="form-control mb-3"
          placeholder="Email Address"
          value={email}
          onChange={(e)=>setEmail(e.target.value)}
        /> 

        <p className="entergasAmount mb-2">Enter Your Number :</p>
        <input
          type="number"
          className="form-control mb-3"
          placeholder="1234567890"
          value={number}
          onChange={(e)=>setNumber(e.target.value)}
        />
        {/* <Link to="/qr-payment"> */}
          <button className="btn check_out_btn w-100" onClick={handleSubmit}>Confirm</button>
        {/* </Link> */}

      </div>

}

    </div>
  );
};

export default ScanQRDetail;
