// import React from "react";
// import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
// import { useSelector } from "react-redux";


// const styles = StyleSheet.create({
//   page: {
//     flexDirection: "column",
//     backgroundColor: "#ffffff",
//     padding: 20,
//   },
//   header: {
//     textAlign: "center",
//     marginBottom: 10,
//   },
//   item: {
//     flexDirection: "row",
//     justifyContent: "space-between",
//     marginBottom: 5,
//   },
//   total: {
//     flexDirection: "row",
//     justifyContent: "space-between",
//     marginTop: 10,
//     borderTopWidth: 1,
//     paddingTop: 5,
//   },
// });

// const ReceiptPDF = ( { apiResponse } ) => {
//     const { cartItems, cartTotalAmount, cartTotalTax, cartSubTotalAmount } =
// useSelector((state) => state.cart);
//   return (
//     <Document>
//       <Page size="A4" style={styles.page}>
//         <Text style={styles.header}>Receipt</Text>
//         {cartItems.map((items, index) => (
//           <View style={styles.item} key={index}>
//             <Text>{items.cartQuantity} x {items.name}</Text>
//             <Text>${items.main_cat_id === 2 ? items.price : items.selectedSubproduct?.price}</Text>
//           </View>
//         ))}
//         <View style={styles.total}>
//           <Text>Sub total</Text>
//           <Text>${cartSubTotalAmount}</Text>
//         </View>
//         <View style={styles.total}>
//           <Text>Tax</Text>
//           <Text>${cartTotalTax}</Text>
//         </View>
//         <View style={styles.total}>
//           <Text>Total</Text>
//           <Text>${cartTotalAmount}</Text>
//         </View>
//       </Page>
//     </Document>
    
//   );
// };

// export default ReceiptPDF;

import React, { useEffect, useState } from 'react';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logo from "../assets/images/logo.jpg"
// import { useSelector } from "react-redux";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 20,
  },
  header: {
    textAlign: "center",
    marginBottom: 10,
    color: "red"
  },
  item: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  total: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
    borderTopWidth: 1,
    paddingTop: 5,
  },
  logo: {
    width: 50, // Adjust the width as needed
    height: 50, // Adjust the height as needed
    marginBottom: 10,
  },
  loopMain:{
    fontSize: "12px",
    flexDirection: "row",
    justifyContent: "space-between",

  },
  marginRight:{
    marginRight: "10px",
  },
  sub:{
    fontSize: "13px",
    color:"green"
  },
  productName: {
    fontSize:"16px",
    width:"85%",
  },
  marginBottom:{
    marginBottom: "10px",
  },
});


const ReceiptPDF = ({ apiResponse,CartData }) => {
  const [friesNum, setFriesNum] = useState();

  useEffect(() => {
    if (CartData) {
      setFriesNum(CartData);
    }
  }, []);
  console.log(CartData)
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image
          style={styles.logo}
          src={logo} // Replace with the path to your logo image
        />
        <Text style={styles.header}>Receipt</Text>
        {CartData?.cartItems?.map((items, index) => (
          <View style={styles.marginBottom}>
            <View style={styles.item} key={index}>
              <Text style={styles.productName}>
                {items.cartQuantity} x  {items.prod_name} {items.name} {items.main_cat_id ==1 ?`(${items?.selectedSubproduct?.name})`:""}
              </Text>
              
                
                {items.main_cat_id == 2 && <Text>$ {items.price} </Text>}
                    {items.main_cat_id == 1 && (
                      <Text>
                        ${" "}
                        {items.is_sub_avaiable == 0
                          ? items.price
                          : items.selectedSubproduct?.price}
                      </Text>
                    )}
              
            </View>
            <View style={styles.marginBottom}>
              {items.main_cat_id == 1 &&
                items.is_sub_avaiable == 1 &&
                Object.keys(items.selectedFriesType)?.length > 0 && (
                  <View style={styles.loopMain}>
                    <Text style={styles.marginRight}>
                      {items.selectedFriesType?.type} (
                      {items.selectedFriesSize?.name})
                    </Text>

                    <Text>
                      ${" "}
                      {items.FriesResult == 0
                        ? "Free"
                        : items.FriesResult.toFixed(2)}
                    </Text>
                  </View>
                )}
            </View>
            <View>
              {items &&
                items.main_cat_id == 1 &&
                items.is_sub_avaiable == 1 &&
                Object.keys(items?.selectedFountainDrinks)?.length > 0 && (
                  <View style={styles.loopMain}>
                    <Text style={styles.marginRight}>
                      {items?.selectedFountainDrinks?.name} (
                      {items?.selectedSoftDrinks?.name})
                    </Text>
                    <Text>
                      ${" "}
                      {items?.selectedSoftDrinks?.price == null
                        ? "Free"
                        : items?.selectedSoftDrinks?.price}
                    </Text>
                  </View>
                )}
            </View>
            <View>
              {items.main_cat_id == 1 &&
              items?.sidesArray?.length > 0 &&
              items.is_sub_avaiable == 1 ? (
                <View>
                  <Text style={styles.sub}>Sides</Text>
                  {items?.sidesArray.map((val, index) => {
                    return (
                      <View key={index} style={styles.loopMain}>
                        <Text style={styles.marginRight}>{val.type}</Text>
                        <Text>
                          $ {val.price} x {items.cartQuantity}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              ) : null}
            </View>
            <View>
              {items.main_cat_id == 1 &&
              items?.cheesesArray?.length > 0 &&
              items.is_sub_avaiable == 1 ? (
                <View>
                  <Text style={styles.sub}>Cheeses</Text>
                  {items.cheesesArray.map((val, index) => {
                    return (
                      <Text key={index} style={styles.loopMain}>
                        {val.type}{" "}
                      </Text>
                    );
                  })}
                </View>
              ) : null}
            </View>
            <View>
              {items.main_cat_id == 1 &&
              items?.toppsecondArray?.length > 0 &&
              items.is_sub_avaiable == 1 ? (
                <View >
                  <Text style={styles.sub}>Toppings</Text>
                  {items?.toppsecondArray?.map((val, index) => {
                    return <Text key={index} style={styles.loopMain}>{val.type} </Text>;
                  })}
                </View>
              ) : null}
            </View>
                        <View>
              {items.main_cat_id == 1 &&
              items?.cookingSauceArray?.length > 0 &&
              items.is_sub_avaiable == 1 ? (
                <View >
                  <Text style={styles.sub}>Sauces</Text>
                  {items?.cookingSauceArray?.map((val, index) => {
                    return <Text key={index} style={styles.loopMain}>{val.type} </Text>;
                  })}
                </View>
              ) : null}
            </View>
            <View>
            <View >

                      <View>
                      {items?.toppingsArray.map((val, index) => {
                        return (
                          <View key={index} style={styles.loopMain}>
                            <Text style={styles.marginRight}>{val.type}</Text>
                            <Text>
                              $ {val.price} x {items.cartQuantity}
                            </Text>
                          </View>
                        );
                      })}
                    </View>
                </View>
            </View>
          </View>
        ))}
        <View style={styles.total}>
          <Text>Sub total</Text>
          <Text>${CartData?.cartSubTotalAmount}</Text>
        </View>
        <View style={styles.total}>
          <Text>Tax</Text>
          <Text>${CartData?.cartTotalTax}</Text>
        </View>
        <View style={styles.total}>
          <Text>Total</Text>
          <Text>${CartData?.cartTotalAmount}</Text>
        </View>
      </Page>
    </Document>
  );
};

export default ReceiptPDF;
