import React from "react";
import {  Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function DeliveryChargesModal({ toggle, modal }) {
  return (
    <div>
      <Modal
        className="product_detals_modal"
        isOpen={modal}
        toggle={toggle}
        centered={true}
      >
        <ModalHeader toggle={toggle}>Delivery Charges</ModalHeader>
        <ModalBody>
          <div className="delevery__char mb-3">
            <p className="text-center">
              <span className="text-success fw-bold">$2.00</span> delevery charges will be added with your order. Delivery
              time may be 30 minutes or more after the order is placed.
            </p>
          </div>

          <p className="text-danger text-center">
            <b>Note:</b> Delivery is limited to 2 mile radius.
          </p>
        </ModalBody>
        <ModalFooter>

          <button className="btn check_out_btn" onClick={toggle}>OK</button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default DeliveryChargesModal;
