import React, { useState } from "react";
import Slider from "react-slick";
import FcategoryImg from "../../assets/images/food_category.jpg";
import GcategoryImg from "../../assets/images/grocery_category.jpg";
import { memo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const PosCategories = (props) => {
  const { midCategory, getCatID, cat_id, handleMidCategory } = props;
  var settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    autoplay: true,
    // initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // dots:false,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // const [visibleItems, setVisibleItems] = useState(9);
  // const [showAll, setShowAll] = useState(false);
  // const handleArrowClick = () => {
  //   setVisibleItems(visibleItems + 6);
  // };
  // const handleArrowClick = () => {
  //   setShowAll(!showAll);
  //   setVisibleItems(9);
  // };

  return (
    <>
      <div className="mt-2 row">
        {/* --------responsive-mob--slider------- */}
        <div className=" d-md-block">
          <div className="row">
          {midCategory &&
              midCategory.map((value, index) => (
                <div
                  className="col-md-3 col-lg-2 mb-2"
                  key={index}
                  onClick={() => getCatID(value.id, value.name, value.slug)}
                >
                  {/* <a href=""> */}
                  <div
                    className={
                      cat_id === value.id
                        ? "cat_div pos-menu__card py-1"
                        : "pos-menu__card py-1"
                    }
                  >
                    <LazyLoadImage
                      height={70}
                      width={75}
                      src={value.image}
                      alt={value?.name}
                      title={value?.name}
                      loading='lazy'
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                          value.main_cat_id === "1"
                            ? FcategoryImg
                            : GcategoryImg;
                      }}
                    />
                    <p>{value?.name}</p>
                  </div>
                  {/* </a> */}
                </div>
              ))}



          </div>
        </div>
      </div>
    </>
  );
};

export default memo(PosCategories);
