import React from "react";
import { Link } from "react-router-dom";
import {  Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import questionMark from "../../assets/images/question-mark.png"

function PaymentModal({ toggle, modal }) {
  return (
    <div>
      <Modal
        className="product_detals_modal"
        isOpen={modal}
        toggle={toggle}
        centered={true}
        backdrop="static"
      >
        <ModalHeader>Payment Card</ModalHeader>
        <ModalBody>
          <div className="text-center">
          <img height={50} width={50} src={questionMark} alt="questionMark" />
            <h5 className="mt-2">Do You Want To Add Payment Card?</h5>
          </div>
                {/* <Link to="/payment-method">
                <button type="button" className="delivery_btn">Add Payment Card</button>
                </Link>

              <Link to="/">
                <button type="button" className="delivery_btn mt-2 ms-2">Skip</button>
              </Link> */}
        </ModalBody>
        <ModalFooter>
        <Link to="/payment-method">
          <button className="btn check_out_btn">Add Payment Card</button>
          </Link>
          <Link to="/">
          <button className="btn check_out_btn">Skip</button>
          </Link>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default PaymentModal;
