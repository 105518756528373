import React, { useEffect, useState } from 'react'
import UserSidbar from '../components/UserSidbar'
import { useSelector } from 'react-redux';
import { baseURL } from '../config/apiUrl';
import axios from 'axios';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';

const GasPaymentDetail = () => {
  const [paymnentData, setPaymnentData] = useState([]);
  const { token } = useSelector((state) => state.auth);

    const fetchPaymnentData = async () => {
        var config = {
          method: "get",
          url: baseURL + `/user_index`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        // setLoading(true);
        await axios(config)
          .then(function (response) {
            const { data } = response.data;
            setPaymnentData(data);
            // setLoading(false);
          })
          .catch(function (error) {
            console.log(error);
            // setLoading(false);
          });
      };
      useEffect(() => {
        fetchPaymnentData();
       // eslint-disable-next-line
      }, []);
  return (
    <div>
                    <Helmet>
                    <title>Makfuels | Gas-Payment-Details</title>
        <link rel="canonical" href="https://makcom.cf/gas-payment-detail" />
      </Helmet>
    <section className="container-fluid mt-3">
      <div className="row">
        <UserSidbar />
        <div className="col-md-9">
          <div
            className="p-3 mb-4"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
              borderRadius: "8px",
            }}
          >
            <h4 className="mb-3">Gas Payments</h4>
            {paymnentData.map((value,index)=>(
   
         <div className="qr__main_s mt-2" key={index}>
        <div className="user_details_card">
          <h3 className="text-center">E-Invoice</h3>
          <p>
            <span className="fw-bold">Date:</span>{" "}
            {moment(value?.created_at).format("DD-MMM-YYYY")}
          </p>
          <p>
            <span className="fw-bold">Location:</span> {value?.address}
          </p>
          <p>
            <span className="fw-bold">Pump No:</span> {value?.pump}
          </p>
          <p>
            <span className="fw-bold">Name:</span> {value?.name}
          </p>
          <p>
            <span className="fw-bold">Gas Price:</span> $ {value?.amount}
          </p>
          <p>
            <span className="fw-bold">Email:</span> {value?.email}
          </p>
          <p>
            <span className="fw-bold">Phone:</span> {value?.phone_number}
          </p>
          <p>
            <span className="fw-bold">Card Deatails:</span> <br />
            XXXX-XXXX-XXXX-
            <span className="fw-bold card_last_four">
              {value?.card_number}
            </span>
          </p>
        </div>
      </div> 
      ))}
          </div>
        </div>
      </div>
    </section>
  </div>
  )
}

export default GasPaymentDetail