import React, { useEffect, useState } from "react";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import UserSidbar from '../components/UserSidbar'
import { baseURL } from ".././config/apiUrl";
import axios from "axios";
const OrderView = () => {

  const { token } = useSelector((state) => state.auth);

  const selectedMyOrderId = useSelector((state)=>state.mySingleOrders.selectedMyOrderId)
  const [orderData, setorderData] = useState();

  const sendRequest = () => {
    var config = {
      method: "get",
      url: baseURL + `/order/${selectedMyOrderId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setorderData(response.data.data);
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    if (selectedMyOrderId) {
      sendRequest();
    }
    // eslint-disable-next-line
  }, [selectedMyOrderId]);
  

  /////////////////////////
  /////////////////////////
  /////////////////////////
  /////////////////////////

  if (orderData?.user_details===false){

    return (
      <div>
        <Helmet>
                      <title>Makfuels | Order-View</title>
        <link rel="canonical" href="https://makcom.cf/order-view" />
      </Helmet>
      <section className="container-fluid mt-3">
        <div className="row">
        <UserSidbar/>
            <div className="col-md-9">
        <div className="p-3" style={{boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px', borderRadius: '8px'}}>
          <h4 className='mb-3 ms-3'>Order View</h4>
          <div >
          <section>
          <div className="mt-3 Order_viEw">
        <div className="row m-auto">
          <div className="col-md-6 mb-3">
            <div className="card card__StyLe" style={{width: '100%'}}>
              <ul className="list-group list-group-flush">
                <li className="list-group-item"><b>Order ID </b> : #{orderData.orders[0]?.order_id}</li>
                <li className="list-group-item"><b> Date</b> : {moment( orderData.orders[0]?.created_at).format('MMM DD yyyy')}</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card card__StyLe" style={{width: '100%'}}>
              <ul className="list-group list-group-flush">
                {/* <li className="list-group-item d-flex justify-content-between">payment Status : <label className="order-paid">{state.data?.status}</label></li> */}
                <li className="list-group-item d-flex justify-content-between">Order Status :  <label className="order-progress">{orderData?.status}</label></li>
                <li className="list-group-item d-flex justify-content-between">Email : <span><b>{orderData?.user_email}</b></span></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row m-auto mt-3">
          <div className="col-md-8 mb-3">
            <div className="card__StyLe m-0">
              <div className='table-responsive'>
              <table className="table table-bordered checkout-table-das">
                <thead>
                  <tr>
                    
                    <th scope="col">Items</th>
                    <th scope="col">Addons</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>

                { orderData.map((val,index)=>{        
                  return(
                    
                     <tr key={index}>
                     <th scope="row"><b>{val.cartQuantity}</b> {val?.product_name}</th>
                     <td >
                       {val?.addon_id.length === 0 && "No Addons"}

                     {val?.addon_id.map((value,ind)=><li key={ind}>{value.type}</li>)}

                     </td>
                     <td></td>
                     <td>$ {val?.total_price}</td>
                   </tr>
                )})}

                </tbody>
              </table>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div className="payment-order-summary-wrap">
                <div className="card">
                  <div className="card-header">Order Summary</div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between">
                      Total Item: <span>{orderData?.orders[0]?.total_price}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                      Sub Total{" "}
                      <span>$ {orderData?.total_price}</span>
                    </li>
                    {orderData?.shipping_details !== "" && (
                      <li className="list-group-item d-flex justify-content-between">
                        Delivery <span>$ 2.00</span>
                      </li>
                    )}
                    <li className="list-group-item d-flex justify-content-between">
                      Total Cost <span>$ {orderData[0].total_price}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>
          </div>
        </div>
      </div>
        </div>
      </section>
    </div>
    )
  }







  ///////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////

  return (
        <div>
      <section className="container-fluid mt-3">
        <div className="row">
            <UserSidbar/>
      <div className="col-md-9">
        <div className="p-3" style={{boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px', borderRadius: '8px'}}>
          <h4 className='mb-3 ms-3'>Order View</h4>
          <div >
          <section>
                  <div className="mt-3 Order_viEw">
                    <div className="row m-auto">
                      <div className="col-md-6">
                        <div
                          className="card card__StyLe"
                          style={{ width: "100%" }}
                        >
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                              <b>Order ID </b> : #{orderData?.orders[0]?.id}
                            </li>
                            <li className="list-group-item">
                              <b> Date</b> :{" "}
                              {moment(orderData?.orders[0]?.created_at).format(
                                "MMM DD yyyy"
                              )}
                            </li>
                            {/* <li className="list-group-item">
                              <b> State</b> :{state?.user_details.state}
                            </li> */}
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="card card__StyLe"
                          style={{ width: "100%" }}
                        >
                          <ul className="list-group list-group-flush">
                            {/* <li className="list-group-item d-flex justify-content-between">payment Status : <label className="order-paid">{state.data?.status}</label></li> */}
                            <li className="list-group-item d-flex justify-content-between">
                              Order Status :{" "}
                              <label className="order-progress">
                                {orderData?.orders[0]?.status}
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="row m-auto mt-3">
                      <div className="col-md-8">
                        <div className="card__StyLe order__itemss_ m-0">
                          {/* //////////////////////////////////////////////////
                          //////////////////////////////////////////////////
                          //////////////////////////////////////////////////
                          ////////////////////////////////////////////////// */}

                                
                              <div className="single__item_ py-2">
                                <div className="row">
                                  <div className="col-md-1">
                                    <h6><b></b></h6>
                                  </div>
                                  <div className="col-md-7">
                                    <p>
                                    <h5 ><b>PRODUCT</b></h5>
                                    </p>
                                            
                                          
                                              
                                  </div>
                                  <div className="col-md-4">
                                    <h5 className="text-end"><b>PRICE</b></h5>
                                  </div>
                                </div>
                              </div>

                               {/* //////////////////////////////////////////////////
                          //////////////////////////////////////////////////
                          //////////////////////////////////////////////////
                          ////////////////////////////////////////////////// */}

                          
                          {orderData?.orders?.map((val, ind) => {
                            
                            let inre = ind + 1;
                            return (
                              <div className="single__item_ py-2" key={ind}>
                                <div className="row">
                                  <div className="col-md-1">
                                    <p>#{inre}</p>
                                  </div>
                                  <div className="col-md-7">
                                    <p>
                                    <b > {val.cartQuantity} x </b> {val?.product_name} - <span style={{fontWeight:"600"}}>{val.sub_product_name}</span>
                                    </p>
                                            
                                          
                                              
                                    {val.fries && (
                                      <div className="d-flex justify-content-between order__itemss_main_divs">
                                        <p>
                                          {val?.fries?.type} ({val?.fries?.size}
                                          )
                                        </p>
                                        <p>$ {val?.fries?.price}</p>
                                      </div>
                                    )}
                                    {val.soda && (
                                      <div className="d-flex justify-content-between order__itemss_main_divs">
                                        <p>
                                          {val?.soda[0].name} ({val?.sodaSize[0].name})
                                        </p>
                                        {val?.sodaSize[0].price ==null?
                                        <p>INCLUDED</p>:
                                        <p>$ {val?.sodaSize[0].price}</p>
                                        
                                      }
                                      </div>
                                    )}
                                    
                                    
                                    {val.cheeses && 
                                    <div>
                                    <p className="fw-bold"><u>Cheeses</u></p>
                                    <div className="d-flex justify-content-between order__itemss_main_divs">
                                      <p>{val.cheeses[0].name}</p>
                                    </div>
                                  </div>
                                    }

                                  {val?.extrasauces &&
                                    <div>
                                    <p className="fw-bold" ><u>Dip Sauces</u></p>
                                    {(val.sauce_rule == null)?"": 
                                    <p className="exclude_sauces">{`Exclude ${val.sauce_rule} ${(val.sauce_rule > 1)?"Sauces Price": "Sauce Price"} `}</p>
                                    }
                                    {val?.extrasauces?.map((items,index)=>{
                                      return(
                                        <div className="d-flex justify-content-between order__itemss_main_divs">
                                        <p>{items.name}</p>
                                        <p>$ {items.price}</p>
                                      </div>
                                      )
                                    })}

                                  </div>
                                    }
                                   
                                    {val?.sides &&
                                    <div>
                                    <p className="fw-bold"><u>Sides</u></p>
                                    {(val.side_rule == null)?"": 
                                    <p className="exclude_sauces">{`Exclude ${val.side_rule} ${(val.side_rule > 1)?"Sides Price": "Side Price"} `}</p>
                                    }
                                    {val?.sides?.map((items,index)=>{
                                      return(
                                        <div className="d-flex justify-content-between order__itemss_main_divs">
                                        <p>{items.name}</p>
                                        <p>$ {items.price}</p>
                                      </div>
                                      )
                                    })}

                                  </div>
                                    }
                                    {val?.toppings &&
                                    <div>
                                    <p className="fw-bold"><u>Toppings</u></p>
                                    {val?.toppings?.map((items,index)=>{
                                      return(
                                        <div className="d-flex justify-content-between order__itemss_main_divs">
                                        <p>{items.name}</p>
                                      </div>
                                      )
                                    })}

                                  </div>
                                    } 
                                    {val?.cookingSauce &&
                                    <div>
                                    <p className="fw-bold"><u>Cooking Sauce</u></p>
                                    {val?.cookingSauce?.map((items,index)=>{
                                      return(
                                        <div className="d-flex justify-content-between order__itemss_main_divs">
                                        <p>{items.name}</p>
                                      </div>
                                      )
                                    })}

                                  </div>
                                    }
                                                                        {val?.toppingsArray &&
                                    <div>
                                    {/* <p className="fw-bold">Sides</p> */}
                                    {val?.toppingsArray?.map((items,index)=>{
                                      return(
                                        <div className="d-flex justify-content-between order__itemss_main_divs">
                                        <p>{items.name}</p>
                                        <p>$ {items.price}</p>
                                      </div>
                                      )
                                    })}

                                  </div>
                                    }
                                  </div>
                                  {/* <div className="col-md-2">
                                    <p>x 2</p>
                                  </div> */}
                                  <div className="col-md-4">
                                    <p className="text-end">$ {val.product_price}</p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="row">
                          <div className="payment-order-summary-wrap">
                            <div className="card">
                              <div className="card-header">Order Summary</div>
                              <ul className="list-group list-group-flush">
                                <li className="list-group-item d-flex justify-content-between">
                                  {/* Total Item: <span>{totalItem}</span> */}
                                </li>
                                <li className="list-group-item d-flex justify-content-between">
                                  Sub Total{" "}
                                  <span>$ {orderData?.orders[0]?.total_price}</span>
                                </li>
                                {/* {state?.data.shipping_details !== "" && (
                                  <li className="list-group-item d-flex justify-content-between">
                                    Delivery <span>$ 2.00</span>
                                  </li>
                                )} */}
                                <li className="list-group-item d-flex justify-content-between">
                                  {/* Total Cost <span>$ {TotalAmount}</span> */}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OrderView;
