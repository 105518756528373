import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeFromCart,
  increaseCart,
  decreaseCart,
} from "../redux/features/slices/cartSlice";
import { Link } from "react-router-dom";
import empty_carts from "../assets/images/empty_cart.png";
import { useCallback } from "react";
import ReceiptPDF from "../pages/ReceiptPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { baseURL } from "../config/apiUrl";
import axios from "axios";
import { toast } from "react-toastify";
import CollectPaymentModal from "./modal/CollectPaymentModal";

const ModalCartShow = () => {
  const [loading, setLoading] = useState(false);
  const cart = useSelector((state) => state.cart);
  const { token } = useSelector((state) => state.auth);

  const { cartItems, cartTotalAmount, cartTotalTax, cartSubTotalAmount } =
    useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const handleRemoveFromCart = useCallback(
    (product) => {
      dispatch(removeFromCart(product));
    },
    [dispatch]
  );
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  // const handlePrintReceipt = () => {
  //   // Create a new PDF document
  //   const pdfBlob = new Blob([<ReceiptPDF
  //     cartItems={cartItems}
  //     cartSubTotalAmount={cartSubTotalAmount}
  //     cartTotalTax={cartTotalTax}
  //     cartTotalAmount={cartTotalAmount}
  //   />], { type: "application/pdf" });

  //   // Generate the PDF URL
  //   const pdfUrl = URL.createObjectURL(pdfBlob);

  //   // Open the PDF in a new tab
  //   window.open(pdfUrl);
  // };


  const handleIncreaseCart = useCallback(
    (product) => {
      dispatch(increaseCart(product));
    },
    [dispatch]
  );

  const handleDecreaseCart = useCallback(
    (product) => {
      dispatch(decreaseCart(product));
    },
    [dispatch]
  );
  console.log(JSON.stringify(cart.cartItems));
  // ---Proceed-and-dowmload---
  const handleSubmitCash = async (e) => {
    // e.preventDefault();
    var data = new FormData();
    data.append("order_items", JSON.stringify(cart.cartItems));
    data.append("total_price", cart.cartTotalAmount);

    var config = {
      method: "post",
      url: baseURL + "/pos_order",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    setLoading(true);
    await axios(config)
      .then(function (response) {
        toast.success(response.data.message);
        setLoading(false);

        if (response.data.success == true) {
          console.log("ok");
        }
        // setTimeout(() => {
        //   navigate("/order-confirmation", {
        //     state: { orderID: response.data.data },
        //   },{replace:true});
        //   setLoading(false);
        // }, 2000);
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
        console.log(error);
        setLoading(false);
      });
  };

  

  return (
    <div >
      <div
        className="px-3 mt-3 pb-4"
        id="myDIV"

      >
        <p style={{ fontSize: "1.3rem" }} className="mb-3 pt-3">
          Order List
        </p>
        {cartItems.length === 0 && (
          <div className="text-center">
            <img
              height={40}
              width={40}
              className=""
              style={{ width: "40px", marginBottom: "8px" }}
              src={empty_carts}
              alt="empty-cart"
            ></img>

            <h1 style={{ fontSize: "1.5rem" }}>Your Cart is Empty Now</h1>
          </div>
        )}
        {/* {cartItems.length>0 && <h6>Foodsy</h6>} */}
        <div
          className="right__card__main_h p-2"
          style={{ maxHeight: "240px", overflowY: "auto", overflowX: "hidden" }}
        >
          {cartItems.map((items, index) => {
            const totalSauceRedux = items.extrasaucesArray.reduce((totalQuantity, val) => totalQuantity + val.quantity * items.cartQuantity, 0)
                      // ---Calculate-Quantity-Start---
          const newQuantity = items.finalSauceQuantity;
          const drinkPrice =
            items && items?.selectedSoftDrinks?.price == null
              ? 0
              : parseFloat(items && items?.selectedSoftDrinks?.price);

          const totalSaucePrice = items.extrasaucesArray.reduce(
            (accumulator, currentItem) => {
              return parseFloat(currentItem.price) * newQuantity;
            },
            0
          );
          let totalSidePrice = items?.sidesArray?.reduce(
            (accumulator, currentItem) => {
              return parseFloat(currentItem.price) + accumulator;
            },
            0
          );
          const totalSidePrices =
            totalSidePrice == undefined ? 0 : totalSidePrice;
          let FriesResultsUpd =
            items.FriesResult == undefined ? 0 : items.FriesResult;
          let pricing =
            items.discount_price === null ? items.price : items.discount_price;
          pricing =
            pricing === null ? items.selectedSubproduct?.price : pricing;
          let toppingPrice =
            items.toppingsArray.reduce(
              (total, currentValue) =>
                (total =
                  total + parseFloat(currentValue.price) * items.cartQuantity),
              0
            ) +
            totalSaucePrice * items.cartQuantity +
            totalSidePrices * items.cartQuantity +
            FriesResultsUpd +
            drinkPrice +
            pricing * items.cartQuantity;
          console.log(toppingPrice, "toppingPrice");
          // ---Calculate-Quantity-End---
            return (
              <div className="card__items main_right_items  mb-2" key={index}>
                <div className="d-flex justify-content-between">
                  {/* {items.main_cat_id === "2"} */}
                  <p>
                    {items.cartQuantity} x {items.prod_name} {items.name}
                    {items.main_cat_id === "1"
                      ? items.is_sub_avaiable == 0
                        ? null
                        : ` (${items.selectedSubproduct?.name})`
                      : items.selectedSubproduct?.name &&
                        ` (${items.selectedSubproduct.name})`}
                  </p>

                  <p className="ms-2">
                    {/* {items.discount_price === null && (
                    <span>
                      {items.main_cat_id === "2" ? "" : `$${items.price}`}
                    </span>
                  )}
                  {items.discount_price !== null && (
                    <span>
                      {items.main_cat_id === "2"
                        ? ""
                        : `$${items.discount_price}`}
                    </span>
                  )} */}

                    {items.main_cat_id == 2 && <span className="text-nowrap">$ {items.price} </span>}
                    {items.main_cat_id == 1 && (
                      <span className="text-nowrap">
                        ${" "}
                        {items.is_sub_avaiable == 0
                          ? items.price
                          : items.selectedSubproduct?.price}
                      </span>
                    )}

                    <span>
                      <i
                        className="fa-solid fa-circle-xmark aa"
                        onClick={() => handleRemoveFromCart(items)}
                      />
                    </span>
                  </p>
                </div>
                <p>
                  <b>
                    {items.toppingsArray.length > 0 && items.main_cat_id === "1"
                      ? "Add Onns"
                      : ""}
                  </b>
                  {/* <b>
                  {items.toppingsArray.length > 0 && items.main_cat_id === "2"
                    ? "Varients"
                    : ""}
                </b> */}
                </p>
                {/* <p>
                    {items.main_cat_id === "1"
                      ? items.is_sub_avaiable == 0
                        ? null
                        : ` (${items.selectedFriesType?.type})`
                      : items.selectedFriesType?.type &&
                        ` (${items.selectedFriesType.type})`}
                  </p> */}
                {items.main_cat_id == 1 && items.is_sub_avaiable == 1 && Object.keys(items.selectedFriesType).length >0  &&

                (
                  <div
                    style={{ fontSize: "12px" }}
                    className="d-flex justify-content-between"
                  >
                    {console.log(cartItems,"items.selectedFriesType")}
                     {console.log(cartItems,"items.selectedFriesType")}
                    <div>
                      {items.selectedFriesType?.type} (
                      {items.selectedFriesSize?.name})
                    </div>
                    <div>$ {items.FriesResult==0?"Free":items.FriesResult}</div>
                  </div>
                )}
                {items && items.main_cat_id == 1 && items.is_sub_avaiable == 1 && Object.keys(items?.selectedFountainDrinks)?.length >0 &&  (
                  <div
                    style={{ fontSize: "12px" }}
                    className="d-flex justify-content-between"
                  >
                    <div>
                      {items?.selectedFountainDrinks?.name} (
                      {items?.selectedSoftDrinks?.name})
                    </div>
                    <div>$ {items?.selectedSoftDrinks?.price==null?"Free":items?.selectedSoftDrinks?.price}</div>
                  </div>
                )}

                {items.main_cat_id == 1 &&
                items?.cheesesArray.length > 0 &&
                items.is_sub_avaiable == 1 ? (
                  <ul>
                    <h6>Cheeses</h6>
                    {items.cheesesArray.map((val, index) => {
                      return <li key={index}>{val.type} </li>;
                    })}
                  </ul>
                ) : null}

                {items.main_cat_id == 1 &&
                items?.toppsecondArray?.length > 0 &&
                items.is_sub_avaiable == 1 ? (
                  <ul className="mt-2">
                    <h6>Toppings</h6>
                    {items?.toppsecondArray?.map((val, index) => {
                      return <li key={index}>{val.type} </li>;
                    })}
                  </ul>
                ) : null}

{items.main_cat_id == 1 &&
                items?.sidesArray.length > 0 &&
                items.is_sub_avaiable == 1 ? (
                  <ul className="mt-2">
                    <h6>Sides</h6>
                    {items?.sidesArray.map((val, index) => {
                    return (
                      <li key={index}>
                        {val.type}{" "}
                        <span className="float-end">
                          $ {val.price} x {items.cartQuantity}
                        </span>
                      </li>
                    );
                    })}
                  </ul>
                ) : null}

                {items.main_cat_id == 1 &&
                items?.cookingSauceArray.length > 0 &&
                items.is_sub_avaiable == 1 ? (
                  <ul className="mt-2">
                    <h6>Sauces</h6>
                    {items.cookingSauceArray.map((val, index) => {
                      return <li key={index}>{val.type} </li>;
                    })}
                  </ul>
                ) : null}

                {items.main_cat_id == 1 &&
                items?.extrasaucesArray.length > 0 &&
                items.is_sub_avaiable == 1 ? (
                  <ul className="mt-2">
                    {items.extrasaucesArray.length > 0 && (
                      <div>
                        <h6>Dip Sauces</h6>
                        <p className="exclude_sauces my-1">{`Exclude ${
                          totalSauceRedux - items.finalSauceQuantity
                        } ${
                          totalSauceRedux - items.finalSauceQuantity > 1
                            ? "Sauces"
                            : "Sauce"
                        } Price`}</p>
                      </div>
                    )}
                    {items.extrasaucesArray.map((val, index) => {
                      return (
                        <li key={index}>
                          <div className="d-flex justify-content-between">
                            <p>
                              {val.quantity * items.cartQuantity} x {val.name}
                            </p>
                            <p>$ {val.price}</p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                ) : null}

                <ul className="mt-2">
                  {items.toppingsArray.map((val, index) => {
                    return (
                      <li key={index}>
                        {val.type}{" "}
                        <span className="float-end">
                          ${val.price} x {items.cartQuantity}
                        </span>
                      </li>
                    );
                  })}
                </ul>
                <div>
                <div className="d-flex justify-content-between itemsTax">
                  <p>Tax:</p>
                  <p>
                    ${" "}
                    {(
                      (toppingPrice.toFixed(2) / 100) *
                      items.tax_percentage
                    ).toFixed(2)}
                  </p>
                </div>
                <div className="d-flex justify-content-between itemsSubTotal">
                  <p>Total:</p>
                  <p>$ {toppingPrice.toFixed(2)}</p>
                </div>

                {/* <p>{items.tax_percentage}</p> */}
              </div>
                <div className="quAntity__s">
                  <div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => handleIncreaseCart(items)}
                    >
                      +
                    </div>
                    <div>{items.cartQuantity} </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDecreaseCart(items)}
                    >
                      -
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default memo(ModalCartShow);
