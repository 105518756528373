import axios from "axios";
import productImg from "../assets/images/food_product.png";
import { baseURL } from "../config/apiUrl";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Breadcrumb, BreadcrumbItem, Spinner } from "reactstrap";
import { Link, useParams , useNavigate } from "react-router-dom";
import ProductModal from "../components/modal/SingleProductModal";
import { getTotals } from "../redux/features/slices/cartSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function ProductPage() {

  const [productValue, setproductValue]= useState("") 
  const [prod_id, setprod_id]= useState("")           
  const [catName, setcatName]= useState("")           

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isshow, setisShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [seoTags, setSeoTags] = useState();
  const [url, setUrl] = useState();
  const [seoTagsData, setSeoTagsData] = useState([]);
  const params = useParams();  
  const dispatch = useDispatch() 
  const cart = useSelector((state) => state.cart);
  const navigate = useNavigate()

  useEffect(()=>{
    setUrl(window.location.href)
    dispatch(getTotals());
  },[window.location.href])

  const fetchSeoTags = async () => {
    try {
      const response = await axios.get(`${baseURL}/metatagToShow/${seoTags}`);
      const { data } = response.data;
      setSeoTagsData(data);
      console.log("seotagdata" , data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {

      fetchSeoTags();

    // eslint-disable-next-line
  }, [seoTags]);


  const fetchPopularItems = async () => {

    setLoading(true);
    try {

      var config = {
        method: "get",
        url: baseURL + `/get_product_by_id/${params.id}`,
      };

      const response = await axios(config);

      const { data  } = response.data;
      setProducts(data);
      setSeoTags(data.slug)
      setproductValue(data)
      setprod_id(data.id)
      setcatName(data.cart_name)
      setLoading(false);
    } catch (error) {
      console.error(error);
      setisShow(true)
      setLoading(false);
    }
  };
  
  useEffect(()=>{
    fetchPopularItems()
  },[])

  const openModal = ()=>{
    if (products.main_cat_id == 1) {
      handleShow()
    }
    else{
      navigate("/")
    }
  }

  return (
    
    <>

<Helmet>
        {seoTagsData?.length === 0 ? (
          <title> { params.slug.slice(0,1).toUpperCase()+params.slug.slice(1).toString().split("-").join(" ").toString()
           } | Makfuels</title>
        ) : (
          seoTagsData?.map((value, index) => {
            const { name, content } = value;
            if (name.toLowerCase() === "title") {
              return <title key={index}>  {content} | Makfuels </title>;
            } else {
              return (
                <title key={index}>
                  {seoTags ? `${seoTags} | Makfuels`  : ""}
                </title>
              );
            }
          })
        )}
        {seoTagsData?.map((value, index) => {
          const { name } = value;
          if (name.toLowerCase() === "description") {
            return (
              <meta name={value.name} content={value.content} key={index} />
            )
          }
          return null
        })}

        {seoTagsData.length !== 0 ? "":<meta name="descriptiom" content="Fuel up at Makfuels, your Missouri gas station. Discover affordable prices 24/7 on food, cigars, cigarettes, liquor, beer, soda, energy drinks, and more. Visit us for convenience on every journe" />}
        <meta name="robots" content="index, follow" />
        

        {/* ////////////////////////////////////////////////////////////////////////// */}
        {/* ////////////////////////////////////////////////////////////////////////// */}

        <link rel="canonical" href={url} />
        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://makfuels.com" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Makfuels A Gas Sation" />
        <meta
          property="og:description"
          content="Fuel up at Makfuels, your Missouri gas station. Discover affordable prices on food, cigars, cigarettes, liquor, beer, soda, energy drinks, and more."
        />
        <meta
          property="og:image"
          content="https://makfuels.com/static/media/logo.4ed5bcbedcce5108870c.jpg"
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://makfuels.com" />
        <meta name="twitter:title" content="Makfuels A Gas Sation" />
        <meta
          name="twitter:description"
          content="Fuel up at Makfuels, your Missouri gas station. Discover affordable prices on food, cigars, cigarettes, liquor, beer, soda, energy drinks, and more."
        />
        <meta
          name="twitter:image"
          content="https://makfuels.com/static/media/logo.4ed5bcbedcce5108870c.jpg"
        />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{
            "@context": "http://www.schema.org",
            "@type": "Corporation",
            "name": "Makfuels",
            "url": ${url},
            "sameAs": [
            "https://www.facebook.com/makfuelsofficial/",
            "https://twitter.com/",
            "https://www.instagram.com/",           
          "https://www.linkedin.com/company/",
            "https://www.pinterest.com/"
            ],
            "logo": "https://makfuels.com/static/media/logo.4ed5bcbedcce5108870c.jpg ",
            "image": "https://makfuels.com/static/media/logo.4ed5bcbedcce5108870c.jpg",
            "description": ${seoTagsData?.map((value, index) => {
              const { name } = value;
              if (name.toLowerCase() === "description") {
                return (
                  <meta name={value.name} content={value.content} key={index} />
                )
              }
              return null
            })},
            "address": {
            "@type": "PostalAddress",
            "streetAddress": "Howdershell Rd",
            "addressLocality": "Hazelwood",
            "addressRegion": "Missouri",
            "postalCode": "6901",	
            "addressCountry": "USA"
            },
            "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+1-314-724-2828",
            "contactType": "Telephone" ,
          "email":"contact@makfuels.com"
        }`,
          }}
        />

        {/* ////////////////////////////////////////////////////////////////////////// */}
        {/* ////////////////////////////////////////////////////////////////////////// */}


      </Helmet>

{    ( loading && products?.length === 0) ?

      <div className="main_spinner_divv">
      
      <Spinner color="warning" type="grow"/>
      </div> : products.length !== 0 &&(
  <>
    <h1 className="hide_details3 heading_h3  mt-5 text-center">{products?.name}</h1>

    <div className="row m-5 border border-grey border-rounded p-5 shadow">

            <Breadcrumb className="px-3" style={{fontSize:"medium" }}  onClick={handleClose}>
              <BreadcrumbItem style={{color:"blue"}}>
                <Link to="/">Home</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>{seoTags}</BreadcrumbItem>
            </Breadcrumb>


                <div className="col-md-12 col-sm-12 col-lg-5 col-xl-5 d-flex align-items-center justify-content-center">
                  <div className="product__information d-flex align-items-center justify-content-center flex-column">
                    <LazyLoadImage
                      className="pro__imG__"
                      loading='lazy'
                      width="100%"
                      height="auto"
                      PlaceholderSrc={productImg}
                      src={products?.image}
                      alt={products?.name}
                      title={products?.name}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = productImg;
                      }}
                    />
                     <h3 className="heading_h3 show_details p-2">{products?.name}</h3>
                                     
                    {/* to show description uncomment blow code */}

                   {products?.description !== null ? (
                      <p className="show_details">
                        <hr />

                        <div
                          className="description_para"
                          dangerouslySetInnerHTML={{
                            __html: products?.description,
                          }}
                        />
                      </p>
                    ) : (
                      ""
                    )} 
                    </div>


                    </div>
                    <div className="col-lg-7 col-xl-7 p-4 d-flex  justify-content-center flex-column">

                    {products?.description !== null ? (
                  <p className="hide_detalis hide_details2">
                    <h2 style={{fontSize:"1.5rem !important"}} className="hide_details3">Description</h2>
                    <hr />

                    <div
                      className="pt-1 pb-2 mb-3 description_para2 hide_details_headings"
                      dangerouslySetInnerHTML={{
                        __html: products?.description,
                      }}
                    />
                    <hr />
                  </p>
                ) : (
                  ""
                )}
            <button
            onClick={openModal}
              className="btn check_out_btn w-100"
            >
             Order Now
            </button>

               </div>
            </div>



</>

)}

{
  isshow?<div className="qr_thank_you m-4 text-center"><h2 >Something Went Wrong...</h2></div>:""
}


{products.main_cat_id == "1" && (

  <ProductModal
          productValue={productValue}
          prod_id={prod_id}
          catName={catName?.toLowerCase()}
          show={show}
          seoTags={seoTags}
          handleClose={handleClose}
          handleShow={handleShow}
        />
      )}
      
    </>

  )
}
