import React from 'react'
import UserSidbar from '../components/UserSidbar'
import { Helmet } from 'react-helmet-async'

const AddressBook = () => {
  return (
        <div>
                        <Helmet>
                        <title>Makfuels | Address-Book</title>
        <link rel="canonical" href="https://makcom.cf/address-book" />
      </Helmet>
      <section className="container-fluid mt-3">
        <div className="row">
            <UserSidbar/>
            <div className="col-md-9">
        <div className="p-3" style={{boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px', borderRadius: '8px'}}>
          <div className='d-flex justify-content-between align-items-center'>
          <h4 className=' mb-3'>Address Book</h4>
          <button className="btn address__book_add">Add New</button>
          </div>
          
          <div className='mb-3 address__book_Cart'>
            <div className="row">
              <div className='d-flex justify-content-between'>
              <h6 className='mb-2'>Address #1 </h6>
              <div>default</div>
              </div>
              <div className="col-md-4">
                <div className="d-flex"><p className='me-3'><b>Name</b></p><p>Muhammad Ahmer</p></div>
              </div>
              <div className="col-md-4">
                <div className="d-flex"><p className='me-3'><b>Phone number</b></p><p>03473647030</p></div>
              </div>
              <div className="col-md-4">
                <div className="d-flex"><p className='me-3'><b>Postal code</b></p><p>75500</p></div>
              </div>
              <div className="col-md-11 mt-2 mb-2">
              <div className="d-flex">
                <p className='me-3'><b>Street</b></p>
                <p>House K-23, 5th Street, Defence View Society, K Block, Karachi</p>
                </div>
              </div>

              <div className="col-md-8">

              </div>
              
              <div className="col-md-6"></div>
            </div>
            {/* <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col" colSpan={2}>Address #1 - default shipping address</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Name</th>
                  <td>Muhammad Ahmer</td>
                </tr>
                <tr>
                  <th scope="row">Street</th>
                  <td>House K-23, 5th Street, Defence View Society, K Block, Karachi</td>
                </tr>
                <tr>
                  <th scope="row">Postal code</th>
                  <td>75500</td>
                </tr>
                <tr>
                  <th scope="row">Phone number</th>
                  <td>03473647030</td>
                </tr>
              </tbody>
            </table> */}
            <div className="d-flex">
              <button className="btn btn-secondary">Edit Address</button>
              <button className="btn check_out_btn ms-3">Delete Address</button>
            </div>
          </div>
          <div className='address__book_Cart'>
            <div className="row">
              <div className='d-flex justify-content-between'>
              <h6 className='mb-2'>Address #2 </h6>
              <div></div>
              </div>
              <div className="col-md-4">
                <div className="d-flex"><p className='me-3'><b>Name</b></p><p>Muhammad Ahmer</p></div>
              </div>
              <div className="col-md-4">
                <div className="d-flex"><p className='me-3'><b>Phone number</b></p><p>03473647030</p></div>
              </div>
              <div className="col-md-4">
                <div className="d-flex"><p className='me-3'><b>Postal code</b></p><p>75500</p></div>
              </div>
              <div className="col-md-11 mt-2 mb-2">
              <div className="d-flex">
                <p className='me-3'><b>Street</b></p>
                <p>House K-23, 5th Street, Defence View Society, K Block, Karachi</p>
                </div>
              </div>

              <div className="col-md-8">

              </div>
              
              <div className="col-md-6"></div>
            </div>
            {/* <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col" colSpan={2}>Address #1 - default shipping address</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Name</th>
                  <td>Muhammad Ahmer</td>
                </tr>
                <tr>
                  <th scope="row">Street</th>
                  <td>House K-23, 5th Street, Defence View Society, K Block, Karachi</td>
                </tr>
                <tr>
                  <th scope="row">Postal code</th>
                  <td>75500</td>
                </tr>
                <tr>
                  <th scope="row">Phone number</th>
                  <td>03473647030</td>
                </tr>
              </tbody>
            </table> */}
            <div className="d-flex">
              <button className="btn btn-secondary">Edit Address</button>
              <button className="btn check_out_btn ms-3">Delete Address</button>
            </div>
          </div>
        </div>
      </div>
        </div>
      </section>
      {/* ----Profile--modal-start--- */}
      {/* Button trigger modal */}
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        // aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              {/* <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5> */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="p-3">
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                  />
                  <label htmlFor="floatingInput">Name</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                  />
                  <label htmlFor="floatingInput">Email address</label>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ----Profile--modal-end--- */}
    </div>
  )
}

export default AddressBook