import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../config/apiUrl";
import { login } from "../redux/features/auth/authSlice";
import { signUpSchema } from "../schemas";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import PaymentModal from "./modal/PaymentModal";

const Pos = () => {
  document.title = "Makfuels | Login";
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [eye, seteye] = useState(true);
  const [showPassword, setshowPassword] = useState("password");
  const Eye = () => {
    if (showPassword === "password") {
      setshowPassword("text");
      seteye(false);
    } else {
      setshowPassword("password");
      seteye(true);
    }
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const submitForm = async (e) => {
    e.preventDefault();
    var data = new FormData();
    data.append("email", email);
    data.append("password", password);
    setLoading(true);
    var config = {
      method: "post",
      url: baseURL + "/login",
      data: data,
    };

    await axios(config)
      .then(function (response) {
        const { message, data } = response.data;
        dispatch(login(data));
        setLoading(false);
        // Check if user_type is "pos" and redirect accordingly
        if (data.user_type === "pos") {
          navigate("/pos-system", { replace: true });
          toast.success(message);
          // toast.success("User login successfully.");
          
    } 
    else {
      toast.error("User is unauthenticated");
    }
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
        // toast.error(error.response?.data?.message || "An error occurred");
        setLoading(false);
      });
  };

  let date = new Date(new Date().setFullYear(new Date().getFullYear() - 21));
  let getDate = moment(date).format("MMM DD YYYY");
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://makcom.cf/login" />
      </Helmet>
      <div className="form-modal">
        <div className="form-toggle">
          <button id="login-toggle" className="w-100">log in</button>
        </div>
        <div id="login-form">
          <form onSubmit={submitForm}>
            <input
              type="text"
              placeholder="Enter email or username"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="position-relative pAss_Sty">
              <input
                type={showPassword}
                placeholder="Enter password"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <i
                onClick={Eye}
                className={`position-absolute fa ${
                  eye ? "fa-eye-slash" : "fa-eye"
                }`}
              ></i>
            </div>
            <button
              type="submit"
              className="btn login"
              disabled={loading ? true : false}
            >
              {loading ? (
                <div className="spinner-border text-warning" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "login"
              )}
            </button>
            {/* <p><a href="javascript:void(0)">Forgotten account</a></p> */}
            <hr />
          </form>
        </div>
      </div>
      <PaymentModal toggle={toggle} modal={modal} />
    </>
  );
};

export default Pos;
