
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import { Link } from "react-router-dom";
// import newCons1 from "../assets/images/cons_slide_1.jpg";
// import newCons2 from "../assets/images/cons_slide_2.jpg";
// import newCons3 from "../assets/images/cons_slide_3.jpg";
// import logo from "../assets/images/logo.jpg";
// // import shell2 from "../assets/images/shell2.png";
// import MainHeader from "../components/MainHeader";


import apos from "../assets/images/apostro1.png";
import { Helmet } from "react-helmet-async";

const AboutUs = () => {
  return (
    <>
      <Helmet>
      <link rel="canonical" href="https://makfuels.com/about-us" />
<title> About Us | Makfuels</title>
<meta name="description" content=" MAK FUELS has been serving Missouri and Illinois customers with their fuel needs for over 30 years, we have been supplying quality FUELS to our dealer network in these markets"/>

{/* !-- Facebook Meta Tags --> */}
<meta property="og:url" content="makfuels.com/about-us"/>
<meta property="og:type" content="website"/>
<meta property="og:title" content="About Us | Makfuels"/>
<meta property="og:description" content="MAK FUELS has been serving Missouri and Illinois customers with their fuel needs for over 30 years, we have been supplying quality FUELS to our dealer network in these markets"/>
<meta property="og:image" content="https://makfuels.com/static/media/logo.4ed5bcbedcce5108870c.jpg "/>

{/* <!-- Twitter Meta Tags --> */}
<meta name="twitter:card" content="summary_large_image"/>
<meta property="twitter:url" content="makfuels.com/about-us"/>
<meta name="twitter:title" content="About Us | Makfuels"/>
<meta name="twitter:description" content="MAK FUELS has been serving Missouri and Illinois customers with their fuel needs for over 30 years, we have been supplying quality FUELS to our dealer network in these markets"/>
<meta name="twitter:image" content="https://makfuels.com/static/media/logo.4ed5bcbedcce5108870c.jpg "/>

<script type='application/ld+json'
         dangerouslySetInnerHTML={{ __html: `{
            "@context": "http://www.schema.org",
            "@type": "Corporation",
            "name": "Makfuels",
            "url": "https://makfuels.com/about-us ",
            "sameAs": [
            "https://www.facebook.com/makfuelsofficial/",
            "https://twitter.com/",
            "https://www.instagram.com/",           
          "https://www.linkedin.com/company/",
            "https://www.pinterest.com/"
            ],
            "logo": "https://makfuels.com/static/media/logo.4ed5bcbedcce5108870c.jpg ",
            "image": "https://makfuels.com/static/media/logo.4ed5bcbedcce5108870c.jpg",
            "description": " MAK FUELS has been serving Missouri and Illinois customers with their fuel needs for over 30 years, we have been supplying quality FUELS to our dealer network in these markets.",
            "address": {
            "@type": "PostalAddress",
            "streetAddress": "Howdershell Rd",
            "addressLocality": "Hazelwood",
            "addressRegion": "Missouri",
            "postalCode": "6901",	
            "addressCountry": "USA"
            },
            "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+1-314-724-2828",
            "contactType": "Telephone" ,
            "email":"contact@makfuels.com"
          }`}}
        
          />

      </Helmet>
      <section>
        <div className="privacy_background mt-3">
          <div>
            <h1 className="bann_heading">About Us</h1>
          </div>
        </div>
      </section>

      <section className="cons_conte my-5">
        <div className="col-lg-6 col-md-8 col-10 m-auto">
          <div className="text-center">
            <LazyLoadImage  height={35} width={50} src={apos} alt="apos.png" loading='lazy' />
          </div>
          <div className="cont___">
            <p>
              MAK FUELS has been serving Missouri and Illinois customers with
              their fuel needs for over 30 years, we have been supplying quality
              FUELS to our dealer network in these markets, we are currently
              expanding our dealer network in Georgia, Florida, Arizona and
              Texas, we help our dealers at every level from finding a strategic
              location to build a brand new facility.
            </p>
            <br />
            <p>
              Although Our primary business is to supply quality petroleum
              products to gas stations but we are also very focused on energy
              efficiency, climate change and Fuels of the future, we are
              committed to design and development new locations with net zero
              carbon emission fuels.
            </p>
            <br />
            <p>
              We have been working with several partners to provide our
              customers with verity of energy sources, our VISION 2050 is to
              develop state of the art facilities which will supply Fuels that
              are carbon neutral.
            </p>
            <br />
            <p>
              Currently we are building network of fueling facilities with
              variety of fuels to offer, our goal is to scale up our platform
              with new market conditions and needs, this would enable us to
              deliver net zero carbon emission fuels to our customer.
            </p>
          </div>
        </div>
      </section>
      {/* <footer className="footer__">
        <div className="px-3 py-3">
          <div className="row p-0 m-0">
            <div className="col-md-2 mb-2 mb-md-0">
              <div className="d-flex align-items-center">
                <img className="header-img me-2" width={50} height={50} src={logo} alt="logo.png" />
                <p>&copy; 2023 Makfuels</p>
              </div>
            </div>
            <div className="col-md-5 mb-2 mb-md-0">
              <div className="add_em">
                <p>Address : 6800 Olive Blvd Suite B Saint Louis, MO, 63131</p>
                <p>Email : info@makfuels.com</p>
              </div>
            </div>
            <div className="col-md-3 mb-2 mb-md-0">
              <div className="add_em" >
                <p>Fax : 314-727-4004</p>
                <p>Phone : 314-727-4000</p>
              </div>
            </div>
            <div className="col-md-2 mb-2 mb-md-0">
              <div className="add_em">
                <Link to="/privacy-policy">
                  <p className="pri_pol">Privacy Policy</p>
                </Link>
                <Link to="/terms-of-use">
                  <p className="pri_pol">Terms and Conditions</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer> */}
    </>
  );
};

export default AboutUs;
