import React from "react";
import MainHeader from "../components/MainHeader";
import { Helmet } from "react-helmet-async";

const TermConditions = () => {
  return (
    <>
        <Helmet>
        <meta charset="utf-8" />
<meta name="viewport" content="width=device-width,initial-scale=1"/>
<meta name="robots" content="index, follow"/>
<link rel="canonical" href="https://makfuels.com/terms-of-use" />
<title> Terms and Conditions | Makfuels</title>
<meta name="description" content=" Makfuels's terms and conditions clearly define the parameters through which your properties, ownership, license & restrictions will be undertaken. "/>
{/* <!-- Facebook Meta Tags --> */}
<meta property="og:url" content="makfuels.com/terms-of-use"/>
<meta property="og:type" content="website"/>
<meta property="og:title" content="Terms And Conditions | Makfuels"/>
<meta property="og:description" content="Makfuels's terms and conditions clearly define the parameters through which your properties, ownership, license & restrictions will be undertaken"/>
<meta property="og:image" content="https://makfuels.com/static/media/logo.4ed5bcbedcce5108870c.jpg "/>

{/* <!-- Twitter Meta Tags --> */}
<meta name="twitter:card" content="summary_large_image"/>
<meta property="twitter:url" content="makfuels.com/terms-of-use"/>
<meta name="twitter:title" content="Terms And Conditions | Makfuels"/>
<meta name="twitter:description" content="Makfuels's terms and conditions clearly define the parameters through which your properties, ownership, license & restrictions will be undertaken"/>
<meta name="twitter:image" content="https://makfuels.com/static/media/logo.4ed5bcbedcce5108870c.jpg "/>

<script type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: `{
            "@context": "http://www.schema.org",
            "@type": "Corporation",
            "name": "Makfuels",
            "url": "https://makfuels.com/terms-of-use ",
            "sameAs": [
            "https://www.facebook.com/makfuelsofficial/",
            "https://twitter.com/",
            "https://www.instagram.com/",           
          "https://www.linkedin.com/company/",
            "https://www.pinterest.com/"
            ],
            "logo": "https://makfuels.com/static/media/logo.4ed5bcbedcce5108870c.jpg ",
            "image": "https://makfuels.com/static/media/logo.4ed5bcbedcce5108870c.jpg",
            "description": " Makfuels's terms and conditions clearly define the parameters through which your properties, ownership, license & restrictions will be undertaken.",
            "address": {
            "@type": "PostalAddress",
            "streetAddress": "Howdershell Rd",
            "addressLocality": "Hazelwood",
            "addressRegion": "Missouri",
            "postalCode": "6901",	
            "addressCountry": "USA"
            },
            "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+1-314-724-2828",
            "contactType": "Telephone" ,
            "email":"contact@makfuels.com"
          }`}}
        
          />

      </Helmet>
      {/* <MainHeader /> */}
      <section>
        <div className="privacy_background mt-3">
          <div>
            <h1 className="bann_heading">TERMS OF USE</h1>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="privacy_content my-5">
          <h1 className="back__privacy_head">
            Makfuels  PROPERTIES TERMS OF USE AGREEMENT
          </h1>
          <p>
            Welcome to Makfuels! Makfuels Corporation (together with its
            affiliates and subsidiaries, “Makfuels”) has provided various
             properties, including without limitation its website
            (Makfuels.com) and mobile application (collectively, the “
            Properties”) for your convenience to help you learn about our
            company, services and products. In consideration of Makfuels
            allowing you to use our  Properties and the services provided
            by and related to the  Properties, you signify your
            acceptance of and agreement to be bound by the terms and conditions
            of this Terms of Use Agreement (the “Agreement”).
          </p>
          <p>
            Your use of the  Properties constitutes and evidences your
            express consent to these terms of use and your acceptance of this
            Agreement. You acknowledge and agree that your use of the 
            Properties has the same effect as physically signing this Agreement
            with Makfuels and creates a valid, binding contract between you and
            Makfuels. Therefore, please read all of the following terms and
            conditions carefully before continuing. Please be aware that these
            terms of use may be changed or updated at any time without prior
            notice. Changes are effective when posted on the  Properties.
            Please review them periodically for current information. If you do
            not agree to these terms and conditions and do not wish to enter
            into this Agreement, you should discontinue all use of the 
            Properties and obtain services through other resources.
          </p>
          <h2 className="back__privacy_head_h2">
            OWNERSHIP, LICENSE AND RESTRICTIONS ON USE
          </h2>
          <p>
            All of the information and content on the  Properties,
            including but not limited to all text, graphics, images, software
            applications and code, video, audio, and user interface design
            (“Content”) is the property of Makfuels or is being used by Makfuels
            with the express permission of a licensor, and is protected by
            United States and foreign copyright laws and conventions. You may
            not modify, reproduce, copy, distribute, transmit, display, publish,
            download or upload, sell, license, create derivative works of or use
            any aspect of  Properties or their Content for commercial or
            public purposes or for any other purpose not expressly permitted by
            these terms of use and this Agreement. The use of the Content on any
            other website, mobile application, or in a networked computer
            environment for any purpose, or any other republication,
            redistribution or use of the Content, including, without limitation,
            framing the Content within another website, is expressly prohibited
            without the prior written permission of Makfuels.
          </p>
          <p>
            The trademarks, service marks, logos, URLs and domain names,
            registered or not, (“Marks”) appearing on or in the 
            Properties are the property of Makfuels and other parties. You may
            not use, copy, download, display, transmit or modify any Mark in any
            way without the prior written consent of the owner of such Mark. You
            may not include the Makfuels name, logo or any Mark or any variation
            of the foregoing, as a metatag, hidden textual element, or any other
            indicator that may create an impression of affiliation, sponsorship,
            endorsement or any other relationship between the 
            Properties, Makfuels and any other website, mobile application,
            person, entity or organization.
          </p>
          <p>
            To assist and inform visitors to our  Properties, Makfuels
            makes licensed or fair use of Marks belonging to third parties for
            display on the  Properties. Makfuels makes no claim of
            ownership of these Marks, and such materials are to be considered
            the exclusive property of the respective third parties. Makfuels’s
            use of Marks belonging to third parties is not intended to suggest
            affiliation with, sponsorship of, or endorsement by the Mark’s
            holder.
          </p>
          <p>
            Makfuels hereby grants a non-exclusive, non-transferable, limited
            license to view the  Properties, and to print insignificant
            portions of materials retrieved from the  Properties,
            provided (1) it is used only for informational, non-commercial
            purposes, (2) the copyright notice or other notices are not removed
            or obscured, and (3) you do not violate any third party rights.
          </p>
          <p>
            All information submitted to Makfuels via the  Properties
            shall be deemed and remain the property of Makfuels and we shall be
            free to use, for any purpose, anywhere and in any format, any ideas,
            concepts, know-how or techniques contained in the information you
            may provide to Makfuels through the  Properties. Makfuels
            shall not be subject to any obligations of confidentiality regarding
            such information, except as specifically stated in our privacy
            policies, specifically agreed to, or required by law. You are
            advised that Makfuels does not want you to provide and prohibits you
            from providing any information or materials to Makfuels that are
            defamatory, threatening, obscene, harassing, or otherwise unlawful
            or offensive, or that incorporate the proprietary material of
            another without authority. Makfuels reserves the right, at its
            discretion, to refuse access to the  Properties or to take
            any other appropriate action if it believes that a visitor’s conduct
            is in violation of applicable law or this Agreement.
          </p>
          <h2 className="back__privacy_head_h2">LINKS TO THIRD PARTY SITES</h2>
          <p>
            From time to time, Makfuels may provide links from the 
            Properties to a non-Makfuels website or mobile application;
            likewise, Makfuels may permit a link from a non-Makfuels website or
            mobile application to the  Properties. Such links are for
            your convenience only. Makfuels does not control or review any link,
            and accepts no responsibility for the content, products or services
            provided at these linked websites or mobile applications. If you
            decide to access such non-Makfuels websites or mobile applications,
            you do so solely at your own risk and you should be aware that
            non-Makfuels websites and mobile applications are governed by their
            own terms and conditions and privacy policies. Links to the 
            Properties may be made only with the permission of Makfuels.
          </p>
          <h2 className="back__privacy_head_h2">
            ACCURACY AND INTEGRITY OF INFORMATION
          </h2>
          <p>
            While Makfuels attempts to ensure the integrity and accurateness of
            its  Properties, it makes no representations, warranties or
            guarantees as to the correctness or accuracy of the 
            Properties and/or any content thereon. It is possible that the
             Properties could include typographical errors, inaccuracies
            or other errors, and that unauthorized additions, deletions and
            alterations could be made by third parties. If an inaccuracy arises,
            please let us know so that it can be corrected. The 
            Properties may contain information related to Makfuels stores, as
            well as daily fuel prices, product prices, store specials, store
            facilities, store locations, service offerings and service
            availability. Makfuels makes no warranties or guarantees that such
            information is accurate or that each service or product will be
            available at a specific time or at every store location.
          </p>
          <h2 className="back__privacy_head_h2">
            ACCESS TO  PROPERTIES
          </h2>
          <p>
            No warranty or representation is made that the  Properties,
            or any part or function thereof, will be available and not subject
            to interruption at any time. In the event access to the 
            Properties or a portion thereof is limited requiring a user ID and
            password (“Protected Content”), you agree to access Protected
            Content using only your user ID and password or your social media
            profile(s). You agree to protect the confidentiality of your user ID
            and password, and not to share or disclose your user ID or password
            to any third party. Access to the  Properties may be revoked
            by Makfuels at any time with or without cause or notice.
          </p>
          <h2 className="back__privacy_head_h2">ONLINE AND MOBILE ORDERING</h2>
          <p>
            We may offer the capability through the  Properties to place
            orders for products and services for pick-up at a Makfuels location.
            All orders placed using the  Properties are subject to
            availability and confirmation of order terms, including but not
            limited to the purchase price. The inclusion of any products or
            services on the  Properties does not imply or warrant the
            availability of such products or services, and availability and
            prices of products and services are subject to change at any time
            without notice.
          </p>
          <p>
            Your submitted order is an offer to purchase, which shall be
            accepted only upon the later of (i) confirmation by email from
            Makfuels, and (ii) payment in full for the order. Makfuels reserves
            the right to refuse any order in its sole discretion for any reason,
            including but not limited to lack of availability, errors on the
             Properties, and/or errors in the order. You agree to pay all
            charges incurred by you, on your behalf, or by your account through
            the  Properties, at the price in effect when such charges are
            incurred. By submitting your order, you represent that all details
            of your order are correct and that you are authorized to use the
            payment method used to place the order. You are solely responsible
            for any taxes applicable to your order.
          </p>
          <p>
            By submitting an order, you consent to the collection and
            transmission of your personal information, including but not limited
            to your name, user name and password, address, email address,
            location, and payment data (including your credit card information),
            to Makfuels and/or its trusted third party vendors to record,
            process, transmit, and store your personal information for the
            purposes of fulfilling your order, subject to Makfuels’s 
            Privacy Policy.
          </p>
          <p>
            You cannot make changes to an order once it is placed. Makfuels in
            its sole discretion may permit you to cancel an order and issue a
            refund, if applicable, prior to the designated pick-up time on the
            order. No cancellations or refunds are permitted after the
            designated pick-up time. You may request a cancellation pursuant to
            the instructions provided in the confirmation email.
          </p>
          <h2 className="back__privacy_head_h2">MOBILE MESSAGING PROGRAM</h2>
          <p>
            We may offer the capability to receive SMS/MMS mobile messages
            related to the  Properties (the “SMS Program”) by
            affirmatively opting in, such as through online, mobile, or
            application-based enrollment forms. Regardless of the opt-in method
            utilized, you agree that this Agreement and Makfuels’s 
            Privacy Policy apply to your participation in the SMS Program. By
            participating in the SMS Program, you agree to receive autodialed or
            prerecorded marketing mobile messages at the phone number associated
            with your opt-in. Messaging and data rates may apply. You must have
            a wireless device capable of two-way messaging and be a wireless
            service subscriber with text messaging service permitting
            participation in the SMS Program. Not all cellular phone providers
            carry the necessary service to participate. Check your phone
            capabilities for specific text messaging instructions. The SMS
            Program is offered on an “as-is” basis and may not be available in
            all areas at all times and may not continue to work in the event of
            product, software, coverage or other changes made by your wireless
            carrier. Makfuels will not be liable for any delays or failure of
            delivery of any mobile messages connected with the SMS Program.
            Delivery of mobile messages is subject to effective transmission
            from your wireless service provider/network operator and is outside
            of Makfuels’s control.
          </p>
          <p>
            If you desire to stop participating in the SMS Program, you agree to
            reply with the appropriate termination command as specified through
            such SMS Program (e.g., STOP, END, CANCEL, UNSUBSCRIBE, or QUIT) to
            a mobile message from Makfuels in order to opt out of the Program.
            You may receive an additional mobile message confirming your
            decision to opt out. You understand and agree that the foregoing
            options are the only reasonable methods of opting out. You also
            understand and agree that any other method of opting out, including,
            but not limited to, texting words other than those set forth in the
            appropriate SMS Program or verbally requesting one of our employees
            to remove you from our list is not a reasonable means of opting out.
          </p>
          <p>
            If at any time you intend to stop using the mobile telephone number
            that has been used to subscribe to the SMS Program, including
            canceling your service plan or selling or transferring the phone
            number to another party, you agree that you will complete the opt
            out process set forth above prior to ending your use of the mobile
            telephone number. You understand and agree that your obligation to
            do so is a material part of these terms and conditions. You further
            agree that, if you discontinue the use of your mobile telephone
            number without notifying Makfuels of such change, you agree that you
            will be responsible for all costs (including attorneys’ fees) and
            liabilities incurred by Makfuels, or any party that assists in the
            delivery of the mobile messages, as a result of claims brought by
            individual(s) who are later assigned that mobile telephone number.
            This duty and agreement shall survive any cancellation or
            termination of your agreement to participate in any of our Programs
          </p>
          <p>
            YOU AGREE THAT YOU SHALL INDEMNIFY, DEFEND, AND HOLD Makfuels
            HARMLESS FROM ANY CLAIM OR LIABILITY RESULTING FROM YOUR FAILURE TO
            NOTIFY Makfuels OF A CHANGE IN THE INFORMATION YOU HAVE PROVIDED,
            INCLUDING ANY CLAIM OR LIABILITY UNDER THE TELEPHONE CONSUMER
            PROTECTION ACT, 47 U.S.C. § 227, et seq., OR SIMILAR STATE AND
            FEDERAL LAWS, AND ANY REGULATIONS PROMULGATED THEREUNDER RESULTING
            FROM Makfuels ATTEMPTING TO CONTACT YOU AT THE MOBILE TELEPHONE
            NUMBER YOU PROVIDED.
          </p>
          <h2 className="back__privacy_head_h2">
            DISCLAIMERS; LIMITATION OF LIABILITY
          </h2>
          <p>
            No warranty. Content is provided for informational purposes only and
            should not be relied on. Our intent is to provide accurate and
            up-to-date content on the  Properties; however, that is not
            always possible. THEREFORE, ALL CONTENT, SERVICES AND FUNCTIONS ON
            THE  PROPERTIES, OR ANY OTHER WEBSITE OR MOBILE APPLICATION
            LINKED TO THE  PROPERTIES, ARE PROVIDED “AS IS” AND “AS
            AVAILABLE” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED,
            INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
            NON-INFRINGEMENT. Some jurisdictions do not allow the exclusion of
            certain implied warranties, in which event any required warranty
            applies to the minimum extent legally required.
          </p>
          <p>
            No warranty or representation is made that any Content, service,
            product or function, including without limitation any downloadable
            files, available at or on the  Properties or any linked
            website or mobile application is free of computer viruses or
            destructive or harmful programs, software, code or devices which may
            erase, scramble, lock or disable any software, content or equipment
            or may prevent users from using a website or mobile application or
            any other item of hardware or software. You should routinely scan
            your system and downloaded files for such disabling devices.
          </p>
          <p>
            From time to time Makfuels’s  Properties may contain or
            provide information supplied by third parties. Any opinions, advice,
            statements, services, offers, or other information expressed or made
            available by a third party, are those of such third party and not
            Makfuels. Makfuels does not independently verify the information
            provided by third parties, does not guarantee the accuracy,
            completeness, timeliness or correct sequencing of such information,
            and assumes no responsibility for Content displayed on the 
            Properties that may be provided by another party.
          </p>
          <p>
            If you are dissatisfied with any portion or function of the 
            Properties, or with any of these terms of use, your sole and
            exclusive remedy is to discontinue using the  Properties. IN
            NO EVENT WILL Makfuels BE LIABLE TO ANY PARTY FOR ANY DIRECT,
            INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES,
            INCLUDING WITHOUT LIMITATION ANY LOST PROFITS, BUSINESS
            INTERRUPTIONS, LOSS OF PROGRAMS OR DATA ON YOUR EQUIPMENT, OR
            OTHERWISE, EVEN IF WE OR ANY OF OUR REPRESENTATIVES ARE EXPRESSLY
            ADVISED OF THE POSSIBILITY OR LIKELIHOOD OF SUCH DAMAGES, ARISING
            OUT OF OR RELATED TO ANY USE OF OR INABILITY TO USE THE 
            PROPERTIES, OR ANY OTHER LINKED WEBSITE OR MOBILE APPLICATION,
            INCLUDING WITHOUT LIMITATION USE OF OR RELIANCE ON INFORMATION,
            INTERRUPTIONS, ERRORS, DEFECTS, MISTAKES, OMISSIONS, DELETION OF
            FILES, DISABLING DEVICES, DELAYS IN OPERATION OR TRANSMISSION,
            NONDELIVERY OF INFORMATION, THEFT, UNAUTHORIZED ACCESS, DISCLOSURES
            OF COMMUNICATIONS, OR ANY FAILURE OF PERFORMANCE. Some jurisdictions
            do not allow limitation or exclusion of certain liabilities or
            damages, in which event in such jurisdictions the foregoing shall
            apply to the maximum extent allowed by law.
          </p>
          <h2 className="back__privacy_head_h2">UNLAWFUL AND PROHIBITED USE</h2>
          <p>
            As a specific condition of your use of any  Property, you
            explicitly agree not to use it for any purpose that is unlawful or
            prohibited by these terms of use and this Agreement. You expressly
            agree not to use the  Properties in any way that could
            damage, disable, overburden, or impair the same, or interfere with
            anyone else’s use. Furthermore, you agree not to attempt to gain
            access to any computer systems or networks connected to Makfuels
            through fraud, hacking, password mining or any other means, or to
            attempt to reverse engineer any portion of the  Properties.
          </p>
          <h2 className="back__privacy_head_h2">
            GOVERNING LAW AND JURISDICTION
          </h2>
          <p>
            These terms of use and this Agreement shall be governed by the laws
            of the State of Oklahoma, without regard to Oklahoma’s conflict of
            laws provisions. By using the  Properties you irrevocably
            consent to the exclusive jurisdiction and venue of the state and
            federal courts in Tulsa, Oklahoma for all disputes arising out of or
            relating to the use of the  Properties. Makfuels does not
            represent that the information in or on the  Properties is
            appropriate or available for use in other locations, and access from
            certain locations may be strictly prohibited. Those who access the
             Properties do so on their own initiative and are responsible
            for compliance with all applicable local laws.
          </p>
          <h2 className="back__privacy_head_h2">INDEMNITY</h2>
          <p>
            YOU SPECIFICALLY AGREE TO INDEMNIFY AND HOLD HARMLESS Makfuels, ITS
            AFFILIATES, SUBSIDIARIES, STOCKHOLDERS, OFFICERS AND EMPLOYEES, FROM
            AND AGAINST ANY CLAIM, DEMAND OR DAMAGE, INCLUDING REASONABLE
            ATTORNEYS’ FEES, ASSERTED BY ANY THIRD PARTY DUE TO OR ARISING OUT
            OF YOUR USE OF OR CONDUCT ON THE  PROPERTIES.
          </p>
          <h2 className="back__privacy_head_h2">PRIVACY POLICY</h2>
          <p>
            Our  Privacy Policy, as it may change from time to time, is
            incorporated as a part of this Agreement and is set forth below.
            Accordingly, all terms contained herein, apply equally to the
             Privacy Policy.
          </p>
          <h2 className="back__privacy_head_h2">MODIFICATION</h2>
          <p>
            Makfuels may amend these terms of use and this Agreement from time
            to time without specific notice to you. Any modifications to the
            terms of use and this Agreement will be effective immediately upon
            posting of, or reference to the posting of, such modification on the
             Properties. It is your responsibility to review these terms
            of use and this Agreement each time you use the  Properties
            so that you are aware of any modifications made. Your continued
            usage of the  Properties shall represent and acknowledge your
            acceptance of the then-current form of the terms of use and this
            Agreement. For your convenience and future reference, the date of
            the current version of this Agreement is noted below. By continuing
            to visit and use the  Properties following the posting of a
            revised Agreement, you are agreeing to any changes in the revised
            Agreement.
          </p>
          <h2 className="back__privacy_head_h2">
            ENTIRE AGREEMENT; SEVERABILITY
          </h2>
          <p>
            These terms of use and this Agreement incorporate by reference any
            notices contained in or on the  Properties and constitute the
            entire agreement with respect to your access to and use of the
             Properties. If any provision of this Agreement is determined
            by any appropriate authority with proper jurisdiction to be
            unlawful, void or unenforceable, then that provision shall be deemed
            severable from the remaining provisions and shall not affect their
            validity and enforceability.
          </p>
          <h2 className="back__privacy_head_h2">
             MILLENNIUM COPYRIGHT ACT (“DMCA”) NOTICE
          </h2>
          <p>
            Makfuels abides by the federal  Millennium Copyright Act
            (DMCA) by responding to notices of alleged infringement that comply
            with the DMCA and other applicable laws. As part of our response, we
            may remove or disable access to material residing on an 
            Property that is controlled or operated by Makfuels that is claimed
            to be infringing, in which case we will make a good-faith attempt to
            contact the person who submitted the affected material so that they
            may make a counter notification, also in accordance with the DMCA.
          </p>
          <p>
            Before serving either a Notice of Infringing Material or
            Counter-Notification, you may wish to contact a lawyer to better
            understand your rights and obligations under the DMCA and other
            applicable laws. The following notice requirements are intended to
            comply with Makfuels’s rights and obligations under the DMCA and, in
            particular, section 512(c), and do not constitute legal advice.
          </p>
          <p>
            To file a notice of infringing material on a  Property owned
            or controlled by Makfuels please provide a notification containing
            the following details:
          </p>
          <p>
            <ul>
              <li>
                <p className="mb-2">
                  Reasonably sufficient details to enable us to identify the
                  work claimed to be infringed or, if multiple works are claimed
                  to be infringed, a representative list of such works (for
                  example: title, author, any registration or tracking number,
                  URL);
                </p>
              </li>
              <li>
                <p className="mb-2">
                  Reasonably sufficient detail to enable us to identify and
                  locate the material that is claimed to be infringing (for
                  example a link to the page that contains the material); as
                  well as your contact information so that we can contact you
                  (for example, your address, telephone number, email address);
                </p>
              </li>
              <li>
                <p className="mb-2">
                  A statement that you have a good faith belief that the use of
                  the material identified is not authorized by the copyright
                  owner, its agent, or the law;
                </p>
              </li>
              <li>
                <p className="mb-2">
                  A statement, under penalty of perjury, that the information in
                  the notification is accurate and that you are authorized to
                  act on behalf of the owner of the exclusive right that is
                  alleged to be infringed.
                </p>
              </li>
              <li>
                <p className="mb-2">Your physical or electronic signature.</p>
              </li>
            </ul>
          </p>
        </div>
      </section>
    </>
  );
};

export default TermConditions;
