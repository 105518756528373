import React from "react";
import Slider from "react-slick";
import { Spinner } from "reactstrap";
import productImg from "../../assets/images/grocery_product.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const RelatedProductSlider = (props) => {
  const { loading, relatedProduct, handleClickPro } = props;

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="mt-2 mb-2">
        <p>Related Products</p>
        <div className="rem_arrow_slick mb-1">
          <Slider {...settings}>
            {loading && <Spinner color="warning" type="grow"></Spinner>}
            {!loading && relatedProduct.length === 0 && (
              <div className="m-auto text-center">
                No Related Products Found
              </div>
            )}
            {!loading &&
              relatedProduct.map((val, ind) => (
                <div key={ind}>
                  <div
                    className="related__pro_d"
                    key={ind}
                    onClick={() => handleClickPro(val)}
                  >
                    <LazyLoadImage
                      src={val.image}
                      alt={val.name}
                      title={val.name}
                      loading='lazy'
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = productImg;
                      }}
                    />
                    <p className="related__pro_name">{val.name}</p>
                    {/* <p className="related__pro_price">{val.price}</p> */}
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default RelatedProductSlider;
