import React from "react";
import { useSelector } from "react-redux";

const CheckoutCart = () => {
  const cart = useSelector((state) => state.cart);

  return (
    <>
      <div className="cart__sec px-3 pb-4" id="myDIV">
        <h6 className="mb-3 pt-3">Final items</h6>
        {cart.cartItems.length === 0 && (
          <p>
            <p>Your cart is currently empty</p>
          </p>
        )}
        {cart?.cartItems.map((items, index) => {
          const totalSauceRedux = items.extrasaucesArray.reduce(
            (totalQuantity, val) =>
              totalQuantity + val.quantity * items.cartQuantity,
            0
          );
          // ---Calculate-Quantity-Start---
          const newQuantity = items.finalSauceQuantity;
          const drinkPrice =
            items && items?.selectedSoftDrinks?.price == null
              ? 0
              : parseFloat(items && items?.selectedSoftDrinks?.price);

          const totalSaucePrice = items.extrasaucesArray.reduce(
            (accumulator, currentItem) => {
              return parseFloat(currentItem.price) * newQuantity;
            },
            0
          );
          let totalSidePrice = items?.sidesArray?.reduce(
            (accumulator, currentItem, i) => {
              if (items.sidesQuantity > i) {
                return accumulator;
              } else {
                return parseFloat(currentItem.price) + accumulator;
              }
            },
            0
          );
          const totalSidePrices =
            totalSidePrice == undefined ? 0 : totalSidePrice;
          let FriesResultsUpd =
            items.FriesResult == undefined ? 0 : items.FriesResult;
          let pricing =
            items.discount_price === null ? items.price : items.discount_price;
          pricing =
            pricing === null ? items.selectedSubproduct?.price : pricing;
          let toppingPrice =
            items.toppingsArray.reduce(
              (total, currentValue) =>
                (total =
                  total + parseFloat(currentValue.price) * items.cartQuantity),
              0
            ) +
            totalSaucePrice * items.cartQuantity +
            totalSidePrices * items.cartQuantity +
            FriesResultsUpd +
            drinkPrice +
            pricing * items.cartQuantity;
          console.log(toppingPrice, "toppingPrice");
          // ---Calculate-Quantity-End---
          return (
            <div className="card__items   mb-2" key={index}>
              <div className="d-flex justify-content-between">
                {/* {items.main_cat_id === "2"} */}
                <p>
                  {items.cartQuantity} x {items.prod_name} {items.name}
                  {items.main_cat_id === "1"
                    ? items.is_sub_avaiable == 0
                      ? null
                      : ` (${items.selectedSubproduct?.name})`
                    : items.selectedSubproduct?.name &&
                      ` (${items.selectedSubproduct.name})`}
                </p>

                <p className="ms-2">
                  {/* {items.discount_price === null && (
                    <span>
                      {items.main_cat_id === "2" ? "" : `$${items.price}`}
                    </span>
                  )}
                  {items.discount_price !== null && (
                    <span>
                      {items.main_cat_id === "2"
                        ? ""
                        : `$${items.discount_price}`}
                    </span>
                  )} */}

                  {items.main_cat_id == 2 && (
                    <span className="text-nowrap">$ {items.price} </span>
                  )}
                  {items.main_cat_id == 1 && (
                    <span className="text-nowrap">
                      ${" "}
                      {items.is_sub_avaiable == 0
                        ? items.price
                        : items.selectedSubproduct?.price}
                    </span>
                  )}
                </p>
              </div>
              <p>
                {/* <b>
                    {items.toppingsArray.length > 0 && items.main_cat_id === "1"
                      ? "Add Onns"
                      : ""}
                  </b> */}
                {/* <b>
                  {items.toppingsArray.length > 0 && items.main_cat_id === "2"
                    ? "Varients"
                    : ""}
                </b> */}
              </p>
              {/* <p>
                    {items.main_cat_id === "1"
                      ? items.is_sub_avaiable == 0
                        ? null
                        : ` (${items.selectedFriesType?.type})`
                      : items.selectedFriesType?.type &&
                        ` (${items.selectedFriesType.type})`}
                  </p> */}
              {items.main_cat_id == 1 &&
                items.is_sub_avaiable == 1 &&
                Object.keys(items.selectedFriesType).length > 0 && (
                  <div
                    style={{ fontSize: "12px" }}
                    className="d-flex justify-content-between"
                  >
                    <div>
                      {items.selectedFriesType?.type} (
                      {items.selectedFriesSize?.name})
                    </div>
                    <div>
                      {" "}
                      {items.FriesResult == 0
                        ? "INCLUDED"
                        : `$ ${items.FriesResult.toFixed(2)}`}
                    </div>
                  </div>
                )}
              {items &&
                items.main_cat_id == 1 &&
                items.is_sub_avaiable == 1 &&
                Object.keys(items?.selectedFountainDrinks)?.length > 0 && (
                  <div
                    style={{ fontSize: "12px" }}
                    className="d-flex justify-content-between"
                  >
                    <div>
                      {items?.selectedFountainDrinks?.name} (
                      {items?.selectedSoftDrinks?.name})
                    </div>
                    <div>
                      {items?.selectedSoftDrinks?.price == null
                        ? "INCLUDED"
                        : `$ ${items?.selectedSoftDrinks?.price}`}
                    </div>
                  </div>
                )}

              {items.main_cat_id == 1 &&
              items?.cheesesArray.length > 0 &&
              items.is_sub_avaiable == 1 ? (
                <ul>
                  <h6>Cheeses</h6>
                  {items.cheesesArray.map((val, index) => {
                    return <li key={index}>{val.type} </li>;
                  })}
                </ul>
              ) : null}

              {items.main_cat_id == 1 &&
              items?.toppsecondArray?.length > 0 &&
              items.is_sub_avaiable == 1 ? (
                <ul className="mt-2">
                  <h6>Toppings</h6>
                  {items?.toppsecondArray?.map((val, index) => {
                    return <li key={index}>{val.type} </li>;
                  })}
                </ul>
              ) : null}

              {items.main_cat_id == 1 &&
              items?.sidesArray.length > 0 &&
              items.is_sub_avaiable == 1 ? (
                <ul className="mt-2">
                  <h6>Sides</h6>
                  {items.sidesQuantity != 0 ? (
                    <p className="exclude_sauces my-1">{`Exclude ${
                      items.sidesQuantity
                    } ${items.sidesQuantity > 1 ? "Sides" : "Side"} Price`}</p>
                  ) : (
                    " "
                  )}
                  {items?.sidesArray.map((val, index) => {
                    return (
                      <li key={index}>
                        {val.type}{" "}
                        <span className="float-end">
                          $ {val.price} x {items.cartQuantity}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              ) : null}

              {items.main_cat_id == 1 &&
              items?.cookingSauceArray.length > 0 &&
              items.is_sub_avaiable == 1 ? (
                <ul className="mt-2">
                  <h6>Sauces</h6>
                  {items.cookingSauceArray.map((val, index) => {
                    return <li key={index}>{val.type} </li>;
                  })}
                </ul>
              ) : null}

              {items.main_cat_id == 1 &&
              items?.extrasaucesArray.length > 0 &&
              items.is_sub_avaiable == 1 ? (
                <ul className="mt-2">
                  {items.extrasaucesArray.length > 0 && (
                    <div>
                      <h6>Dip Sauces</h6>
                      <p className="exclude_sauces my-1">{`Exclude ${
                        totalSauceRedux - items.finalSauceQuantity
                      } ${
                        totalSauceRedux - items.finalSauceQuantity > 1
                          ? "Sauces"
                          : "Sauce"
                      } Price`}</p>
                    </div>
                  )}
                  {items.extrasaucesArray.map((val, index) => {
                    return (
                      <li key={index}>
                        <div className="d-flex justify-content-between">
                          <p>
                            {val.quantity * items.cartQuantity} x {val.name}
                          </p>
                          <p>$ {val.price}</p>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              ) : null}

              <ul className="mt-2">
                {items.toppingsArray.map((val, index) => {
                  return (
                    <li key={index}>
                      {val.type}{" "}
                      <span className="float-end">
                        ${val.price} x {items.cartQuantity}
                      </span>
                    </li>
                  );
                })}
              </ul>
              <div>
                <div className="d-flex justify-content-between itemsTax">
                  <p>Tax:</p>
                  <p>
                    ${" "}
                    {(
                      (toppingPrice.toFixed(2) / 100) *
                      items.tax_percentage
                    ).toFixed(2)}
                  </p>
                </div>
                <div className="d-flex justify-content-between itemsSubTotal">
                  <p>Total:</p>
                  <p>$ {toppingPrice.toFixed(2)}</p>
                </div>

                {/* <p>{items.tax_percentage}</p> */}
              </div>
            </div>
          );
        })}

        <div className="amount__total">
          <div className="d-flex justify-content-between">
            <p>Sub total</p>
            <p>$ {cart.cartSubTotalAmount}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Tax</p>
            <p className="discount__price">$ {cart.cartTotalTax}</p>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <p>Total</p>
            <p>$ {cart.cartTotalAmount}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutCart;
