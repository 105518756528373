import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { baseURL, imageUrl } from "../config/apiUrl";
import { logout } from "../redux/features/auth/authSlice";
import { Spinner } from "reactstrap";
import moment from 'moment/moment';
import { Helmet } from "react-helmet-async";


const LocationInvoice = () => {
const [data, setdata] = useState([])
const [type, setType] = useState()
const [typeName, setTypeName] = useState()
const [prices, setPrices] = useState()
const [selectDate, setSelectDate] = useState()
const [loading, setloading] = useState(false)
const { token, name ,address} = useSelector((state) => state.auth);
const dispatch = useDispatch();
const navigate = useNavigate();

  const fetchInvoive = () => {
    var config = {
      method: "get",
      url: baseURL + `/getInvoices/${type?type:0}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setloading(true)

    axios(config)
      .then(function (response) {
        const { data } = response.data;
        setdata(data);
    setloading(false)

      })
      .catch(function (error) {
        console.log(error);
    setloading(false)

      });
  };
  const fetchPrice = () => {
    var config = {
      method: "get",
      url: baseURL + `/getPricesByLocationId`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const { data } = response.data;
        setPrices(data);

      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchPrice()
    fetchInvoive();
  // eslint-disable-next-line
  }, []);
  const handleLogout = () => {
    dispatch(logout());
    // dispatch(clearCart());
    navigate("/");
  };
  const handleClick = (num,name) =>{
    setType(num)
    setTypeName(name)
  }
  useEffect(() => {
    if(type){

      fetchInvoive();
    }
  // eslint-disable-next-line
  }, [type])
  
  const handleDate = () => {
    var data = new FormData();
    data.append("date", selectDate);
    var config = {
      method: "post",
      url: baseURL + `/getInvoicesByDate/${type?type:0}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data:data
    };
    setloading(true)

    axios(config)
      .then(function (response) {
        const { data } = response.data;
        setdata(data);
    setloading(false)

      })
      .catch(function (error) {
        console.log(error);
    setloading(false)

      });
  };
  const currentDate = new Date();
  return (
    <>
                  <Helmet>
                  <title>Makfuels | Member-Invoice</title>
        <link rel="canonical" href="https://makcom.cf/member-invoice" />
      </Helmet>
      <section className="container mt-2">
        <div className="location__eft_main">
          <div className="d-flex align-items-center justify-content-between">
            <h3 className="loca_hea_d">
              <span>
                <i className="fa-solid fa-location-dot"></i>
              </span>{" "}
              {address}
            </h3>
            <span className="text-success">Current Date ({moment(currentDate).format('MMM DD YYYY')})</span>
            <div className="d-flex align-items-center">
              <p>Hi, {name}</p>
        <button className="logOut_btn" onClick={handleLogout}>Log out</button>

            </div>

          </div>

          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Variation</th>
                <th scope="col">Product Code</th>
                <th scope="col">Price</th>
                <th scope="col">Valid Start</th>
                <th scope="col">Valid End</th>
              </tr>
            </thead>
            <tbody>
        { prices && prices.length===0 &&<tr>
                <td colSpan={4}>
                <div className="no_file__found">No Files Found <i className="fa-solid fa-circle-xmark text-danger"></i></div>
                </td>
              </tr>}

              {prices && prices.map((value,index)=>{
                return(
              <tr key={index}>
                <th scope="row">{value.variation_name}</th>
                <td>{value.type}</td>
                <td>$ {value.price}</td>
                <td>{moment(value.valid_start).format('MMM DD YYYY')}</td>
                <td>{moment(value.valid_end).format('MMM DD YYYY')}</td>
              </tr>

)})}
            </tbody>
          </table>
<div>
  <p>Mak pricing is privileged and confidential. Your are not authorized to disseminate this data to any third party,</p>
  <p><b className="text-danger"> Note: </b> The price does not include taxes and delivery charges.</p>
</div>
          <div className="main__btn_dIv">
            <button className={type==="1"?"invoices_btnss active":"invoices_btnss"} onClick={()=>handleClick("1","EFT")}>EFT</button>
            <button className={type==="2"?"invoices_btnss active":"invoices_btnss"} onClick={()=>handleClick("2","INVOICS")}>INVOICE</button>
            {/* <button className={type==3?"invoices_btnss active":"invoices_btnss"} onClick={()=>handleClick("3","LOST RUN")}>LOST RUN</button> */}
          </div>
        </div>
        <div className="pdfs__contents">
          <div className="d-flex justify-content-between">
            <h4 className="csv__HeA">{typeName?typeName:"ALL"} Files</h4>
            <div className="seArCh_date">
              <div className="form-floating mb-1">
                <input
                  type="date"
                  className="form-control"
                  id="floatingInput"
                  onChange={(e)=>setSelectDate(e.target.value)}                  
                />
                <label htmlFor="floatingInput">Filter By Date</label>
              </div>

              <button className="seArch_btn" onClick={handleDate}>
                Search <i className="fa-solid fa-magnifying-glass"></i>
              </button>
            </div>
          </div>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">S.NO</th>
                <th scope="col">File</th>
                <th scope="col">Date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
            {loading && <tr><td colSpan={4} className="text-center"><Spinner/></td></tr>}
        {!loading && data.length===0 &&<tr>
                <td colSpan={4}>
                <div className="no_file__found">No Files Found <i className="fa-solid fa-circle-xmark text-danger"></i></div>
                </td>
              </tr>}

              {!loading && data && data.map((value,index)=>{
                return(
              <tr key={index}>
                <th scope="row">{index}</th>
                <td>{value.extension}</td>
                <td>{value.date}</td>
                <td>
                  <div>
                    <button className="btn check_out_btn"><a className="text-white" href={imageUrl + `/${value.filename}`} download="Invoice" rel="noopener noreferrer" target="_blank">View</a></button>
                  </div>
                </td>
              </tr>

)})}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
};

export default LocationInvoice;
