import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import QrReader from "react-qr-reader";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

function QRscanner() {
  const [selected, setSelected] = useState("environment");
  const [startScan, setStartScan] = useState(false);
  const [data, setData] = useState("");
  const navigate = useNavigate();

  const handleScan = async (scanData) => {
    if (scanData && scanData !== "") {
      setData(scanData);
      setStartScan(false);
      navigate("/scan-qr", { state: { data: scanData } });
    }
  };
  const handleError = (err) => {
    console.error(err);
  };
  return (
    <>
      <Helmet>
        <title>Makfuels | QR-Scanner</title>
        <link rel="canonical" href="https://makcom.cf/qr_scanner" />
      </Helmet>
      <div className="container mt-3">
        <Link to="/">
          <button className="back_btn_q">
            <i className="fa-solid fa-left-long me-1"></i>Back
          </button>
        </Link>
        <div className="qr__main_s mt-2">
          <h3 className="scan_qr_head">Scan QR</h3>
          {startScan && (
            <>
              <select
                className="form-select front_back_select"
                onChange={(e) => setSelected(e.target.value)}
              >
                <option value={"environment"}>Back Camera</option>
                <option value={"user"}>Front Camera</option>
              </select>
              <div className="qrReader_main mb-2">
                <QrReader
                  facingMode={selected}
                  delay={1000}
                  onError={handleError}
                  onScan={handleScan}
                  // chooseDeviceId={()=>selected}
                />
              </div>
            </>
          )}
          <div className="text-center">
            <button
              className="back_btn_q"
              onClick={() => {
                setStartScan(!startScan);
              }}
            >
              {startScan ? "Stop Scan" : "Start Scan"}
            </button>
          </div>
        </div>

        {/* {loadingScan && <p>Loading</p>} */}
        {data !== "" && <p>{data}</p>}
      </div>
    </>
  );
}

export default QRscanner;
