import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { baseURL } from "../../config/apiUrl";
import {
  addToCart,
  // getTotals,
} from "../../redux/features/slices/cartSlice";
import productImg from "../../assets/images/food_product.png";
import { Modal, Spinner } from "reactstrap";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import RelatedProductSlider from "../slider/RelatedProductSlider";
import { useNavigate, Link } from "react-router-dom";

const ProductModal = ({
  productValue,
  prod_id,
  show,
  handleClose,
  search,
  seoTags,
  catName,
}) => {
  const dispatch = useDispatch();
  let [num, setNum] = useState(1);
  const [addons, setAddons] = useState([]);
  const [Subproduct, setSubproduct] = useState([]);
  const [selectedSubproduct, setSelectedSubproduct] = useState([]);
  const [selectedFountainDrinks, setSelectedFountainDrinks] = useState([]);
  const [selectedFriesType, setSelectedFriesType] = useState([]);
  const [selectedFriesSize, setSelectedFriesSize] = useState({});
  const [selectedSoftDrinks, setSelectedSoftDrinks] = useState([]);
  const [selectedDrinksBrand, setSelectedDrinksBrand] = useState({});
  const [sauces, setSauces] = useState([]);
  // eslint-disable-next-line
  const [toppings, setToppings] = useState();
  const [toppingsArray, setToppingsArray] = useState([]);
  const [relatedProduct, setRelatedProduct] = useState([]);
  const [prodID, setprodID] = useState();
  const [products, setProducts] = useState(productValue);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  const [saucesArray, setsaucesArray] = useState([]);
  const [extrasaucesArray, setExtrasaucesArray] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [cheeses, setCheeses] = useState([]);
  const [cheesesArray, setCheesesArray] = useState([]);
  const [toppsecond, setToppsecond] = useState([]);
  const [toppsecondArray, settoppsecondArray] = useState([]);
  const [cookingSauce, setcookingSauce] = useState([]);
  const [cookingSauceArray, setcookingSauceArray] = useState([]);
  const [sides, setSides] = useState([]);
  const [softDrinks, setSoftDrinks] = useState([]);
  const [fountainDrinks, setFountainDrinks] = useState([]);
  const [sidesu, setSidesu] = useState([]);
  const [sidesQuantity, setSidesQuantity] = useState(0);
  const [friesType, setFriesType] = useState([]);
  const [friesSize, setFriesSize] = useState([]);
  const [freeFries, setFreeFries] = useState([]);
  const [sidesArray, setSidesArray] = useState([]);
  const [sodaSelectionError, setsodaSelectionError] = useState();
  const [incompleteSelections, setIncompleteSelections] = useState();
  const [friesSizeArray, setFriesSizeArray] = useState([
    selectedFriesType?.friesSize,
  ]);
  const [drinksBrand, setDrinksBrand] = useState([]);

  useEffect(() => {
    setProducts(productValue);
  }, [productValue]);

  const handleIncrement = () => {
    if (num < 20) {
      setNum(Number(num) + 1);
    }
  };
  const handleDecrement = () => {
    if (num > 1) {
      setNum(Number(num) - 1);
    }
  };

  let handleChange = (e) => {
    setNum(e.target.value);
  };
  let handleAddToCart = (product, num, toppingsArray) => {
    if (Object.keys(selectedSoftDrinks).length > 0) {
      if (
        selectedFountainDrinks &&
        Object.keys(selectedFountainDrinks).length !== 0
      ) {
        let data = {
          ...product,
          num,
          toppingsArray,
          selectedSubproduct,
          selectedFriesType,
          selectedFriesSize,
          sidesArray,
          selectedFountainDrinks,
          selectedSoftDrinks,
          saucesArray,
          price: parseFloat(Subproresult),
          extrasaucesArray,
          finalSauceQuantity: finalQuantity,
          sidesQuantity,
          cheesesArray,
          toppsecondArray,
          cookingSauceArray,
          FriesResult,
        };

        dispatch(addToCart(data));
        // dispatch(getTotals());
        if (search === undefined) {
          navigate("/");
        }
        setIsDisabled(false);
        setToppings();
        setSoftDrinks([]);
        setFountainDrinks([]);
        setDrinksBrand([]);
        setSelectedFountainDrinks({});
        setSelectedDrinksBrand("");
        setSelectedFriesTypeId(null);
        setFriesSizeArrayy([]);
        setSelectedSoftDrinks({});
        setSelectedFriesType([]);
        setSelectedFriesSize({});
        setFriesType([]);
        setFriesSizeArray([]);
        setToppingsArray([]);
        setSidesArray([]);
        setSidesQuantity(0);
        setCheesesArray([]);
        setcookingSauceArray([]);
        settoppsecondArray([]);
        setSelectedSubproduct("");
        setsaucesArray([]);
        setExtrasaucesArray([]);
        setNum(1);
        handleClose();
        setprodID();
        setTotalQuantity();
      } else {
        setsodaSelectionError("Please Select Drink");
        setIncompleteSelections("Incomplete Selection(s)");
      }
    } else {
      let data = {
        ...product,
        num,
        toppingsArray,
        selectedSubproduct,
        selectedFriesType,
        selectedFriesSize,
        sidesArray,
        selectedFountainDrinks,
        selectedSoftDrinks,
        saucesArray,
        price: parseFloat(Subproresult),
        extrasaucesArray,
        finalSauceQuantity: finalQuantity,
        sidesQuantity,
        cheesesArray,
        toppsecondArray,
        cookingSauceArray,
        FriesResult,
      };
      dispatch(addToCart(data));
      // dispatch(getTotals());
      if (search === undefined) {
        navigate("/");
      }
      setToppings();
      setIsDisabled(false);
      setSoftDrinks([]);
      setFountainDrinks([]);
      setSelectedFountainDrinks({});
      setSelectedFriesTypeId(null);
      setFriesSizeArrayy([]);
      setSelectedSoftDrinks({});
      setSelectedFriesType({});
      setSelectedFriesSize({});
      setFriesType([]);
      setFriesSizeArray([]);
      setToppingsArray([]);
      setSidesArray([]);
      setCheesesArray([]);
      setcookingSauceArray([]);
      settoppsecondArray([]);
      setSelectedSubproduct("");
      setsaucesArray([]);
      setExtrasaucesArray([]);
      setSidesQuantity(0);
      setNum(1);
      handleClose();
      setprodID();
      setTotalQuantity();
    }
    // console.log(Object.keys(selectedFountainDrinks).length)
    // if (selectedFountainDrinks && Object.keys(selectedFountainDrinks).length !== 0) {
    //   let data = {
    //     ...product,
    //     num,
    //     toppingsArray,
    //     selectedSubproduct,
    //     selectedFriesType,
    //     selectedFriesSize,
    //     sidesArray,
    //     selectedFountainDrinks,
    //     selectedSoftDrinks,
    //     saucesArray,
    //     extrasaucesArray,
    //     finalSauceQuantity: finalQuantity,
    //     cheesesArray,
    //     toppsecondArray,
    //     cookingSauceArray,
    //     FriesResult,
    //   };
    //   dispatch(addToCart(data));
    //   // dispatch(getTotals());
    //   if (search === undefined) {
    //     navigate("/");
    //   }
    //   setToppings();
    //   setSoftDrinks([]);
    //   setFountainDrinks([]);
    //   setSelectedFountainDrinks({});
    //   setSelectedFriesTypeId(null);
    //   setFriesSizeArrayy([]);
    //   setSelectedSoftDrinks({});
    //   setSelectedFriesType({});
    //   setSelectedFriesSize({});
    //   setFriesType([]);
    //   setFriesSizeArray([]);
    //   setToppingsArray([]);
    //   setSidesArray([]);
    //   setCheesesArray([]);
    //   setcookingSauceArray([]);
    //   settoppsecondArray([]);
    //   setSelectedSubproduct("");
    //   setsaucesArray([]);
    //   setExtrasaucesArray([]);
    //   setNum(1);
    //   handleClose();
    //   setprodID();
    //   setTotalQuantity();
    // } else {
    //   // Handle the case when selectedFountainDrinks is not selected or empty.
    //   // You can show an error message or perform other actions as needed.
    //   console.error('selectedFountainDrinks is empty or not selected.');
    // }
  };

  const handleRadioSauce = (val, sauceLoopingid) => {
    const sauce = {
      id: val.id,
      name: val.name,
      image: val.image,
      price: val.price,
    };

    // Create a copy of the saucesArray
    const updatedSaucesArray = [...saucesArray];

    // Check if the sauceLoopingid index already exists in the saucesArray
    if (updatedSaucesArray[sauceLoopingid]) {
      // If the index already exists, update the sauce data at that index
      updatedSaucesArray[sauceLoopingid] = sauce;
    } else {
      // If the index does not exist, add the sauce data at the specified index
      updatedSaucesArray.splice(sauceLoopingid, 0, sauce);
    }

    // Set the updated saucesArray
    setsaucesArray(updatedSaucesArray);

    // Perform any required logic with the saucesArray
  };
  const handleRadio = (val) => {
    const subItems = {
      id: val.id,
      name: val.name,
      price: val.price,
      // sides: [val.sides],
    };

    const ahmer = val?.softDrinks?.map((currEle, ind) => currEle.price);
    setFriesSizeArrayy([]);
    setFriesType(val.fries_type);
    setFriesSize(val.fries_size);
    setFreeFries(val.freeFries);
    setSoftDrinks(val.soft_drinks);
    setSelectedSubproduct(subItems);
    setSelectedFountainDrinks({});
    setSelectedSoftDrinks({});
    setSelectedDrinksBrand({});
    setSelectedFriesType({});
    setSelectedFriesSize({});
    setSelectedFriesTypeId(null);
    setsodaSelectionError("");
    setIncompleteSelections("");

    // Initialize the default soft drink object
    const defaultSoftDrink = {};

    // Loop through val.soft_drinks to find a soft drink with a null price
    for (const softDrink of val.soft_drinks) {
      if (softDrink.price === null) {
        // Set the id, name, and price from the soft drink with null price
        defaultSoftDrink.id = softDrink.id;
        defaultSoftDrink.name = softDrink.name;
        defaultSoftDrink.price = softDrink.price;
        defaultSoftDrink.brands = softDrink.brands;

        // defaultSoftDrink.fountain = softDrink.fountainDrinks;
        // fountain: val.fountainDrinks,
        // You can also set other properties like 'fountain' if needed.

        // Break the loop if a soft drink with null price is found
        break;
      }
    }

    // Now you can set the selected soft drink
    setSelectedSoftDrinks(defaultSoftDrink);
    setDrinksBrand(defaultSoftDrink.brands);
    // setDrinksBrand(defaultSoftDrink.brands);
    // setFountainDrinks(val?.soft_drinks?.fountainDrinks);
  };

  const Subproresult = selectedSubproduct.price;

  const [friesSizeArrayy, setFriesSizeArrayy] = useState([]);
  const [matchedItemss, setmatchedItemss] = useState([]);

  const [selectedFriesTypeId, setSelectedFriesTypeId] = useState(null);
  // ----Fries--Type--Radio-Start----
  const handleRadioFriesType = (val) => {
    if (selectedFriesTypeId === val.id) {
      setSelectedFriesTypeId(null);
      setSelectedFriesType("");
      setFriesSizeArrayy([]);
      setSelectedFriesSize("");
      setIsDisabled(false);
    } else {
      const subItems = {
        id: val.id,
        type: val.type,
        friesSize: [...friesSize],
        freeFries: [...freeFries],
        ComboFreeFries: matchedItemss,
      };
      const filteredFriesSize = subItems.friesSize.filter(
        (fries) => fries.type === subItems.type
      );
      subItems.friesSize = filteredFriesSize;
      setSelectedFriesType(subItems);
      setFriesSizeArrayy(subItems.friesSize);
      var maaz = subItems?.freeFries;
      const matchedItems = subItems.friesSize?.filter((itemFirst) =>
        maaz?.some((itemSecond) => itemFirst.id === itemSecond.id)
      );

      if (matchedItems?.length != 0) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
      // console.log(subItems?.freeFries);
      // console.log(matchedItems[0]);
      const defaultFriesData = {
        id: matchedItems[0]?.id,
        name: matchedItems[0]?.size,
        price: matchedItems[0]?.price,
      };
      setmatchedItemss(matchedItems);
      setSelectedFriesSize("");
      setSelectedFriesSize(defaultFriesData);

      setSelectedFriesTypeId(val.id); // Update the selected radio button ID
    }
  };

  // ----Fries--Type--Radio-End----
  // var ahmer = selectedFriesType.friesSize;
  // var maaz = selectedFriesType?.freeFries;
  // useEffect(() => {
  //   const matchedItems = friesSizeArrayy?.filter((itemFirst) =>
  //     maaz?.some((itemSecond) => itemFirst.id === itemSecond.id)
  //   );
  //   setmatchedItemss(matchedItems);
  // }, [friesSizeArrayy]);
  // setSelectedFriesSize(matchedItemss[0]);
  // ----Fries--Size--Radio-Start----
  const handleRadioFriesSize = (val) => {
    const subItems = {
      id: val.id,
      name: val.size,
      price: val.price,
    };
    setSelectedFriesSize(subItems);
    setIsDisabled(false);
  };
  // ----Fries--Size--Radio-End----
  let matchedItemPrice = null;
  matchedItemss?.forEach((item) => {
    if (item.id == selectedFriesSize.id) {
      matchedItemPrice = item.price;
    }
  });

  let highestPrice = 0.0;

  // Loop through the data to find the highest price
  matchedItemss?.forEach((item) => {
    const price = parseFloat(item.price);
    if (price > highestPrice) {
      highestPrice = price.toFixed(2);
    }
  });

  let FriesResult =
    matchedItemPrice !== null
      ? 0
      : parseFloat(selectedFriesSize.price) - highestPrice || 0;
  FriesResult.toFixed(2);

  const handleRadioFountainDrinks = (val) => {
    const subItems = {
      id: val.id,
      name: val.name,
      price: val.price,
    };

    setSelectedFountainDrinks(subItems);
  };

  const handleRadioSoftDrinks = (val) => {
    if (selectedSoftDrinks.id === val.id) {
      setSelectedFountainDrinks("");
      setFountainDrinks([]);
      setSelectedDrinksBrand("");
      setDrinksBrand([]);
      setSelectedSoftDrinks("");
    } else {
      const subItems = {
        id: val.id,
        name: val.name,
        price: val.price,
        fountain: val.fountainDrinks,
      };

      setSelectedSoftDrinks(subItems);
      // setFountainDrinks(val.fountainDrinks);
      setDrinksBrand(val.brands);
      setSelectedFountainDrinks("");
      setSelectedDrinksBrand("");
    }
  };
  const SoftDrinksresult =
    selectedSoftDrinks.price == null ? 0 : parseFloat(selectedSoftDrinks.price);

  // ----Drinks-Brand-----
  const handleRadioDrinksBrand = (val) => {
    if (selectedDrinksBrand.id === val.id) {
      setSelectedFountainDrinks("");
      setFountainDrinks([]);
      setSelectedDrinksBrand("");
    } else {
      const subItems = {
        id: val.id,
        name: val.name,
      };

      setSelectedDrinksBrand(subItems);
      setFountainDrinks(val.fountainDrinks);
      // setDrinksBrand(val.brands);
      setSelectedFountainDrinks("");
    }
  };

  function handleCheck(event, price) {
    const item = {
      id: event.target.id,
      type: event.target.name,
      price: event.target.value,
    };
    if (event.target.checked) {
      // setToppings(item);
      // eslint-disable-next-line
      if (toppingsArray.map((val) => val["type"]).indexOf(item["type"]) == -1)
        setToppingsArray([...toppingsArray, item]);
    } else {
      // eslint-disable-next-line
      setToppingsArray(
        // eslint-disable-next-line
        toppingsArray.filter((val) => val["type"] != item["type"])
      );
    }
  }

  const result = toppingsArray.reduce(
    (total, currentValue) => (total = total + parseFloat(currentValue.price)),
    0
  );

  // ---Sides---Checkk-Start----
  function handleCheckSides(event, price) {
    const item = {
      id: event.target.id,
      type: event.target.name,
      price: event.target.value,
    };
    if (event.target.checked) {
      // setToppings(item);
      // eslint-disable-next-line
      if (sidesArray.map((val) => val["type"]).indexOf(item["type"]) == -1)
        setSidesArray([...sidesArray, item]);
    } else {
      // eslint-disable-next-line
      setSidesArray(
        // eslint-disable-next-line
        sidesArray.filter((val) => val["type"] != item["type"])
      );
    }
  }

  // yaha hai

  let Sidesresult = sidesArray.reduce((total, currentValue, i) => {
    if (sidesQuantity > i) {
      return total;
    } else {
      return (total = total + parseFloat(currentValue.price));
    }
  }, 0);

  // ---Sides---Checkk-End----

  const handleClickPro = (val) => {
    setprodID(val.id);
    setProducts(val);
    setNum(1);
    setToppings();
    setToppingsArray([]);
    setSidesArray([]);
    setCheesesArray([]);
    setcookingSauceArray([]);
    settoppsecondArray([]);
  };

  const fetchSubProduct = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}/getSubProductByProductId/${
          prodID === undefined ? prod_id : prodID
        }`
      );
      setSubproduct(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // ----fetch--Sides-Start-----
  const fetchSides = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}/allSides/${prodID === undefined ? prod_id : prodID}`
      );
      setSidesu(response.data.data.sides);
      setSidesQuantity(response.data.data.quantity);
      // yaha hai
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  // ----fetch--Sides-End-----

  // ----fetch--Cheese-Start-----
  const fetchCheeses = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}/getCheeseByProductId/${
          prodID === undefined ? prod_id : prodID
        }`
      );
      setCheeses(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  // ----fetch--Cheese-End-----

  // ----fetch--Toppsecond-Start-----
  const fetchToppsecond = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}/getToppingsByProductId/${
          prodID === undefined ? prod_id : prodID
        }`
      );
      setToppsecond(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  // ----fetch--Toppsecond-End-----

  // ----fetch--cookingSauce-Start-----
  const fetchCookingSauce = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}/getCookingSaucesByProductId/${
          prodID === undefined ? prod_id : prodID
        }`
      );
      setcookingSauce(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  // ----fetch--cookingSauce-End-----

  const fetchSauces = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}/getSaucesForProduct/${
          prodID === undefined ? prod_id : prodID
        }`
      );
      setSauces(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAddons = async () => {
    var config = {
      method: "get",
      url: baseURL + `/getAddOns/${prodID === undefined ? prod_id : prodID}`,
      headers: {},
    };
    setLoading(true);
    await axios(config)
      .then(function (response) {
        setAddons(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };
  const fetchsRelatedProduct = async () => {
    var config = {
      method: "get",
      url:
        baseURL +
        `/getRelatedProducts/${prodID === undefined ? prod_id : prodID}`,
      headers: {},
    };
    setLoading(true);
    await axios(config)
      .then(function (response) {
        setRelatedProduct(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };
  useMemo(
    () => {
      if (prodID) {
        fetchSubProduct();
        fetchAddons();
        fetchSauces();
        fetchCheeses();
        fetchSides();
        fetchToppsecond();
        fetchCookingSauce();
        fetchsRelatedProduct();
      }
    },
    // eslint-disable-next-line
    [prodID]
  );

  useEffect(() => {
    if (prod_id) {
      fetchAddons();
      fetchSubProduct();
      fetchSauces();
      fetchCheeses();
      fetchSides();
      fetchToppsecond();
      fetchCookingSauce();
      fetchsRelatedProduct();
    }
    // eslint-disable-next-line
  }, [prod_id]);

  const handleCloseModal = (event) => {
    setNum(1);
    setToppings();
    setSoftDrinks([]);
    setFriesSizeArrayy([]);
    setSelectedFriesTypeId(null);
    setDrinksBrand([]);

    setFountainDrinks([]);
    setSelectedFountainDrinks({});
    setSelectedSoftDrinks({});
    setSelectedDrinksBrand("");
    setSelectedFriesType({});
    setSelectedFriesSize({});
    setFriesType([]);
    setFriesSizeArray([]);
    setToppingsArray([]);
    setSidesArray([]);
    setCheesesArray([]);
    setcookingSauceArray([]);
    settoppsecondArray([]);
    setExtrasaucesArray([]);
    setSelectedSubproduct("");
    setSelectedFountainDrinks("");
    setSides([]);
    setsaucesArray([]);
    handleClose();
    setprodID();
    setTotalQuantity();
    if (search === undefined) {
      navigate("/");
    }
  };
  const [showMore, setShowMore] = useState(false);

  const handleDecrementSauce = (val, sauceLoopingid) => {
    const updatedSaucesArray = saucesArray.map((sauce) =>
      sauce.id === val.id && sauce.identifier === sauceLoopingid
        ? { ...sauce, quantity: sauce.quantity - 1 }
        : sauce
    );

    // Filter out sauces with quantity greater than 0
    const filteredSaucesArray = updatedSaucesArray.filter(
      (sauce) => sauce.quantity > 0
    );

    setsaucesArray(filteredSaucesArray);
  };

  const handleIncrementSauce = (val, sauceLoopingid) => {
    const existingSauce = saucesArray.find(
      (sauce) => sauce.id === val.id && sauce.identifier === sauceLoopingid
    );

    if (existingSauce) {
      // If sauce with the same id and identifier already exists, increase the quantity by 1
      const updatedSaucesArray = saucesArray.map((sauce) =>
        sauce.id === val.id && sauce.identifier === sauceLoopingid
          ? { ...sauce, quantity: sauce.quantity + 1 }
          : sauce
      );
      setsaucesArray(updatedSaucesArray);
    } else {
      // If sauce with the same id and identifier does not exist, add a new entry to the saucesArray
      const sauce = {
        id: val.id,
        name: val.name,
        image: val.image,
        price: val.price,
        quantity: 1,
        identifier: sauceLoopingid,
      };
      const updatedSaucesArray = [...saucesArray, sauce];
      setsaucesArray(updatedSaucesArray);
    }
  };

  const totalSauceQuantity = saucesArray.reduce((accumulator, currentItem) => {
    return accumulator + currentItem.quantity;
  }, 0);


  const [totalQuantity, setTotalQuantity] = useState();

  const handleIncrementExtraSauce = (val) => {
    const updatedExtrasaucesArray = extrasaucesArray.map((sauce) => {
      if (sauce.id === val.id) {
        const updatedSauce = {
          ...sauce,
          quantity: sauce.quantity + 1,
          totalsauceprice: parseFloat(sauce.price) * (sauce.quantity + 1),
          // totalsauceprice: sauce.price,
        };

        return updatedSauce;
      }

      return sauce;
    });

    const existingExtraSauce = updatedExtrasaucesArray.find(
      (sauce) => sauce.id === val.id
    );

    if (!existingExtraSauce) {
      const extrasauce = {
        id: val.id,
        name: val.name,
        image: val.image,
        price: val.price,
        quantity: 1,
        totalsauceprice: parseFloat(val.price) * 1,
        // totalsaucepriceaa: val.price * 1,
        // totalsauceprice: val.price,
      };

      updatedExtrasaucesArray.push(extrasauce);
    }

    const totalQuantity = updatedExtrasaucesArray.reduce(
      (accumulator, currentItem) => accumulator + currentItem.quantity,
      0
    );

    setExtrasaucesArray(updatedExtrasaucesArray);
    setTotalQuantity(totalQuantity);
  };

  const handleDecrementExtraSauce = (val) => {
    const updatedExtrasaucesArray = extrasaucesArray.map((sauce) =>
      sauce.id === val.id
        ? {
            ...sauce,
            quantity: sauce.quantity - 1,
            totalsauceprice: parseFloat(sauce.price) * (sauce.quantity - 1),
          }
        : sauce
    );

    // Calculate total quantity
    const totalQuantity = updatedExtrasaucesArray.reduce(
      (total, sauce) => total + sauce.quantity,
      0
    );

    // Filter out sauces with quantity greater than 0
    const filteredExtrasaucesArray = updatedExtrasaucesArray.filter(
      (sauce) => sauce.quantity > 0
    );

    setExtrasaucesArray(filteredExtrasaucesArray);
    setTotalQuantity(totalQuantity); // Set the total quantity state here
  };

  const finalQuantity = Math.abs(totalQuantity - sauces.sauce_quantity);
  const totalSaucePrice =
    totalQuantity > sauces.sauce_quantity
      ? extrasaucesArray.reduce((accumulator, currentItem) => {
          return parseFloat(currentItem.price) * finalQuantity;
          // return accumulator + parseFloat(currentItem.price)  ;
        }, 0)
      : 0;

  // -----Cheese-Array---Start-----

  const [checkboxesDisabled, setCheckboxesDisabled] = useState(false);
  const maxAllowedItems = products.cheeses_rule;
  useEffect(() => {
    if (cheesesArray?.length >= maxAllowedItems) {
      setCheckboxesDisabled(true);
    } else {
      setCheckboxesDisabled(false);
    }
  }, [cheesesArray]);

  const handleCheckCheese = (event) => {
    const item = {
      id: event.target.id,
      type: event.target.name,
      price: event.target.value,
    };

    // Set your desired limit here

    if (event.target.checked) {
      if (
        cheesesArray.map((val) => val.type).indexOf(item.type) === -1 &&
        cheesesArray?.length < maxAllowedItems
      ) {
        setCheesesArray([...cheesesArray, item]);
      }
    } else {
      setCheesesArray(cheesesArray.filter((val) => val.type !== item.type));
    }
  };

  // -----Cheese-Array---end-----

  // -----Toppsecond-Array---Start-----
  const [checkboxesDisabledTop, setCheckboxesDisabledTop] = useState(false);
  const maxAllowedItemsTop = products.toppings_rule;
  useEffect(() => {
    if (toppsecondArray.length >= maxAllowedItemsTop) {
      setCheckboxesDisabledTop(true);
    } else {
      setCheckboxesDisabledTop(false);
    }
  }, [toppsecondArray]);

  const handleCheckToppsecond = (event) => {
    const item = {
      id: event.target.id,
      type: event.target.name,
      price: event.target.value,
    };

    // Set your desired limit here

    if (event.target.checked) {
      if (
        toppsecondArray.map((val) => val.type).indexOf(item.type) === -1 &&
        toppsecondArray.length < maxAllowedItemsTop
      ) {
        settoppsecondArray([...toppsecondArray, item]);
      }
    } else {
      settoppsecondArray(
        toppsecondArray.filter((val) => val.type !== item.type)
      );
    }
  };
  // -----Toppsecond-Array---end-----

  // -----CookingSauce-Array---Start-----
  const [checkboxesDisabledCs, setCheckboxesDisabledCs] = useState(false);
  const maxAllowedItemsCs = products.cooking_sauces_rule;
  useEffect(() => {
    if (cookingSauceArray.length >= maxAllowedItemsCs) {
      setCheckboxesDisabledCs(true);
    } else {
      setCheckboxesDisabledCs(false);
    }
  }, [cookingSauceArray]);

  const handleCheckCookingSauce = (event) => {
    const item = {
      id: event.target.id,
      type: event.target.name,
      price: event.target.value,
    };

    // Set your desired limit here

    if (event.target.checked) {
      if (
        cookingSauceArray.map((val) => val.type).indexOf(item.type) === -1 &&
        cookingSauceArray.length < maxAllowedItemsCs
      ) {
        setcookingSauceArray([...cookingSauceArray, item]);
      }
    } else {
      setcookingSauceArray(
        cookingSauceArray.filter((val) => val.type !== item.type)
      );
    }
  };
  // -----CookingSauce-Array---end-----


  useEffect(() => {
    // Check if both subproduct and sauces are selected
    if (selectedSubproduct && sauces.sauce_quantity <= totalQuantity) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
    if (selectedSubproduct && sauces.sauce_quantity == null) {
      setIsButtonDisabled(false);
    }
  }, [selectedSubproduct, totalSauceQuantity , totalQuantity]);

  return (
    <>
      {products && (
        <Modal isOpen={show} size="xl">
          <div className="modal-content product_detals_modal">
            <div className="modal-header">
              <h5 className="modal-title">
                <div className="back_btn">
                  <span onClick={handleCloseModal}>
                    <i className="fa-solid fa-chevron-left" />
                    Back
                  </span>
                </div>
              </h5>
              <div className="d-flex align-items-center">
                <p>Total</p>
                {productValue.is_sub_avaiable == 0 ? (
                  <p className="ms-5 fw-bold">
                    ${" "}
                    {(
                      (parseFloat(
                        products.discount_price === null
                          ? products.price
                          : products.discount_price
                      ) +
                        result) *
                      num
                    ).toFixed(2)}
                  </p>
                ) : Subproresult == null ? (
                  <p className="ms-5 fw-bold">$ 0.00</p>
                ) : (
                  <p className="ms-5 fw-bold">
                    ${" "}
                    {(
                      (parseFloat(Subproresult) +
                        Sidesresult +
                        SoftDrinksresult +
                        result +
                        FriesResult +
                        totalSaucePrice) *
                      num
                    ).toFixed(2)}
                  </p>
                )}
              </div>
            </div>

            <Breadcrumb
              className="pt-3 px-3"
              style={{ fontSize: "small" }}
              onClick={handleCloseModal}
            >
              <BreadcrumbItem style={{ color: "blue" }}>
                <Link to="/">Home</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>{seoTags}</BreadcrumbItem>
            </Breadcrumb>

            <div className="modal-body pt-0 pb-0">
              <div className="d-flex align-items-center justifyChange justify-content-between mb-2 ">
                <h1 className="hide_detalis product_name">{products.name}</h1>
                <span className="d-flex blockNone">
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center me-3">
                      <p className="me-2">Quantity &nbsp;</p>
                      <div className="cart__box">
                        <div
                          className="qty qty-minus"
                          onClick={handleDecrement}
                        >
                          -
                        </div>
                        <input
                          className="qty"
                          type="number"
                          value={num}
                          onChange={handleChange}
                          disabled
                        />
                        <div className="qty qty-plus" onClick={handleIncrement}>
                          +
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    className="btn check_out_btn"
                    onClick={() =>
                      handleAddToCart(products, num, toppingsArray)
                    }
                    // disabled={productValue.is_sub_avaiable==1 &&  isButtonDisabled}
                    // disabled={
                    //   productValue.is_sub_avaiable != 1 ||
                    //   (selectedSubproduct != "" &&
                    //     totalQuantity >= sauces.sauce_quantity) ||
                    //   //  ( productValue.is_sub_avaiable == 1 && sauces.sauce_quantity === null &&selectedSubproduct != "" )
                    //   (sauces.sauce_quantity === null && selectedSubproduct != "")
                    //     ? false
                    //     : true
                    // }

                    // // // // //disabled error here // // // // //

                  disabled={
                      isDisabled == false && isButtonDisabled == false &&
                      ((selectedSubproduct.length !== 0 &&
                        totalQuantity >= sauces.sauce_quantity) ||
                        (totalQuantity !== undefined &&
                          selectedSubproduct.length !== 0) ||
                        (sauces.sauce_quantity === null &&
                          selectedSubproduct.length !== 0))
                        ? false
                        : true
                    }
                  >
                    Add to Cart
                  </button>
                </span>
              </div>
              <div className="row">
                {/* {products?.description !== null ? (
                  <p className="hide_detalis hide_details2">
                    <hr />

                    <div
                      className="pt-1 pb-2 mb-3 description_para hide_details_headings"
                      dangerouslySetInnerHTML={{
                        __html: products?.description,
                      }}
                    />
                    <hr />
                  </p>
                ) : (
                  ""
                )} */}

                <div className="col-md-3">
                  <div className="product__information">
                    <LazyLoadImage
                      className="pro__imG__"
                      loading="lazy"
                      src={products.image}
                      alt={products.name}
                      title={products.name}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = productImg;
                      }}
                    />

                    <div className="d-flex justify-content-between align-items-baseline my-2">
                      <h3 className="heading_h3">{products.name}</h3>

                      <p>
                        {products.discount_price == null ? (
                          products.price
                        ) : (
                          <span>
                            <del>${products.price}</del>
                            <span>${products.discount_price}</span>
                          </span>
                        )}
                      </p>
                    </div>
                    {/* //////////////////////////////
              //////////////////////////////
              //////////////////////////////
              ////////////////////////////// */}

                    {/* to show description uncomment blow code */}

                    {/* {products?.description !== null ? (
                      <p className="show_details">
                        <hr />

                        <div
                          className="description_para"
                          dangerouslySetInnerHTML={{
                            __html: products?.description,
                          }}
                        />
                        <hr />
                      </p>
                    ) : (
                      ""
                    )} */}

                    {/* //////////////////////////////
              //////////////////////////////
              //////////////////////////////
              ////////////////////////////// */}
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="add__onns_main">
                    <p className="selectdrinkError">{incompleteSelections}</p>

                    {/* <div className="sub_main_grid">
                      <div className="add__onns py-1 px-2 ">
                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            <div className="d-flex align-items-center">
                              <img
                                className="addons_img me-2"
                                src={bbq_ranch}
                                alt="product"
                              />
                              <p>
                                Combo(12pc Mixed, 6 Biscuits, 6 Tenders, Family
                                Fries)
                              </p>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="me-2 text-nowrap">$ 32.00</div>
                            <div>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {productValue.is_sub_avaiable == 1 && (
                      <div>
                        <div className="row">
                          <div className="sub_main_grid">
                            {loading && (
                              <Spinner color="warning" type="grow"></Spinner>
                            )}

                            {!loading &&
                              Subproduct &&
                              Subproduct.map((val, index) => (
                                <label
                                  className=""
                                  key={index}
                                  name="flexRadioDefault"
                                  id={`subPro${index}`}
                                >
                                  <div className="add__onns py-1 px-2 ">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div>
                                        <div className="d-flex align-items-center">
                                          <LazyLoadImage
                                            className="addons_img me-2"
                                            src={val.image}
                                            loading="lazy"
                                            alt={val.name}
                                            title={val.name}
                                            onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src = products.image;
                                            }}
                                          />
                                          <p>{val.name}</p>
                                        </div>
                                      </div>
                                      <div className="d-flex">


                                        {(val.out_of_stock == 0) && (  
                                        <div className="me-2 text-nowrap">
                                          {val.discount_price === null &&
                                            `$ ${val.price}`}
                                            
                                            {" "}&nbsp; &nbsp;

                                          {val.discount_price !== null && (
                                            <span>
                                              <span>
                                                ${val.discount_price}&nbsp;
                                                &nbsp;
                                              </span>
                                            </span>)}
                                        </div>
                                          )}


                                          {(val.out_of_stock == 1) && (  
                                        <div className="me-2 text-nowrap">
                                         
                                            <span>
                                              <span className="text-danger">
                                                Out Of Stock
                                                &nbsp;
                                              </span>
                                            </span>
                                        </div>
                                          )}


                                        <div>
                                          {(val.out_of_stock == 0) && (
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="flexRadioDefault"
                                              checked={
                                                selectedSubproduct.id == val.id
                                              }
                                              onChange={() => handleRadio(val)}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </label>
                              ))}
                          </div>
                        </div>
                      </div>
                    )}

                    {/* {productValue.is_sub_avaiable == 1 && (
                      <div>
                        <div className="row">
                          {loading && (
                            <Spinner color="warning" type="grow"></Spinner>
                          )}

                          {!loading &&
                            Subproduct &&
                            Subproduct.map((val, index) => (
                              <div className="col-md-4">
                                <label
                                  className="modal_label_"
                                  key={index}
                                  name="flexRadioDefault"
                                  id={`subPro${index}`}
                                >
                                  <div className="d-flex justify-content-between align-items-center add__onns py-1 px-2 mb-2">
                                    <div className="d-flex align-items-center">
                                      <p>
                                        <img
                                          className="addons_img me-2"
                                          src={val.image}
                                          alt="product"
                                          onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = products.image;
                                          }}
                                        />
                                      </p>
                                      <p style={{ maxWidth: "100px" }}>
                                        {val.name}
                                      </p>
                                    </div>

                                    <p>
                                      {val.discount_price === null &&
                                        `$ ${val.price}`}{" "}
                                      &nbsp; &nbsp;
                                      {val.discount_price !== null && (
                                        <span>
                                          <span>
                                            ${val.discount_price}&nbsp; &nbsp;
                                          </span>
                                        </span>
                                      )}
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        checked={
                                          selectedSubproduct.id == val.id
                                        }
                                        onChange={() => handleRadio(val)}
                                      />
                                    </p>
                                  </div>
                                </label>
                              </div>
                            ))}
                        </div>
                      </div>
                    )} */}

                    {/* ---Fries-Type-Start-- */}
                    {productValue.is_sub_avaiable == 1 &&
                      productValue.is_fries_available == 1 &&
                      friesType?.length > 0 && (
                        <div>
                          <h3 className="sauces__h4_head mt-2 mb-2">Fries</h3>

                          <div className="row">
                            {loading && (
                              <Spinner color="warning" type="grow"></Spinner>
                            )}

                            {!loading &&
                              friesType &&
                              friesType.map((val, index) => (
                                <div className="col-md-6">
                                  <label
                                    className="modal_label_"
                                    key={index}
                                    name={`friesType`}
                                    id={`sidess${index}`}
                                  >
                                    <div className="d-flex justify-content-between align-items-center add__onns py-1 px-2 mb-2">
                                      <div className="d-flex align-items-center">
                                        <p>
                                          <LazyLoadImage
                                            className="addons_img me-2"
                                            src={val.image}
                                            loading="lazy"
                                            alt={val.type}
                                            title={val.type}
                                            onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src = products.image;
                                            }}
                                          />
                                        </p>
                                        <p style={{ maxWidth: "100px" }}>
                                          {val.type}
                                        </p>
                                      </div>

                                      <p>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name={`friesType`}
                                          checked={
                                            selectedFriesTypeId === val.id
                                          }
                                          // value={val.id} // Set the value to the ID or any other unique identifier
                                          onChange={() =>
                                            handleRadioFriesType(val)
                                          } // Call the handleRadio function when the radio button is changed
                                        />
                                      </p>
                                    </div>
                                  </label>
                                </div>
                              ))}
                          </div>
                          {selectedFriesType.id !== undefined &&
                          selectedFriesSize.id === undefined ? (
                            <p className="p-2" style={{ color: "red" }}>
                              Please select fries size also!{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    {/* --Fries-Type_End---- */}

                    {/* ---Fries-Size-Start-- */}

                    {productValue.is_sub_avaiable == 1 &&
                      productValue.is_fries_available == 1 &&
                      friesSizeArrayy?.length > 0 && (
                        <div>
                          {/* <h4 className="sauces__h4_head">Fries Type</h4> */}
                          <div className="row">
                            {loading && (
                              <Spinner color="warning" type="grow"></Spinner>
                            )}

                            {!loading &&
                              friesSizeArrayy &&
                              friesSizeArrayy?.map((val, index) => (
                                <div className="col-md-6">
                                  <label
                                    className="modal_label_"
                                    key={index}
                                    name={`friesSize`}
                                    id={`sidess${index}`}
                                  >
                                    <div className="d-flex justify-content-between align-items-center add__onns py-2 px-2 mb-2">
                                      <div className="d-flex align-items-center">
                                        <p style={{ maxWidth: "100px" }}>
                                          {val.size}
                                        </p>
                                      </div>

                                      <p>
                                        <span>
                                          <span>
                                            {val.price == null
                                              ? ""
                                              : // : `$ ${matchedItemss?.some(matchedItem => matchedItem.id === val.id) ? "INCLUDED" : (val.price-highestPrice).toFixed(2)}`}
                                              matchedItemss?.some(
                                                  (matchedItem) =>
                                                    matchedItem.id === val.id
                                                )
                                              ? "INCLUDED"
                                              : `$ ${(
                                                  val.price - highestPrice
                                                ).toFixed(2)}`}
                                            &nbsp; &nbsp;
                                          </span>
                                        </span>

                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={`friesSize`}
                                          checked={
                                            selectedFriesSize.id === val.id
                                          }
                                          // value={val.id} // Set the value to the ID or any other unique identifier
                                          onChange={() =>
                                            handleRadioFriesSize(val)
                                          } // Call the handleRadio function when the radio button is changed
                                        />
                                      </p>
                                    </div>
                                  </label>
                                </div>
                              ))}
                          </div>
                        </div>
                      )}

                    {/* --Fries-Size_End---- */}

                    {productValue.is_sub_avaiable == 1 &&
                      cheeses?.length > 0 && (
                        <div className="mb-2">
                          <h3 className="sauces__h4_head mt-2 mb-2">Cheeses</h3>
                          <div>
                            <div>
                              <div className="row">
                                {loading && (
                                  <Spinner
                                    color="warning"
                                    type="grow"
                                  ></Spinner>
                                )}
                                {!loading &&
                                  cheeses &&
                                  cheeses.map((val, index) => (
                                    <div className="col-md-6">
                                      <label
                                        className="modal_label_"
                                        key={index}
                                      >
                                        <div className="d-flex justify-content-between align-items-baseline add__onns py-1 px-2 mb-2">
                                          <span>
                                            <LazyLoadImage
                                              className="addons_img me-3"
                                              src={val.image}
                                              loading="lazy"
                                              alt={val.name}
                                              title={val.name}
                                              onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = products.image;
                                              }}
                                            />{" "}
                                            {val.name}
                                          </span>
                                          <p>
                                            <input
                                              className="form-check-input"
                                              onChange={handleCheckCheese}
                                              type="checkbox"
                                              name={val.name}
                                              id={val.id}
                                              value={
                                                val.discount_price === null
                                                  ? val.price
                                                  : val.discount_price
                                              }
                                              disabled={
                                                checkboxesDisabled &&
                                                cheesesArray.length >=
                                                  maxAllowedItems &&
                                                cheesesArray
                                                  .map((item) => item.type)
                                                  .indexOf(val.name) === -1
                                              }
                                            />
                                          </p>
                                        </div>
                                      </label>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                    {productValue.is_sub_avaiable == 1 &&
                      toppsecond?.length > 0 && (
                        <div className="mb-2">
                          <h3 className="sauces__h4_head mt-2 mb-2">
                            Toppings
                          </h3>
                          <div>
                            <div>
                              <div className="row">
                                {loading && (
                                  <Spinner
                                    color="warning"
                                    type="grow"
                                  ></Spinner>
                                )}
                                {!loading &&
                                  toppsecond &&
                                  toppsecond.map((val, index) => (
                                    <div className="col-md-6">
                                      <label
                                        className="modal_label_"
                                        key={index}
                                      >
                                        <div className="d-flex justify-content-between align-items-baseline add__onns py-1 px-2 mb-2">
                                          <span>
                                            <LazyLoadImage
                                              className="addons_img me-3"
                                              src={val.image}
                                              loading="lazy"
                                              alt={val.name}
                                              title={val.name}
                                              onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = products.image;
                                              }}
                                            />{" "}
                                            {val.name}
                                          </span>
                                          <p>
                                            <input
                                              className="form-check-input"
                                              onChange={handleCheckToppsecond}
                                              type="checkbox"
                                              name={val.name}
                                              id={val.id}
                                              value={
                                                val.discount_price === null
                                                  ? val.price
                                                  : val.discount_price
                                              }
                                              disabled={
                                                checkboxesDisabledTop &&
                                                toppsecondArray.length >=
                                                  maxAllowedItemsTop &&
                                                toppsecondArray
                                                  .map((item) => item.type)
                                                  .indexOf(val.name) === -1
                                              }
                                            />
                                          </p>
                                        </div>
                                      </label>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                    {productValue.is_sub_avaiable == 1 &&
                      cookingSauce?.length > 0 && (
                        <div className="mb-2">
                          <h3 className="sauces__h4_head mt-2 mb-2">Sauces</h3>
                          <div>
                            <div>
                              <div className="row">
                                {loading && (
                                  <Spinner
                                    color="warning"
                                    type="grow"
                                  ></Spinner>
                                )}
                                {!loading &&
                                  cookingSauce &&
                                  cookingSauce.map((val, index) => (
                                    <div className="col-md-6">
                                      <label
                                        className="modal_label_"
                                        key={index}
                                      >
                                        <div className="d-flex justify-content-between align-items-baseline add__onns py-1 px-2 mb-2">
                                          <span>
                                            <LazyLoadImage
                                              className="addons_img me-3"
                                              src={val.image}
                                              loading="lazy"
                                              alt={val.name}
                                              title={val.name}
                                              onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = products.image;
                                              }}
                                            />{" "}
                                            {val.name}
                                          </span>
                                          <p>
                                            <input
                                              className="form-check-input"
                                              onChange={handleCheckCookingSauce}
                                              type="checkbox"
                                              name={val.name}
                                              id={val.id}
                                              value={
                                                val.discount_price === null
                                                  ? val.price
                                                  : val.discount_price
                                              }
                                              disabled={
                                                checkboxesDisabledCs &&
                                                cookingSauceArray.length >=
                                                  maxAllowedItemsCs &&
                                                cookingSauceArray
                                                  .map((item) => item.type)
                                                  .indexOf(val.name) === -1
                                              }
                                            />
                                          </p>
                                        </div>
                                      </label>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                    {productValue.is_sub_avaiable == 1 &&
                      sauces?.data?.length > 0 && (
                        <div className="mb-2">
                          <h3 className="sauces__h4_head mt-2 mb-2">
                            Dip Sauces{" "}
                            <span className="sauces__select">
                              (
                              {`Please Select ${sauces.sauce_quantity} Free ${
                                sauces.sauce_quantity <= 1
                                  ? "Dip Sauce"
                                  : "Dip Sauces"
                              }`}
                              )
                            </span>
                          </h3>

                          <div className="row">
                            {loading && (
                              <Spinner color="warning" type="grow"></Spinner>
                            )}
                            {sauces.data &&
                              sauces.data.map((val, index) => {
                                let sauceLoopingid = index;
                                const quantitySauce = extrasaucesArray.find(
                                  (sauce) => sauce.id === val.id
                                );
                                return (
                                  <div className="col-md-6">
                                    <label
                                      className="modal_label_"
                                      key={sauceLoopingid}
                                    >
                                      <div className="d-flex flex-wrap justify-content-between align-items-center add__onns py-1 px-2 mb-2 position-relative">
                                        <span
                                          style={{ width: "150px" }}
                                          className="d-flex align-items-center"
                                        >
                                          <LazyLoadImage
                                            className="addons_img me-3"
                                            src={val.image}
                                            loading="lazy"
                                            alt={val.name}
                                            title={val.name}
                                            onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src = products.image;
                                            }}
                                          />{" "}
                                          <span>{val.name}</span>
                                        </span>
                                        <div>$ {val.price}</div>

                                        <div className="d-flex align-items-center me-3">
                                          <div className="cart__box">
                                            <button
                                              className="qty qty-minus"
                                              onClick={() =>
                                                handleDecrementExtraSauce(
                                                  val,
                                                  sauceLoopingid
                                                )
                                              }
                                              // disabled={sauces.sauce_quantity>= totalSauceQuantity?true:false}
                                            >
                                              -
                                            </button>
                                            <input
                                              className="qty"
                                              type="number"
                                              value={
                                                quantitySauce?.quantity || 0
                                              }
                                              disabled
                                            />
                                            <button
                                              className="qty qty-plus"
                                              onClick={() =>
                                                handleIncrementExtraSauce(
                                                  val,
                                                  sauceLoopingid
                                                )
                                              }
                                              // disabled={sauces.sauce_quantity == totalSauceQuantity}
                                              // disabled={console.log(totalSauceQuantity)}
                                            >
                                              +
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </label>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      )}

                    {addons?.length > 0 && (
                      <div className="mb-2">
                        <h3 className="sauces__h4_head mt-2 mb-2">Add ons</h3>
                        <div>
                          <div>
                            <div className="row">
                              {loading && (
                                <Spinner color="warning" type="grow"></Spinner>
                              )}
                              {!loading &&
                                addons &&
                                addons.map((val, index) => (
                                  <div className="col-md-6">
                                    <label className="modal_label_" key={index}>
                                      <div className="d-flex justify-content-between align-items-baseline add__onns py-1 px-2 mb-2">
                                        <span>
                                          <LazyLoadImage
                                            className="addons_img me-3"
                                            src={val.image}
                                            loading="lazy"
                                            alt={val.name}
                                            title={val.name}
                                            onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src = products.image;
                                            }}
                                          />{" "}
                                          {val.name}
                                        </span>
                                        <p>
                                          {val.discount_price === null &&
                                            `$ ${val.price}`}{" "}
                                          &nbsp; &nbsp;
                                          {val.discount_price !== null && (
                                            <span>
                                              <del className="me-4 text-danger">
                                                $ {val.price}
                                              </del>
                                              <span>
                                                ${val.discount_price}&nbsp;
                                                &nbsp;
                                              </span>
                                            </span>
                                          )}
                                          {/* $ {val.price} &nbsp; &nbsp;{" "} */}
                                          <input
                                            className="form-check-input"
                                            onChange={handleCheck}
                                            type="checkbox"
                                            name={val.name}
                                            id={val.id}
                                            value={
                                              val.discount_price === null
                                                ? val.price
                                                : val.discount_price
                                            }
                                          />
                                        </p>
                                      </div>
                                    </label>
                                  </div>
                                ))}
                              {/* {!loading && addons.length === 0
                                                ? "No addons Added"
                                                : ""} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* /////////////////////////////// */}
                    {/* /////////////////////////////// */}
                    {/* /////////////////////////////// */}
                    {/* /////////////////////////////// */}

                    {products.is_sides_available == 1 && sidesu?.length > 0 && (
                      <div className="mb-2">
                        <h3 className="sauces__h4_head mt-2 mb-2">
                          Sides{" "}
                          {sidesQuantity ? (
                            <span className="sauces__select">
                              (
                              {`Please Select ${sidesQuantity} Free ${
                                sidesQuantity <= 1 ? "Side" : "Sides"
                              }`}
                              )
                            </span>
                          ) : (
                            ""
                          )}
                        </h3>
                        <div>
                          <div>
                            <div className="row">
                              {loading && (
                                <Spinner color="warning" type="grow"></Spinner>
                              )}
                              {!loading &&
                                sidesu &&
                                sidesu.map((val, index) => (
                                  <div className="col-md-6">
                                    <label className="modal_label_" key={index}>
                                      <div className="d-flex justify-content-between align-items-center add__onns py-1 px-2 mb-2">
                                        <div className="d-flex align-items-center">
                                          <LazyLoadImage
                                            className="addons_img me-3"
                                            src={val.image}
                                            loading="lazy"
                                            alt={val.name}
                                            title={val.name}
                                            onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src = products.image;
                                            }}
                                          />{" "}
                                          <p>{val.name}</p>
                                        </div>
                                        <div className="text-nowrap ms-2">
                                          <span>
                                            <span>
                                              $ {val.price}&nbsp; &nbsp;
                                            </span>
                                            <input
                                              className="form-check-input"
                                              onChange={handleCheckSides}
                                              type="checkbox"
                                              name={val.name}
                                              id={val.id}
                                              value={val.price}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </label>
                                  </div>
                                ))}
                              {/* {!loading && addons.length === 0
                                                ? "No addons Added"
                                                : ""} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* ---Sides--With--Fries-Start--- */}

                    {productValue.sides_column_to_products == 1 &&
                      friesType?.length > 0 && (
                        <div>
                          <h3 className="sauces__h4_head">Fries</h3>
                          <div className="row">
                            {loading && (
                              <Spinner color="warning" type="grow"></Spinner>
                            )}

                            {!loading &&
                              friesType &&
                              friesType.map((val, index) => (
                                <div className="col-md-3">
                                  <label
                                    className="modal_label_"
                                    key={index}
                                    name={`friesType`}
                                    id={`sidess${index}`}
                                  >
                                    <div className="d-flex justify-content-between align-items-center add__onns py-1 px-2 mb-2">
                                      <div className="d-flex align-items-center">
                                        <p>
                                          <LazyLoadImage
                                            className="addons_img me-2"
                                            src={val.image}
                                            loading="lazy"
                                            alt={val.type}
                                            title={val.type}
                                            onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src = products.image;
                                            }}
                                          />
                                        </p>
                                        <p style={{ maxWidth: "100px" }}>
                                          {val.type}
                                        </p>
                                      </div>

                                      <p>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name={`friesType`}
                                          checked={
                                            selectedFriesTypeId === val.id
                                          }
                                          // value={val.id} // Set the value to the ID or any other unique identifier
                                          onChange={() =>
                                            handleRadioFriesType(val)
                                          } // Call the handleRadio function when the radio button is changed
                                        />
                                      </p>
                                    </div>
                                  </label>
                                </div>
                              ))}
                          </div>
                          {selectedFriesType.id !== undefined &&
                          selectedFriesSize.id === undefined ? (
                            <p className="p-2" style={{ color: "red" }}>
                              Please select fries size{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    {/* --Fries-Type_End---- */}

                    {/* ---Fries-Size-Start-- */}

                    {productValue.sides_column_to_products == 1 &&
                      friesSizeArrayy?.length > 0 && (
                        <div>
                          {/* <h4 className="sauces__h4_head">Fries Type</h4> */}
                          <div className="row">
                            {loading && (
                              <Spinner color="warning" type="grow"></Spinner>
                            )}

                            {!loading &&
                              friesSizeArrayy &&
                              friesSizeArrayy?.map((val, index) => (
                                <div className="col-md-6">
                                  <label
                                    className="modal_label_"
                                    key={index}
                                    name={`friesSize`}
                                    id={`sidess${index}`}
                                  >
                                    <div className="d-flex justify-content-between align-items-center add__onns py-2 px-2 mb-2">
                                      <div className="d-flex align-items-center">
                                        <p style={{ maxWidth: "100px" }}>
                                          {val.size}
                                        </p>
                                      </div>

                                      <p>
                                        <span>
                                          <span>
                                            {val.price == null
                                              ? ""
                                              : // : `$ ${matchedItemss?.some(matchedItem => matchedItem.id === val.id) ? "INCLUDED" : (val.price-highestPrice).toFixed(2)}`}
                                              matchedItemss?.some(
                                                  (matchedItem) =>
                                                    matchedItem.id === val.id
                                                )
                                              ? "INCLUDED"
                                              : `$ ${(
                                                  val.price - highestPrice
                                                ).toFixed(2)}`}
                                            &nbsp; &nbsp;
                                          </span>
                                        </span>

                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={`friesSize`}
                                          checked={
                                            selectedFriesSize.id === val.id
                                          }
                                          // value={val.id} // Set the value to the ID or any other unique identifier
                                          onChange={() =>
                                            handleRadioFriesSize(val)
                                          } // Call the handleRadio function when the radio button is changed
                                        />
                                      </p>
                                    </div>
                                  </label>
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    {/* ---Sides--With--Fries-End--- */}
                    {productValue.is_sub_avaiable == 1 &&
                      softDrinks?.length > 0 && (
                        <div>
                          <h3 className="sauces__h4_head mt-2 mb-2">Soda</h3>
                          <div className="row">
                            {loading && (
                              <Spinner color="warning" type="grow"></Spinner>
                            )}

                            {!loading &&
                              softDrinks &&
                              softDrinks.map((val, index) => (
                                <div className="col-md-6">
                                  <label
                                    className="modal_label_"
                                    key={index}
                                    name={`sides_radio4`}
                                    id={`sidess${index}`}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                      }}
                                      className="d-flex justify-content-between align-items-center add__onns py-1 px-2 mb-2 py-2"
                                    >
                                      <div className="d-flex align-items-center">
                                        <p>
                                          {/* <img
                                            className="addons_img me-2"
                                            src={val.image}
                                            alt="product"
                                            onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src = products.image;
                                            }}
                                          /> */}
                                        </p>
                                        <p style={{ maxWidth: "200px" }}>
                                          {val.name}
                                        </p>
                                      </div>

                                      <p>
                                        <span>
                                          <span>
                                            {val.price == null
                                              ? "INCLUDED"
                                              : `$ ${val.price}`}
                                            &nbsp; &nbsp;
                                          </span>
                                        </span>

                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name={`sides_radio4`}
                                          checked={
                                            selectedSoftDrinks.id === val.id
                                          }
                                          // value={val.id} // Set the value to the ID or any other unique identifier
                                          onChange={() =>
                                            handleRadioSoftDrinks(val)
                                          } // Call the handleRadio function when the radio button is changed
                                        />
                                      </p>
                                    </div>
                                  </label>
                                </div>
                              ))}
                            {selectedSoftDrinks.length !== 0 &&
                            selectedSoftDrinks.id === "" ? (
                              <p className="mb-2" style={{ color: "red" }}>
                                select drink brand !
                              </p>
                            ) : (
                              ""
                            )}
                          </div>

                          {/* yaha add krna hai */}
                        </div>
                      )}

                    {/* ---Brand-Start--- */}
                    {productValue.is_sub_avaiable == 1 &&
                      drinksBrand?.length > 0 && (
                        <div>
                          <h3 className="sauces__h4_head mt-2 mb-2">Brand</h3>
                          <div className="row">
                            {loading && (
                              <Spinner color="warning" type="grow"></Spinner>
                            )}

                            {!loading &&
                              drinksBrand &&
                              drinksBrand.map((val, index) => (
                                <div className="col-md-6">
                                  <label
                                    className="modal_label_"
                                    key={index}
                                    name={`sides_radio5`}
                                    id={`sidesss${index}`}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                      }}
                                      className="d-flex justify-content-between align-items-center add__onns py-1 px-2 mb-2 py-2"
                                    >
                                      <div className="d-flex align-items-center">
                                        <p>
                                          {/* <img
                                            className="addons_img me-2"
                                            src={val.image}
                                            alt="product"
                                            onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src = products.image;
                                            }}
                                          /> */}
                                        </p>
                                        <p style={{ maxWidth: "200px" }}>
                                          {val.name}
                                        </p>
                                      </div>

                                      <p>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name={`sides_radio5`}
                                          checked={
                                            selectedDrinksBrand.id === val.id
                                          }
                                          // value={val.id} // Set the value to the ID or any other unique identifier
                                          onChange={() =>
                                            handleRadioDrinksBrand(val)
                                          } // Call the handleRadio function when the radio button is changed
                                        />
                                      </p>
                                    </div>
                                  </label>
                                </div>
                              ))}
                          </div>
                        </div>
                      )}

                    {/* --New--Fountain--Start- */}
                    {productValue.is_sub_avaiable == 1 &&
                      softDrinks?.length > 0 &&
                      fountainDrinks?.length > 0 && (
                        <div>
                          <p className="selectdrinkError">
                            {sodaSelectionError}
                          </p>
                          {/* <h4 className="sauces__h4_head">Fountain Drinks</h4> */}
                          <div className="d-flex gap-2 flex-wrap">
                            {loading && (
                              <Spinner color="warning" type="grow"></Spinner>
                            )}

                            {!loading &&
                              fountainDrinks &&
                              fountainDrinks.map((val, index) => (
                                <label
                                  className="modal_label_"
                                  key={index}
                                  name={`sides_radio${selectedSubproduct.id}`}
                                  id={`sidess${index}`}
                                >
                                  <input
                                    className="form-check-input fountain_custom_input"
                                    type="radio"
                                    name={`sides_radio${selectedSubproduct.id}`}
                                    checked={
                                      selectedFountainDrinks.id === val.id
                                    }
                                    // value={val.id} // Set the value to the ID or any other unique identifier
                                    onChange={() =>
                                      handleRadioFountainDrinks(val)
                                    } // Call the handleRadio function when the radio button is changed
                                  />
                                  <div className="fountaindrink_div text-center">
                                    <LazyLoadImage
                                      className="fountain__image"
                                      src={val.image}
                                      alt={val.name}
                                      title={val.name}
                                      loading="lazy"
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = products.image;
                                      }}
                                    />
                                    <p style={{ fontSize: "10px" }}>
                                      {val.name}
                                    </p>
                                  </div>
                                </label>
                              ))}
                          </div>
                        </div>
                      )}
                    {/* --New--Fountain--end- */}
                  </div>
                </div>
              </div>
              <RelatedProductSlider
                loading={loading}
                relatedProduct={relatedProduct}
                handleClickPro={handleClickPro}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ProductModal;
