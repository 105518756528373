import React, { useEffect, useState } from "react";
import { Link ,useParams } from "react-router-dom";
import { baseURL } from "../config/apiUrl";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment/moment";

const CouponSend = () => {
    
  const params = useParams();
  const [email, setEmail] = useState()
  const [phoneNumber, setphoneNumber] = useState("");
    const [loading, setLoading] = useState(false)
  const [isShow , setIsShow] = useState(false)
  const [message , setMessage] = useState("")
  const [isError , setIsError] = useState(false);

  const [errorMssg , setErrorMsg] = useState(false);
  const [id , setId] = useState("")

  const PHONE_REGEX =  new RegExp(/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/);


  useEffect(() => {

    setId(params.coupon_id)
    console.log(params.coupon_id);
  }, [params.coupon_id])
  
  useEffect(()=>{

    let config = {
      method: "get",
      url: baseURL + `/coupon/${params.coupon_id}`
    };
    // setLoading(true)
    axios(config)
      .then(function (response) {

      if (new Date().getTime() > new Date(response.data.data.expiry_date).getTime()) {
        console.log("expire");
        setIsShow(true)
        setMessage("Offer Expired...")
      }
      else{
        console.log("not expire");
      }
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });

  },[])


  function formatPhoneNumber(input) {
    //  if the input is null, return a null value
    if (!input) return input;
    // remove all characters from the input except number input.
    const numberInput = input.replace(/[^\d]/g, "");
    //  take the length of the value of the input
    const numberInputLength = numberInput.length;
    // if the number length is 1, 2, or 3, then return it as it is.
    if (numberInputLength < 4) {
      return numberInput;
    } else if (numberInputLength < 7) {
      // if the number input length is 4, 5, or 6, format it accordingly.
      return `(${numberInput.slice(0, 3)}) ${numberInput.slice(3)}`;
    } else {
      //  if the number input length is 7, 8, 9, 10, or more, format it like the below.
      return `(${numberInput.slice(0, 3)}) ${numberInput.slice(
        3,
        6
      )}-${numberInput.slice(6, 10)}`;
    }
    // return empty string in case any condition doesn't satisfy.
    return "";
  }



  const handlephoneNumber = (e) => {
    // format phone number
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    //  set the formatted phone number to the input value
    setphoneNumber(formattedPhoneNumber);
     
  };


  const handleSubmit = (e) =>{

    e.preventDefault();

    if(email === undefined || phoneNumber === undefined){
      toast.error("Please filled all fields properly")
    }
    else{
      
      if (PHONE_REGEX.test(phoneNumber) === false) {
        setIsError(true)
        setErrorMsg("Please enter a valid mobile number !")
        setTimeout(() => {
          setIsError(false)
        }, 2000);
        return
       }

    setLoading(true)
    let data = new FormData();
    data.append("email", email);
    data.append("phone_number", phoneNumber);
    data.append("coupon_id", id);

    let config = {
      method: "post",
      url: baseURL + `/scan_to_avail`,
      data: data,
      header:{}
    };
    setLoading(true)
    axios(config)
      .then(function (response) {
       setLoading(false);
       setIsShow(true)
       setMessage(response.data.message)
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
      
    }

  
    
  }

  if (loading) {
    return ( 
    <div className="main_spinner_divv">
      
      <Spinner color="warning" type="grow"/>
      </div>
    )}

  return (
    <div className="container mt-3">

      <Link to="/">
        <button className="back_btn_q">
          <i className="fa-solid fa-left-long me-1"></i>Home
        </button>
      </Link>

{
  (isShow)? <div className="qr_thank_you m-4 text-center"><h2 >{message}...</h2></div>:

<form onSubmit={handleSubmit} className="enter__amount__qr qr__main_s p-3">

        <p className="entergasAmount mb-2">Enter Your Email : </p>
        <input
          type="email"
          className="form-control mb-3"
          placeholder="Email Address"
          value={email}
          onChange={(e)=>setEmail(e.target.value)}
        /> 

        <p className="entergasAmount mb-2">Enter Your Number : <span style={{fontSize:"9px"}}>(We will send you coupons and special marketing offers)</span> </p>
        <input
          className="form-control mb-2"
          placeholder="(555) xxx-xxxx"
          onChange={(e) => handlephoneNumber(e)} value={phoneNumber}
        />
      {(isError)? <p className="mb-1" style={{color:"red"}}>{errorMssg}</p>:""}

        {/* <Link to="/qr-payment"> */}
          <button className="btn check_out_btn w-100" type="submit">Send Coupon</button>
        {/* </Link> */}

      </form>

}

    </div>
  );
};

export default CouponSend;