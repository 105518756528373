import React from 'react'
import logo from "../assets/images/logo.jpg";
import bell from "../assets/images/bell.png";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const MemberWaiting = () => {
  return (
    <section>
                    <Helmet>
        <link rel="canonical" href="https://makcom.cf/member-waiting" />
      </Helmet>
    <div className="col-md-7 m-auto">
      <div className="Waiting_main py-4 mt-4">
        <div className="text-center">
          <img src={logo} alt="" style={{width: '10%', borderRadius: '15px'}} />
          <h4 className="my-2">Your request for membership is under review please wait or <br /> you can contact our support team,</h4>
          <h3 className="text-success">Thank You</h3>
          <img className="noti__fy" src={bell} alt="notify" />
          <div>
            <Link to="/"><button className="btn check_out_btn mt-3">Go to Home</button></Link>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default MemberWaiting