import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector ,useDispatch } from "react-redux";
import { baseURL } from "../config/apiUrl";
import UserSidbar from "../components/UserSidbar";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import { Spinner } from "reactstrap";
import { Helmet } from "react-helmet-async";
import { getAllOrders ,getSelectedOrderId } from  "../redux/features/slices/orderSlice"


const MyOrders = () => {
  
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [myUser, setmyUser] = useState([]);
  const [orders, setOrders] = useState([]);
  const [count, setcount] = useState(1);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  let [searchParams, setSearchParams] = useSearchParams();
  const pages = searchParams.get("page");
  const [page, setPage] = useState(parseInt(pages));
  const navigate = useNavigate();
  // const [page, setPage] = useState(1);

  const handleChange = (e, p) => {
    setPage(p);
  };
  // -----------User Get API-Start-----------
  const UserApi = async() => {
    var config = {
      method: "get",
      url: baseURL + "/user",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    await axios(config)
      .then(function (response) {
        setmyUser(response.data.data);
        setLoading(false);
        console.log("user" , response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    UserApi();
  // eslint-disable-next-line
  }, []);

  const getorder = (val)=>{
    dispatch(getSelectedOrderId(val.id))
  }


  const fetchOrderDetails = async() => {
    var config = {
      method: "get",
      url: baseURL + `/get_orders_by_user_id/${myUser.id}?page=${page}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    // /////////////////////////////////
    // /////////////////////////////////
    // /////////////////////////////////
    setLoading(true);
    await axios(config)
      .then(function (response) {
        const { data } = response.data;
        setOrders(data.data);
        setcount(data.last_page);
        setLoading(false);
        dispatch(getAllOrders(data))
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  /////////////////////////////////
  /////////////////////////////////
  /////////////////////////////////


  useEffect(() => {
    if (myUser.id) {
      fetchOrderDetails();
      navigate(`/myorders?page=${page}`);
    }
  // eslint-disable-next-line
  }, [myUser.id, page]);

  return (
    <div>
                    <Helmet>
                    <title>Makfuels | My-Orders</title>
        <link rel="canonical" href="https://makcom.cf/myorders" />
      </Helmet>
      <section className="container-fluid mt-3">
        <div className="row">
          <UserSidbar />
          <div className="col-md-12 col-lg-9 mb-2">
            <div
              className="p-3 mb-4"
              style={{
                boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
                borderRadius: "8px",
              }}
            >
              <h4 className="mb-3">My Orders</h4>
              <div className="col-md-11">
                <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Order ID</th>
                      <th scope="col">Date</th>
                      <th scope="col">View Order</th>
                      <th scope="col">Total Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading && (
                      <tr>
                        <td colSpan={4} className="text-center">
                          <Spinner />
                        </td>
                      </tr>
                    )}
                    {!loading && orders.length === 0 && (
                      <tr>
                        <td colSpan={4} className="text-center">
                          Empty Orders
                        </td>
                      </tr>
                    )}

                    {!loading &&
                      orders?.map((val, index) => {
                        let TotalAmount =
                        val?.shipping_details === ""
                          ? val?.total_price
                          : (
                              parseFloat(val?.total_price) + parseFloat("2.00")
                            ).toFixed(2);
                        return (
                          <tr key={index}>
                            <td>#{val?.id}</td>
                            <td >
                              {moment(val?.created_at).format("MMM DD yyyy")}
                              
                            </td>
                            <td>
                              <Link
                                 to="/order-view"
                                 >
                                <button className="btn check_out_btn" onClick={()=>{getorder(val)}}>
                                  View
                                </button>
                                
                              </Link>
                            </td>
                            <td>$ {TotalAmount}</td>
                          </tr>
                        );
                      })}

                    {/* <tr>
                  <td>#123234223</td>
                  <td>12-08-2022</td>
                  <td><button className='btn check_out_btn' data-bs-toggle="modal" data-bs-target="#view_order">View</button></td>
                  <td>$ 99.00</td>
                </tr> */}
                  </tbody>
                </table>
                </div>
              </div>
            </div>

            <Pagination
              count={count}
              page={page}
              onChange={handleChange}
              color="primary"
            />
          </div>
        </div>
      </section>
      {/* ----Profile--modal-start--- */}
      {/* Button trigger modal */}
      {/* Modal */}
      <div
        className="modal fade"
        id="view_order"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundImage: "linear-gradient(#f8c301, #dcae00)" }}
            >
              <h5 className="modal-title">View Order</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="px-2 py-">
                <div className="row mb-2">
                  <div className="col-md-6">
                    <span>OrderID: </span>
                    <span>456123</span>
                  </div>
                  <div className="col-md-6 text-end">
                    <span>Order Date: </span>
                    <span>12-02-2022</span>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6">
                    <span>Phone: </span>
                    <span>3351248941</span>
                  </div>
                  <div className="col-md-6 text-end">
                    <span>City: </span>
                    <span>Saint-Luis</span>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-12">
                    <span>Items: </span>
                    <span>
                      <ul>
                        <li>
                          <div className="d-flex justify-content-between">
                            <p>burger</p>
                            <p>$20.00</p>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex justify-content-between">
                            <p>burger</p>
                            <p>$20.00</p>
                          </div>
                        </li>
                      </ul>
                    </span>
                  </div>

                  <div className="col-md-12 mb-2">
                    <div className="d-flex justify-content-between">
                      <p>Total Amount</p>
                      <p>$40.00</p>
                    </div>
                  </div>
                  <div className="col-md-12 ">
                    <span>Payment Method: </span>
                    <span>Credit Card</span>
                  </div>
                </div>
                {/* <div className="form-floating mb-3">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                  />
                  <label htmlFor="floatingInput">Name</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                  />
                  <label htmlFor="floatingInput">Email address</label>
                </div> */}
              </div>
            </div>
            {/* <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn check_out_btn">
                Save changes
              </button>
            </div> */}
          </div>
        </div>
      </div>
      {/* ----Profile--modal-end--- */}
    </div>
  );
};

export default MyOrders;
