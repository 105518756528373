import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { baseURL } from "../config/apiUrl";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import { Spinner } from "reactstrap";
import { Helmet } from "react-helmet-async";

const ScanQrUserDetails = () => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const { token } = useSelector((state) => state.auth);
  const { state } = useLocation();

  const fetchUserData = async () => {
    var config = {
      method: "get",
      url: baseURL + `/gas_payment_details/${state.orderID}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    await axios(config)
      .then(function (response) {
        const { data } = response.data;
        setUserData(data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchUserData();
  }, []);

  if (loading) {
    return (
      <div className="main_spinner_divv">
        <Spinner color="warning" type="grow" />
      </div>
    );
  }

  return (
    <>
                  <Helmet>
        <link rel="canonical" href="https://makcom.cf/payment-successful" />
      </Helmet>
      <div className="container mt-3">
        <Link to="/">
          <button className="back_btn_q">Home</button>
        </Link>
        <div className="qr__main_s mt-2">
          <div className="user_details_card">
            <h3 className="text-center">E-Invoice</h3>
            <p>
              <span className="fw-bold">Date:</span>{" "}
              {moment(userData?.created_at).format("DD-MMM-YYYY")}
            </p>
            <p>
              <span className="fw-bold">Location:</span> {userData?.address}
            </p>
            <p>
              <span className="fw-bold">Pump No:</span> {userData?.pump}
            </p>
            <p>
              <span className="fw-bold">Name:</span> {userData?.name}
            </p>
            <p>
              <span className="fw-bold">Gas Price:</span> $ {userData?.amount}
            </p>
            <p>
              <span className="fw-bold">Email:</span> {userData?.email}
            </p>
            <p>
              <span className="fw-bold">Phone:</span> {userData?.phone_number}
            </p>
            <p>
              <span className="fw-bold">Card Deatails:</span> <br />
              XXXX-XXXX-XXXX-
              <span className="fw-bold card_last_four">
                {userData?.card_number}
              </span>
            </p>
          </div>
        </div>

        <div className="qr__main_s mt-3 pay_rece_div mb-3">
          <div className="text-center">
            <p>
              <i className="fa-regular fa-square-check fuel_successful_tick"></i>
            </p>
            <p className="pay_rec_succ">Payment received successfully</p>
            <p className="pay_rec_fuel">Please fill your fuel</p>
            <Link to="/">
              <button className="back_btn_q mt-2">Return to Home</button>
            </Link>
          </div>
        </div>
        {/* {data !== "" && <p>{data}</p>} */}
      </div>
    </>
  );
};

export default ScanQrUserDetails;
