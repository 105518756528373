import axios from "axios";
import { useFormik } from "formik";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../config/apiUrl";
import { login } from "../redux/features/auth/authSlice";
import { UserGasRequest } from "../schemas";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Helmet } from "react-helmet-async";

const MemberLogin = (args) => {
  document.title="Makfuels | Member - Login"

  const [modal, setModal] = useState(false);
  const [eye, seteye] = useState(true);
  const [showpassword, setshowpassword] = useState("password");

  const toggle = () => setModal(!modal);

  

  const Eye = () => {
    if (showpassword === "password") {
      setshowpassword("text");
      seteye(false);
    } else {
      setshowpassword("password");
      seteye(true);
    }
  };

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    address: "",
    password: "",
    c_password: "",
    zip_code: "",
    state: "",
    city: "",
    federal_tax_id_front: "",
    federal_tax_id_back: "",
    license_back: "",
    license_front: "",
    state_registeration_front: "",
    state_registeration_back: "",
    state_tax_id_front: "",
    state_tax_id_back: "",
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const submitForm = async (e) => {
    e.preventDefault();
    var data = new FormData();
    data.append("email", email);
    data.append("password", password);
    setLoading(true);
    var config = {
      method: "post",
      url: baseURL + "/partner_login",
      data: data,
    };

    await axios(config)
      .then(function (response) {
        const { message, data } = response.data;
        dispatch(login(data));
        toast.success(
          message === "Not Active" ? "Your Request is pending.." : message
        );

        setLoading(false);

        navigate("/member-invoice");
      })
      .catch(function (error) {
        toggle();
        setLoading(false);
      });
    // setLoading(false)
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: UserGasRequest,
    onSubmit: (values, action) => {
      var data = new FormData();
      data.append("name", values.name);
      data.append("email", values.email);
      data.append("phone", values.phone);
      data.append("federal_tax_id_front", values.federal_tax_id_front);
      data.append("federal_tax_id_back", values.federal_tax_id_back);

      data.append("state_registeration_front", values.state_registeration_front);
      data.append("state_registeration_back", values.state_registeration_back);

      data.append("state_tax_id_front", values.state_tax_id_front);
      data.append("state_tax_id_back", values.state_tax_id_back);

      data.append("license_front", values.license_front);
      data.append("license_back", values.license_back);
      data.append("address", values.address);
      data.append("zip_code", values.zip_code);
      data.append("state", selectState);
      data.append("city", selectCity);
      data.append("password", values.password);
      data.append("c_password", values.c_password);
      setLoading(true);

      var config = {
        method: "post",
        url: baseURL + "/partner_register",
        data: data,
      };

      axios(config)
        .then(function (response) {
          const { message, data } = response.data;
          dispatch(login(data));
          toast.success(
            message === "Not Active" ? "Your Request is pending.." : message
          );
          navigate("/member-invoice");
          setLoading(false);
          // toggleLogin()
        })
        .catch(function (error) {
          // toast.error("error.response.data.message");
          if (error.response.data.message === "Not Active.") {
            toggle();
            toggleLogin();
          } else {
            toast.error(error.response.data.message);
          }
          setLoading(false);
        });
    },
  });

  const [selectState, setSlectState] = useState();
  const [selectCity, setSelectCity] = useState();

  useMemo(() => {
    const fetchStateAndCities = async () => {
      try {
        const formData = new FormData();
        formData.append("zip_code", values.zip_code);
  
        const response = await axios.post(baseURL + "/getStateAndCity", formData);
        const { city, state } = response.data.data || {};

        setSelectCity(city);
        setSlectState(state);
      } catch (error) {
        console.log(error);
        setSelectCity();
        setSlectState();
      }
    };
  
    if (String(values.zip_code).length >= 4) {
      fetchStateAndCities();
    }
  }, [values.zip_code]);

  function toggleSignup() {
    document.getElementById("login-toggle").style.backgroundColor = "#ffcc07";
    document.getElementById("login-toggle").style.color = "#fff";
    document.getElementById("signup-toggle").style.backgroundColor = "#ffcc07";
    document.getElementById("signup-toggle").style.color = "#fff";
    document.getElementById("login-form").style.display = "none";
    document.getElementById("signup-form").style.display = "block";
    document.getElementById("login-toggle").innerHTML = "Back";
    document.getElementById("login-toggle").style.width = "50%";
    document.getElementById("login-toggle").style.padding = "8px";
  }

  function toggleLogin() {
    document.getElementById("login-toggle").style.backgroundColor = "#ffcc07";
    document.getElementById("login-toggle").style.color = "#fff";
    document.getElementById("signup-toggle").style.backgroundColor = "#fff";
    document.getElementById("signup-toggle").style.color = "#222";
    document.getElementById("signup-form").style.display = "none";
    document.getElementById("login-form").style.display = "block";
    document.getElementById("login-toggle").innerHTML = "Log in";
    document.getElementById("login-toggle").style.width = "100%";
    document.getElementById("login-toggle").style.padding = "1rem";
  }

  // if (token){
  //   return <LogoutModal/>
  // }

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://makcom.cf/member-login" />
      </Helmet>
        <div className="cons_conte">
      <div className="form-modal memberGasP">
        <div className="form-toggle">
          <button id="login-toggle" onClick={toggleLogin}>
            log in
          </button>
          {/* <button className='fs-6' id="signup-toggle" onClick={toggleSignup}>Request for Account</button> */}
        </div>
        <div id="login-form">
          <form onSubmit={submitForm}>
            <input
              type="text"
              placeholder="Enter email or username"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="position-relative pAss_Sty">
              <input
                type={showpassword}
                placeholder="Enter password"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <i
                onClick={Eye}
                className={`position-absolute fa ${
                  eye ? "fa-eye-slash" : "fa-eye"
                }`}
              ></i>
            </div>

            <div className="text-center py-2 request___Account">
              <span id="signup-toggle" onClick={toggleSignup} className="">
                Register Now &nbsp;<i className="fa-solid fa-right-long"></i>
              </span>
            </div>
            <div>
              
              </div>
            {/* <div className='text-center py-2 request___Account'><h6 id="signup-toggle" onClick={toggleSignup}>Register Now</h6></div> */}

            <button
              type="submit"
              className="btn login"
              disabled={loading ? true : false}
            >
              {loading ? (
                <div className="spinner-border text-warning" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "login"
              )}
            </button>

            {/* <p><a href="javascript:void(0)">Forgotten account</a></p> */}
            <hr />
            <Link to="/">
            <div className="member_BackToHome">Back To Home</div>
            </Link>

          </form>
          
        </div>

        <div id="signup-form" className="memberGas_sign__up">
          <form onSubmit={handleSubmit}>
              <h5 className="w-100 d-flex py-3">Register for membership</h5>
            <div className="row">

              <div className="position-relative col-md-6">
                <input
                  type="text"
                  placeholder="Enter your name"
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.name && touched.name ? (
                  <p className="form-error">{errors.name}</p>
                ) : null}
              </div>

              <div className="position-relative col-md-6">
                <input
                  type="email"
                  placeholder="Enter your email"
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.name && touched.email ? (
                  <p className="form-error">{errors.email}</p>
                ) : null}
              </div>


            <div className="position-relative col-md-6">
              <input
                type="text"
                placeholder="Enter your phone"
                id="phone"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.phone && touched.phone ? (
                <p className="form-error">{errors.phone}</p>
              ) : null}
            </div>

            <div className="position-relative col-md-6">
              {/* <label htmlFor="zip_code">zip_code</label> */}
              <input
                type="number"
                className="bg-light form-control"
                id="zip_code"
                name="zip_code"
                placeholder="zip_code"
                value={values.zip_code}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.zip_code && touched.zip_code ? (
                <p className="form-error">{errors.zip_code}</p>
              ) : null}
            </div>

            <div className="position-relative col-md-6 my-2">
              {/* <label>State</label> */}

              <select
                className="form-select"
                style={{ borderRadius: "11px" }}
                value={selectState}
                disabled
              >
                {selectState === undefined ? (
                  <option value={0}>Select State</option>
                ) : (
                  <option value={selectState}>{selectState}</option>
                )}
              </select>
              {errors.state && touched.state ? (
                <p className="form-error">{errors.state}</p>
              ) : null}
            </div>

            <div className="position-relative col-md-6 my-2">
              {/* <label>City</label> */}
              <select
                className="form-select"
                style={{ borderRadius: "11px" }}
                value={selectCity}
                disabled
              >
                {selectCity === undefined ? (
                  <option value={0}>select City</option>
                ) : (
                  <option value={selectCity}>{selectCity}</option>
                )}
              </select>
              {/* {errors.city && touched.city ? (
                          <p className="form-error">{errors.city}</p>
                        ) : null} */}
            </div>


            <div className="position-relative col-md-6 mt-2">
            <h6 className="license_h6"> Federal Tax ID (Front Side)</h6>

              <input
                className="m-0"
                type="file"
                id="federal_tax_id_front"
                name="federal_tax_id_front"
                accept="image/*"
                onBlur={handleBlur}
                onChange={(e) =>
                  setFieldValue("federal_tax_id_front", e.currentTarget.files[0])
                }
              />
              {errors.federal_tax_id_front && touched.federal_tax_id_front ? (
                <p className="form-error">{errors.federal_tax_id_front}</p>
              ) : null}
            </div>

            <div className="position-relative col-md-6 mt-2">
            <h6 className="license_h6"> Federal Tax ID (Back Side)</h6>

              <input
                className="m-0"
                type="file"
                id="federal_tax_id_back"
                name="federal_tax_id_back"
                accept="image/*"
                onBlur={handleBlur}
                onChange={(e) =>
                  setFieldValue("federal_tax_id_back", e.currentTarget.files[0])
                }
              />
              {errors.federal_tax_id_back && touched.federal_tax_id_back ? (
                <p className="form-error">{errors.federal_tax_id_back}</p>
              ) : null}
            </div>

            <div className="position-relative col-md-6 mt-2">
            <h6 className="license_h6"> Secretary of state registration (Front)</h6>

              <input
                className="m-0"
                type="file"
                id="state_registeration_front"
                name="state_registeration_front"
                accept="image/*"
                onBlur={handleBlur}
                onChange={(e) =>
                  setFieldValue("state_registeration_front", e.currentTarget.files[0])
                }
              />
              {errors.state_registeration_front && touched.state_registeration_front ? (
                <p className="form-error">{errors.state_registeration_front}</p>
              ) : null}
            </div>

            <div className="position-relative col-md-6 mt-2">
            <h6 className="license_h6"> Secretary of state registration (Back)</h6>

              <input
                className="m-0"
                type="file"
                id="state_registeration_back"
                name="state_registeration_back"
                accept="image/*"
                onBlur={handleBlur}
                onChange={(e) =>
                  setFieldValue("state_registeration_back", e.currentTarget.files[0])
                }
              />
              {errors.state_registeration_back && touched.state_registeration_back ? (
                <p className="form-error">{errors.state_registeration_back}</p>
              ) : null}
            </div>



            <div className="position-relative col-md-6 mt-2">
            <h6 className="license_h6"> State Tax ID (Front Side)</h6>
              <input
                className="m-0"
                type="file"
                id="state_tax_id_front"
                name="state_tax_id_front"
                accept="image/*"
                onBlur={handleBlur}
                onChange={(e) =>
                  setFieldValue("state_tax_id_front", e.currentTarget.files[0])
                }
              />
              {errors.state_tax_id_front && touched.state_tax_id_front ? (
                <p className="form-error">{errors.state_tax_id_front}</p>
              ) : null}
            </div>

            <div className="position-relative col-md-6 mt-2">
            <h6 className="license_h6"> State Tax ID (Back Side)</h6>
              <input
                className="m-0"
                type="file"
                id="state_tax_id_back"
                name="state_tax_id_back"
                accept="image/*"
                onBlur={handleBlur}
                onChange={(e) =>
                  setFieldValue("state_tax_id_back", e.currentTarget.files[0])
                }
              />
              {errors.state_tax_id_back && touched.state_tax_id_back ? (
                <p className="form-error">{errors.state_tax_id_back}</p>
              ) : null}
            </div>



            <div className="position-relative col-md-6 mt-2">
            <h6 className="license_h6"> Driving License (Front Side)</h6>
              <input
                className="m-0"
                type="file"
                id="license_front"
                name="license_front"
                accept="image/*"
                onBlur={handleBlur}
                onChange={(e) =>
                  setFieldValue("license_front", e.currentTarget.files[0])
                }
              />
              {errors.license_front && touched.license_front ? (
                <p className="form-error">{errors.license_front}</p>
              ) : null}
            </div>

            <div className="position-relative col-md-6 mt-2">
            <h6 className="license_h6"> Driving License (Back Side)</h6>

              <input
                className="m-0"
                type="file"
                id="license_back"
                name="license_back"
                accept="image/*"
                onBlur={handleBlur}
                onChange={(e) =>
                  setFieldValue("license_back", e.currentTarget.files[0])
                }
              />
              {errors.license_back && touched.license_back ? (
                <p className="form-error">{errors.license_back}</p>
              ) : null}
            </div>

          
            <div className="position-relative col-md-12">
              <input
                type="text"
                placeholder="Enter your address"
                id="address"
                name="address"
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.address && touched.address ? (
                <p className="form-error">{errors.address}</p>
              ) : null}
            </div>

            <div className="position-relative col-md-6">
              <input
                type="password"
                placeholder="Password"
                id="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.password && touched.password ? (
                <p className="form-error">{errors.password}</p>
              ) : null}
            </div>

            <div className="position-relative col-md-6">
              <input
                type="password"
                placeholder="Re-type password"
                id="c_password"
                name="c_password"
                value={values.c_password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.c_password && touched.c_password ? (
                <p className="form-error">{errors.c_password}</p>
              ) : null}
            </div>

            </div>

            <button
              type="submit"
              className="btn signup"
              disabled={loading ? true : false}
            >
              {loading ? (
                <div className="spinner-border text-warning" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Submit"
              )}
            </button>
            {/* <p>Clicking <strong>create account</strong> means that you are agree to our <a href="javascript:void(0)">terms of services</a>.</p> */}
            <hr />
          </form>
        </div>
      </div>

      <div>
        <Modal isOpen={modal} toggle={toggle} {...args}>
          <ModalHeader toggle={toggle}>Request Alert </ModalHeader>
          <ModalBody>
            <div className="text-center fs-5">
              <p>
                <i className="fa-solid fa-check fs-2 text-success"></i>
              </p>
              Your request has been sent to admin
              <br />
              Please wait for approval
              <p></p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button className="check_out_btn" onClick={toggle}>
              Done
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
    </>

  );
};

export default MemberLogin;
