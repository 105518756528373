import React from "react";
import { NavLink } from "react-router-dom";
import { logout } from "../redux/features/auth/authSlice";
import { useDispatch } from "react-redux";
import { clearCart } from "../redux/features/slices/cartSlice";

const UserSidbar = () => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearCart());
  };
  return (
    <div className="col-lg-3 col-md-12 mb-2">
      <div
        className="px-3"
        style={{
          boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
          borderRadius: "20px",
        }}
      >
        <div className="p-5">
          <NavLink className="user__SideBar" to="/myProfile">
            <div className="d-flex justify-content-between align-items-center mb-2 userSlider_">
              <p className="m-0">My Profile</p>
              <i className="fa-solid fa-chevron-right" />
            </div>
          </NavLink>
          {/* <NavLink className="user__SideBar" to="/address-book"><div className="d-flex justify-content-between align-items-center mb-2">
          <p className="m-0">Address Book</p>
          <i className="fa-solid fa-chevron-right" />
        </div></NavLink> */}
          <NavLink className="user__SideBar" to="/myorders?page=1">
            <div className="d-flex justify-content-between align-items-center mb-2 userSlider_">
              <p className="m-0">My Orders</p>
              <i className="fa-solid fa-chevron-right" />
            </div>
          </NavLink>
          <NavLink className="user__SideBar" to="/notifications">
            <div className="d-flex justify-content-between align-items-center mb-2 userSlider_">
              <p className="m-0">Notifications</p>
              <i className="fa-solid fa-chevron-right" />
            </div>
          </NavLink>
          {/* <NavLink className="user__SideBar" to="/payment-method">
            <div className="d-flex justify-content-between align-items-center mb-2 userSlider_">
              <p className="m-0">Card Management</p>
              <i className="fa-solid fa-chevron-right" />
            </div>
          </NavLink>
          <NavLink className="user__SideBar" to="/gas-payment-detail">
            <div className="d-flex justify-content-between align-items-center mb-2 userSlider_">
              <p className="m-0">Gas Payment Detail</p>
              <i className="fa-solid fa-chevron-right" />
            </div>
          </NavLink> */}
          {/* <div className="d-flex justify-content-between align-items-center mb-2">
          <p className="m-0">My wishlist</p>
          <i className="fa-solid fa-chevron-right" />
        </div> */}
          <NavLink className="user__SideBar" to="/">
            <div
              onClick={handleLogout}
              className="d-flex justify-content-between align-items-center mb-2 userSlider_"
            >
              <p className="m-0">Logout</p>
              <i className="fa-solid fa-chevron-right" />
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default UserSidbar;
