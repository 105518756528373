import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeFromCart,
  increaseCart,
  decreaseCart,
} from "../redux/features/slices/cartSlice";
import { Link } from "react-router-dom";
import empty_carts from "../assets/images/empty_cart.png";
import { useCallback } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const RightSideCart = () => {
  const { cartItems, cartTotalAmount, cartTotalTax, cartSubTotalAmount } =
    useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const handleRemoveFromCart = useCallback(
    (product) => {
      dispatch(removeFromCart(product));
    },
    [dispatch]
  );

  const handleIncreaseCart = useCallback(
    (product) => {
      dispatch(increaseCart(product));
    },
    [dispatch]
  );

  const handleDecreaseCart = useCallback(
    (product) => {
      dispatch(decreaseCart(product));
    },
    [dispatch]
  );

  return (
    <div style={{ position: "sticky", top: "40px", width: "100%" }}>
      <div className="cart__sec px-3 mt-3 pb-4" id="myDIV">
        <p style={{ fontSize: "1.3rem" }} className="mb-3 pt-3">
          My Cart
        </p>
        <div className="cart_flow">
          {cartItems?.length === 0 && (
            <div className="text-center">
              <LazyLoadImage
                height={40}
                width={40}
                className=" "
                style={{ width: "40px", marginBottom: "8px" }}
                src={empty_carts}
                alt="empty-cart"
              />

              <h1 style={{ fontSize: "1.5rem" }}>Your Cart is Empty Now</h1>
            </div>
          )}
          {/* {cartItems.length>0 && <h6>Foodsy</h6>} */}

          {cartItems.map((items, index) => {
            const totalSauceRedux = items.extrasaucesArray.reduce(
              (totalQuantity, val) =>
                totalQuantity + val.quantity * items.cartQuantity,
              0
            );
            // ---Calculate-Quantity-Start---
            const newQuantity = items.finalSauceQuantity;
            const drinkPrice =
              items && items?.selectedSoftDrinks?.price == null
                ? 0
                : parseFloat(items && items?.selectedSoftDrinks?.price);

            const totalSaucePrice = items.extrasaucesArray.reduce(
              (accumulator, currentItem) => {
                return parseFloat(currentItem.price) * newQuantity;
              },
              0
            );

            let totalSidePrice = items?.sidesArray?.reduce(
              (accumulator, currentItem, i) => {
                if (items.sidesQuantity > i) {
                  return accumulator;
                } else {
                  return parseFloat(currentItem.price) + accumulator;
                }
              },
              0
            );
            const totalSidePrices =
              totalSidePrice == undefined ? 0 : totalSidePrice;
            let FriesResultsUpd =
              items.FriesResult == undefined ? 0 : items.FriesResult;
            let pricing =
              items.discount_price === null
                ? items.price
                : items.discount_price;
            pricing =
              pricing === null ? items.selectedSubproduct?.price : pricing;
            let toppingPrice =
              items.toppingsArray.reduce(
                (total, currentValue) =>
                  (total =
                    total +
                    parseFloat(currentValue.price) * items.cartQuantity),
                0
              ) +
              totalSaucePrice * items.cartQuantity +
              totalSidePrices * items.cartQuantity +
              FriesResultsUpd +
              drinkPrice +
              pricing * items.cartQuantity;
            console.log(toppingPrice, "toppingPrice");
            // ---Calculate-Quantity-End---
            return (
              <div className="card__items main_right_items  mb-2"  key={index}>
                <div className="d-flex justify-content-between">
                  {/* {items.main_cat_id === "2"} */}
                  <p>
                    {items.cartQuantity} x {items.prod_name}{" "}
                    {items.main_cat_id == 1 ? items.name : null}
                    {items.main_cat_id === "1"
                      ? items.is_sub_avaiable == 0
                        ? null
                        : ` (${items.selectedSubproduct?.name})`
                      : items.selectedSubproduct?.name &&
                        `  (${items.name})`}
                  </p>

                  <p className="ms-2">
                    {/* {items.discount_price === null && (
                    <span>
                      {items.main_cat_id === "2" ? "" : `$${items.price}`}
                    </span>
                  )}
                  {items.discount_price !== null && (
                    <span>
                      {items.main_cat_id === "2"
                        ? ""
                        : `$${items.discount_price}`}
                    </span>
                  )} */}

                    {items.main_cat_id == 2 && (
                      <span className="text-nowrap">$ {items.price} </span>
                    )}
                    {items.main_cat_id == 1 && (
                      <span className="text-nowrap">
                        ${" "}
                        {items.is_sub_avaiable == 0
                          ? items.price
                          : items.selectedSubproduct?.price}
                      </span>
                    )}

                    <span>
                      <i
                        className="fa-solid fa-circle-xmark aa"
                        onClick={() => handleRemoveFromCart(items)}
                      />
                    </span>
                  </p>
                </div>
                <p>
                  {/* <b>
                    {items.toppingsArray.length > 0 && items.main_cat_id === "1"
                      ? "Add Onns"
                      : ""}
                  </b> */}
                  {/* <b>
                  {items.toppingsArray.length > 0 && items.main_cat_id === "2"
                    ? "Varients"
                    : ""}
                </b> */}
                </p>
                {/* <p>
                    {items.main_cat_id === "1"
                      ? items.is_sub_avaiable == 0
                        ? null
                        : ` (${items.selectedFriesType?.type})`
                      : items.selectedFriesType?.type &&
                        ` (${items.selectedFriesType.type})`}
                  </p> */}
                {items.main_cat_id == 1 &&
                  items.is_sub_avaiable == 1 &&
                  Object.keys(items.selectedFriesType)?.length > 0 && (
                    <div
                      style={{ fontSize: "12px" }}
                      className="d-flex justify-content-between"
                    >
                      <div>
                        {items.selectedFriesType?.type} (
                        {items.selectedFriesSize?.name})
                      </div>

                      <div>
                        {" "}
                        {items.FriesResult == 0
                          ? "INCLUDED"
                          : `$ ${items.FriesResult.toFixed(2)}`}
                      </div>
                    </div>
                  )}
                {items &&
                  items.main_cat_id == 1 &&
                  items.is_sub_avaiable == 1 &&
                  Object.keys(items?.selectedFountainDrinks)?.length > 0 && (
                    <div
                      style={{ fontSize: "12px" }}
                      className="d-flex justify-content-between"
                    >
                      <div>
                        {items?.selectedFountainDrinks?.name} (
                        {items?.selectedSoftDrinks?.name})
                      </div>
                      <div>
                        {items?.selectedSoftDrinks?.price == null
                          ? "INCLUDED"
                          : `$ ${items?.selectedSoftDrinks?.price}`}
                      </div>
                    </div>
                  )}

                {items.main_cat_id == 1 &&
                items?.cheesesArray?.length > 0 &&
                items.is_sub_avaiable == 1 ? (
                  <ul>
                    <h6>Cheeses</h6>
                    {items.cheesesArray.map((val, index) => {
                      return <li key={index}>{val.type} </li>;
                    })}
                  </ul>
                ) : null}

                {items.main_cat_id == 1 &&
                items?.toppsecondArray?.length > 0 &&
                items.is_sub_avaiable == 1 ? (
                  <ul className="mt-2">
                    <h6>Toppings</h6>
                    {items?.toppsecondArray?.map((val, index) => {
                      return <li key={index}>{val.type} </li>;
                    })}
                  </ul>
                ) : null}
                {items.main_cat_id == 1 &&
                items?.sidesArray?.length > 0 &&
                items.is_sub_avaiable == 1 ? (
                  <ul className="mt-2">
                    <h6>Sides</h6>
                    {items.sidesQuantity != 0 ? (
                      <p className="exclude_sauces my-1">{`Exclude ${
                        items.sidesQuantity
                      } ${
                        items.sidesQuantity > 1 ? "Sides" : "Side"
                      } Price`}</p>
                    ) : (
                      " "
                    )}

                    {items?.sidesArray.map((val, index) => {
                      return (
                        <li key={index}>
                          {val.type}{" "}
                         
                          <span className="float-end">
                           {( items.sidesQuantity >= index+1)?<span style={{color:"red"}}>(Free)</span>:` $ ${val.price} x ${items.cartQuantity}`}
                            
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                ) : null}

                {items.main_cat_id == 1 &&
                items?.cookingSauceArray?.length > 0 &&
                items.is_sub_avaiable == 1 ? (
                  <ul className="mt-2">
                    <h6>Sauces</h6>
                    {items.cookingSauceArray.map((val, index) => {
                      return <li key={index}>{val.type} </li>;
                    })}
                  </ul>
                ) : null}

                {items.main_cat_id == 1 &&
                items?.extrasaucesArray?.length > 0 &&
                items.is_sub_avaiable == 1 ? (
                  <ul className="mt-2">
                    {items.extrasaucesArray?.length > 0 && (
                      <div>
                        <h6>Dip Sauces</h6>
                        <p className="exclude_sauces my-1">{`Exclude ${
                          totalSauceRedux - items.finalSauceQuantity
                        } ${
                          totalSauceRedux - items.finalSauceQuantity > 1
                            ? "Sauces"
                            : "Sauce"
                        } Price`}</p>
                      </div>
                    )}
                    {items.extrasaucesArray.map((val, index) => {
                      return (
                        <li key={index}>
                          <div className="d-flex justify-content-between">
                            <p>
                              {val.quantity * items.cartQuantity} x {val.name}
                            </p>
                            <p>$ {val.price}</p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                ) : null}

                <ul className="mt-2">
                  <h6>{items.toppingsArray.length > 0 ? "Addons" : ""}</h6>
                  {items.toppingsArray.map((val, index) => {
                    return (
                      <li key={index}>
                        {items.cartQuantity} x {val.type}{" "}
                        <span className="float-end">${val.price}</span>
                      </li>
                    );
                  })}
                </ul>
                <div>
                  <div className="d-flex justify-content-between itemsTax">
                    <p>Tax:</p>
                    <p>
                      ${" "}
                      {(
                        (toppingPrice.toFixed(2) / 100) *
                        items.tax_percentage
                      ).toFixed(2)}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between itemsSubTotal">
                    <p>Total:</p>
                    <p>$ {toppingPrice.toFixed(2)}</p>
                  </div>

                  {/* <p>{items.tax_percentage}</p> */}
                </div>
                <div className="quAntity__s">
                  <div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => handleIncreaseCart(items)}
                    >
                      +
                    </div>
                    <div>{items.cartQuantity} </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDecreaseCart(items)}
                    >
                      -
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="amount__total">
          <div className="d-flex justify-content-between">
            <p>Sub total</p>
            <p>$ {cartSubTotalAmount}</p>
          </div>
          <div className="d-flex justify-content-between width-100">
            <p>Tax</p>
            <p className="discount__price">$ {cartTotalTax}</p>
          </div>
          <div className="d-flex justify-content-between mt-3 ">
            <p>Total</p>
            <p>$ {cartTotalAmount}</p>
          </div>
        </div>
        <div className="mt-3">
          <Link to={cartItems?.length === 0 ? "" : "/shopping-cart"}>
            <button
              className="btn check_out_btn w-100"
              disabled={cartItems?.length === 0 ? true : false}
            >
              PROCEED
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default memo(RightSideCart);
