import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeFromCart,
  increaseCart,
  decreaseCart,
} from "../redux/features/slices/cartSlice";
import { Link } from "react-router-dom";
import empty_carts from "../assets/images/empty_cart.png";
import { useCallback } from "react";
import ReceiptPDF from "../pages/ReceiptPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { baseURL } from "../config/apiUrl";
import axios from "axios";
import { toast } from "react-toastify";
import CollectPaymentModal from "./modal/CollectPaymentModal";

const PosRightSideCart = () => {
  const [loading, setLoading] = useState(false);
  const cart = useSelector((state) => state.cart);
  const { token } = useSelector((state) => state.auth);

  const { cartItems, cartTotalAmount, cartTotalTax, cartSubTotalAmount } =
    useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const handleRemoveFromCart = useCallback(
    (product) => {
      dispatch(removeFromCart(product));
    },
    [dispatch]
  );
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  // const handlePrintReceipt = () => {
  //   // Create a new PDF document
  //   const pdfBlob = new Blob([<ReceiptPDF
  //     cartItems={cartItems}
  //     cartSubTotalAmount={cartSubTotalAmount}
  //     cartTotalTax={cartTotalTax}
  //     cartTotalAmount={cartTotalAmount}
  //   />], { type: "application/pdf" });

  //   // Generate the PDF URL
  //   const pdfUrl = URL.createObjectURL(pdfBlob);

  //   // Open the PDF in a new tab
  //   window.open(pdfUrl);
  // };

  const handleIncreaseCart = useCallback(
    (product) => {
      dispatch(increaseCart(product));
    },
    [dispatch]
  );

  const handleDecreaseCart = useCallback(
    (product) => {
      dispatch(decreaseCart(product));
    },
    [dispatch]
  );
  // ---Proceed-and-dowmload---
  const handleSubmitCash = async (e) => {
    // e.preventDefault();
    var data = new FormData();
    data.append("order_items", JSON.stringify(cart.cartItems));
    data.append("total_price", cart.cartTotalAmount);

    var config = {
      method: "post",
      url: baseURL + "/pos_order",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    setLoading(true);
    await axios(config)
      .then(function (response) {
        toast.success(response.data.message);
        setLoading(false);

        if (response.data.success == true) {
          console.log("ok");
        }
        // setTimeout(() => {
        //   navigate("/order-confirmation", {
        //     state: { orderID: response.data.data },
        //   },{replace:true});
        //   setLoading(false);
        // }, 2000);
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <div style={{ position: "sticky", top: "10px", width: "100%" }}>
      <div
        className="cart__sec px-3 mt-3 pb-4"
        id="myDIV"
        style={{
          height: "85vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <p style={{ fontSize: "1.3rem" }} className="mb-2 pt-3">
          Order List
        </p>
        <div className="cart_flow">
          {cartItems.length === 0 && (
            <div className="text-center">
              <img
                height={40}
                width={40}
                className=""
                style={{ width: "40px", marginBottom: "8px" }}
                src={empty_carts}
                alt="empty-cart"
              ></img>

              <h1 style={{ fontSize: "1.5rem" }}>Your Cart is Empty Now</h1>
            </div>
          )}
          {/* {cartItems.length>0 && <h6>Foodsy</h6>} */}
          <div className="right__card__main_h p-2">
            {cartItems.map((items, index) => {
              const totalSauceRedux = items.extrasaucesArray.reduce(
                (totalQuantity, val) =>
                  totalQuantity + val.quantity * items.cartQuantity,
                0
              );
              return (
                <div className="card__items main_right_items  mb-2" key={index}>
                  <div className="d-flex justify-content-between">
                    {/* {items.main_cat_id === "2"} */}
                    <p>
                      {items.cartQuantity} x {items.prod_name} {items.name}
                      {items.main_cat_id === "1"
                        ? items.is_sub_avaiable == 0
                          ? null
                          : ` (${items.selectedSubproduct?.name})`
                        : items.selectedSubproduct?.name &&
                          ` (${items.selectedSubproduct.name})`}
                    </p>

                    <p className="ms-2">
                      {/* {items.discount_price === null && (
                    <span>
                      {items.main_cat_id === "2" ? "" : `$${items.price}`}
                    </span>
                  )}
                  {items.discount_price !== null && (
                    <span>
                      {items.main_cat_id === "2"
                        ? ""
                        : `$${items.discount_price}`}
                    </span>
                  )} */}

                      {items.main_cat_id == 2 && (
                        <span className="text-nowrap">$ {items.price} </span>
                      )}
                      {items.main_cat_id == 1 && (
                        <span className="text-nowrap">
                          ${" "}
                          {items.is_sub_avaiable == 0
                            ? items.price
                            : items.selectedSubproduct?.price}
                        </span>
                      )}

                      <span>
                        <i
                          className="fa-solid fa-circle-xmark aa"
                          onClick={() => handleRemoveFromCart(items)}
                        />
                      </span>
                    </p>
                  </div>

                  {/* <p>
                    {items.main_cat_id === "1"
                      ? items.is_sub_avaiable == 0
                        ? null
                        : ` (${items.selectedFriesType?.type})`
                      : items.selectedFriesType?.type &&
                        ` (${items.selectedFriesType.type})`}
                  </p> */}
                  {items.main_cat_id == 1 &&
                    items.is_sub_avaiable == 1 &&
                    Object.keys(items.selectedFriesType).length > 0 && (
                      <div
                        style={{ fontSize: "12px" }}
                        className="d-flex justify-content-between"
                      >
                        <div>
                          {items.selectedFriesType?.type} (
                          {items.selectedFriesSize?.name})
                        </div>
                        <div>
                          ${" "}
                          {items.FriesResult == 0 ? "Free" : items.FriesResult}
                        </div>
                      </div>
                    )}
                  {items &&
                    items.main_cat_id == 1 &&
                    items.is_sub_avaiable == 1 &&
                    Object.keys(items?.selectedFountainDrinks)?.length > 0 && (
                      <div
                        style={{ fontSize: "12px" }}
                        className="d-flex justify-content-between"
                      >
                        <div>
                          {items?.selectedFountainDrinks?.name} (
                          {items?.selectedSoftDrinks?.name})
                        </div>
                        <div>
                          ${" "}
                          {items?.selectedSoftDrinks?.price == null
                            ? "Free"
                            : items?.selectedSoftDrinks?.price}
                        </div>
                      </div>
                    )}

                  {items.main_cat_id == 1 &&
                  items?.cheesesArray.length > 0 &&
                  items.is_sub_avaiable == 1 ? (
                    <ul>
                      <h6>Cheeses</h6>
                      {items.cheesesArray.map((val, index) => {
                        return <li key={index}>{val.type} </li>;
                      })}
                    </ul>
                  ) : null}

                  {items.main_cat_id == 1 &&
                  items?.toppsecondArray?.length > 0 &&
                  items.is_sub_avaiable == 1 ? (
                    <ul className="mt-2">
                      <h6>Toppings</h6>
                      {items?.toppsecondArray?.map((val, index) => {
                        return <li key={index}>{val.type} </li>;
                      })}
                    </ul>
                  ) : null}

                  {items.main_cat_id == 1 &&
                  items?.sidesArray.length > 0 &&
                  items.is_sub_avaiable == 1 ? (
                    <ul className="mt-2">
                      <h6>Sides</h6>
                      {items.sidesQuantity != 0 ? (
                      <p className="exclude_sauces my-1">{`Exclude ${
                        items.sidesQuantity
                      } ${
                        items.sidesQuantity > 1 ? "Sides" : "Side"
                      } Price`}</p>
                    ) : (
                      " "
                    )}
                      {items?.sidesArray.map((val, index) => {
                        return (
                          <li key={index}>
                            {items.cartQuantity} x {val.type}{" "}
                            <span className="float-end">
                           {( items.sidesQuantity >= index+1)?<span style={{color:"red"}}>(Free)</span>:` $ ${val.price} x ${items.cartQuantity}`}
                            
                          </span>
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}

                  {items.main_cat_id == 1 &&
                  items?.cookingSauceArray.length > 0 &&
                  items.is_sub_avaiable == 1 ? (
                    <ul className="mt-2">exclude_sauces
                      <h6>Sauces</h6>
                      {items.cookingSauceArray.map((val, index) => {
                        return <li key={index}>{val.type} </li>;
                      })}
                    </ul>
                  ) : null}

                  {items.main_cat_id == 1 &&
                  items?.extrasaucesArray.length > 0 &&
                  items.is_sub_avaiable == 1 ? (
                    <ul className="mt-2">
                      {items.extrasaucesArray.length > 0 && (
                        <div>
                          <h6>Dip Sauces</h6>
                          <p className="exclude_sauces my-1">{`Exclude ${
                            totalSauceRedux - items.finalSauceQuantity
                          } ${
                            totalSauceRedux - items.finalSauceQuantity > 1
                              ? "Sauces"
                              : "Sauce"
                          } Price`}</p>
                        </div>
                      )}
                      {items.extrasaucesArray.map((val, index) => {
                        return (
                          <li key={index}>
                            <div className="d-flex justify-content-between">
                              <p>
                                {val.quantity * items.cartQuantity} x {val.name}
                              </p>
                              <p>$ {val.price}</p>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}

                  <ul className="mt-2">
                    <h6>{items.toppingsArray.length > 0 ? "AddOns" : ""}</h6>
                    {/* <b>
                  {items.toppingsArray.length > 0 && items.main_cat_id === "2"
                    ? "Varients"
                    : ""}
                </b> */}

                    {items.toppingsArray.map((val, index) => {
                      return (
                        <li key={index}>
                          {val.type}{" "}
                          <span className="float-end">
                            ${val.price} x {items.cartQuantity}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="quAntity__s">
                    <div>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => handleIncreaseCart(items)}
                      >
                        +
                      </div>
                      <div>{items.cartQuantity} </div>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDecreaseCart(items)}
                      >
                        -
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* ///////////////////////////////// */}

        <div className="amount__total" style={{ marginTop: "15px" }}>
          <div className="d-flex justify-content-between">
            <p>Sub total</p>
            <p>$ {cartSubTotalAmount}</p>
          </div>
          <div className="d-flex justify-content-between width-100">
            <p>Tax</p>
            <p className="discount__price">$ {cartTotalTax}</p>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <p>Total</p>
            <p>$ {cartTotalAmount}</p>
          </div>
        </div>
        <div className="mt-3">
          <button
            className="btn check_out_btn w-100"
            disabled={cartItems.length === 0 ? true : false}
            // onClick={handleSubmitCash}
            onClick={toggle}
          >
            COLLECT PAYMENT
          </button>
          {/* <PDFDownloadLink
            onClick={handleSubmitCash}
            document={ReceiptPDF()}
            fileName="generated_pdf.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                "Loading document..."
              ) : (
                <button 
                  className="btn check_out_btn w-100"
                  disabled={cartItems.length === 0 ? true : false}
                  // onClick={handleSubmitCash}
                >
                  COLLECT PAYMENT
                </button>
              )
            }
          </PDFDownloadLink> */}
        </div>
      </div>
      <CollectPaymentModal toggle={toggle} modal={modal} />
    </div>
  );
};

export default memo(PosRightSideCart);
