import React from "react";
import MainHeader from "../components/MainHeader";
import { Helmet } from "react-helmet-async";

const PrivacyPolicy = () => {
  return (
    <>
              <Helmet>
              <meta name="robots" content="index, follow"/>
<link rel="canonical" href="https://makfuels.com/about-us" />
<title> Privacy Policy | Makfuels</title>
<meta name="description" content=" Makfuels is not responsible for the privacy statements or other content on websites and mobile applications outside of the Properties."/>


{/* <!-- Facebook Meta Tags --> */}
<meta property="og:url" content="makfuels.com/privacy-policy"/>
<meta property="og:type" content="website"/>
<meta property="og:title" content="Privacy And Policy | Makfuels"/>
<meta property="og:description" content="Makfuels is not responsible for the privacy statements or other content on websites and mobile applications outside of the Properties."/>
<meta property="og:image" content="https://makfuels.com/static/media/logo.4ed5bcbedcce5108870c.jpg "/>

{/* <!-- Twitter Meta Tags --> */}
<meta name="twitter:card" content="summary_large_image"/>
<meta property="twitter:url" content="makfuels.com/privacy-policy"/>
<meta name="twitter:title" content="Privacy And Policy | Makfuels"/>
<meta name="twitter:description" content="Makfuels is not responsible for the privacy statements or other content on websites and mobile applications outside of the Properties."/>
<meta name="twitter:image" content="https://makfuels.com/static/media/logo.4ed5bcbedcce5108870c.jpg "/>

<script type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: `{
            "@context": "http://www.schema.org",
            "@type": "Corporation",
            "name": "Makfuels",
            "url": "https://makfuels.com/privacy-policy ",
            "sameAs": [
            "https://www.facebook.com/makfuelsofficial/",
            "https://twitter.com/",
            "https://www.instagram.com/",           
          "https://www.linkedin.com/company/",
            "https://www.pinterest.com/"
            ],
            "logo": "https://makfuels.com/static/media/logo.4ed5bcbedcce5108870c.jpg ",
            "image": "https://makfuels.com/static/media/logo.4ed5bcbedcce5108870c.jpg",
            "description": " Makfuels is not responsible for the privacy statements or other content on websites and mobile applications outside of the Properties.",
            "address": {
            "@type": "PostalAddress",
            "streetAddress": "Howdershell Rd",
            "addressLocality": "Hazelwood",
            "addressRegion": "Missouri",
            "postalCode": "6901",	
            "addressCountry": "USA"
            },
            "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+1-314-724-2828",
            "contactType": "Telephone" ,
            "email":"contact@makfuels.com"
          }`}}
        
          />

      </Helmet>
      {/* <MainHeader /> */}

      <section>
        <div className="privacy_background  mt-3">
          <div>
            <h1 className="bann_heading">PRIVACY</h1>
          </div>
        </div>
      </section>

      <section className="container">
        <div className="privacy_content my-5">
          <h1 className="back__privacy_head">MAKFUELS PRIVACY POLICY</h1>
          <p>
            THIS PRIVACY POLICY IS A PART OF AND INCORPORATED INTO THE TERMS OF
            USE AGREEMENT, WHICH GOVERNS YOUR USE OF THE PROPERTIES.
            <br />
            In consideration of Makfuels Corporation (together with its
            affiliates and subsidiaries, “Makfuels”) allowing you to use its
            various properties, including without limitation its website
            (Makfuels.com) and mobile application (collectively, the “
            Properties”), you agree to be bound by the terms of this Privacy
            Policy. Your use of the Properties constitutes and evidences your
            acceptance of the Terms of Use Agreement, which incorporates this
            Privacy Policy. If you do not agree to the terms of this Privacy
            Policy, you should immediately discontinue using any and all of the
            Properties and obtain services through other resources.
          </p>
          <h2 className="back__privacy_head_h2">OUR COMMITMENT TO PRIVACY</h2>
          <p>
            Makfuels is committed to providing high quality service along with
            the highest level of integrity in dealing with our customers. As an
            extension of this commitment, this Privacy Policy is designed to
            assist you in understanding how we collect, use and safeguard
            information and to assist you in making informed decisions when
            visiting or utilizing services provided by Makfuels in or on the
            Properties. This Privacy Policy will be periodically reviewed and
            assessed against new technologies, business practices, our
            customers’ needs, and/or legal developments and may be revised
            accordingly.
          </p>
          <h2 className="back__privacy_head_h2">
            TYPES OF INFORMATION WE COLLECT & WAYS WE USE INFORMATION
          </h2>
          <p>
            Generally speaking, when you visit and navigate our Properties and
            when you communicate with us via the Properties, we will not collect
            personal information about you unless you provide us that
            information voluntarily. You should be aware that when you visit our
            Properties, we collect certain information that does not identify
            you personally, but provides us with “usage data,” such as the
            number of visitors we receive, what pages are visited by a visitor
            or the ISPs or types of browsers most frequently used. This data
            helps us to analyze and improve the usefulness of the information we
            provide on the Properties, as well as our products and service. More
            specifically, the types of information we collect from the
            Properties is as follows:
            <p className="mt-2">
              <ol>
                <li>
                  <p>
                    Aggregate Information. Makfuels may collect IP address (but
                    not the email address) of any visitor to our Properties. The
                    Properties also track the pages within our Properties that
                    you view, the date and time you view those pages and various
                    other statistics. This information does not identify you
                    personally. We use this information to help us evaluate
                    visitor traffic patterns and improve our Properties by
                    making our systems more responsive to the needs of visitors.
                  </p>
                </li>
                <li>
                  <p>
                    Personal Information. When you choose to provide it, we
                    collect personal information such as your name, address,
                    telephone number, financial information, email address and
                    other information, in connection with services and products
                    available or described on the Properties.
                  </p>
                </li>
                <li>
                  <p>
                    Cookies. Like most businesses operating websites and/or
                    mobile applications, we may track your visits to our
                    Properties by giving you HTML “cookies” when you enter.
                    Cookies are pieces of information transferred to your
                    device’s hard drive or shared with your device’s temporary
                    memory for record-keeping purposes. Cookies are not computer
                    programs and have no ability to read data residing on your
                    device or instruct it to perform any step or function. By
                    assigning a unique data element to each visitor, the
                    Properties able to recognize repeat users, track usage
                    patterns and better serve you when you return to a
                    particular Property. For example, a cookie can be used to
                    store your preferences for certain kinds of information or
                    to store a password so that you do not have to input it
                    every time you visit one of our Properties. Cookies also
                    help us to collect anonymous click-stream data for tracking
                    visitor trends and patterns. Our cookies do not store
                    personal information. Most browsers are initially set up to
                    accept cookies. If you prefer, you can reset your browser
                    either to notify you when you have received a cookie or to
                    refuse to accept cookies. You should understand that certain
                    areas of our Properties may not function properly if you set
                    your browser not to accept cookies.
                  </p>
                </li>
              </ol>
            </p>
            <p>
              Makfuels collects and uses your personal information to operate
              the Properties and deliver the services you have requested.
              Makfuels also uses your personally identifiable information to
              inform you of other products or services available from Makfuels.
              We may also contact you via surveys to conduct research about your
              opinion of current services or of potential new services that may
              be offered. By providing personal information into any of the
              above areas, you grant us permission to use such information for
              our business purposes, including without limitation contacting you
              regarding product or service offers or customer survey requests,
              and you grant us permission to securely transmit your personal
              information to third parties to fulfill our business purposes.
              Further, we may contact you about selected products, services,
              companies and events that we think may interest you or to contact
              you regarding your requests, questions, comments or other
              information you have provided. Makfuels does not sell, rent or
              lease its customer lists to third parties. Makfuels may, from time
              to time, contact you on behalf of external business partners about
              a particular offering that may be of interest to you. In those
              cases, your unique personally identifiable information (e-mail,
              name, address, telephone number) is not transferred to the third
              party. In addition, Makfuels may share data with trusted partners
              to help us perform statistical analysis, send you email or postal
              mail, provide customer support, process payments or complete
              transactions, or arrange for deliveries. All such third parties
              are prohibited from using your personal information except to
              provide these services to Makfuels, and they are required to
              maintain the confidentiality of your information. If you later
              decide you do not want your information to be used in these ways,
              please let us know through the opt-out procedures described below.
            </p>
            <p>
              Makfuels keeps track of the sites and pages our customers visit
              within the Properties in order to determine what Makfuels products
              and services are the most popular. This data is used to deliver
              customized content and advertising within the Properties to
              customers whose behavior indicates that they are interested in a
              particular subject area.
            </p>
            <p>
              By providing the personal information of a person other than
              yourself or that of an entity into any of the above areas, you are
              solely responsible for obtaining from such person or entity any
              and all required rights, releases, consents, clearances, licenses,
              and other authorizations necessary to exploit and otherwise use
              their information (the “Clearances”). By submitting such
              information, you represent and warrant to Makfuels that all
              required Clearances have been obtained and Makfuels is entitled to
              rely on the same.
            </p>
            <p>
              Please keep in mind that if you directly disclose personally
              identifiable information or personally sensitive data through
              Makfuels public message boards and/or social media channels, this
              information may be collected and used by others.
            </p>
            <p className="mb-3">
              Makfuels encourages you to review the privacy statements of
              websites or mobile applications you choose to link to from the
              Properties so that you can understand how those websites and
              applications collect, use and share your information. Makfuels is
              not responsible for the privacy statements or other content on
              websites and mobile applications outside of the Properties.
            </p>
          </p>
          <h2 className="back__privacy_head_h2">
            HOW WE DISCLOSE INFORMATION WE COLLECT
          </h2>
          <p>
            Makfuels may disclose your information to our affiliated companies
            and our stores as well as to third parties that provide services to
            us as permitted by law, unless we are otherwise notified by you
            through the opt-out procedures described below. Makfuels may also
            disclose your information: (a) in response to requests from local,
            state or federal law enforcement officials; (b) in response to any
            judicial, administrative, or similar proceeding such as a subpoena;
            (c) when required by law; (d) to protect and defend our rights,
            business or property; (e) in special circumstances including in
            connection with a fundamental corporate structure or ownership
            change or in response to a physical threat (actual or perceived) to
            an individual; (f) to register or verify your account on the
            Properties; (g) to communicate with you regarding the status of your
            account or provide information otherwise related to your use of the
            Properties or (h) to enforce this Privacy Policy or the Terms of Use
            Agreement.
          </p>
          <h2 className="back__privacy_head_h2">
            HOW WE PROTECT YOUR INFORMATION
          </h2>
          <p >
            You should be aware that, in general, information sent through the
            Internet is not secure. Makfuels, however, takes commercially
            reasonably steps to secure your personal information from
            unauthorized access, use or disclosure. Makfuels secures the
            personally identifiable information you provide on computer servers
            in a controlled, secure environment, protected from unauthorized
            access, use or disclosure. That said, however, you should be aware
            of the following:
            <p className="mt-2">
              <ol>
                <li>
                  Information Security. Makfuels does not warrant the security
                  of your information. However, we utilize encryption and/or
                  security software to safeguard the confidentiality of personal
                  information we collect from unauthorized access or disclosure
                  and accidental loss, alteration or destruction.
                </li>
                <li>
                  Evaluation of Information Protection Practices. Periodically,
                  our operations and business practices are reviewed for
                  compliance with corporate policies and procedures governing
                  the security, confidentiality and quality of information.
                </li>
                <li>
                  Employee Access to Your Information. In general, our business
                  practices limit employee access to personal information and
                  limit the use and disclosure of such information to authorized
                  persons, processes and transactions.
                </li>
              </ol>
            </p>
          </p>
          <h2 className="back__privacy_head_h2">
            USE OF SOCIAL MEDIA PLATFORMS OR OTHER CONNECTED THIRD PARTY
            SERVICES
          </h2>
          <p>
            Makfuels reminds visitors that we provide additional links to
            resources we think you’ll find useful. These links will lead you to
            sites that are not affiliated with Makfuels and may operate under
            different privacy practices. Our visitors are responsible for
            reviewing the privacy policies for such other websites, as we have
            no control over information that is submitted to these companies.
            Makfuels may obtain information about you from such third-party
            websites, which varies depending on such third-party site. Our
            access to your information is controlled by your privacy settings on
            such third-party site and your consent.
          </p>
          <h2 className="back__privacy_head_h2">
            CHOICE/OPT-OUT – INFORMATION USES
          </h2>
          <p>
            When you are asked to provide certain specific information while
            visiting one of our Properties, you may be given the opportunity to
            choose whether to have your information used for certain purposes.
            Otherwise, visitors who no longer wish to be contacted, to have
            their information shared pursuant to this Privacy Policy, or to
            receive promotional email notifications may always opt-out of
            receiving these notifications by clicking the unsubscribe link;
            provided, however, Makfuels may still disclose your information for
            any and all reasons described in (a) – (h) of the section above
            entitled “How We Disclose Information We Collect.” You may opt out
            at any time and for any reason.
          </p>
          <h2 className="back__privacy_head_h2">CHILDREN’S PRIVACY</h2>
          <p>
            At Makfuels, we are sensitive to the need to protect the privacy of
            children who use the Internet. Makfuels does not specifically target
            any products or services on any Property to children under 13 years
            old. Further, we will not knowingly collect personal information
            from children under the age of thirteen (13) consistent with the
            Children’s Online Privacy Protection Act. Individuals under the age
            of 13 should only use the Properties with the permission and under
            the supervision of a parent or guardian. Individuals under the age
            of thirteen (13) should not attempt to provide any personal
            information on any of the Properties. If we become aware that a
            child under the age of thirteen (13) has provided personal
            information to us through our Properties, we will delete it from our
            files. If you are a parent or legal guardian of someone under the
            age of thirteen (13) whom you believe has submitted personal
            information to us, please contact us at contact@Makfuels.com, and we
            will take appropriate steps to remove such information from our
            files within three business days.
          </p>
          <p>
            Please note that the Properties may contain links to content
            providers that collect information from children under the age of
            13. Makfuels has no control over such content providers and is not
            responsible for the privacy practices or the content of other
            websites or mobile applications. Because the Internet and mobile
            applications offer open access to a wide range of information, it is
            important that parents supervise and prevent access to inappropriate
            content, email or chat sessions.
          </p>
          <h2 className="back__privacy_head_h2">
            ELECTRONIC COMMUNICATIONS FROM Makfuels
          </h2>
          <p>
            Visitors to Makfuels’s Properties are given the opportunity to
            specifically consent and request to receive from Makfuels and/or its
            third party service providers, by electronic delivery, (which
            includes but is not limited to email, text message, automatic
            telephone dialing system or artificial or prerecorded voice
            recordings to cell phones and landlines “Electronic
            Communications”), promotional and marketing information and
            materials, documents, and any other communications which they may
            have requested to receive from Makfuels. When you consent to receive
            Electronic Communications, you are specifically agreeing that
            Electronic Communications provided by Makfuels (and/or its third
            party service providers) are reasonable and proper notice, for the
            purpose of any and all laws, rules, and regulations, and you agree
            that such Electronic Communications fully satisfy any requirement
            that such communications be provided to you in writing or in a form
            that you may keep. By providing your consent, you acknowledge that,
            for your records, you are able to access and retain Electronic
            Communications, and this Privacy Policy and the Terms of Use
            Agreement, by printing and/or downloading and saving each to a
            computer. You acknowledge and agree that you are under no obligation
            to consent to (i) receive Electronic Communications, or (ii) receive
            Electronic Communications as a condition of purchasing any property,
            goods or services from Makfuels.
          </p>
          <p>
            Your current valid email address or cell phone number is required in
            order for you to receive Electronic Communications from Makfuels. It
            is your responsibility to ensure the accuracy of your email address
            and cell phone number. You may request a paper version of an
            Electronic Communication or any agreement to which your electronic
            signature applies. You acknowledge that Makfuels reserves the right
            to charge you a reasonable fee for the production and mailing of
            paper versions of Electronic Communications, or this Privacy Policy
            or the Terms of Use Agreement. To request a paper copy of an
            Electronic Communication, contact us at contact@Makfuels.com.
          </p>
          <p>
            You have the right to withdraw your consent to receive Electronic
            Communications from Makfuels; however, you acknowledge and agree
            that Makfuels has the right to restrict or terminate your access to
            certain aspects of the Properties if you withdraw your consent to
            receive Electronic Communications. If you wish to withdraw your
            consent, either click the unsubscribe link from the Makfuels email
            you received or follow the opt-out instructions sent via text.
          </p>
          <h2 className="back__privacy_head_h2">RELEASE AND LIMITATIONS</h2>
          <p>
            BY USING THE PROPERTIES YOU RELEASE, WAIVE AND DISCHARGE Makfuels
            FROM ANY AND ALL LIABILITY RELATED TO, AND AGREE THAT Makfuels IS
            NOT RESPONSIBLE FOR: (I) ANY DISCLOSURE OF PERSONAL INFORMATION MADE
            BY YOU TO A THIRD PARTY THROUGH YOUR USE OF THE PROPERTIES; (II) ANY
            DISCLOSURE OF PERSONAL INFORMATION OBTAINED ILLEGALLY FROM US; OR
            (III) ANY ACCIDENTAL DISCLOSURE OF PERSONAL INFORMATION MADE BY US.
          </p>
          <h2 className="back__privacy_head_h2">HOW TO CONTACT US</h2>
          <p>
            If you have any comments or questions regarding the Properties or
            any of the terms of this Privacy Policy or the Terms of Use
            Agreement, please contact us at https://www.makcom.cf/contact-us or
            by any means listed on the “Contact Us” section of any particular
            Application.
          </p>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
