import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { logout } from '../../redux/features/auth/authSlice';

const LogoutModal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const handleLogout = () => {
      dispatch(logout());
    //   dispatch(clearCart());
      navigate("/member-login");
    };
  return (
    <div>
    <div className="modal fade" id="logoutModal" data-bs-backdrop="static" tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header" style={{backgroundImage: 'linear-gradient(#f8c301, #dcae00)'}}>
            {/* <h5 className="modal-title" >Modal title</h5> */}
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body text-center already_login">
          <i className="fa-solid fa-triangle-exclamation"></i>
           <p>Already Logged In</p>
          </div>
          <div className="modal-footer">
            {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
            <button type="button" className="btn check_out_btn" data-bs-dismiss="modal" onClick={handleLogout}>Logout</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default LogoutModal