import axios from 'axios';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from "../assets/images/logo.jpg";
import { baseURL } from '../config/apiUrl';
import { logout } from '../redux/features/auth/authSlice';
import { Helmet } from 'react-helmet-async';

const MemberHome = () => {
  const { token, name } = useSelector((state) => state.auth);


  // const [loading, setloading] = useState(false)
  const [memberPricing, setMemberPricing] = useState([])
  const dispatch = useDispatch();
  const navigate = useNavigate();

const fetchMemberPricing = () =>{
  var config = {
    method: 'get',
    url: baseURL + `/getToDisplay`,
    headers: { 
      Authorization: `Bearer ${token}`
    }
  };
  // setloading(true)
   axios(config)
  .then(function (response) {
    const {data}=response.data
    setMemberPricing(data)
    // setloading(false)
  })
  .catch(function (error) {
  // setloading(false)
    console.log(error);
  });
}

useEffect(() => {
  fetchMemberPricing()
  // eslint-disable-next-line
}, [])


const handleLogout = () => {
  dispatch(logout());
  // dispatch(clearCart());
  navigate("/");
};

  return (
    <div>
                    <Helmet>
                    <title>Makfuels | Special-Prices</title>
        <link rel="canonical" href="https://makcom.cf/special-prices" />
      </Helmet>
    <section>
      <div className="d-flex align-items-md-center justify-content-end col-md-5 m-auto mt-5">
        <p className="me-2">Hi! {name}</p>
        {/* <p className="me-2">Anas Murtaza</p> */}
        <button className="btn check_out_btn" onClick={handleLogout}>Log out</button>
      </div>
      <div className="main_Special col-md-5 m-auto mt-2">
        <div className="p-4">
          <div className="d-flex align-items-center">
            <img src={logo} alt="logo" />
            <h2 className="ms-4 prices__heading">Members Prices</h2>
          </div>
          <div className="text-center mt-3 gallon__">
            <h2>Regular ${memberPricing?.regular_price}<sup>9</sup> Per Gallon</h2>
            <h2>Diesel ${memberPricing?.diesel_price}<sup>9</sup> Per Gallon</h2>
            {/* <h2>Regular $ 2.59<sup>9</sup> Per Gallon</h2>
            <h2>Diesel $ 2.89<sup>9</sup> Per Gallon</h2> */}
            {/* <h3>PER GALLON</h3> */}
            {/* <h1 className="text-danger">Christmas Day Special</h1> */}
            <h5>Valid {moment( memberPricing?.valid_start).format('MMM DD')} to {moment( memberPricing?.valid_end).format('MMM DD')}</h5>
            {/* <h5>Valid 14 Dec to 30 Dec</h5> */}
            <p className="mt-2"><b>Phone:</b> {memberPricing?.phone}</p>
            {/* <p className="mt-2"><b>Phone:</b> 03458252363	</p> */}
          </div>
        </div>
        <div className="mt-2 pric__footer py-3 text-center">
          <i className="fa-solid fa-location-dot" />
          <span>Address : {memberPricing?.address}</span> 
          {/* <span>Address : 6800 Olive Blvd Suite B Saint Louis, MO, 63132	</span>  */}
        </div>
      </div>
    </section>
    {/* <section>
        <div className=' col-md-9 m-auto mt-5'>
    <h4 className="mb-2">Special Gas Prices</h4>
      <div className="thanYou_tbl">
        <table className="table table-striped mb-4">
          <thead>
            <tr>
              <th scope="col">Type</th>
              <th scope="col">Price</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Petrol
              </td>
              <td>$ 99</td>
            </tr>
            <tr>
              <td>Diesel
              </td>
              <td>$ 99</td>
            </tr>
            <tr>
              <td>High Octane
              </td>
              <td>$ 99</td>
            </tr>

          </tbody>
        </table>
      </div>
      </div>
    </section> */}
  </div>

  )
}

export default MemberHome