import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import ShoppingCart from "./pages/ShoppingCart";
import { Route, Routes, useNavigate , Navigate  } from "react-router-dom";
// import Grocery from './pages/Grocery';
import Checkout from "./pages/Checkout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./components/Login";
import MyProfile from "./pages/MyProfile";
import AddressBook from "./pages/AddressBook";
import MyOrders from "./pages/MyOrders";
import MemberLogin from "./components/MemberLogin";
import MemberWaiting from "./components/MemberWaiting";
import OrderConfirmation from "./pages/OrderConfirmation";
import MemberHome from "./pages/MemberHome";
// import Footer from './components/Footer';
import { useSelector } from "react-redux";
import OrderView from "./pages/OrderView";
import SearchHome from "./pages/SearchHome";
import NotFound from "./pages/NotFound";
import Home from "./pages/Home";
import PageLayout from "./pages/PageLayout";
import DeliveryCheckout from "./pages/DeliveryCheckout";
// import MainHome from "./pages/MainHome";
import ContactUs from "./pages/ContactUs";
// import NewConstruction from "./pages/NewConstruction";
import LocationInvoice from "./pages/LocationInvoice";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermConditions from "./pages/TermConditions";
import Notifications from "./pages/Notifications";
import QRscanner from "./pages/QRscanner";
import ScanQRDetail from "./components/ScanQRDetail";
import CouponSend from "./pages/CouponSend";
import ScanQrUserDetails from "./components/ScanQrUserDetails";
import QrCheckout from "./pages/QrCheckout";
import GasPaymentDetail from "./pages/GasPaymentDetail";
import PaymentMethod from "./pages/PaymentMethod";
import Menu from "./pages/Menu";
import Pos from "./components/Pos";
import PosSystem from "./pages/PosSystem";
import ReceiptPDF from "./pages/ReceiptPDF";
import Footer from "./components/Footer";
import AboutUs from "./pages/AboutUs";
import ProductPage from "./pages/ProductPage";



function App() {
  const { token , user_type } = useSelector((state) => state.auth);

   let navigate = useNavigate();

  return (
    <>
      <ToastContainer />

  {    (user_type === "pos" )?
      
      <Routes>
        
        <Route element={<PageLayout />}/>
        <Route exact path="/pos-system" element={<PosSystem />} />
        <Route exact path="/pos-system/:name" element={<PosSystem />} />
        <Route path="*" element={<Navigate to="/pos-system" replace={true} />} />

        </Routes>:

<Routes>
        {/* <Route exact path="/" element={<MainHome />} /> */}
          {/* <Route exact path="/foods" element={ <FoodHome/> } /> */}
          {/* <Route exact path="/grocery" element={ <Grocery/> } /> */}
          {/* <Route exact path="/search?page:id" element={<SearchHome />} /> */}

        <Route element={<PageLayout />}>

           
        {/* <Route exact path="/shop" element={<Home />} /> */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/:name" element={<Home />} />
        <Route exact path="/product/:slug/:id" element={<ProductPage />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/terms-of-use" element={<TermConditions />} />


          <Route exact path="/shopping-cart" element={<ShoppingCart />} />
          <Route exact path="/checkout" element={<Checkout />} />
          <Route exact path="/delivery-checkout" element={<DeliveryCheckout />} />
          <Route exact path="/myProfile" element={<MyProfile />} />
          <Route exact path="/notifications" element={<Notifications />} />
          <Route exact path="/address-book" element={<AddressBook />} />
          <Route exact path="/myorders" element={<MyOrders />} />
          <Route exact path="/order-view" element={<OrderView />} />
          <Route exact path="/search" element={<SearchHome />} />
          <Route exact path="/payment-method" element={<PaymentMethod />} />

        </Route>

        {token && (
        <Route exact path="/member-invoice" element={<LocationInvoice />} />
        )}
        <Route exact path="/special-prices" element={<MemberHome />} />
        <Route
          exact
          path="/order-confirmation"
          element={<OrderConfirmation />}
        />
          <Route exact  path="/qr_scanner" element={<QRscanner />} />
          <Route exact  path="/scan-qr" element={<ScanQRDetail />} />
          <Route exact path="/couponsend/:coupon_id" element={<CouponSend />} />
          <Route exact  path="/payment-successful" element={<ScanQrUserDetails />} />
          <Route exact  path="/qr-payment" element={<QrCheckout />} />
          <Route exact  path="/gas-payment-detail" element={<GasPaymentDetail />} />

        {/* <Route exact path="/new-construction" element={<NewConstruction />} /> */}
        <Route exact path="/member-waiting" element={<MemberWaiting />} />
        <Route exact path="/member-login" element={<MemberLogin />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/menu" element={<Menu />} />
        <Route exact path="/pos" element={<Pos />} />
        <Route path="*" element={<Navigate to="/" replace={true} />} /> 

        <Route exact path="*" element={<NotFound />} />
      </Routes>
}
      
      <Footer/>
    </>
  );
}

export default App;
