import React, { memo, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { baseURL } from '../../config/apiUrl';
import bannerImg from "../../assets/images/banner.jpg";
import { useSelector } from 'react-redux';
import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Banner = () => {
    const { token } = useSelector((state) => state.auth);
    const [banner, setBanner] = useState();
  
    const fetchBanner = async () => {
      var config = {
        method: "get",
        url: baseURL + "/getBanners/0",
        headers: {},
      };
      // setLoader(true)
      await axios(config)
        .then(function (response) {
          const { data } = response.data;
          setBanner(data);
          // setLoader(false)
        })
        .catch(function (error) {
          console.log(error);
          // setLoader(false)
        });
    };

  
    useEffect(() => {
      fetchBanner();
    }, []);
  
  return (
        <>
                   <div className="__banner mt-3 position-relative d-flex align-items-center">
              <div
                id="carouselExampleIndicators"
                className="carousel slide w-100"
                data-bs-ride="carousel"
              >
                <div className="carousel-indicators">
                  {banner &&
                    banner.map((value, index) => (
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to={index}
                        className={index === 0 ? "active" : ""}
                        aria-current="true"
                        aria-label={index}
                        key={index}
                      />
                    ))}
                </div>
                <div
                  className="carousel-inner"
                  style={{ borderRadius: "20px" }}
                >
                  {
                    banner?.map((value, index) => (
                      <div
                        className={
                          index === 0
                            ? "carousel-item active"
                            : "carousel-item "
                        }
                        key={index}
                      >
                        <Link to={token ? "" : value.link}>
                          <img
                            width="100%"
                            height="auto"
                            fetchpriority="high"
                            src={value.image}
                            className="d-block"
                            alt="..."
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = bannerImg;
                            }}
                          />
                        </Link>
                      </div>
                    ))}
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
        </>
  )
}

export default memo(Banner);