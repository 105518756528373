import React, { useState } from "react";
import Slider from "react-slick";
import FcategoryImg from "../../assets/images/food_category.jpg";
import GcategoryImg from "../../assets/images/grocery_category.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { memo } from "react";

const Categories = (props) => {
  const { midCategory, getCatID, cat_id, handleMidCategory } = props;
  var settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    autoplay: true,
    // initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // dots:false,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [visibleItems, setVisibleItems] = useState(9);
  const [showAll, setShowAll] = useState(false);
  // const handleArrowClick = () => {
  //   setVisibleItems(visibleItems + 6);
  // };
  const handleArrowClick = () => {
    setShowAll(!showAll);
    setVisibleItems(9);
  };

  return (
    <>
      <div className="mt-3 row">
        {/* --------responsive-mob--slider------- */}
        <div className="slider-res-mob d-md-none d-block min_height_20">
          <Slider {...settings}>
            {midCategory &&
              midCategory.map((value, index) => (
                <div
                  className="col-md-2"
                  key={index}
                  onClick={() => getCatID(value.id, value.name, value.slug)}
                >
                  {/* <a href=""> */}
                  <div
                    className={
                      cat_id === value.id
                        ? "cat_div menu__card py-2"
                        : "menu__card py-2"
                    }
                  >
                    <LazyLoadImage
                      height={70}
                      width={75}
                      fetchpriority="high"
                      src={value.image}
                      loading='lazy'
                      alt={value?.name}
                      title={value?.name}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                          value.main_cat_id === "1"
                            ? FcategoryImg
                            : GcategoryImg;
                      }}
                    />
                    <p>{value?.name}</p>
                  </div>
                  {/* </a> */}
                </div>
              ))}
          </Slider>
        </div>
        <div className="d-none d-md-block min_height_20">
          <div className="row ">
            {/* <div className="col-md-3 col-lg-4 mb-2">
              <div className="main_category_main__">
                <img src={chicken_main} alt="mid-category" />
              </div>
            </div>
            <div className="col-md-3 col-lg-4 mb-2">
              <div className="main_category_main__">
                <img src={sandwich_main} alt="mid-category" />
              </div>
            </div>
            <div className="col-md-3 col-lg-4 mb-2">
              <div className="main_category_main__">
                <img src={icecream_main} alt="mid-category" />
              </div>
            </div> */}
            {/* {midCategory &&
  midCategory.slice(0, visibleItems).map((value, index) => (
                <div
                  className="col-md-3 col-lg-2"
                  key={index}
                  onClick={() => getCatID(value.id, value.name, value.slug)}
                >
                  <div
                    className={
                      cat_id === value.id
                        ? "cat_div menu__card py-2"
                        : "menu__card py-2"
                    }
                  >
                    <img
                      height={70}
                      width={75}
                      src={value.image}
                      alt="mid-category"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                          value.main_cat_id === "1"
                            ? FcategoryImg
                            : GcategoryImg;
                      }}
                    />
                    <p>{value?.name}</p>
                  </div>
                </div>
              ))} */}

{midCategory &&
  (showAll
    ? midCategory.map((value, index) => (
        <div
          className={index < 3 ? "col-md-4 col-lg-4 mb-2" : "col-md-3 col-lg-2"}
          key={index}
          onClick={() => getCatID(value.id, value.name, value.slug)}
        >
          {index < 3 ? (
            <div className="main_category_main__">
              <LazyLoadImage src={value.image} alt={value?.name} title={value?.name} loading='lazy' />
            </div>
          ) : (
            <div
              className={
                cat_id === value.id
                  ? "cat_div menu__card py-2"
                  : "menu__card py-2"
              }
            >
              <LazyLoadImage
                src={value.image}
                alt={value?.name}
                title={value?.name}
                loading='lazy'
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    value.main_cat_id === "1" ? FcategoryImg : GcategoryImg;
                }}
              />
              <p>{value?.name}</p>
            </div>
          )}
        </div>
      ))
    : midCategory.slice(0, visibleItems).map((value, index) => (
        <div
          className={index < 3 ? "col-md-4 col-lg-4 mb-2" : "col-md-3 col-lg-2"}
          key={index}
          onClick={() => getCatID(value.id, value.name, value.slug)}
        >
          {index < 3 ? (
            <div className="main_category_main__">
              <LazyLoadImage src={value.image} alt={value?.name} title={value?.name} loading='lazy' />
            </div>
          ) : (
            <div
              className={
                cat_id === value.id
                  ? "cat_div menu__card py-2"
                  : "menu__card py-2"
              }
            >
              <LazyLoadImage
                height={70}
                width={75}
                src={value.image}
                alt={value?.name}
                title={value?.name}
                loading='lazy'
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    value.main_cat_id === "1" ? FcategoryImg : GcategoryImg;
                }}
              />
              <p>{value?.name}</p>
            </div>
          )}
        </div>
      )))}



            <div className="text-center">
              {/* <div className="text-center"><i onClick={handleArrowClick} className="fa-solid fa-circle-chevron-down show_more_i"></i></div> */}
              <p onClick={handleArrowClick} className="show_more_i">
                <i
                  className={`fa-solid ${
                    showAll ? "fa-circle-chevron-up" : "fa-circle-chevron-down"
                  }`}
                ></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Categories);
