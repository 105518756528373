import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  clearCart,
  decreaseCart,
  getTotals,
  increaseCart,
  removeFromCart,
} from "../redux/features/slices/cartSlice";
import empty_carts from "../assets/images/empty_cart.png";
import { baseURL } from "../config/apiUrl";
import FproductImg from "../assets/images/food_product.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import GproductImg from "../assets/images/grocery_product.jpg";
import axios from "axios";
import { Helmet } from "react-helmet-async";

const ShoppingCart = () => {
  const [riderCount, setRiderCount] = useState();
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);

  const handleincreaseCart = (product) => {
    dispatch(increaseCart(product));
  };
  const handleDecreaseCart = (product) => {
    dispatch(decreaseCart(product));
  };
  const handleRemoveFromCart = (product) => {
    dispatch(removeFromCart(product));
  };
  const handleClearCart = () => {
    dispatch(clearCart());
  };
  const fetchRiderCount = async () => {
    var config = {
      method: "get",
      url: baseURL + `/riderCount`,
      // data : data
    };
    await axios(config)
      .then(function (response) {
        const { data } = response.data;
        setRiderCount(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchRiderCount();
  }, []);

  const newQuantity = cart.cartItems.extrasaucesArray;

  // const totalSaucePrice = cart.cartItems.extrasaucesArray.reduce((accumulator, currentItem) => {
  //   // return accumulator + parseFloat(currentItem.price);
  //   return parseFloat(currentItem.price)*newQuantity;
  // }, 0);
  return (
    <>
      <Helmet>
        <title>Makfuels | Shopping Cart</title>
        <link rel="canonical" href="https://makcom.cf/shopping-cart" />
      </Helmet>

      <div className="container-fluid px-md-5 px-2 mt-4">
        <h1 style={{ fontStyle: "1.7rem" }} className="mb-2 ms-3">
          Shopping Cart
        </h1>

        {cart.cartItems.length === 0 ? (
          <div className="text-center">
            <LazyLoadImage
              className=""
              style={{ width: "100px" }}
              loading='lazy'
              src={empty_carts}
              alt="empty-cart"
              title="empty-cart"
            ></LazyLoadImage>

            <h3>Your Cart is Empty</h3>
            <h5 className="mb-2">Add something to make me happy</h5>
            <Link to="/">
              <button className="btn check_out_btn">Continue Shopping</button>
            </Link>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-8 mb-2">
              <Link to="/" title="Makfuels">
                <button className="btn continue_shop__btn mb-3">
                  <i className="fa-solid fa-circle-left"></i> Continue Shopping
                </button>
              </Link>
              {/* <div className="row mb-2">
              <div className="col">Product</div>
              <div className="col-md-3">Add Ons</div>
              <div className="col">Price</div>
              <div className="col">Quantity</div>
              <div className="col">Total Price</div>
            </div> */}
              <div className="cart-items">
                {cart.cartItems &&
                  cart.cartItems.map((cartItem, index) => {
                    const totalSauceRedux = cartItem.extrasaucesArray.reduce(
                      (totalQuantity, val) =>
                        totalQuantity + val.quantity * cartItem.cartQuantity,
                      0
                    );
                    const newQuantity = cartItem.finalSauceQuantity;
                    const drinkPrice =
                      cartItem && cartItem?.selectedSoftDrinks?.price == null
                        ? 0
                        : parseFloat(
                            cartItem && cartItem?.selectedSoftDrinks?.price
                          );

                    const totalSaucePrice = cartItem.extrasaucesArray.reduce(
                      (accumulator, currentItem) => {
                        return parseFloat(currentItem.price) * newQuantity;
                      },
                      0
                    );
                    let totalSidePrice = cartItem?.sidesArray?.reduce(
                      (total, currentValue, i) => {
                        if (cartItem?.sidesQuantity > i) {
                          return total;
                        } else {
                          return (total =
                            total + parseFloat(currentValue.price));
                        }
                      },
                      0
                    );
                    const totalSidePrices =
                      totalSidePrice == undefined ? 0 : totalSidePrice;
                    let FriesResultsUpd =
                      cartItem.FriesResult == undefined
                        ? 0
                        : cartItem.FriesResult;
                    let pricing =
                      cartItem.discount_price === null
                        ? cartItem.price
                        : cartItem.discount_price;
                    pricing =
                      pricing === null
                        ? cartItem.selectedSubproduct?.price
                        : pricing;
                    let toppingPrice =
                      cartItem.toppingsArray.reduce(
                        (total, currentValue) =>
                          (total =
                            total +
                            parseFloat(currentValue.price) *
                              cartItem.cartQuantity),
                        0
                      ) +
                      totalSaucePrice * cartItem.cartQuantity +
                      totalSidePrices * cartItem.cartQuantity +
                      FriesResultsUpd +
                      drinkPrice +
                      pricing * cartItem.cartQuantity;

                    return (
                      <div className="mb-2 p_cart__items py-2 px-2" key={index}>
                        <div className="row">
                          <div className=" col-8 col-sm-6 m-auto">
                            <div className="cart__product_m d-flex align-items-center">
                              <LazyLoadImage
                                width={50}
                                height={50}
                                src={cartItem.image}
                                alt={cartItem.name}
                                title={cartItem.name}
                                loading='lazy'
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src =
                                    cartItem.main_cat_id === "1"
                                      ? FproductImg
                                      : GproductImg;
                                }}
                              />
                              <span className="ms-1">
                                <span className="product___naMe">
                                  {cartItem.cartQuantity} x {cartItem.prod_name}{" "}
                                  {cartItem.name}
                                  {cartItem.main_cat_id === "1"
                                    ? cartItem.is_sub_avaiable == 0
                                      ? null
                                      : ` (${cartItem.selectedSubproduct?.name})`
                                    : cartItem.selectedSubproduct?.name &&
                                      ` (${cartItem.selectedSubproduct.name})`}
                                </span>

                                {cartItem.main_cat_id == 1 &&
                                  cartItem.is_sub_avaiable == 1 &&
                                  Object.keys(cartItem.selectedFriesType)
                                    .length > 0 && (
                                    <div
                                      style={{ fontSize: "11px" }}
                                      className="d-flex justify-content-between"
                                    >
                                      <span className="">
                                        {cartItem.selectedFriesType?.type} (
                                        {cartItem.selectedFriesSize?.name})
                                      </span>
                                      <span className=" text-end">
                                        {cartItem.FriesResult == 0
                                          ? "INCLUDED"
                                          : `$ ${cartItem.FriesResult.toFixed(
                                              2
                                            )}`}
                                      </span>
                                    </div>
                                  )}
                                {cartItem &&
                                  cartItem.main_cat_id == 1 &&
                                  cartItem.is_sub_avaiable == 1 &&
                                  Object.keys(cartItem?.selectedFountainDrinks)
                                    ?.length > 0 && (
                                    <div
                                      style={{ fontSize: "11px" }}
                                      className="d-flex justify-content-between"
                                    >
                                      <span className="">
                                        {cartItem?.selectedFountainDrinks?.name}{" "}
                                        ({cartItem?.selectedSoftDrinks?.name})
                                      </span>
                                      <span className=" text-end">
                                        {cartItem?.selectedSoftDrinks?.price ==
                                        null
                                          ? "INCLUDED"
                                          : `$ ${cartItem?.selectedSoftDrinks?.price}`}
                                      </span>
                                    </div>
                                  )}
                                {cartItem.main_cat_id == 1 &&
                                cartItem?.extrasaucesArray.length > 0 &&
                                cartItem.is_sub_avaiable == 1 ? (
                                  <ul
                                    className="mt-2 p-0"
                                    style={{
                                      marginBottom: "0",
                                      listStyle: "none",
                                      fontSize: "10px",
                                    }}
                                  >
                                    {cartItem.extrasaucesArray.length > 0 && (
                                      <div>
                                        <h6>Dip Sauces</h6>
                                        <p className="exclude_sauces my-1">{`Exclude ${
                                          totalSauceRedux -
                                          cartItem.finalSauceQuantity
                                        } ${
                                          totalSauceRedux -
                                            cartItem.finalSauceQuantity >
                                          1
                                            ? "Sauces"
                                            : "Sauce"
                                        } Price`}</p>
                                      </div>
                                    )}
                                    {cartItem.extrasaucesArray.map(
                                      (val, index) => {
                                        return (
                                          <li key={index}>
                                            <div className="d-flex justify-content-between">
                                              <p>
                                                {val.quantity *
                                                  cartItem.cartQuantity}{" "}
                                                x {val.name}
                                              </p>
                                              <p>$ {val.price}</p>
                                            </div>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                ) : null}

                                {/* yaha hai */}
                                {cartItem.main_cat_id == 1 &&
                                cartItem?.sidesArray?.length > 0 &&
                                cartItem?.is_sub_avaiable == 1 ? (
                                  <div>
                                    <p className="sub__head_fetch">Sides</p>
                                    {cartItem.sidesQuantity != 0 ? (
                                      <p className="exclude_sauces">
                                        {`Exclude ${cartItem.sidesQuantity} ${
                                          cartItem.sidesQuantity > 1
                                            ? "Side"
                                            : "Sides"
                                        } Price`}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    <ul
                                      style={{
                                        marginBottom: "0",
                                        listStyle: "none",
                                        fontSize: "10px",
                                      }}
                                      className="px-1 pb-0"
                                    >
                                      {/* <b>
                    {cartItem.cheesesArray.length === 0 && cartItem.main_cat_id==="1" ?
                      "No addons" : ""}
                  </b> */}
                                      {cartItem?.sidesArray?.map(
                                        (val, index) => {
                                          return (
                                            //  <li key={index}><span>{val.type}</span><span className='cart__AdOns'>+${val.price}</span></li>
                                            <li key={index}>
                                              <div className="d-flex">
                                                <div className="w-100">
                                                  {val.type}
                                                </div>
                                                <div className="cart__AdOns d-flex justify-content-end w-50">
                                                  +${val.price}
                                                </div>
                                              </div>
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                ) : null}

                                {/* ----Cheeses-Fetch-Start----- */}
                                {cartItem.main_cat_id == 1 &&
                                cartItem?.cheesesArray?.length > 0 &&
                                cartItem.is_sub_avaiable == 1 ? (
                                  <div>
                                    <p className="sub__head_fetch">Cheeses</p>
                                    <ul
                                      style={{
                                        marginBottom: "0",
                                        listStyle: "none",
                                        fontSize: "10px",
                                      }}
                                      className="px-1 pb-0"
                                    >
                                      {/* <b>
                    {cartItem.cheesesArray.length === 0 && cartItem.main_cat_id==="1" ?
                      "No addons" : ""}
                  </b> */}
                                      {cartItem?.cheesesArray?.map(
                                        (val, index) => {
                                          return (
                                            //  <li key={index}><span>{val.type}</span><span className='cart__AdOns'>+${val.price}</span></li>
                                            <li key={index}>
                                              <div className="d-flex">
                                                <div className="w-100">
                                                  {val.type}
                                                </div>
                                              </div>
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                ) : null}

                                {/* ----Cheeses-Fetch-end----- */}
                                {/* ----toppsecondArray-Fetch-Start----- */}
                                {cartItem.main_cat_id == 1 &&
                                cartItem?.cheesesArray?.length > 0 &&
                                cartItem.is_sub_avaiable == 1 ? (
                                  <div>
                                    <p className="sub__head_fetch">Toppings</p>
                                    <ul
                                      style={{
                                        marginBottom: "0",
                                        listStyle: "none",
                                        fontSize: "10px",
                                      }}
                                      className="px-1 pb-0"
                                    >
                                      {/* <b>
                    {cartItem.cheesesArray.length === 0 && cartItem.main_cat_id==="1" ?
                      "No addons" : ""}
                  </b> */}
                                      {cartItem?.toppsecondArray?.map(
                                        (val, index) => {
                                          return (
                                            //  <li key={index}><span>{val.type}</span><span className='cart__AdOns'>+${val.price}</span></li>
                                            <li key={index}>
                                              <div className="d-flex">
                                                <div className="w-100">
                                                  {val.type}
                                                </div>
                                              </div>
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                ) : null}

                                {/* ----toppsecondArray-Fetch-end----- */}

                                {/* ----cookingSauceArray-Fetch-Start----- */}
                                {cartItem.main_cat_id == 1 &&
                                cartItem?.cheesesArray?.length > 0 &&
                                cartItem.is_sub_avaiable == 1 ? (
                                  <div>
                                    <p className="sub__head_fetch">Sauces</p>
                                    <ul
                                      style={{
                                        marginBottom: "0",
                                        listStyle: "none",
                                        fontSize: "10px",
                                      }}
                                      className="px-1 pb-0"
                                    >
                                      {/* <b>
                    {cartItem.cheesesArray.length === 0 && cartItem.main_cat_id==="1" ?
                      "No addons" : ""}
                  </b> */}
                                      {cartItem?.cookingSauceArray?.map(
                                        (val, index) => {
                                          return (
                                            //  <li key={index}><span>{val.type}</span><span className='cart__AdOns'>+${val.price}</span></li>
                                            <li key={index}>
                                              <div className="d-flex">
                                                <div className="w-100">
                                                  {val.type}
                                                </div>
                                              </div>
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                ) : null}

                                {/* ----cookingSauceArray-Fetch-end----- */}

                                {/* ----extrasaucesArray-Fetch-Start----- */}
                                {cartItem.main_cat_id == 1 &&
                                cartItem?.cheesesArray?.length > 0 &&
                                cartItem.is_sub_avaiable == 1 ? (
                                  <div>
                                    <p className="sub__head_fetch">
                                      Dip Sauces
                                    </p>
                                    <ul
                                      style={{
                                        marginBottom: "0",
                                        listStyle: "none",
                                        fontSize: "10px",
                                      }}
                                      className="px-1 pb-0"
                                    >
                                      {/* <b>
                    {cartItem.cheesesArray.length === 0 && cartItem.main_cat_id==="1" ?
                      "No addons" : ""}
                  </b> */}
                                      {cartItem?.extrasaucesArray?.map(
                                        (val, index) => {
                                          return (
                                            //  <li key={index}><span>{val.type}</span><span className='cart__AdOns'>+${val.price}</span></li>
                                            <li key={index}>
                                              <div className="d-flex">
                                                <div className="w-100">
                                                  {val.quantity} x {val.name}
                                                </div>
                                                <div className="cart__AdOns d-flex justify-content-end w-50">
                                                  +${val.price}
                                                </div>
                                              </div>
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                ) : null}

                                {/* ----extrasaucesArray-Fetch-end----- */}

                                <ul
                                  style={{
                                    marginBottom: "0",
                                    listStyle: "none",
                                    fontSize: "10px",
                                  }}
                                  className="px-1 pb-0"
                                >
                                  <p className="sub__head_fetch">
                                    {cartItem.toppingsArray.length > 0
                                      ? "Addons"
                                      : ""}
                                  </p>
                                  {cartItem.toppingsArray.map((val, index) => {
                                    return (
                                      //  <li key={index}><span>{val.type}</span><span className='cart__AdOns'>+${val.price}</span></li>
                                      <li key={index}>
                                        <div className="d-flex">
                                          <div className="w-100">
                                            {val.type}
                                          </div>
                                          <div className="cart__AdOns d-flex justify-content-end w-50">
                                            +${val.price}
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ul>
                                <div
                                  style={{ Width: "100%" }}
                                  className="d-flex justify-content-between itemsTax"
                                >
                                  <p>Tax:</p>
                                  <p>
                                    ${" "}
                                    {(
                                      (toppingPrice.toFixed(2) / 100) *
                                      cartItem.tax_percentage
                                    ).toFixed(2)}
                                  </p>
                                </div>
                              </span>
                            </div>
                          </div>
                          {/* <div className="col-sm-3 m-autoa">
                      <ul className="px-1 pb-0">
                        <b>
                          {cartItem.toppingsArray.length === 0 && "No addons"}
                        </b>
                        {cartItem.toppingsArray.map((val, index) => {
                          return (
                            //  <li key={index}><span>{val.type}</span><span className='cart__AdOns'>+${val.price}</span></li>
                            <li key={index}>
                              <div className="d-flex">
                                <div className="w-100">{val.type}</div>
                                <div className="cart__AdOns d-flex justify-content-end w-50">
                                  +${val.price}
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div> */}

                          {/* <div className=" col-4 text-md-center text-end col-sm-3 m-auto">
                            {cartItem.main_cat_id === 1 && (
                              <span>
                                ${" "}
                                {cartItem.is_sub_avaiable === 0
                                  ? cartItem.price
                                  : cartItem.selectedSubproduct?.price}
                              </span>
                            )}
                            {cartItem.main_cat_id === 2 && (
                              <span>$ {cartItem.price} </span>
                            )}

                          </div> */}
                          <div className=" col col-sm-3  m-auto">
                            <div className="cart__box d-flex">
                              <div
                                className="qty qty-minus"
                                onClick={() => handleDecreaseCart(cartItem)}
                              >
                                -
                              </div>
                              <input
                                // id="cartQty"
                                className="qty"
                                type="number"
                                value={cartItem.cartQuantity}
                                disabled={true}
                              />
                              <div
                                className="qty qty-plus"
                                onClick={() => handleincreaseCart(cartItem)}
                              >
                                +
                              </div>
                            </div>
                          </div>
                          <div className=" col col-sm-3 m-auto">
                            <div className="text-end">
                              {/* <p>${cartItem.price * cartItem.cartQuantity}</p> */}

                              <p className="top__Price_">
                                ${toppingPrice.toFixed(2)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <i
                          className="fa-solid fa-trash-can cart___dlt"
                          onClick={() => handleRemoveFromCart(cartItem)}
                        />
                      </div>
                    );
                  })}
              </div>
              <button
                className="btn check_out_btn"
                onClick={() => handleClearCart()}
              >
                Clear Cart
              </button>
            </div>
            <div className="col-md-4 ">
              <div className="p-3  amount__cart">
                <div className="d-flex justify-content-between mb-2">
                  <p className="w-50">Subtotal</p>
                  <p className="w-50">$ {cart.cartSubTotalAmount}</p>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <p className="w-50">Tax</p>
                  <p className="w-50">$ {cart.cartTotalTax}</p>
                </div>
                <div className="d-flex justify-content-between mb-3 align-items-baseline">
                  <p>Voucher</p>
                  <input
                    type="text"
                    className="form-control w-50"
                    placeholder="Enter"
                  />
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <p className="w-50">Total</p>
                  <p className="w-50">$ {cart.cartTotalAmount}</p>
                </div>
                {/* <Link to="/checkout"> */}
                <button
                  type="button"
                  className="btn check_out_btn w-100"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Go to checkout
                </button>
                {/* </Link> */}
              </div>
            </div>
          </div>
        )}
      </div>

      <div>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content product_detals_modal">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Please Select
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body text-center p-5">
                {/* {riderCount > 0 && (
                  <Link to="/delivery-checkout">
                    <button
                      type="button"
                      className="delivery_btn"
                      data-bs-dismiss="modal"
                    >
                      Delivery
                    </button>
                  </Link>
                )} */}

                <Link to="/checkout">
                  <button
                    type="button"
                    className="delivery_btn mt-2 ms-sm-2 ms-0"
                    data-bs-dismiss="modal"
                  >
                    Pick Up
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShoppingCart;
