import axios from "axios";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DeliveryChargesModal from "../components/modal/DeliveryChargesModal";
import { baseURL } from "../config/apiUrl";
import { checkoutSchema } from "../schemas";
import { Helmet } from "react-helmet-async";
// import CardDeleteModal from "../components/modal/CardDeleteModal";
// import credit_img from "../assets/images/credit-card.png"

const DeliveryCheckout = () => {
  const [filledData, setfilledData] = useState();
  const cart = useSelector((state) => state.cart);
  const [loading, setLoading] = useState(false);
  const [selectState, setSlectState] = useState();
  const [selectCity, setSelectCity] = useState();
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    setModal(prevModal => !prevModal);
  }, []);
  
  useEffect(() => {
    setTimeout(() => {
      toggle();
    }, 500);
  }, [toggle]);
  
  const initialValues = {
    card_holder_name: filledData?.card_holder_name || "",
    card_holder_last_name: filledData?.card_holder_last_name || "",
    card_number: filledData?.card_number || "",
    expiration_month: filledData?.expiration_month || "",
    expiration_year: filledData?.expiration_year || "",
    cvc: filledData?.cvc || "",
    zipcode: filledData?.zipcode || "",
    // state: filledData?.state || "" ,
    // city: filledData?.city || "",
    phone_number: filledData?.phone_number || "",
    email: filledData?.email || "",
    billing_details: filledData?.billing_details || "",
    shipping_details: filledData?.shipping_details || "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema: checkoutSchema,
      onSubmit: async (values, action) => {
        var data = new FormData();
        data.append("card_holder_name", values.card_holder_name);
        data.append("card_holder_last_name", values.card_holder_last_name);
        data.append("card_number", values.card_number);
        data.append("expiration_month", values.expiration_month);
        data.append("expiration_year", values.expiration_year);
        data.append("cvc", values.cvc);
        data.append("zipcode", values.zipcode);
        data.append("state", selectState);
        data.append("city", selectCity);
        data.append("phone_number", values.phone_number);
        data.append("email", values.email);
        data.append("billing_details", values.billing_details);
        data.append("shipping_details", isChecked ===false?values.shipping_details:values.billing_details);
        data.append("order_items", JSON.stringify(cart.cartItems));
        data.append("total_price", cart.cartTotalAmount);

        var config = {
          method: "post",
          url: baseURL + "/book_order",
          data: data,
        };
        setLoading(true);
        await axios(config)
          .then(function (response) {
            toast.success(response.data.message);
            setTimeout(() => {
              navigate("/order-confirmation", {
                state: 
                { orderID: response.data.data,
                  deliveryCharges:2.00
                },
              });
              setLoading(false);
            }, 2000);
          })
          .catch(function (error) {
            toast.error(error.response.data.message);
            console.log(error);
            setLoading(false);
          });
      },
    });

    useMemo(() => {
      const fetchStateAndCities = async () => {
        try {
          const formData = new FormData();
          formData.append("zip_code", values.zipcode);
    
          const response = await axios.post(baseURL + "/getStateAndCity", formData);
          const { city, state } = response.data.data || {};
    
          setSelectCity(city);
          setSlectState(state);
        } catch (error) {
          console.log(error);
          setSelectCity();
          setSlectState();
        }
      };
    
      if (String(values.zipcode).length >= 4) {
        fetchStateAndCities();
      }
    }, [values.zipcode]);


  const fetchUserDetail = async(e) => {
    if (e.target.value.includes(".com")) {
      var data = new FormData();
      data.append("email", e.target.value);

      var config = {
        method: "post",
        url: baseURL + "/get_details",
        data: data,
      };

      await axios(config)
        .then(function (response) {
          setfilledData(response.data.data.user_details);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

let TotalAmount= (parseFloat(cart.cartTotalAmount) +  parseFloat('2.00')).toFixed(2)

const [isChecked, setIsChecked] = useState(false);


const { token } = useSelector((state) => state.auth);
const [selectedCard, setSelectedCard] = useState("");
const [paymentCard, setPaymentCard] = useState([]);

const handleOptionChange = (e) => {
  const cardId = e.target.id;
  if (selectedCard === cardId) {
    setSelectedCard(''); 
  } else {
    setSelectedCard(cardId); 
  }
};
const fetchPaymentCart = () => {
  let config = {
    method: "get",
    url: baseURL + `/getCardDetails`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  axios(config)
    .then(function (response) {
      setPaymentCard(response.data.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

useEffect(() => {
  if(token){
    fetchPaymentCart();
  }
  // eslint-disable-next-line
}, [token]);

const handleSubmitCard = async() => {
  var data = new FormData();
  data.append("card_id", selectedCard);
  data.append("order_items", JSON.stringify(cart.cartItems));
  data.append("total_price", cart.cartTotalAmount);

  var config = {
    method: "post",
    url: baseURL + "/book_order",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  setLoading(true);
 await axios(config)
    .then(function (response) {
      toast.success(response.data.message);
      setTimeout(() => {
        navigate("/order-confirmation", {
          state: { orderID: response.data.data },
        });
        setLoading(false);
      }, 2000);
    })
    .catch(function (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    });
};


  return (
    <>
                  <Helmet>
                  <title>Makfuels | Delivery-Checkout</title>
        <link rel="canonical" href="https://makcom.cf/delivery-checkout" />
      </Helmet>
    <section className="container-fluid px-lg-5 px-2 pt-4 mt-2">
      <div className="row">
        <div className="col-md-9 mb-3">
          <div className="p-3 pay__details_ naV__StyLe">
            <div className="d-flex align-items-center">
              <h4 className="me-3">Payment Details</h4>
            </div>
            {/* -----tabs-Start---- */}
            {token &&
                        <div>
                        {paymentCard &&
                    paymentCard.map((value, index) => {
                      return(
                      <div className="mb-2" key={index}>
                        <div className="card_details__main_div">
                          <div className="card_number mb-2">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="cardID"
                                id={value.id}
                                // eslint-disable-next-line
                                checked={selectedCard == value.id}
                                onChange={handleOptionChange}
                              />
                              <label className="form-check-label" htmlFor={value.id}>
                                XXXX-XXXX-XXXX-
                                <span className="fw-bold card_last_four">
                                  {value.card_number}
                                </span>
                              </label>
                            </div>
                          </div>
                          {/* eslint-disable-next-line */}
                          {selectedCard == value.id && (
                            <div>
                              <button
                                className="qr_add_new_card"
                                onClick={() => handleSubmitCard()}
                                disabled={loading ? true : false}
                              >
                                {loading ? (
                                      <div
                                        className="spinner-border text-secondary"
                                        role="status"
                                      >
                                        <span className="visually-hidden">Loading...</span>
                                      </div>
                                    ) : (
                                      "CONFIRM"
                                    )}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                        )})}
                        </div>

            }
            <div>
                <form onSubmit={handleSubmit}  className={`col-md-9 ${ selectedCard === "" ? "d-block" : "d-none"}`}>
                {/* <form onSubmit={handleSubmit}  className="col-md-9"> */}
                  <div className="py-2">
                    <div className="row py-2">
                      <div className="col-md-4 pt-md-0 pt-3">
                        <label>Email</label>
                        <input
                          type="text"
                          className="bg-light form-control"
                          placeholder="name@example.com"
                          id="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onKeyUp={fetchUserDetail}
                        />
                        {errors.email && touched.email ? (
                          <p className="form-error">{errors.email}</p>
                        ) : null}
                      </div>
                      <div className="col-md-4">
                        <label>Card Holder First Name</label>
                        <input
                          type="text"
                          className="bg-light form-control"
                          placeholder="Haris"
                          id="card_holder_name"
                          name="card_holder_name"
                          value={values.card_holder_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.card_holder_name && touched.card_holder_name ? (
                          <p className="form-error">
                            {errors.card_holder_name}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-4 pt-md-0 pt-3">
                        <label>Last Name </label>
                        <input
                          type="text"
                          className="bg-light form-control"
                          placeholder="Umer"
                          id="card_holder_last_name"
                          name="card_holder_last_name"
                          value={values.card_holder_last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.card_holder_last_name &&
                        touched.card_holder_last_name ? (
                          <p className="form-error">
                            {errors.card_holder_last_name}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-6 pt-md-0 pt-3">
                        <label>Card Number</label>
                        <input
                          type="number"
                          className="bg-light form-control"
                          placeholder={12345678912345}
                          id="card_number"
                          name="card_number"
                          value={values.card_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.card_number && touched.card_number ? (
                          <p className="form-error">{errors.card_number}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="row py-2">
                      <div className="col-md-3">
                        <label>Expiration Month</label>
                        <select
                          className="form-select"
                          id="expiration_month"
                          name="expiration_month"
                          value={values.expiration_month}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option>Month</option>
                          <option value="01">Jan</option>
                          <option value="02">Feb</option>
                          <option value="03">Mar</option>
                          <option value="04">Apr</option>
                          <option value="05">May</option>
                          <option value="06">Jun</option>
                          <option value="07">Jul</option>
                          <option value="08">Aug</option>
                          <option value="09">Sep</option>
                          <option value="10">Oct</option>
                          <option value="11">Nov</option>
                          <option value="12">Dec</option>
                        </select>
                        {errors.expiration_month && touched.expiration_month ? (
                          <p className="form-error">
                            {errors.expiration_month}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-3 pt-md-0 pt-3">
                        <label>Expiration Year</label>
                        <select
                          className="form-select"
                          id="expiration_year"
                          name="expiration_year"
                          value={values.expiration_year}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option>Year</option>
                          {/* <option value={2022}>2022</option> */}
                          <option value={2023}>2023</option>
                          <option value={2024}>2024</option>
                          <option value={2025}>2025</option>
                          <option value={2026}>2026</option>
                          <option value={2027}>2027</option>
                          <option value={2028}>2028</option>
                          <option value={2029}>2029</option>
                          <option value={2030}>2030</option>
                        </select>
                        {errors.expiration_year && touched.expiration_year ? (
                          <p className="form-error">{errors.expiration_year}</p>
                        ) : null}
                      </div>
                      <div className="col-md-3 pt-md-0 pt-3">
                        <label>CVC</label>
                        <input
                          type="number"
                          className="bg-light form-control"
                          placeholder={123}
                          id="cvc"
                          name="cvc"
                          value={values.cvc}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.cvc && touched.cvc ? (
                          <p className="form-error">{errors.cvc}</p>
                        ) : null}
                      </div>
                      <div className="col-md-3 pt-md-0 pt-3">
                        <label htmlFor="zipcode">Zipcode</label>
                        <input
                          type="number"
                          className="bg-light form-control"
                          placeholder={12345}
                          id="zipcode"
                          name="zipcode"
                          value={values.zipcode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.zipcode && touched.zipcode ? (
                          <p className="form-error">{errors.zipcode}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="row py-2">
                      <div className="col-md-6">
                        <label>State</label>
                        {/* <input
                          type="text"
                          className="bg-light form-control"
                          placeholder=""
                          id="state"
                          name="state"
                          value={values.state}
                          // onChange={handleChange}
                          // onBlur={handleBlur}
                        /> */}
                        <select className="form-select" value={selectState}  disabled>

                            {selectState === undefined ? <option value={0} >Select State</option>:

                            <option value={selectState} >{selectState}</option>
                            }

                            </select>
                        {errors.state && touched.state ? (
                          <p className="form-error">{errors.state}</p>
                        ) : null}
                      </div>
                      <div className="col-md-6 pt-md-0 pt-3">
                        <label>City</label>
                        {/* <input
                          type="text"
                          className="bg-light form-control"
                          placeholder="Karachi"
                          id="city"
                          name="city"
                          value={selectCity}
                          // onChange={handleChange}
                          // onBlur={handleBlur}
                          disabled
                        /> */}
                          <select className="form-select"  value={selectCity}  disabled>

                          {selectCity === undefined ? <option value={0} >select City</option>:

                          <option value={selectCity} >{selectCity}</option>
                          }

                        </select>
                        {/* {errors.city && touched.city ? (
                          <p className="form-error">{errors.city}</p>
                        ) : null} */}
                      </div>
                    </div>
                    <div className="row py-2">
                      <div className="col-md-6">
                        <label htmlFor="state">Phone Number</label>
                        <input
                          type="number"
                          className="bg-light form-control"
                          placeholder=""
                          id="phone_number"
                          name="phone_number"
                          value={values.phone_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.phone_number && touched.phone_number ? (
                          <p className="form-error">{errors.phone_number}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="row py-2">
                      <div className="col-md-12">
                        <label>Billing Address</label>
                        <input
                          type="text"
                          className="bg-light form-control"
                          placeholder="Enter Billing Address"
                          id="billing_details"
                          name="billing_details"
                          value={values.billing_details}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.billing_details && touched.billing_details ? (
                          <p className="form-error">{errors.billing_details}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-check form-switch my-3">
                    <label className="form-check-label" >Your shipping address same as above?</label>
                    <input className="form-check-input" type="checkbox"   onClick={() => setIsChecked(!isChecked)}/>
                    </div>

                    {isChecked===false && 
                    <div className="row py-2">
                    <div className="col-md-12">
                      <label>Shipping Address</label>
                      <input
                        type="text"
                        className="bg-light form-control"
                        placeholder="Enter Shipping Address"
                        id="shipping_details"
                        name="shipping_details"
                        value={values.shipping_details}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      {/* {errors.billing_details && touched.billing_details ? (
                        <p className="form-error">{errors.billing_details}</p>
                      ) : null} */}
                    </div>
                  </div>
                    }


                    <div className="py-3 pb-4">
                      <button
                        type="submit"
                        className="btn check_out_btn mr-3"
                        disabled={loading ? true : false}
                      >
                        {loading ? (
                          <div
                            className="spinner-border text-secondary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          "Confirm"
                        )}
                      </button>
                    </div>
                  </div>
                  <div >

                    </div>
                </form>
            </div>
            {/* -----tabs-end---- */}
          </div>
        </div>
        {/* -------Cart-Start--------- */}
        <div className="col-md-3">
          <div className="cart__sec px-3 pb-4" id="myDIV">
            <h6 className="mb-3 pt-3">Final items</h6>
            {cart.cartItems.length === 0 && (
              <p>
                <p>Your cart is currently empty</p>
              </p>
            )}
        {cart?.cartItems.map((items, index) => {
            const totalSauceRedux = items.extrasaucesArray.reduce((totalQuantity, val) => totalQuantity + val.quantity * items.cartQuantity, 0)
                                  // ---Calculate-Quantity-Start---
          const newQuantity = items.finalSauceQuantity;
          const drinkPrice =
            items && items?.selectedSoftDrinks?.price == null
              ? 0
              : parseFloat(items && items?.selectedSoftDrinks?.price);

          const totalSaucePrice = items.extrasaucesArray.reduce(
            (accumulator, currentItem) => {
              return parseFloat(currentItem.price) * newQuantity;
            },
            0
          );
          let totalSidePrice = items?.sidesArray?.reduce(
            (accumulator, currentItem) => {
              return parseFloat(currentItem.price) + accumulator;
            },
            0
          );
          const totalSidePrices =
            totalSidePrice == undefined ? 0 : totalSidePrice;
          let FriesResultsUpd =
            items.FriesResult == undefined ? 0 : items.FriesResult;
          let pricing =
            items.discount_price === null ? items.price : items.discount_price;
          pricing =
            pricing === null ? items.selectedSubproduct?.price : pricing;
          let toppingPrice =
            items.toppingsArray.reduce(
              (total, currentValue) =>
                (total =
                  total + parseFloat(currentValue.price) * items.cartQuantity),
              0
            ) +
            totalSaucePrice * items.cartQuantity +
            totalSidePrices * items.cartQuantity +
            FriesResultsUpd +
            drinkPrice +
            pricing * items.cartQuantity;
          console.log(toppingPrice, "toppingPrice");
          // ---Calculate-Quantity-End---
            return (
              <div className="card__items   mb-2" key={index}>
                <div className="d-flex justify-content-between">
                  {/* {items.main_cat_id === "2"} */}
                  <p>
                    {items.cartQuantity} x {items.prod_name} {items.name}
                    {items.main_cat_id === "1"
                      ? items.is_sub_avaiable == 0
                        ? null
                        : ` (${items.selectedSubproduct?.name})`
                      : items.selectedSubproduct?.name &&
                        ` (${items.selectedSubproduct.name})`}
                  </p>

                  <p className='ms-2'>
                    {/* {items.discount_price === null && (
                    <span>
                      {items.main_cat_id === "2" ? "" : `$${items.price}`}
                    </span>
                  )}
                  {items.discount_price !== null && (
                    <span>
                      {items.main_cat_id === "2"
                        ? ""
                        : `$${items.discount_price}`}
                    </span>
                  )} */}

                    {items.main_cat_id == 2 && <span className="text-nowrap">$ {items.price} </span>}
                    {items.main_cat_id == 1 && (
                      <span className="text-nowrap">
                        ${" "}
                        {items.is_sub_avaiable == 0
                          ? items.price
                          : items.selectedSubproduct?.price}
                      </span>
                    )}


                  </p>
                </div>
                <p>
                  <b>
                    {items.toppingsArray.length > 0 && items.main_cat_id === "1"
                      ? "Add Onns"
                      : ""}
                  </b>
                  {/* <b>
                  {items.toppingsArray.length > 0 && items.main_cat_id === "2"
                    ? "Varients"
                    : ""}
                </b> */}
                </p>
                {/* <p>
                    {items.main_cat_id === "1"
                      ? items.is_sub_avaiable == 0
                        ? null
                        : ` (${items.selectedFriesType?.type})`
                      : items.selectedFriesType?.type &&
                        ` (${items.selectedFriesType.type})`}
                  </p> */}
                {items.main_cat_id == 1 && items.is_sub_avaiable == 1 && Object.keys(items.selectedFriesType).length >0  &&

                (
                  <div
                    style={{ fontSize: "12px" }}
                    className="d-flex justify-content-between"
                  >
                    <div>
                      {items.selectedFriesType?.type} (
                      {items.selectedFriesSize?.name})
                    </div>
                    <div> {items.FriesResult==0?"INCLUDED":`$ ${(items.FriesResult).toFixed(2)}`}</div>

                  </div>
                )}
                {items && items.main_cat_id == 1 && items.is_sub_avaiable == 1 && Object.keys(items?.selectedFountainDrinks)?.length >0 &&  (
                  <div
                    style={{ fontSize: "12px" }}
                    className="d-flex justify-content-between"
                  >
                    <div>
                      {items?.selectedFountainDrinks?.name} (
                      {items?.selectedSoftDrinks?.name})
                    </div>
                    <div>{items?.selectedSoftDrinks?.price==null?"INCLUDED": `$ ${items?.selectedSoftDrinks?.price}`}</div>

                  </div>
                )}

                {items.main_cat_id == 1 &&
                items?.cheesesArray.length > 0 &&
                items.is_sub_avaiable == 1 ? (
                  <ul>
                    <h6>Cheeses</h6>
                    {items.cheesesArray.map((val, index) => {
                      return <li key={index}>{val.type} </li>;
                    })}
                  </ul>
                ) : null}

                {items.main_cat_id == 1 &&
                items?.toppsecondArray?.length > 0 &&
                items.is_sub_avaiable == 1 ? (
                  <ul className="mt-2">
                    <h6>Toppings</h6>
                    {items?.toppsecondArray?.map((val, index) => {
                      return <li key={index}>{val.type} </li>;
                    })}
                  </ul>
                ) : null}

{items.main_cat_id == 1 &&
                items?.sidesArray.length > 0 &&
                items.is_sub_avaiable == 1 ? (
                  <ul className="mt-2">
                    <h6>Sides</h6>
                    {items?.sidesArray.map((val, index) => {
                    return (
                      <li key={index}>
                        {val.type}{" "}
                        <span className="float-end">
                          $ {val.price} x {items.cartQuantity}
                        </span>
                      </li>
                    );
                    })}
                  </ul>
                ) : null}

                {items.main_cat_id == 1 &&
                items?.cookingSauceArray.length > 0 &&
                items.is_sub_avaiable == 1 ? (
                  <ul className="mt-2">
                    <h6>Sauces</h6>
                    {items.cookingSauceArray.map((val, index) => {
                      return <li key={index}>{val.type} </li>;
                    })}
                  </ul>
                ) : null}

                {items.main_cat_id == 1 &&
                items?.extrasaucesArray.length > 0 &&
                items.is_sub_avaiable == 1 ? (
                  <ul className="mt-2">
                    {items.extrasaucesArray.length > 0 && (
                      <div>
                        <h6>Dip Sauces</h6>
                        <p className="exclude_sauces my-1">{`Exclude ${
                          totalSauceRedux - items.finalSauceQuantity
                        } ${
                          totalSauceRedux - items.finalSauceQuantity > 1
                            ? "Sauces"
                            : "Sauce"
                        } Price`}</p>
                      </div>
                    )}
                    {items.extrasaucesArray.map((val, index) => {
                      return (
                        <li key={index}>
                          <div className="d-flex justify-content-between">
                            <p>
                              {val.quantity * items.cartQuantity} x {val.name}
                            </p>
                            <p>$ {val.price}</p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                ) : null}

                <ul className="mt-2">
                  {items.toppingsArray.map((val, index) => {
                    return (
                      <li key={index}>
                        {val.type}{" "}
                        <span className="float-end">
                          ${val.price} x {items.cartQuantity}
                        </span>
                      </li>
                    );
                  })}
                </ul>
                <div>
                <div className="d-flex justify-content-between itemsTax">
                  <p>Tax:</p>
                  <p>
                    ${" "}
                    {(
                      (toppingPrice.toFixed(2) / 100) *
                      items.tax_percentage
                    ).toFixed(2)}
                  </p>
                </div>
                <div className="d-flex justify-content-between itemsSubTotal">
                  <p>Total:</p>
                  <p>$ {toppingPrice.toFixed(2)}</p>
                </div>

                {/* <p>{items.tax_percentage}</p> */}
              </div>

              </div>
            );
          })}

            <div className="amount__total">
              <div className="d-flex justify-content-between">
                <p>Sub total</p>
                <p>$ {cart.cartSubTotalAmount}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p>Tax</p>
                <p className="discount__price">$ {cart.cartTotalTax}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p>Delivery Charges</p>
                <p className="discount__price">$ 2.00</p>
              </div>
              <div className="d-flex justify-content-between mt-3">
                <p>Total</p>
                <p>$ {TotalAmount}</p>
              </div>
            </div>
          </div>
        </div>
        {/* -------Cart-end--------- */}
      </div>

    </section>
 <DeliveryChargesModal toggle={toggle} modal={modal}/>
 {/* <CardDeleteModal
        toggle={toggleM}
        modal={modal_card}
        deleteID={deleteID}
        fetchPaymentCart={fetchPaymentCart}
      /> */}
 </>
  );
};

export default DeliveryCheckout;
