// import React, { useEffect, useState } from 'react'
// import makFuelMenu from '../assets/images/makFuelMenu.png'
// import burgerSlide1 from '../assets/images/burderSlide1.jpg'
// import burgerSlide2 from '../assets/images/burderSlide2.jpg'
// import axios from 'axios'
// import { baseURL } from '../config/apiUrl'

// const Menu = () => {
//   const [menuImages, setMenuImages] = useState([])
//   const fetchMenuImages = async () => {
//     try {
//       const response = await axios.get(`${baseURL}/menuToShow`);
//       const { data } = response.data;
//       setMenuImages(data);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     fetchMenuImages()
//   }, [])
  
//   return (
//     <>
//     <div>
//     <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">
//         <div className="carousel-inner">
//           <div className="carousel-item active">
//             <img src={makFuelMenu} className="d-block w-100 slide__image_style" alt="..." />
//           </div>
//           <div className="carousel-item">
//             <img src={burgerSlide2} className="d-block w-100 slide__image_style" alt="..." />
//           </div>
//           <div className="carousel-item">
//             <img src={burgerSlide1} className="d-block w-100 slide__image_style" alt="..." />
//           </div>
//         </div>
//       </div>
//     </div>
//     </>
//   )
// }

// export default Menu
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseURL } from '../config/apiUrl';
import { Spinner } from 'reactstrap';
import Slider from 'react-slick';

const Menu = () => {
  const [menuImages, setMenuImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchMenuImages = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseURL}/menuToShow`);
      const { data } = response.data;
      setMenuImages(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchMenuImages();
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    prevArrow: null,
    nextArrow: null,
    pauseOnHover: false,
  };

  if (loading) {
    return (
      <div className="main_spinner_divv">
        <Spinner color="warning" type="grow" />
      </div>
    );
  }

  return (
    <div className="menu_slide_wrapper">
      <div className="fullpage-slider">
        <Slider {...settings}>
          {menuImages &&
            menuImages.map((value, index) => (
              <div className="slide-container" key={index}>
                <img
                  width="100%"
                  height="auto"
                  src={value.image_file}
                  className="slide__image"
                  alt={`Slide ${index + 1}`}
                />
              </div>
            ))}
        </Slider>
      </div>
    </div>
  );
};

export default Menu;
