import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import success from "../assets/images/success.png";
import { clearCartHome } from "../redux/features/slices/cartSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import FproductImg from "../assets/images/food_product.png";
import GproductImg from "../assets/images/grocery_product.jpg";
import { Helmet } from "react-helmet-async";

const OrderConfirmation = () => {
  const { cartItems } = useSelector((state) => state.cart);
  const cart = useSelector((state) => state.cart);
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const backHome = () => {
    dispatch(clearCartHome());
    navigate("/");
  };
  useEffect(() => {
    const handleDisableBackButton = () => {
      window.history.pushState(null, "", window.location.href);
      backHome();
    };

    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handleDisableBackButton);

    return () => {
      window.removeEventListener("popstate", handleDisableBackButton);
    };
    // eslint-disable-next-line
  }, []);

  let TotalAmount = state?.deliveryCharges
    ? (
        parseFloat(cart.cartTotalAmount) + parseFloat(state?.deliveryCharges)
      ).toFixed(2)
    : cart.cartTotalAmount;

  return (
    <>
      <Helmet>
        <title>Makfuels | Thank You</title>
        <link rel="canonical" href="https://makcom.cf/order-confirmation" />
      </Helmet>
      <section className="container-fluid px-5 pt-4 mt-2">
        <div className="text-center">
          <div className="d-flex justify-content-center align-items-center">
            <h1 className="text-success your_order_is__ mb-0">
              Your order is confirmed
            </h1>
            <LazyLoadImage
            loading='lazy'
              height={50}
              width={50}
              className="confirmed__img ms-2"
              src={success}
              alt="confirmed img"
            />
          </div>
          <h2 style={{ fontSize: "1.3rem" }}>OrderID # {state.orderID}</h2>
        </div>
        <div className="col-lg-6 m-auto mt-2">
          <div className="thanYou_tbl table-responsive">
            <table className="table table-striped m-0">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Items</th>
                  <th scope="col">Addons</th>
                  <th scope="col" className="text-center">
                    Price
                  </th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((items, index) => {
                  const totalSauceRedux = items.extrasaucesArray.reduce(
                    (totalQuantity, val) =>
                      totalQuantity + val.quantity * items.cartQuantity,
                    0
                  );
                  const newQuantity = items.finalSauceQuantity;
                  const drinkPrice =
                    items && items?.selectedSoftDrinks?.price == null
                      ? 0
                      : parseFloat(items && items?.selectedSoftDrinks?.price);
                  const totalSaucePrice = items.extrasaucesArray.reduce(
                    (accumulator, currentItem) => {
                      return parseFloat(currentItem.price) * newQuantity;
                    },
                    0
                  );
                  const totalSidePrice = items.sidesArray.reduce(
                    (total, currentValue, i) => {
                      if (items?.sidesQuantity > i) {
                        return total;
                      } else {
                        return (total = total + parseFloat(currentValue.price));
                      }
                    },
                    0
                  );
                  const totalSidePrices =
                    totalSidePrice == undefined ? 0 : totalSidePrice;
                  let FriesResultsUpd =
                    items.FriesResult == undefined ? 0 : items.FriesResult;
                  let pricing =
                    items.discount_price === null
                      ? items.price
                      : items.discount_price;
                  pricing =
                    pricing === null
                      ? items.selectedSubproduct?.price
                      : pricing;
                  let toppingPrice =
                    items.toppingsArray.reduce(
                      (total, currentValue) =>
                        (total =
                          total +
                          parseFloat(currentValue.price) * items.cartQuantity),
                      0
                    ) +
                    totalSaucePrice * items.cartQuantity +
                    totalSidePrices * items.cartQuantity +
                    FriesResultsUpd +
                    drinkPrice +
                    pricing * items.cartQuantity;

                  return (
                    <tr key={index}>
                      <td>
                        <LazyLoadImage
                          height={50}
                          width={60}
                          loading='lazy'
                          className="thank_pro_img"
                          src={items.image}
                          alt={`${items.name}`}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src =
                              // eslint-disable-next-line
                              items.main_cat_id == "1"
                                ? FproductImg
                                : GproductImg;
                          }}
                        />
                      </td>
                      <td>
                        {items.cartQuantity} x{" "}
                        {items.main_cat_id == 1
                          ? items.name
                          : items.prod_name + " " + items.name}
                        {/* <p>Add Ons</p> */}
                      </td>
                      <td>
                          <br />
                        {items.main_cat_id == 1 &&
                          items.is_sub_avaiable == 1 &&
                          Object.keys(items.selectedFriesType).length > 0 && (
                            <div
                              style={{ fontSize: "11px" }}
                              className="d-flex justify-content-between"
                            >
                              <span className="w-50">
                                {items.selectedFriesType?.type} (
                                {items.selectedFriesSize?.name})
                              </span>
                              <span className="w-50 text-end">
                                ${" "}
                                {items.FriesResult == 0
                                  ? "Free"
                                  : items.FriesResult.toFixed(2)}
                              </span>
                            </div>
                          )}
                        {items &&
                          items.main_cat_id == 1 &&
                          items.is_sub_avaiable == 1 &&
                          Object.keys(items?.selectedFountainDrinks)?.length >
                            0 && (
                            <div
                              style={{ fontSize: "11px" }}
                              className="d-flex justify-content-between"
                            >
                              <span className="w-50">
                                {items?.selectedFountainDrinks?.name} (
                                {items?.selectedSoftDrinks?.name})
                              </span>
                              <span className="w-50 text-end">
                                ${" "}
                                {items?.selectedSoftDrinks?.price == null
                                  ? "Free"
                                  : items?.selectedSoftDrinks?.price}
                              </span>
                            </div>
                          )}
                        {items.main_cat_id == 1 &&
                        items?.extrasaucesArray.length > 0 &&
                        items.is_sub_avaiable == 1 ? (
                          <ul
                            className="mt-2 p-0"
                            style={{
                              marginBottom: "0",
                              listStyle: "none",
                              fontSize: "10px",
                            }}
                          >
                            {items.extrasaucesArray.length > 0 && (
                              <div>
                                <h6>Dip Sauces</h6>
                                <p className="exclude_sauces my-1">{`Exclude ${
                                  totalSauceRedux - items.finalSauceQuantity
                                } ${
                                  totalSauceRedux - items.finalSauceQuantity > 1
                                    ? "Sauces"
                                    : "Sauce"
                                } Price`}</p>
                              </div>
                            )}
                            {items.extrasaucesArray.map((val, index) => {
                              return (
                                <li key={index}>
                                  <div className="d-flex justify-content-between">
                                    <p>
                                      {val.quantity * items.cartQuantity} x{" "}
                                      {val.name}
                                    </p>
                                    <p>$ {val.price}</p>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        ) : null}
                        {items.main_cat_id == 1 &&
                        items?.sidesArray?.length > 0 &&
                        items.is_sub_avaiable == 1 ? (
                          <div>
                            <h6>Sides</h6>
                           {(items.sidesQuantity >= 1)?
         
                           <p className="exclude_sauces my-1">{`Exclude ${
                                 items.sidesQuantity
                                } ${
                                  items.sidesQuantity > 1 
                                    ? "Sides"
                                    : "Side"
                                } Price`}</p>: ""}
                            <ul
                              style={{
                                marginBottom: "0",
                                listStyle: "none",
                                fontSize: "10px",
                              }}
                              className="px-1 pb-0"
                            >
                              {/* <b>
                    {items.cheesesArray.length === 0 && items.main_cat_id==="1" ?
                      "No addons" : ""}
                  </b> */}
                              {items?.sidesArray?.map((val, index) => {
                                return (
                                  //  <li key={index}><span>{val.type}</span><span className='cart__AdOns'>+${val.price}</span></li>
                                  <li key={index}>
                                    <div className="d-flex">
                                      <div className="w-100">{val.type}</div>
                                      <div className="cart__AdOns d-flex justify-content-end w-50">
                                        +${val.price}
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        ) : null}

                        {/* ----Cheeses-Fetch-Start----- */}
                        {items.main_cat_id == 1 &&
                        items?.cheesesArray?.length > 0 &&
                        items.is_sub_avaiable == 1 ? (
                          <div>
                            <p className="sub__head_fetch">Cheeses</p>
                            <ul
                              style={{
                                marginBottom: "0",
                                listStyle: "none",
                                fontSize: "10px",
                              }}
                              className="px-1 pb-0"
                            >
                              {/* <b>
                    {items.cheesesArray.length === 0 && items.main_cat_id==="1" ?
                      "No addons" : ""}
                  </b> */}
                              {items?.cheesesArray?.map((val, index) => {
                                return (
                                  //  <li key={index}><span>{val.type}</span><span className='cart__AdOns'>+${val.price}</span></li>
                                  <li key={index}>
                                    <div className="d-flex">
                                      <div className="w-100">{val.type}</div>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        ) : null}

                        {/* ----Cheeses-Fetch-end----- */}
                        {/* ----toppsecondArray-Fetch-Start----- */}
                        {items.main_cat_id == 1 &&
                        items?.cheesesArray?.length > 0 &&
                        items.is_sub_avaiable == 1 ? (
                          <div>
                            <p className="sub__head_fetch">Toppings</p>
                            <ul
                              style={{
                                marginBottom: "0",
                                listStyle: "none",
                                fontSize: "10px",
                              }}
                              className="px-1 pb-0"
                            >
                              {/* <b>
                    {items.cheesesArray.length === 0 && items.main_cat_id==="1" ?
                      "No addons" : ""}
                  </b> */}
                              {items?.toppsecondArray?.map((val, index) => {
                                return (
                                  //  <li key={index}><span>{val.type}</span><span className='cart__AdOns'>+${val.price}</span></li>
                                  <li key={index}>
                                    <div className="d-flex">
                                      <div className="w-100">{val.type}</div>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        ) : null}

                        {/* ----toppsecondArray-Fetch-end----- */}

                        {/* ----cookingSauceArray-Fetch-Start----- */}
                        {items.main_cat_id == 1 &&
                        items?.cheesesArray?.length > 0 &&
                        items.is_sub_avaiable == 1 ? (
                          <div>
                            <p className="sub__head_fetch">Sauces</p>
                            <ul
                              style={{
                                marginBottom: "0",
                                listStyle: "none",
                                fontSize: "10px",
                              }}
                              className="px-1 pb-0"
                            >
                              {/* <b>
                    {items.cheesesArray.length === 0 && items.main_cat_id==="1" ?
                      "No addons" : ""}
                  </b> */}
                              {items?.cookingSauceArray?.map((val, index) => {
                                return (
                                  //  <li key={index}><span>{val.type}</span><span className='cart__AdOns'>+${val.price}</span></li>
                                  <li key={index}>
                                    <div className="d-flex">
                                      <div className="w-100">{val.type}</div>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        ) : null}

                        {/* ----cookingSauceArray-Fetch-end----- */}

                        {/* ----extrasaucesArray-Fetch-Start----- */}
                        {items.main_cat_id == 1 &&
                        items?.cheesesArray?.length > 0 &&
                        items.is_sub_avaiable == 1 ? (
                          <div>
                            <p className="sub__head_fetch">Dip Sauces</p>
                            <ul
                              style={{
                                marginBottom: "0",
                                listStyle: "none",
                                fontSize: "10px",
                              }}
                              className="px-1 pb-0"
                            >
                              {items?.extrasaucesArray?.map((val, index) => {
                                return (
                                  //  <li key={index}><span>{val.type}</span><span className='cart__AdOns'>+${val.price}</span></li>
                                  <li key={index}>
                                    <div className="d-flex">
                                      <div className="w-100">
                                        {val.quantity} x {val.name}
                                      </div>
                                      <div className="cart__AdOns d-flex justify-content-end w-50">
                                        +${val.price}
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        ) : null}
                        <ul className="add__Ons_Sty p-0 mb-0">
                          <b>
                            {items.toppingsArray.length > 0 ? "Addons" : ""}
                          </b>
                          {items.toppingsArray.map((val, index) => {
                            return (
                              <li key={index}>
                                {val.type}
                                <span className="ms-2 fw-bold" />
                                {/* <span className="float-end">${val.price}.00 x  {items.cartQuantity}</span> */}
                              </li>
                            );
                          })}
                        </ul>
                        <div>
                          {/* <div className="d-flex  itemsTax">
                  <p className="me-3">Tax:</p>
                  <p>
                    ${" "}
                    {(
                      (toppingPrice.toFixed(2) / 100) *
                      items.tax_percentage
                    ).toFixed(2)}
                  </p>
                </div> */}

                          {/* <p>{items.tax_percentage}</p> */}
                        </div>
                      </td>
                      {/* <td> ${items.price*items.cartQuantity}.00</td> */}
                      <td className="text-center">
                        {" "}
                        $ {items?.price}
                        <div className="d-flex  itemsTax text-center">
                          <p style={{ width: "max-content" }}>
                            Item Tax: ${" "}
                            {(
                              (toppingPrice.toFixed(2) / 100) *
                              items.tax_percentage
                            ).toFixed(2)}
                          </p>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-lg-6 m-auto">
          <div className="p-3 amount__carts">
            <div className="d-flex justify-content-between mb-2">
              <p>Subtotal</p>
              <p>$ {cart.cartSubTotalAmount}</p>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <p>Tax</p>
              <p>$ {cart.cartTotalTax}</p>
            </div>
            {state?.deliveryCharges && (
              <div className="d-flex justify-content-between">
                <p>Delivery Charges</p>
                <p className="discount__price">$ 2.00</p>
              </div>
            )}
            <div className="d-flex justify-content-between mb-2">
              <p className="fw-bold">Total</p>
              <p className="fw-bold">$ {TotalAmount} </p>
            </div>
          </div>
        </div>
        <div className="py-3 pb-4 text-center ">
          <button className="btn check_out_btn mr-3" onClick={backHome}>
            Back to home
          </button>
        </div>
      </section>
    </>
  );
};

export default OrderConfirmation;
