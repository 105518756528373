import moment from "moment";
import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const VerifyAgeModal = ({ handleToggle, modal ,setState }) => {
  const handleConfirm = () =>{
    setState("yes")
    handleToggle()
  }


let date = new Date(new Date().setFullYear(new Date().getFullYear() - 21));
let getDate=moment(date ).format('MMM DD YYYY')
// const handleCallback = () => callback(state)
  return (
    <>

      <Modal className="product_detals_modal" isOpen={modal} toggle={handleToggle} centered={true}>
        <ModalHeader toggle={handleToggle}>Verify Your Age <i className="fa-solid fa-circle-exclamation text-danger"></i></ModalHeader>
        <ModalBody>
          <div className="age__21 col-10 m-auto">
          <p>
          Please verify that you are 21+ years old
          {/* as per State Law to buy this
          item, please agree with our policy link. */}
          </p>
          </div>

          <div className="my-2">
            <div className="form-text text-danger text-center"><span className="note__high"> Please confirm you are born before <b><u>{getDate}</u></b></span></div>
          </div>
          <h6 className="text-center"><b>Note: </b> <span className="text-danger">Proof of ID is required at the time of recieving your item or at the time of delievery.</span></h6>
        </ModalBody>
        <ModalFooter>
          <button className="btn check_out_btn" onClick={()=>handleConfirm()}>Agree</button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default VerifyAgeModal;
